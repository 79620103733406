import React, { createRef } from "react";
import {
    Card,
    Table,
    Button,
    DatePicker,
} from "antd";
import moment from "moment";

import { aiApi } from "../../../../api";
import { PAGE_SIZE } from "../../../../utils/constant";
const {
    reqUsageStatistics,
} = aiApi;


export default class Home extends React.Component {
    state = {
        data: [],
        date: moment(),
    };

    listObj = {};

    getList = async (params = {}) => {
        this.setState({
            loading: true,
        })
        const response = await reqUsageStatistics(params);
        if (response?.data) {
            let arrObj = {};
            response.data.msg.forEach(value => {
                if (value?.creator) {
                    if (arrObj[value.creator.id]) {
                        arrObj[value.creator.id][moment(value.date).date()] = value.used;
                        arrObj[value.creator.id].month += value.used;
                    } else {
                        arrObj[value.creator.id] = {
                            key: value.creator.id,
                            name: value.creator.name,
                            [moment(value.date).date()]: value.used,
                            month: value.used,
                        }
                    }
                }
            });
            let arr = [];
            arr = Object.keys(arrObj).map(value => arrObj[value]);
            this.setState({
                data: arr,
                loading: false,
            })
        }
    }

    handleDateChange = (date) => {
        this.setState({
            date,
        }, () => {
            this.getList({
                date: moment(this.state.date).startOf("month").format("YYYY-MM-DD") + "," + moment(this.state.date).endOf("month").format("YYYY-MM-DD")
            });
        });
    }

    async componentDidMount() {
        this.getList({
            date: moment(this.state.date).startOf("month").format("YYYY-MM-DD") + "," + moment(this.state.date).endOf("month").format("YYYY-MM-DD")
        });
    }

    render() {

        const columns = [
            {
                title: "姓名",
                dataIndex: "name",
                key: "name",
                render: text => text || "-",
            },
            {
                title: "月使用量",
                dataIndex: "month",
                key: "month",
                render: text => (text || 0) + "次",
            },
            ...(() => {
                let arr = [];
                if (moment(this.state.date).startOf("month").valueOf() === moment().startOf("month").valueOf()) {
                    for (let i = 0; i < moment().date(); i++) {
                        arr.push({ title: i + 1 + "日", dataIndex: i + 1 + "", key: i + 1 + "", render: text => (text || 0) + "次" })
                    }
                } else {
                    for (let i = 0; i < moment(this.state.date).endOf("month").date(); i++) {
                        arr.push({ title: i + 1 + "日", dataIndex: i + 1 + "", key: i + 1 + "", render: text => (text || 0) + "次" })
                    }
                }
                return arr;
            })()
        ]

        return (
            <>
                <Button
                    type="link"
                    icon="left"
                    onClick={() => {
                        this.props.history.goBack();
                    }}
                >返回</Button>
                <Card
                    title="用量统计列表"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                >
                    <div className="table-operations" style={{ marginBottom: 24 }}>
                        <DatePicker.MonthPicker
                            value={this.state.date}
                            disabledDate={
                                current => (moment(current).startOf("month").valueOf() > moment().startOf("month").valueOf()) || (moment(current).startOf("month").valueOf() < moment("2023-02-01").startOf("month").valueOf())
                            }
                            onChange={this.handleDateChange} />
                    </div>
                    <Table
                        className="small-table-style"
                        columns={columns}
                        dataSource={this.state.data}
                        pagination={false}
                        loading={this.state.loading}
                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                        size={'small'}
                    />
                </Card>
            </>
        );
    }
}