import React from "react";
import {
    Spin,
    Card,
    Table,
    Descriptions,
    Button,
} from "antd";

import {moaApi} from "../../../../../../api";
import LinkButton from "../../../../../crm/components/link-button";
import tools from "../../../../../../utils/tools";
import {
    MOBILE_OWNER
} from "../../../../../../utils/constant";

const {
    reqMobileDetail,
} = moaApi;

export default class MobileDetail extends React.Component {
    state = {
        loading: true,
        detail: {},
    };
    id = this.props.match.params.id;

    getMobileDetail = async (params = {}) => {
        const response = await reqMobileDetail(params);
        if(response && response.data) {
            this.setState({
                detail: response.data,
                loading: false
            });
        }
    };

    componentDidMount() {
        // 获取详情
        this.getMobileDetail({
            id: this.id
        });
    }

    render() {
        const {detail} = this.state;

        return this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <>
                <Button
                    type="link"
                    icon="left"
                    onClick={() => {
                        this.props.history.goBack();
                    }}
                >返回</Button>

                <Card
                    title="手机号信息"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                    extra={<LinkButton to={`/moa/account/mobile/edit/${this.id}`} txt="编辑" />}
                >
                    <Descriptions
                        style={{
                            marginBottom: 24,
                        }}
                        title="基本信息"
                    >
                        <Descriptions.Item label="开户时间">
                            {tools.unixToTime((new Date(detail.open_account_time)).getTime()/1000, true)}
                        </Descriptions.Item>
                        <Descriptions.Item label="手机号码">
                            {detail.bound_mobile}
                        </Descriptions.Item>
                        <Descriptions.Item label="绑定人">
                            {detail.bound_person.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="手机归属">
                            {MOBILE_OWNER[detail.bound_mobile_owner_type-1].text}
                        </Descriptions.Item>
                        <Descriptions.Item label="手机保管人">
                            {detail.bound_mobile_custodian.name}
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        title="创建信息"
                        style={{
                            marginBottom: 24,
                        }}
                    >
                        <Descriptions.Item label="创建人">
                            {detail.creator && detail.creator.name ? detail.creator.name : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="创建时间">
                            {detail.create_at
                                ? tools.unixToTime(new Date(detail.create_at).getTime() / 1000)
                                : '-'}
                        </Descriptions.Item>
                    </Descriptions>
                </Card>
            </>
        );
    }
}