import { ROLES } from "../../../utils/constant";
import { crmApi } from "../../../api";
import { GO_OUT_CHECK_OBJ } from "../../../utils/constant";

const {
    reqApplyJackList,
    reqApplyList,
    reqGoOutCEOList,
    reqGoOutCOOList,
    reqGoOutCTOList,
    reqGoOutASSList,
    reqGoOutInspecorList,
    reqGoOutManagerList,
    reqMarketingCostApplyList,
    reqNewDailyWorkApplyList,
    reqFileApplyList,
    reqNewPreSellerApplyList,
    reqQuotationCheckList,
} = crmApi;

const {
    ADMIN,
    SELLER,
    CTO,
    SERVICEADMIN,
    FINANCE,
    PRESELLER,
    HRBP,
    BUSINESS,
    HRBP_ASSISTANT,
    CRMADMIN,
    CLOCK,
    TECHNOLOGY,
    LAW,
    WEAPON,
    PRODUCT,
    SERVICE,
    OPERATING,
    OTHER_WEAPON,
    WIN_APPLYER,
    RETURN_ADMIN,
    INVOICE_SERVICE,
    INVOICE_ENTER,
    DEPARTMENT_ADMIN,
    SUPERVISE,
    PRESELLER_ADMIN,
    COST_CHANNEL_ADMIN,
} = ROLES;

const menuList = (memory) => {
    const isJack = !!memory.user?.roles?.find(o => o.type === ROLES.JACK_PROJECT);
    const isAdmin = !!memory.user?.roles?.find(o => o.type === ROLES.ADMIN);
    return [
        {
            title: "首页",
            key: "/crm",
            icon: "radar-chart",
        },
        /* {
          title: "奖金赛",
          key: "/arena",
          icon: "trophy",
          role: [CRMADMIN, SELLER],
        }, */
        {
            title: "客户公海",
            key: "/crm/client/list",
            icon: "contacts",
            role: [CRMADMIN, SELLER, CLOCK, PRESELLER, HRBP, HRBP_ASSISTANT],
        },
        {
            title: "KP管理",
            key: "/crm/client/kp/list",
            icon: "contacts",
            role: [SELLER, BUSINESS, ROLES.MANAGER],
        },
        {
            title: "项目列表",
            key: "/crm/admin/business/list",
            icon: "table",
        },
        {
            title: "回款管理",
            key: "/crm/admin/returned-apply/list",
            icon: "table",
        },
        {
            title: "客户信息",
            key: "/crm/client/detail",
            icon: "smill",
            hide: true,
        },
        {
            title: "修改客户信息",
            key: "/crm/client/edit",
            icon: "smill",
            hide: true,
        },
        {
            title: "添加KP",
            key: "/crm/client/kpadd",
            icon: "smill",
            hide: true,
        },
        {
            title: "KP信息",
            key: "/crm/client/kpdetail",
            icon: "smill",
            hide: true,
        },
        {
            title: "修改KP信息",
            key: "/crm/client/kpedit",
            icon: "smill",
            hide: true,
        },
        {
            title: "添加项目",
            key: "/crm/client/business-add",
            icon: "smill",
            hide: true,
        },
        {
            title: "项目信息",
            key: "/crm/client/business-detail",
            icon: "smill",
            hide: true,
        },
        {
            title: "修改项目",
            key: "/crm/client/business-edit",
            icon: "smill",
            hide: true,
        },
        {
            title: "合同申请",
            key: "/crm/client/contract-add",
            icon: "smill",
            hide: true,
        },
        {
            title: "修改合同信息",
            key: "/crm/client/contract-edit",
            icon: "smill",
            hide: true,
        },
        /*{
              title: '项目经理列表',
              key: '/crm/pre-seller/list',
              icon: 'contacts',
              role: [CRMADMIN, SELLER],
          },*/
        {
            title: "考勤统计",
            key: "/crm/attendance",
            icon: "table",
        },
        {
            title: "薪资结算",
            key: "/crm/compensation",
            icon: "table",
            role: [ADMIN,HRBP,HRBP_ASSISTANT,RETURN_ADMIN,ROLES.ROYALTY],
        },
        {
            title: "销售工作",
            key: "/crm/salesman",
            icon: "project",
            role: [CRMADMIN, SELLER, CLOCK],
            children: [
                {
                    title: "我的客户",
                    key: "/crm/salesman/list",
                    icon: "table",
                    role: [CRMADMIN, SELLER],
                },
                {
                    title: "我的外出申请",
                    key: "/crm/salesman/my-go-out/list",
                    icon: "table",
                    role: [SELLER],
                },
                {
                    title: "我的外出申请",
                    key: "/crm/salesman/my-go-out/detail",
                    hide: true
                },
                {
                    title: "我的打卡",
                    key: "/crm/salesman/my-log",
                    icon: "table",
                },
                {
                    title: "我的合同申请",
                    key: "/crm/salesman/my-contract/list",
                    icon: "table",
                    role: [CRMADMIN, SELLER],
                },
                {
                    title: "我的发票申请",
                    key: "/crm/salesman/my-invoice/list",
                    icon: "table",
                    role: [CRMADMIN, SELLER],
                },
                {
                    title: "我的成本审批",
                    key: "/crm/salesman/my-cost/list",
                    icon: "table",
                    role: [CRMADMIN, SELLER],
                },
                {
                    title: "我的额外费用申请",
                    key: "/crm/salesman/my-marketCost/list",
                    icon: "table",
                    role: [CRMADMIN, SELLER],
                },
                {
                    title: "我的报价单审批",
                    key: "/crm/salesman/quotation/list",
                    icon: "table",
                    role: [CRMADMIN, ROLES.PRESELLER],
                },
                {
                    title: "日志详情",
                    key: "/crm/salesman/log-detail",
                    icon: "smill",
                    hide: true,
                },
                {
                    title: "编辑工作日志",
                    key: "/crm/salesman/log-edit",
                    icon: "smill",
                    hide: true,
                },
                {
                    title: "工作日报",
                    key: "/crm/salesman/daily-log",
                    icon: "table",
                    // hide: true,
                },
                {
                    title: "工作周报",
                    key: "/crm/salesman/week-log",
                    icon: "table",
                    hide: true,
                },
                {
                    title: "我的合同文件申请",
                    key: "/crm/my/contract-file-apply/list",
                    icon: "contacts",
                },
                {
                    title: '我的项目经理申请',
                    key: '/crm/salesman/my-preseller/list',
                    icon: 'table',
                    role: [CRMADMIN, SELLER],
                },
                {
                    title: "我的小队",
                    key: "/crm/salesman/my-team",
                    icon: "table",
                    role: [CRMADMIN, SELLER],
                    hide: true,
                },
                {
                    title: "我的行为分",
                    key: "/crm/salesman/my-behavior/list",
                    icon: "table",
                    role: [CRMADMIN, SELLER],
                    hide: true,
                },
                /* {
                  title: "我的信誉分",
                  key: "/crm/salesman/my-credit/list",
                  icon: "table",
                  role: [CRMADMIN, SELLER],
                }, */
                // {
                //   title: "打卡故障申诉",
                //   key: "/crm/salesman/my-complaint/list",
                //   icon: "table",
                //   role: [CRMADMIN, SELLER],
                // },
                {
                    title: "武器库列表",
                    key: "/crm/salesman/weapon/list",
                    icon: "table",
                    role: [ADMIN, CRMADMIN, SELLER],
                },
            ],
        },
        {
            title: "审批管理",
            key: "/crm/approve",
            icon: "desktop",
            role: [
                ADMIN,
                CRMADMIN,
                BUSINESS,
                SERVICEADMIN,
                CTO,
                HRBP,
                TECHNOLOGY,
                LAW,
                PRODUCT,
                SERVICE,
                OPERATING,
                OTHER_WEAPON,
                WIN_APPLYER,
                RETURN_ADMIN,
                INVOICE_SERVICE,
                INVOICE_ENTER,
                DEPARTMENT_ADMIN,
                PRESELLER_ADMIN,
                COST_CHANNEL_ADMIN,
                ROLES.COST_FINAL_ADMIN,
                ROLES.FINANCE,
                ROLES.MARKET_ADMIN,
                ROLES.MARKET_BUSINESS,
                ROLES.MARKET_FINANCE,
                ROLES.INVOICE_CC,
                ROLES.JACK_PROJECT,
                ROLES.WEAPON_BUSINESS,
                ROLES.SPECIAL_APPROVER,
                ROLES.MARKETCOST_FINAL,
                ROLES.PM_DAILYWORK_APPROVER,
                ROLES.RIRECTOR
            ],
            children: [
                {
                    title: "销售日报批阅",
                    key: "/crm/approve/work-log-apply/list/seller",
                    icon: "edit",
                    count: {
                        type: "daily",
                        api: [reqNewDailyWorkApplyList],
                        params: [{
                            examined: false,
                            is_pm: false,
                            count_only: true,
                        }],
                    },
                    role: [ROLES.MANAGER, HRBP, ROLES.BUSINESS, ROLES.RIRECTOR],
                },
                {
                    title: "项目经理日报批阅",
                    key: "/crm/approve/work-log-apply/list/pm",
                    icon: "edit",
                    count: {
                        type: "pmDaily",
                        api: [reqNewDailyWorkApplyList],
                        params: [{
                            examined: false,
                            is_pm: true,
                            count_only: true,
                        }],
                    },
                    role: [ROLES.PM_DAILYWORK_APPROVER, HRBP],
                },
                {
                    title: "日报批阅",
                    key: "/crm/approve/work-log-apply/list/assistant",
                    icon: "edit",
                    count: {
                        type: "assistantDaily",
                        api: [reqNewDailyWorkApplyList],
                        params: [{
                            examined: false,
                            is_assistant: true,
                            count_only: true,
                        }],
                    },
                    role: [ROLES.ADMIN, HRBP],
                },
                {
                    title: "日报批阅",
                    key: "/crm/approve/work-log-apply/detail",
                    icon: "edit",
                    hide: true,
                },
                {
                    title: "成本审批",
                    key: "/crm/approve/cost-apply/list",
                    icon: "edit",
                    count: {
                        type: "cost",
                        api: [isAdmin || isJack ? reqApplyJackList : reqApplyList],
                        params: [{
                            type: 9,
                            is_history: false,
                            count_only: true,
                        }],
                    },
                    role: [
                        ADMIN,
                        CRMADMIN,
                        BUSINESS,
                        PRODUCT,
                        SERVICE,
                        OPERATING,
                        DEPARTMENT_ADMIN,
                        COST_CHANNEL_ADMIN,
                        ROLES.COST_FINAL_ADMIN,
                        ROLES.FINANCE,
                        ROLES.WEAPON_BUSINESS,
                        HRBP,
                        CTO,
                        ROLES.RIRECTOR
                    ],
                },
                {
                    title: "成本审批",
                    key: "/crm/approve/cost-apply/detail",
                    icon: "edit",
                    hide: true,
                },
                {
                    title: "出门卡审批(助理)",
                    key: "/crm/approve/go-out-apply/list",
                    icon: "edit",
                    count: {
                        type: "assistantGoOut",
                        api: [GO_OUT_CHECK_OBJ[memory.user?.dd_info?.name] === "ASS" ? reqGoOutASSList : (GO_OUT_CHECK_OBJ[memory.user?.dd_info?.name] === "CTO" ? reqGoOutCTOList : (GO_OUT_CHECK_OBJ[memory.user?.dd_info?.name] === "COO" ? reqGoOutCOOList : reqGoOutCEOList))],
                        params: [{
                            is_history: false,
                            count_only: true,
                        }],
                    },
                    role: [ROLES.SPECIAL_APPROVER, ROLES.GO_OUT_APPROVE],
                },
                {
                    title: "出门卡审批(销售)",
                    key: "/crm/approve/go-out-apply/list/seller",
                    icon: "edit",
                    count: {
                        type: "sellerGoOut",
                        api: [reqGoOutInspecorList],
                        params: [{
                            is_history: false,
                            count_only: true,
                        }],
                    },
                    role: [ROLES.SPECIAL_APPROVER, ROLES.BUSINESS, ROLES.RIRECTOR],
                },
                {
                    title: "出门卡审批(总监)",
                    key: "/crm/approve/go-out-apply/list/business",
                    icon: "edit",
                    role: [ROLES.SPECIAL_APPROVER, ROLES.DEPARTMENT_ADMIN],
                    count: {
                        type: "managerGoOut",
                        api: [reqGoOutManagerList],
                        params: [{
                            is_history: false,
                            count_only: true,
                        }],
                    },
                },
                /* {
                  title: "安服部审批",
                  key: "/crm/approve/service-apply/list",
                  icon: "edit",
                  role: [ADMIN, CRMADMIN, SERVICEADMIN, BUSINESS],
                },
                {
                  title: "安服部审批",
                  key: "/crm/approve/service-apply/detail",
                  icon: "edit",
                  hide: true,
                },
                */
                {
                    title: "合同文件审批",
                    key: "/crm/approve/contract-file-apply/list",
                    icon: "edit",
                    count: {
                        type: "contract-file",
                        api: [reqFileApplyList],
                        params: [{
                            examined: false,
                            count_only: true,
                        }],
                    },
                    role: [ROLES.CONTRACT_APPROVE],
                },
                {
                    title: "退跟审批",
                    key: "/crm/approve/seller-admin-apply/list",
                    icon: "edit",
                    count: {
                        type: "abandon",
                        api: [reqApplyList],
                        params: [{
                            is_history: false,
                            type: 1,
                            count_only: true,
                        }],
                    },
                    role: [ADMIN, CRMADMIN, BUSINESS, HRBP, ROLES.RIRECTOR],
                },
                {
                    title: "退跟/跟进审批",
                    key: "/crm/approve/seller-admin-apply/detail",
                    icon: "edit",
                    hide: true,
                },
                {
                    title: "赢单审批",
                    key: "/crm/approve/win-apply/list",
                    icon: "edit",
                    count: {
                        type: "winApply",
                        api: [isJack ? reqApplyJackList : reqApplyList],
                        params: [{
                            is_history: false,
                            type: 6,
                            count_only: true,
                        }],
                    },
                    role: [ADMIN, CRMADMIN, WIN_APPLYER, HRBP],
                },
                {
                    title: "赢单审批",
                    key: "/crm/approve/win-apply/detail",
                    icon: "edit",
                    hide: true,
                },
                {
                    title: "项目经理审批",
                    key: "/crm/pre-seller/apply/list",
                    icon: "edit",
                    role: [ADMIN, CRMADMIN, PRESELLER_ADMIN],
                    count: {
                        type: "preSeller",
                        api: [reqNewPreSellerApplyList],
                        params: [{
                            examined: false,
                            count_only: true,
                        }],
                    },
                },
                {
                    title: "项目经理审批",
                    key: "/crm/pre-seller/apply/detail",
                    icon: "edit",
                    hide: true,
                },
                {
                    title: "合同技术审批",
                    key: "/crm/approve/technology-approve/list",
                    icon: "edit",
                    count: {
                        type: "contractTech",
                        api: [reqApplyList],
                        params: [{
                            type: 8,
                            stage: 2,
                            count_only: true,
                            is_history: false,
                        }],
                    },
                    role: [
                        ADMIN,
                        CRMADMIN,
                        TECHNOLOGY,
                        PRODUCT,
                        SERVICE,
                        OPERATING,
                        OTHER_WEAPON,
                        ROLES.WEAPON_BUSINESS,
                    ],
                },
                {
                    title: "合同技术审批",
                    key: "/crm/approve/technology-approve/detail",
                    icon: "edit",
                    hide: true,
                },
                {
                    title: "合同法务审批",
                    key: "/crm/approve/law-approve/list",
                    icon: "edit",
                    count: {
                        type: "contractfinace",
                        api: [reqApplyList],
                        params: [{
                            type: 8,
                            stage: 3,
                            count_only: true,
                            is_history: false,
                        }],
                    },
                    role: [ADMIN, CRMADMIN, LAW],
                },
                {
                    title: "合同法务审批",
                    key: "/crm/approve/law-approve/detail",
                    icon: "edit",
                    hide: true,
                },
                {
                    title: "发票总监审批",
                    key: "/crm/approve/invoice-business/detail",
                    icon: "edit",
                    hide: true,
                },
                {
                    title: "发票总监审批",
                    key: "/crm/approve/invoice-business/history",
                    icon: "edit",
                    hide: true,
                },
                {
                    title: "发票安服审批",
                    key: "/crm/approve/invoice-service/detail",
                    icon: "edit",
                    hide: true,
                },
                {
                    title: "发票安服审批",
                    key: "/crm/approve/invoice-service/history",
                    icon: "edit",
                    hide: true,
                },
                {
                    title: "发票财务审批",
                    key: "/crm/approve/invoice-finance/list",
                    icon: "edit",
                    count: {
                        type: "invoiceFinace",
                        api: [reqApplyList],
                        params: [{
                            type: 10,
                            stage: 3,
                            count_only: true,
                            is_history: false,
                        }],
                    },
                    role: [ADMIN, CRMADMIN, RETURN_ADMIN],
                },
                {
                    title: "发票财务审批",
                    key: "/crm/approve/invoice-finance/detail",
                    icon: "edit",
                    hide: true,
                },
                {
                    title: "发票财务审批",
                    key: "/crm/approve/invoice-finance/history",
                    icon: "edit",
                    hide: true,
                },
                {
                    title: "发票信息录入",
                    key: "/crm/approve/invoice-enter/list",
                    icon: "edit",
                    count: {
                        type: "invoiceMessage",
                        api: [reqApplyList],
                        params: [{
                            type: 10,
                            stage: 4,
                            count_only: true,
                            is_history: false,
                        }],
                    },
                    role: [ADMIN, CRMADMIN, INVOICE_ENTER, ROLES.INVOICE_CC],
                },
                {
                    title: "发票信息录入",
                    key: "/crm/approve/invoice-enter/detail",
                    icon: "edit",
                    hide: true,
                },
                {
                    title: "发票信息录入",
                    key: "/crm/approve/invoice-enter/history",
                    icon: "edit",
                    hide: true,
                },
                // {
                //   title: "额外费用总监审批",
                //   key: "/crm/approve/marketCost-business/list",
                //   icon: "edit",
                //   count: {
                //     type : "marketBusiness",
                //     api : [reqMarketingCostApplyList],
                //     params : [{
                //       stage : 1,
                //       count_only : true,
                //       is_history : false,
                //     }],
                //   },
                //   role: [ADMIN, CRMADMIN, ROLES.MARKET_BUSINESS],
                // },
                // {
                //   title: "额外费用总经理审批",
                //   key: "/crm/approve/marketCost-manager/list",
                //   icon: "edit",
                //   count: {
                //     type : "marketBusinessAdmin",
                //     api : [reqMarketingCostManagerApplyList,reqMarketingCostManagerApplyList],
                //     params : [{
                //       stage : 1,
                //       count_only : true,
                //       is_history : false,
                //     },{
                //       stage : 1.1,
                //       count_only : true,
                //       is_history : false,
                //     }],
                //   },
                //   role: [ADMIN, CRMADMIN, DEPARTMENT_ADMIN],
                // },
                // {
                //   title: "额外费用副总裁审批",
                //   key: "/crm/approve/marketCost-admin/list",
                //   icon: "edit",
                //   count: {
                //     type : "marketAdmin",
                //     api : [reqMarketingCostApplyList],
                //     params : [{
                //       stage : 2,
                //       count_only : true,
                //       is_history : false,
                //     }],
                //   },
                //   role: [ADMIN, CRMADMIN, ROLES.MARKET_ADMIN],
                // },
                {
                    title: "额外费用财务审批",
                    key: "/crm/approve/marketCost-finance/list",
                    icon: "edit",
                    count: {
                        type: "marketFinance",
                        api: [reqMarketingCostApplyList],
                        params: [{
                            stage: 3,
                            count_only: true,
                            is_history: false,
                        }],
                    },
                    role: [ADMIN, CRMADMIN, ROLES.MARKET_FINANCE],
                },
                {
                    title: "额外费用最终确认",
                    key: "/crm/approve/marketCost-final/list",
                    icon: "edit",
                    count: {
                        type: "marketFinal",
                        api: [reqMarketingCostApplyList],
                        params: [{
                            stage: 4,
                            count_only: true,
                            is_history: false,
                        }],
                    },
                    role: [ADMIN, CRMADMIN, ROLES.MARKETCOST_FINAL],
                },
                {
                    title: "报价单审批",
                    key: "/crm/approve/quotation/list",
                    icon: "edit",
                    count: {
                        type: "quotation",
                        api: [reqQuotationCheckList],
                        params: [{
                            count_only: true,
                            examined: false,
                        }],
                    },
                    role: [ROLES.QUOTATION_APPROVER],
                },
                {
                    title: "打卡审批(管理员)",
                    key: "/crm/approve/work-log-check/list",
                    icon: "edit",
                    role: [ROLES.SPECIAL_APPROVER],
                },
                {
                    title: "打卡审批",
                    key: "/crm/approve/work-log-check-manager/list",
                    icon: "edit",
                    role: [ADMIN, ROLES.DEPARTMENT_ADMIN, HRBP],
                },
                // {
                //   title: "打卡故障申诉审批",
                //   key: "/crm/approve/complaint/list",
                //   icon: "edit",
                //   role: [ADMIN,ROLES.SPECIAL_APPROVER],
                // },
                // {
                //   title: "拜访时长申诉审批",
                //   key: "/crm/approve/visit-complaint/list",
                //   icon: "edit",
                //   role: [ROLES.SPECIAL_APPROVER],
                // },
            ],
        },
        {
            title: "我是管理",
            key: "/crm/admin",
            icon: "desktop",
            role: [
                ADMIN,
                FINANCE,
                HRBP,
                BUSINESS,
                HRBP_ASSISTANT,
                CRMADMIN,
                WEAPON,
                PRODUCT,
                SERVICE,
                OPERATING,
                OTHER_WEAPON,
                RETURN_ADMIN,
                LAW,
                SUPERVISE,
                PRESELLER_ADMIN,
                INVOICE_ENTER,
                ROLES.MARKET_CC,
                ROLES.DAILY_WORK_VIEWER,
                ROLES.WEAPON_BUSINESS,
                ROLES.SPECIAL_APPROVER,
                ROLES.GO_OUT_APPROVE,
                ROLES.CONTRACTADMIN,
                ROLES.MANAGER,
                ROLES.PROJECT_FINISHED,
                ROLES.EQUIPMENT
            ],
            children: [
                {
                    title: "人员列表",
                    key: "/crm/admin/salesman/list",
                    icon: "table",
                    role: [ADMIN, CRMADMIN, HRBP, BUSINESS, FINANCE, ROLES.EQUIPMENT, ROLES.RIRECTOR],
                },
                {
                    title: "团队列表",
                    key: "/crm/admin/department/list",
                    icon: "table",
                    role: [ADMIN, CRMADMIN, HRBP],
                },
                {
                    title: "打卡轨迹",
                    key: "/crm/admin/track",
                    icon: "table",
                    role: [ROLES.SPECIAL_APPROVER, ROLES.CLCLE],
                },
                {
                    title: "胜任力模型",
                    key: "/crm/admin/diagram-mark",
                    icon: "table",
                    role: [ROLES.DEPARTMENT_ADMIN, ROLES.HRBP],
                },
                /* {
                  title: "助理结束点",
                  key:"/crm/admin/assistant-location",
                  icon: "table",
                  role: [ROLES.ADMIN,ROLES.GO_OUT_APPROVE],
                }, */
                // {
                //   title: "奖金列表",
                //   key: "/crm/admin/bonus/list",
                //   icon: "table",
                //   role: [ADMIN, CRMADMIN, HRBP],
                // },
                /* {
                  title: "收支列表",
                  key: "/crm/admin/inandcost/list",
                  icon: "table",
                  role: [ADMIN, CRMADMIN, HRBP],
                }, */
                // {
                //   title: "合同申请列表",
                //   key: "/crm/admin/contract/apply/list",
                //   icon: "table",
                //   role: [ADMIN, CRMADMIN, HRBP, BUSINESS, LAW, FINANCE, SUPERVISE],
                // },
                {
                    title: "合同申请列表",
                    key: "/crm/admin/contract/apply/detail",
                    icon: "table",
                    hide: true,
                },
                {
                    title: "添加人员",
                    key: "/crm/admin/salesman/add",
                    icon: "plus-circle",
                    hide: true,
                },
                {
                    title: "人员详情",
                    key: "/crm/admin/salesman/detail",
                    icon: "smile",
                    hide: true,
                },
                {
                    title: "设置配额",
                    key: "/crm/admin/salesman/edit",
                    icon: "smile",
                    hide: true,
                },
                {
                    title: "设置目标",
                    key: "/crm/admin/salesman/target",
                    icon: "smile",
                    hide: true,
                },
                {
                    title: "销售业绩",
                    key: "/crm/admin/salesman/target",
                    icon: "table",
                    role: [ADMIN, ROLES.MANAGER, ROLES.RIRECTOR, ROLES.HRBP],
                },
                /* {
                  title: "小队列表",
                  key: "/crm/admin/team/list",
                  icon: "table",
                  role: [ADMIN],
                },
                {
                  title: "添加小队",
                  key: "/crm/admin/team/add",
                  icon: "plus-circle",
                  hide: true,
                }, */
                {
                    title: "客户标签",
                    key: "/crm/client-tag/list",
                    icon: "contacts",
                    role: [ROLES.MANAGER],
                },
                {
                    title: "工作日志",
                    key: "/crm/admin/work-log/list",
                    icon: "table",
                    role: [ADMIN, CRMADMIN, HRBP, HRBP_ASSISTANT, BUSINESS, PRODUCT, SERVICE, OPERATING, OTHER_WEAPON, ROLES.WEAPON_BUSINESS, RETURN_ADMIN],
                },
                {
                    title: "工作日报",
                    key: "/crm/admin/daily-log/list",
                    icon: "table",
                    role: [ADMIN, ROLES.MANAGER, HRBP, HRBP_ASSISTANT, RETURN_ADMIN],
                },
                // {
                //   title: "工作周报",
                //   key: "/crm/admin/week-log/list",
                //   icon: "table",
                //   role: [ADMIN, CRMADMIN, HRBP, HRBP_ASSISTANT, BUSINESS],
                // },
                // {
                //   title: "项目经理预约列表",
                //   key: "/crm/admin/pre-seller/apply",
                //   icon: "table",
                //   role: [ADMIN, CRMADMIN, BUSINESS,PRESELLER_ADMIN],
                // },
                // {
                //   title: "我的项目经理工作",
                //   key: "/crm/admin/pre-seller/record",
                //   icon: "table",
                //   role: [ADMIN, CRMADMIN, PRESELLER],
                // },
                {
                    title: "合同管理",
                    key: "/crm/admin/contract/list",
                    icon: "table",
                    role: [ADMIN, CRMADMIN, RETURN_ADMIN, LAW, FINANCE, SUPERVISE],
                },
                {
                    title: "发票列表",
                    key: "/crm/admin/invoice/list",
                    icon: "table",
                    role: [ADMIN, CRMADMIN, RETURN_ADMIN],
                },
                {
                    title: "发票列表",
                    key: "/crm/admin/invoice/already/list",
                    icon: "table",
                    hide: true,
                },
                {
                    title: "发票列表",
                    key: "/crm/admin/invoice/pending/list",
                    icon: "table",
                    hide: true,
                },
                {
                    title: "回款列表",
                    key: "/crm/admin/return/list",
                    icon: "table",
                    role: [RETURN_ADMIN],
                },
                {
                    title: "回款列表",
                    key: "/crm/admin/return/unbind/list",
                    icon: "table",
                    hide: true,
                },
                {
                    title: "额外费用申请列表",
                    key: "/crm/admin/marketCost/list",
                    icon: "table",
                    role: [ADMIN, CRMADMIN, ROLES.MARKET_CC],
                },
                {
                    title: "回款管理",
                    key: "/crm/admin/returned-apply/detail",
                    icon: "smile",
                    hide: true,
                },
                {
                    title: "武器库管理",
                    key: "/crm/admin/weapon/list",
                    icon: "table",
                    role: [ADMIN, CRMADMIN, WEAPON],
                },
                /* {
                  title: "案例库管理",
                  key: "/crm/admin/case/list",
                  icon: "table",
                  role: [ADMIN, CRMADMIN, WEAPON],
                }, */
                {
                    title: "项目提成列表",
                    key: "/crm/admin/commission/list",
                    icon: "table",
                    role: [ROLES.SPECIAL_APPROVER],
                },
                {
                    title: "项目提成历史列表",
                    key: "/crm/admin/commission/history",
                    hide: true,
                },
                {
                    title: "项目提成详情",
                    key: "/crm/admin/commission/detail",
                    hide: true,
                },
                {
                    title: "打卡考勤",
                    key: "/crm/admin/clock-attendance/list",
                    icon: "table",
                    role: [ROLES.ADMIN, ROLES.HRBP, HRBP_ASSISTANT, ROLES.DEPARTMENT_ADMIN]
                },
            ],
        },
        {
            title: "财务工作",
            key: "/crm/finance",
            icon: "desktop",
            role: [ROLES.RETURN_ADMIN, ROLES.CRMADMIN, ROLES.MANAGER, ROLES.VENDOR_ADMIN],
            children: [
                {
                    title: "应付账款",
                    key: "/crm/finance/disbursement/list",
                    icon: "table",
                    role: [ROLES.RETURN_ADMIN, ROLES.CRMADMIN, ROLES.MANAGER],
                },
                {
                    title: "付款发票列表",
                    key: "/crm/finance/invoice/list",
                    icon: "table",
                    role: [ROLES.RETURN_ADMIN, ROLES.CRMADMIN, ROLES.MANAGER],
                },
                {
                    title: "供应商管理",
                    key: "/crm/finance/vendor/list",
                    icon: "table",
                    role: [ROLES.RETURN_ADMIN, ROLES.CRMADMIN, ROLES.MANAGER, ROLES.VENDOR_ADMIN],
                },
            ]
        },
        {
            title: "设备管理",
            key: "/crm/equipment",
            icon: "contacts",
            role: [ROLES.EQUIPMENT],
            children: [
                {
                    title: "设备列表",
                    key: "/crm/equipment/list",
                    icon: "table",
                },
            ]
        },
        {
            title: "系统通知",
            key: "/crm/system",
            icon: "contacts",
            children: [
                {
                    title: "系统更新记录",
                    key: "/crm/announcement/list",
                    icon: "table",
                },
                {
                    title: "消息中心",
                    key: "/crm/system/message/list",
                    icon: "table",
                }
            ]
        },
    ]
};

export default menuList;
