import React from 'react';
import {
    Layout,
    Icon,
    Popover,
    Avatar,
    Button,
    message,
    Breadcrumb,
    Spin,
    ConfigProvider,
} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import {
    Link,
    Route,
    Switch
} from "react-router-dom";
import * as dd from 'dingtalk-jsapi';
import isMobile from "ismobilejs";
import moment from 'moment';
import 'moment/locale/zh-cn';

import "./index.less";
import LeftNav from "./components/left-nav/LeftNav";
import {
    AUTH,
    ROLES,
    CORPID,
    AVATAR
} from "../../utils/constant";
import memory from "../../utils/memory";
import {
    reqSignout,
    crmApi,
    reqSelfInfo,
    reqDepartmentList,
    manageApi
} from "../../api";
import storage from "../../utils/storage";

import Home from "./routes/home";

import menuList from "./config/menu-config";

moment.locale('zh-cn');

const { Header, Content, Footer, Sider } = Layout;
const {
    reqTrades,
} = crmApi;
const {
    reqLogin,
} = manageApi;
const { department_obj, crm_global } = memory;
const {
    RIGHTS
} = ROLES;

export default class ManageIndex extends React.Component {
    state = {
        loading: true,
        collapsed: isMobile(navigator.userAgent).any ? true : false,
    };
    breadcrumb = [];

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    userInfoHeader = () => {
        return (
            <div className="pop-header">
                <Avatar size="small" className="pop-avatar" src={memory.user.dd_info.avatar} />
                <span className="pop-name">{memory.user.dd_info.name}</span>
            </div>
        );
    }
    userInfoContent = () => {
        return (
            <div className="pop-content">
                <Link to="/manage/" className="pop-content-item">
                    <Icon className="icon" type="unordered-list" />
                    <span className="item-name">个人中心</span>
                </Link>
                {
                    memory.user.role_type.indexOf(RIGHTS) > -1 ? (
                        <Link to="/admin" className="pop-content-item">
                            <Icon className="icon" type="safety" />
                            <span className="item-name">后台管理</span>
                        </Link>
                    ) : null
                }
                <div className="pop-content-info">
                    <div className="info-item">
                        <label className="info-item-label">部门：</label>
                        <span className="info-item-name">{department_obj[memory.user.dd_info.department[0]]}</span>
                    </div>
                </div>
                {
                    dd.env.platform !== 'notInDingTalk' ? null : (
                        <footer className="pop-content-footer">
                            <Button type="link" onClick={this.handleSignout}>退出登录</Button>
                        </footer>
                    )
                }
            </div>
        );
    }
    breadcrumbComponent = (menuArr, pathname) => {
        for (let i = 0; i < menuArr.length; i++) {
            if (pathname.indexOf(menuArr[i].key) === 0) {
                if (menuArr[i].key === '/manage') {
                    this.breadcrumb.push(
                        <Breadcrumb.Item key={menuArr[i].key}>
                            <Link to="/manage">
                                {menuArr[i].title}
                            </Link>
                        </Breadcrumb.Item>
                    );
                } else {
                    this.breadcrumb.push(
                        <Breadcrumb.Item key={menuArr[i].key}>
                            {menuArr[i].title}
                        </Breadcrumb.Item>
                    );
                }
                if (menuArr[i].children) {
                    this.breadcrumbComponent(menuArr[i].children, pathname);
                }
            }
        }
        return this.breadcrumb;
    }

    handleSignout = () => {
        (async () => {
            const response = await reqSignout();
            if (response) {
                message.success('账号已退出');
                storage.removeStore(AUTH);
                this.props.history.replace('/');
            }
        })();
    }

    getTradeList = async (params = {}) => {
        const response = await reqTrades(params);
        const data = response.data;
        if (data) {
            crm_global.trade_arr = data || [];
            crm_global.trade_obj = {};
            for (let i = 0; i < data.length; i++) {
                crm_global.trade_obj[data[i]._id] = data[i].name;
            }
            this.setState({
                loading: false
            });
        }
    };

    toLogin = async (code) => {
        const response = await reqLogin({
            code: code,
        });
        const auth = response.data.token;
        storage.saveStore(AUTH, auth);
        message.success('登录成功');

        // 获取个人信息
        const self = await reqSelfInfo();
        if (self && self.data) {
            memory.user = self.data;
            memory.user.dd_info.avatar = memory.user.dd_info.avatar || AVATAR;
            memory.user.role_type = [];
            if (self.data.roles && self.data.roles.length) {
                for (let i = 0; i < self.data.roles.length; i++) {
                    memory.user.role_type.push(self.data.roles[i].type);
                }
            }

            // 获取部门信息
            const dep = await reqDepartmentList();
            if (dep) {
                memory.departments = dep.data;
                for (let i = 0; i < dep.data.length; i++) {
                    memory.department_obj[dep.data[i].id] = dep.data[i].name;
                }
            }
            this.getTradeList();
        }
    }

    componentDidMount = () => {
        const loginFun = this.toLogin;
        if (dd.env.platform !== 'notInDingTalk') {
            dd.ready(function () {
                dd.runtime.permission.requestAuthCode({
                    corpId: CORPID, // 企业id
                    onSuccess: function (info) {
                        const code = info.code; // 通过该免登授权码可以获取用户身份
                        loginFun(code);
                    },
                    onFail: function (err) {
                        message.error('requestAuthCode error: ' + JSON.stringify(err));
                    }
                });
            });
            dd.error(function (error) {
                message.error('dd error: ' + JSON.stringify(error));
            });
        } else {
            this.setState({
                loading: false
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (memory.is_mobile) {
            if (this.props.location.pathname !== prevProps.location.pathname) {
                this.setState({
                    collapsed: true
                });
            }
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        this.breadcrumb = [];
    }

    render() {
        const pathname = this.props.location.pathname;

        return (
            <Layout className="manage">
                {
                    this.state.loading ? (
                        <div className="loading-container">
                            <Spin size="large" />
                        </div>
                    ) : (
                        <ConfigProvider locale={zhCN}>
                            <Sider
                                breakpoint="lg"
                                collapsedWidth={memory.is_mobile ? 0 : 80}
                                trigger={null}
                                collapsible
                                collapsed={this.state.collapsed}
                                style={{
                                    overflow: 'auto',
                                    height: '100%',
                                    position: 'fixed',
                                    left: 0,
                                    zIndex: 99,
                                }}
                            >
                                <div className="manage-logo" title="首页">
                                    <Link to="/gpt">
                                        <Icon className="logo-icon" type="home" style={{ color: '#ccc', fontSize: 26 }} />
                                        {
                                            memory.is_mobile ? (
                                                <span className="logo-name" style={{ color: '#ccc', fontSize: 26, marginLeft: 20 }}>工具库</span>
                                            ) : (
                                                this.state.collapsed ? null : (
                                                    <span className="logo-name" style={{ color: '#ccc', fontSize: 26, marginLeft: 20 }}>工具库</span>
                                                )
                                            )
                                        }
                                    </Link>
                                </div>
                                {<LeftNav />}
                            </Sider>

                            <Layout
                                style={{
                                    marginLeft: memory.is_mobile ? 0 : (this.state.collapsed ? 80 : 200),
                                }}
                            >
                                <Header
                                    className="manage-header"
                                    style={{
                                        position: 'fixed',
                                        zIndex: 97,
                                        left: 0,
                                        right: 0,
                                        marginLeft: memory.is_mobile ? 0 : (this.state.collapsed ? 80 : 200)
                                    }}
                                >
                                    <Icon
                                        className="manage-trigger"
                                        type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                                        onClick={this.toggle}
                                    />

                                    <div className="user">
                                        <Popover className="popover" content={this.userInfoContent()} title={this.userInfoHeader()} placement="bottomRight">
                                            <Avatar className="avatar" src={memory.user.dd_info.avatar} />
                                        </Popover>
                                    </div>
                                </Header>

                                <Breadcrumb
                                    style={{
                                        padding: '24px 24px 0',
                                        marginTop: '64px',
                                    }}>
                                    {this.breadcrumbComponent(menuList, pathname)}
                                </Breadcrumb>

                                <Content style={{ margin: '24px 16px 0' }}>
                                    <Switch>
                                        <Route path="/gpt-admin" component={Home}></Route>
                                    </Switch>
                                </Content>

                                <Footer style={{ textAlign: 'center' }}>谋乐网络科技 ©{moment().year()}</Footer>
                                <div
                                    className="mask-layout"
                                    style={{
                                        display: memory.is_mobile ? (this.state.collapsed ? 'none' : 'block') : 'none'
                                    }}
                                    onClick={() => {
                                        this.setState({
                                            collapsed: true
                                        });
                                    }}
                                >
                                </div>
                            </Layout>
                        </ConfigProvider>
                    )
                }
            </Layout>
        );
    }
}