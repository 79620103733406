import React from "react";
import {
    Card,
    Table,
    Input,
    Button,
    message
} from "antd";
import {Link} from "react-router-dom";
import { SearchOutlined } from '@ant-design/icons';

import memory from "../../../../utils/memory";
import {crmApi,adminApi} from "../../../../api";

const {department_obj} = memory;
const {reqUserList} = crmApi;
const {reqUserRoleEdit} = adminApi;

export default class UserList extends React.Component {
    state = {
        data: [],
        pagination: {},
        loading: false,
    };

    handleTableChange = (pagination, filters) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        });
        let tmpFilters = {...filters};
        for(let item in tmpFilters) {
            if(tmpFilters[item]) {
                if(typeof(tmpFilters[item]) !== 'string' && item !== 'tags') {
                    tmpFilters[item] = tmpFilters[item][0];
                }
            }
        }
        let params = {
            page: pagination.current,
            ...tmpFilters,
        };
        this.getUserList(params);
    }
    /*
    *搜索相关
    * */
    getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`搜索 ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    搜索
                </Button>
                <Button onClick={() => this.handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    重置
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => text
    });
    handleSearch = (selectedKeys, confirm) => {
        confirm();
    };
    handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
    };

    handleClear = async(uid,role) => {
        let rids = [];
        if(role?.length && role.findIndex(o => o.id === "5e954f126800822789275cad") > -1){//销售身份保留
            rids.push("5e954f126800822789275cad");
        }
        if(role?.length && role.findIndex(o => o.id === "5f4dfbca419c782365801ddc") > -1){//后台管理员身份保留
            rids.push("5f4dfbca419c782365801ddc");
        }
        const response = await reqUserRoleEdit({uid,rids});
        if(response?.data){
            message.success("清除成功");
            this.getUserList({page : this.state.pagination.current || 1})
        }
    }

    columns = [
        {
            title: 'No.',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            ...this.getColumnSearchProps('name','姓名')
        },
        {
            title: '部门',
            dataIndex: 'department',
            key: 'department',
        },
        {
            title: '角色',
            dataIndex: 'roles',
            key: 'roles',
        },
        {
            title: '操作',
            dataIndex: 'option',
            valueType: 'option',
            render: (_, record, index) => {
                return (
                    <>
                        <Link to={`/admin/users/role/${record.key}`}>设置角色</Link>
                        {
                            window.location.href.indexOf("localhost:3000/") > -1 || window.location.href.indexOf("oa-test.moule.cn/") > -1 ?
                            <>
                                &nbsp;|&nbsp;
                                <a onClick={() => this.handleClear(record.key,record.role)}>清除角色信息</a>
                            </>
                            :
                            null
                        }
                    </>
                );
            }
        },
    ];

    getUserList = async (params = {}) => {
        this.setState({
            loading: true
        });
        const response = await reqUserList(params);
        const pagination = {...this.state.pagination};
        const data = response.data;
        pagination.total = data.page.total;
        pagination.pageSize = data.page.node;
        let newData = [];
        for(let i = 0; i < data.data.length; i++) {
            const item = data.data[i];
            const obj = {
                key: item._id,
                number: (data.page.current-1)*data.page.node + i + 1,
                name: item.dd_info.name,
                department: department_obj[item.dd_info.department[item.dd_info.department.length-1]],
                role: item.roles,
            };
            let roles = [];
            for(let j = 0; j < item.roles.length; j++) {
                roles.push(item.roles[j].name);
            }
            obj['roles'] = roles.length ? roles.join('、') : '-';
            newData.push(obj);
        }
        this.setState({
            loading: false,
            data: newData,
            pagination
        });
    };

    componentDidMount() {
        this.getUserList({
            page: 1,
        });
    }

    render() {
        return (
            <Card
                title="用户列表"
                bordered={false}
            >
                <Table
                    columns={this.columns}
                    dataSource={this.state.data}
                    pagination={this.state.pagination}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                    size={memory.is_mobile ? 'small' : 'middle'}
                />
            </Card>
        );
    }
}