import React from "react";
import {
    Spin,
    Form,
    Card,
    Row,
    Col,
    Input,
    Select,
    Button,
    message,
    Popconfirm,
    DatePicker,
    Upload,
    Modal,
} from "antd";

import { moaApi } from "../../../../../api";
import {
    MEETING_TYPE,
    MEETING_WAY,
    MEETING_ACTION,
    MEETING_GROUP,
    AUTH
} from "../../../../../utils/constant";
import moment from "moment";
import storage from "../../../../../utils/storage";
import UserSelector from "../../../../../components/user-selector";

const { Option } = Select;
const {
    reqMeetingAdd,
    reqMeetingDetail,
    reqMeetingEdit,
    reqMeetingDel,
} = moaApi;

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

class MeetingAdd extends React.Component {
    state = {
        loading: true,
        detail: {},

        previewVisible: false,
        previewImage: '',
        fileList: [],
    };
    id = this.props.match.params.id;

    handleCancel = () => this.setState({ previewVisible: false });
    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };
    handleChange = async ({ fileList }) => {
        this.setState({ fileList })
    };

    validate = async (values) => {
        const {fileList} = this.state;
        values.date = new Date(values.date._d);
        values.registration_deadline = new Date(values.registration_deadline._d);
        if (fileList.length) {
            values.poster = fileList[0].response ? fileList[0].response.url : fileList[0].url;
        } else {
            values.poster = '';
        }
        let response;
        if (!this.id) {
            for (let i in values) {
                if (!values[i]) {
                    delete values[i];
                }
            }
            if (!values.guest.length) {
                delete values.guest;
            }
        }
        if (this.id) {
            values.id = this.id;
            response = await reqMeetingEdit(values);
            if (response && response.data) {
                message.success('修改成功');
                this.props.history.goBack();
            }
        } else {
            response = await reqMeetingAdd(values);
            if (response && response.data) {
                message.success('添加成功');
            }
        }
    };

    delMeeting = async () => {
        const response = await reqMeetingDel({
            id: this.id
        });
        if (response && response.data) {
            message.success('删除成功');
            this.props.history.replace('/moa/meeting/list');
        }
    };

    getMeetingDetail = async (params = {}) => {
        const response = await reqMeetingDetail(params);
        if (response && response.data) {
            this.setState({
                detail: response.data,
                fileList: response.data.poster ? [
                    {
                        uid: '1',
                        url: response.data.poster,
                        preview: response.data.poster
                    }
                ] : [],
                loading: false,
            });
        }
    };

    componentDidMount() {
        if (this.id) {
            // 获取详情
            this.getMeetingDetail({
                id: this.id
            });
        }
    }

    render() {
        const { detail } = this.state;
        const { previewVisible, previewImage, fileList } = this.state;
        let guest = [];
        if (detail.guest && detail.guest.length) {
            for (let i = 0; i < detail.guest.length; i++) {
                guest.push(detail.guest[i].id);
            }
        }
        let headers = {};
        const auth = storage.getStore(AUTH);
        if (auth) {
            headers = {
                authorization: 'Bearer ' + auth
            };
        }
        const uploadButton = (
            <div>
                <div className="ant-upload-text">上传</div>
            </div>
        );

        return this.id && this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <Form onFinish={this.validate} layout="vertical">
                <Card
                    title={
                        this.id ? "修改会议信息" : "添加会议"
                    }
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                    extra={
                        this.id ? (
                            <Popconfirm
                                title="确定删除该会议信息吗?"
                                onConfirm={this.delMeeting}
                                okText="确定"
                                cancelText="取消"
                            >
                                <Button>删除</Button>
                            </Popconfirm>
                        ) : null
                    }
                >
                    <Card
                        title="会议基本信息"
                        bordered={false}
                        type="inner"
                    >
                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="名称"
                                    name="name"
                                    initialValue={detail.name || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入会议名称',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入会议名称" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="主办方"
                                    name="sponsor"
                                    initialValue={detail.sponsor || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入会议主办方',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入会议主办方" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="会议时间"
                                    name="date"
                                    initialValue={detail.date ? moment(detail.date) : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择会议时间',
                                        },
                                    ]}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请选择会议时间" showTime />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="报名截止时间"
                                    name="registration_deadline"
                                    initialValue={detail.registration_deadline ? moment(detail.registration_deadline) : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择报名截止时间',
                                        },
                                    ]}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请选择报名截止时间" showTime />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="地点"
                                    name="place"
                                    initialValue={detail.place || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入会议地点',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入会议地点" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="类别"
                                    name="type"
                                    initialValue={detail.type !== undefined ? detail.type : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择会议类别',
                                        },
                                    ]}
                                >
                                    <Select placeholder="请选择会议类别">
                                        {MEETING_TYPE.map((item, index) => (
                                            <Option key={item.key} value={item.value}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="方式"
                                    name="way"
                                    initialValue={detail.way !== undefined ? detail.way : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择会议方式',
                                        },
                                    ]}
                                >
                                    <Select placeholder="请选择会议方式">
                                        {MEETING_WAY.map((item, index) => (
                                            <Option key={item.key} value={item.value}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="行为"
                                    name="action"
                                    initialValue={detail.action !== undefined ? detail.action : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择会议行为',
                                        },
                                    ]}
                                >
                                    <Select placeholder="请选择会议行为" mode="multiple">
                                        {MEETING_ACTION.map((item, index) => (
                                            <Option key={item.key} value={item.value}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="参会人群"
                                    name="participating_group"
                                    initialValue={detail.participating_group !== undefined ? detail.participating_group : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择参会人群',
                                        },
                                    ]}
                                >
                                    <Select placeholder="请选择参会人群">
                                        {MEETING_GROUP.map((item, index) => (
                                            <Option key={item.key} value={item.value}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="链接"
                                    name="link"
                                    initialValue={detail.link || ''}
                                    rules={[
                                        {
                                            type: 'url',
                                            message: '请输入完整链接'
                                        }
                                    ]}
                                >
                                    <Input placeholder="请输入会议链接" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="嘉宾"
                                    name="guest"
                                    initialValue={guest}
                                >
                                    <UserSelector
                                        multiple
                                        placeholder="请选择嘉宾"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="备注"
                                    name="remark"
                                    initialValue={detail.remark || ''}
                                >
                                    <Input placeholder="备注" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item label="上传海报">
                                    <Upload
                                        action="/api/files/ue?action=uploadimage"
                                        name="picture"
                                        listType="picture-card"
                                        fileList={fileList}
                                        onPreview={this.handlePreview}
                                        onChange={this.handleChange}
                                        headers={headers}
                                    >
                                        {fileList.length >= 1 ? null : uploadButton}
                                    </Upload>
                                    <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                        <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                    </Modal>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Card>

                <div className="submit-footer">
                    <Button type="primary" htmlType="submit">
                        {
                            this.id ? '保存' : '提交'
                        }
                    </Button>
                </div>
            </Form>
        );
    }
}

export default MeetingAdd;