import React from "react";
import {
    Button,
    Card,
    Table,
    Tag
} from "antd";
import {Link} from "react-router-dom";
import { CheckCircleOutlined } from '@ant-design/icons';

import memory from "../../../../utils/memory";
import {crmApi} from "../../../../api";
import {
    CLIENT_NATURE,
    CLIENT_OBJ,
    CLIENT_TYPE,
    COMPANY_NATURE
} from "../../../../utils/constant";

const {
    reqBusinessAdmin,
    reqClientList
} = crmApi;
const  {crm_global} = memory;
const clientColumns = [
    {
        title: '公司全称',
        dataIndex: 'company_name',
        key: 'company_name',
    },
    {
        title: '公司性质',
        dataIndex: 'company_type',
        key: 'company_type',
    },
    {
        title: '所在行业',
        dataIndex: 'trade',
        key: 'trade',
    },
    {
        title: '标签',
        dataIndex: 'tags',
        key: 'tags',
        render: tags => (
            tags && tags.length ? (
                <span>
                    {tags.map(tag => {
                        return (
                            <Tag color="blue" key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </span>
            ) : '-'
        ),
    },
    {
        title: '关键目标',
        dataIndex: 'is_important',
        key: 'is_important',
        render: (data) => {
            if(data) {
                return <CheckCircleOutlined theme="filled" style={{color: 'green'}} />
            }else {
                return '-';
            }
        }
    },
    {
        title: '客户分类',
        dataIndex: 'classify',
        key: 'classify',
    },
    {
        title: '客户性质',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: '客户状态',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: '省',
        dataIndex: 'place',
        key: 'place',
    },
    {
        title: '市',
        dataIndex: 'city',
        key: 'city',
    },
    {
        title: '当前跟进销售数',
        dataIndex: 'follow_up_sales',
    },
    {
        title: '操作',
        dataIndex: 'option',
        valueType: 'option',
        render: (_, record) => {
            return (
                <Link to={`/crm/client/detail/${record.key}`}>详情</Link>
            );
        }
    },
];

export default class ClientList extends React.Component {
    state = {
        loading: true,
        client: [],
    };
    stage = this.props.match.params.stage;
    tags = this.props.match.params.tags;
    type = this.props.match.params.type; //1总目标  2触达  3转化

    getClientList = async (params = {}) => {
        this.setState({
            loading: true
        });
        const response = await reqBusinessAdmin(params);
        if(response && response.data) {
            const data = response.data;
            let newData = [];
            for(let i = 0; i < data.length; i++) {
                const item = data[i];
                const obj = {
                    key: item._id,
                    company_name: item.company_name ? <Link to={`/crm/client/detail/${item._id}`}>{item.company_name}</Link> : '-',
                    company_type: item.company_type !== undefined ? COMPANY_NATURE[item.company_type].text : '-', //公司性质
                    trade: item.trade && item.trade.length ? (typeof(item.trade) === 'string' ? crm_global.trade_obj[item.trade] : crm_global.trade_obj[item.trade[item.trade.length-1]]) : '-', //所在行业
                    is_important: item.is_important || false,
                    classify: item.classify !== undefined ? CLIENT_TYPE[item.classify].text : '-', //客户分类
                    type: CLIENT_NATURE[item.type].text, //客户性质
                    status: item.status && CLIENT_OBJ[item.status] ? CLIENT_OBJ[item.status] : '-', //客户状态
                    follow_up_sales: item.follow_up_sales.length, //当前跟进销售数
                    place: '-',
                    city: '-',
                    tags: item.tags
                };
                if(item.place.length) {
                    let places = [];
                    let cities = [];
                    for(let j = 0; j < item.place.length; j++) {
                        if(item.place[j].area[0] && places.indexOf(item.place[j].area[0]) === -1) {
                            places.push(item.place[j].area[0]);
                        }
                        if(item.place[j].area[1] && cities.indexOf(item.place[j].area[1]) === -1) {
                            cities.push(item.place[j].area[1]);
                        }
                    }
                    obj.place = places.join('/');
                    obj.city = cities.join('/');
                }
                newData.push(obj);
            }
            this.setState({
                loading: false,
                client: newData
            });
        }
    };
    getClientList2 = async (params = {}) => {
        this.setState({
            loading: true
        });
        const response = await reqClientList(params);
        if(response && response.data) {
            const data = response.data.data;
            let newData = [];
            for(let i = 0; i < data.length; i++) {
                const item = data[i];
                const obj = {
                    key: item._id,
                    company_name: item.company_name ? <Link to={`/crm/client/detail/${item._id}`}>{item.company_name}</Link> : '-',
                    company_type: item.company_type !== undefined ? COMPANY_NATURE[item.company_type].text : '-', //公司性质
                    trade: item.trade && item.trade.length ? (typeof(item.trade) === 'string' ? crm_global.trade_obj[item.trade] : crm_global.trade_obj[item.trade[item.trade.length-1]]) : '-', //所在行业
                    is_important: item.is_important || false,
                    classify: item.classify !== undefined ? CLIENT_TYPE[item.classify].text : '-', //客户分类
                    type: CLIENT_NATURE[item.type].text, //客户性质
                    status: item.status && CLIENT_OBJ[item.status] ? CLIENT_OBJ[item.status] : '-', //客户状态
                    follow_up_sales: item.follow_up_sales.length, //当前跟进销售数
                    place: '-',
                    city: '-',
                    tags: item.tags
                };
                if(item.place.length) {
                    let places = [];
                    let cities = [];
                    for(let j = 0; j < item.place.length; j++) {
                        if(item.place[j].area[0] && places.indexOf(item.place[j].area[0]) === -1) {
                            places.push(item.place[j].area[0]);
                        }
                        if(item.place[j].area[1] && cities.indexOf(item.place[j].area[1]) === -1) {
                            cities.push(item.place[j].area[1]);
                        }
                    }
                    obj.place = places.join('/');
                    obj.city = cities.join('/');
                }
                newData.push(obj);
            }
            this.setState({
                loading: false,
                client: newData
            });
        }
    };

    componentDidMount = () => {
        let params = {};
        if(this.stage) {
            params = {
                stage: this.stage,
                is_important: true,
                is_customer: true,
                stock: 1
            };
            this.getClientList(params);
        }else {
            params.tags = [this.tags];
            if(Number(this.type) === 2) {
                params.contacted = 1;
            }else if(Number(this.type) === 3) {
                params.status = 4;
            }
            params.node = 9999;
            this.getClientList2(params);
        }
    };

    render() {
        return (
            <>
                <Button
                    type="link"
                    icon="left"
                    onClick={() => {
                        this.props.history.goBack();
                    }}
                >返回</Button>

                <Card
                    title="客户列表"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                >
                    <Table
                        columns={clientColumns}
                        dataSource={this.state.client}
                        pagination={false}
                        loading={this.state.loading}
                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                        size={memory.is_mobile ? 'small' : 'middle'}
                    />
                </Card>
            </>
        );
    }
}