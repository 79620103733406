import React from "react";
import { Menu, Icon } from 'antd';
import { Link, withRouter } from 'react-router-dom';

import menuList from "../../config/menu-config";
import memory from "../../../../utils/memory";

const { SubMenu } = Menu;

class LeftNav extends React.Component{
    getMenuNodes = (menuList) => {
        const path = this.props.location.pathname;

        return menuList.map(item => {
            if(item.role) { // 带权限菜单
                if(memory.user.role_type && memory.user.role_type.length) {
                    const userSet = new Set(memory.user.role_type);
                    const menuSet = new Set(item.role);
                    const intersect = memory.user.role_type.filter(x => menuSet.has(x));
                    if(!intersect || !intersect.length) {
                        return;
                    }
                }else {
                    return;
                }
            }
            if(!item.children) {
                if(item.hide) {
                    return;
                }
                return (
                    <Menu.Item key={item.key}>
                        <Link to={item.key}>
                            <Icon type={item.icon} />
                            <span>{item.title}</span>
                        </Link>
                    </Menu.Item>
                );
            }else {
                const cItem = item.children.find(cItem => (path.indexOf(cItem.key) > -1));
                if(cItem) {
                    this.openKey = item.key;
                }
                return (
                    <SubMenu
                        key={item.key}
                        title={
                            <span>
                                <Icon type={item.icon} />
                                <span>{item.title}</span>
                            </span>
                        }
                    >
                        {this.getMenuNodes(item.children)}
                    </SubMenu>
                );
            }
        });
    }

    componentWillMount() {
        this.menuNodes = this.getMenuNodes(menuList);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.menuNodes = this.getMenuNodes(menuList);
    }

    render() {
        const path = this.props.location.pathname;
        let selectedKey;
        if(path.split('/')[path.split('/').length-1] === 'add') {
            selectedKey = path.replace('add', 'list');
        }else {
            selectedKey = path;
        }
        const openKey = this.openKey;
        return (
            <Menu
                theme="dark"
                mode="inline"
                style={{ borderRight: 0 }}
                selectedKeys={[selectedKey]}
                defaultOpenKeys={[openKey]}
            >
                {this.menuNodes}
            </Menu>
        );
    }
}

export default withRouter(LeftNav);