import React from "react";
import {
    Card,
    Form,
    Input,
    Button,
    message,
    Spin
} from "antd";

import { adminApi } from "../../../../../api";
import UserSelector from "../../../../../components/user-selector";

const {
    reqDeviceDetail,
    reqRightDel,
    reqDeviceAdd,
    reqDeviceEdit
} = adminApi;

class DeviceAdd extends React.Component {
    state = {
        detail: {},
        loading: this.props.match.params.id ? true : false
    };
    id = this.props.match.params.id;

    onFinish = async (values) => {
        let response;
        if (this.id) {
            values.equipment_id = this.id;
            delete values.zhongbiao_code;
            response = await reqDeviceEdit(values);
        } else {
            response = await reqDeviceAdd(values);
        }
        if (response && response.data) {
            if (this.id) {
                message.success('修改成功');
                this.props.history.goBack();
            } else {
                message.success('添加成功');
                this.props.history.goBack();
            }
        }
    };

    handleDelete = async () => {
        const response = await reqRightDel({
            id: this.id,
        });
        if (response && response.data) {
            message.success('删除成功');
            this.props.history.goBack();
        }
    };

    getDeviceDetail = async (params = {}) => {
        const response = await reqDeviceDetail(params);
        if (response) {
            this.setState({
                detail: response.data.msg,
                loading: false
            });
        }
    };

    componentDidMount() {
        if (this.id) {
            // 获取详情
            this.getDeviceDetail({
                equipment_id: this.id
            });
        }
    }

    render() {
        const { detail, loading } = this.state;
        const formItemLayout = {
            labelCol: { span: 2 },
            wrapperCol: { span: 18 },
        };

        return loading ? (
            <Spin />
        ) : (
            <Card
                title={
                    this.id ? "修改设备" : "添加设备"
                }
                bordered={false}
            >
                <Form {...formItemLayout} onFinish={this.onFinish}>
                    <Form.Item
                        label="设备编号"
                        name="zhongbiao_code"
                        initialValue={detail.zhongbiao_code || ''}
                        rules={[
                            {
                                required: true,
                                message: '请输入设备编号'
                            }
                        ]}
                    >
                        <Input placeholder="请输入设备编号" disabled={this.id} />
                    </Form.Item>
                    <Form.Item
                        label="设备码"
                        name="equipment_code"
                        initialValue={detail.equipment_code || ''}
                        rules={[
                            {
                                required: true,
                                message: '请输入设备码'
                            }
                        ]}
                    >
                        <Input placeholder="请输入设备码" />
                    </Form.Item>
                    <Form.Item
                        label="电话(账号)"
                        name="mobile"
                        initialValue={detail.mobile || ''}
                    >
                        <Input placeholder="请输入电话(账号)" />
                    </Form.Item>
                    <Form.Item
                        label="密码"
                        name="password"
                        initialValue={detail.password || ''}
                    >
                        <Input placeholder="请输入密码" />
                    </Form.Item>
                    <Form.Item
                        label="使用者"
                        name="user"
                        initialValue={detail?.user?.id || undefined}
                    >
                        <UserSelector
                            placeholder="请选择使用者"
                        />
                    </Form.Item>
                    <Form.Item
                        label="前台版本号"
                        name="version2"
                        initialValue={detail.version2 || ''}
                    >
                        <Input placeholder="请输入前台版本号" />
                    </Form.Item>
                    <Form.Item
                        label="后台版本号"
                        name="version1"
                        initialValue={detail.version1 || ''}
                    >
                        <Input placeholder="请输入后台版本号" />
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 12, offset: 2 }}>
                        <Button type="primary" htmlType="submit">
                            {
                                this.id ? '保存' : '添加'
                            }
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        );
    }
}

export default DeviceAdd;