import React from "react";
import { Menu, Badge } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { DesktopOutlined } from '@ant-design/icons';

import menuList from "../../config/menu-config";
import memory from "../../../../utils/memory";

const { SubMenu } = Menu;

class LeftNav extends React.Component{

    countArr = [];
    getMenuNodes = (menuList,countArr) => {
        const path = this.props.location.pathname;
        const {menuCount} = this.props;

        return menuList.map(item => {
            if(item.role) { // 带权限菜单
                if(memory.user.role_type && memory.user.role_type.length) {
                    const userSet = new Set(memory.user.role_type);
                    const menuSet = new Set(item.role);
                    const intersect = memory.user.role_type.filter(x => menuSet.has(x));
                    if(!intersect || !intersect.length) {
                        return;
                    }
                }else {
                    return;
                }
            }
            if(!item.children) {
                if(item.hide) {
                    return;
                }
                if(countArr && item.count){ 
                    countArr.push(item.count);
                }
                return (
                    <Menu.Item key={item.key}>
                        <Link to={item.key} style={{display: "flex", alignItems: "center"}}>
                            <DesktopOutlined />
                            <span>{item.title}</span>
                            {
                                item.count && memory.menuCount[item.count.type] ? 
                                <Badge count={memory.menuCount[item.count.type]} style={{padding: 0,height: 14,lineHeight: "14px",marginLeft: 4}} />
                                :
                                null
                            }
                        </Link>
                    </Menu.Item>
                );
            }else {
                const cItem = item.children.find(cItem => (path.indexOf(cItem.key) > -1));
                if(cItem) {
                    this.openKey = item.key;
                }
                return (
                    <SubMenu
                        key={item.key}
                        title={
                            <span>
                                <DesktopOutlined />
                                <span>{item.title}</span>
                            </span>
                        }
                    >
                        {this.getMenuNodes(item.children,countArr)}
                    </SubMenu>
                );
            }
        });
    }

    getCount = async(countArr) => {
        let applyCountObj = {}
        for(let i = 0; i < countArr.length; i++){
            let item = countArr[i];
            applyCountObj[item.type] = 0;
            for(let j = 0; j < item.api.length; j++){
                const response = await item.api[j](item.params[j]);
                if(response?.data){
                    applyCountObj[item.type] += response.data.msg;
                }
            }
        }
        memory.menuCount = applyCountObj;
        if(countArr.length){
            this.props.setState();
        }
    }

    componentWillMount() {
        if(this.countArr.length){
            this.menuNodes = this.getMenuNodes(menuList(memory));
        }else{
            this.menuNodes = this.getMenuNodes(menuList(memory),this.countArr);
            this.getCount(this.countArr);
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if(this.countArr.length){
            this.menuNodes = this.getMenuNodes(menuList(memory));
        }else{
            this.menuNodes = this.getMenuNodes(menuList(memory),this.countArr);
            this.getCount(this.countArr);
        }
    }

    render() {
        const path = this.props.location.pathname;
        let selectedKey;
        if(path.split('/')[path.split('/').length-1] === 'add') {
            selectedKey = path.replace('add', 'list');
        }else {
            selectedKey = path;
        }
        const openKey = this.openKey;
        return (
            <Menu
                theme="dark"
                mode="inline"
                style={{ borderRight: 0 }}
                selectedKeys={[selectedKey]}
                defaultOpenKeys={[openKey]}
            >
                {this.menuNodes}
            </Menu>
        );
    }
}

export default withRouter(LeftNav);