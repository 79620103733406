import React from "react";
import {
    Button,
    Card,
    DatePicker,
    Input,
    Table,
    Tag
} from "antd";
import { Link } from "react-router-dom";
import { SearchOutlined } from '@ant-design/icons';

import LinkButton from "../../../crm/components/link-button";
import { crmApi } from "../../../../api";
import tools from "../../../../utils/tools";
import {
    CONTRACT_HEAD_TYPE_ALL,
    CONTRACT_HEAD_TYPE_ALL_OBJ,
    PAGE_SIZE,
    CONTRACT_RISKY_TYPE_OBJ,
    CONTRACT_RISKY_TYPE_GROUP
} from "../../../../utils/constant";

const { RangePicker } = DatePicker;
const {
    reqContractList
} = crmApi;

export default class ContractList extends React.Component {
    state = {
        data: [],
        pagination: {
            showSizeChanger: true,
            showTotal: total => `总数${total}条`,
            pageSizeOptions: ["10", "20", "50", "100", "200"],
            pageSize: PAGE_SIZE,
        },
        loading: false,
    };
    params = {};

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        pager.pageSize = pagination.pageSize;
        this.setState({
            pagination: pager
        });

        let tmpFilters = { ...filters };
        for (let item in tmpFilters) {
            if (tmpFilters[item]) {
                if (typeof (tmpFilters[item]) !== 'string') {
                    tmpFilters[item] = tmpFilters[item][0];
                }
            }
        }
        let params = {
            page: pagination.current,
            node: pagination.pageSize,
            ...tmpFilters,
        };
        this.getContractList(params);
    }

    /*
    *搜索相关
    * */
    getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                {
                    dataIndex === 'sign_date' ? (
                        <div
                            style={{ width: 188, marginBottom: 8 }}
                        >
                            <RangePicker
                                format="YYYY-MM-DD"
                                value={selectedKeys ? selectedKeys[0] : ''}
                                onChange={value => {
                                    this.params.sign_date = value;
                                    setSelectedKeys(value ? [value] : [])
                                }}
                            />
                        </div>
                    ) : (
                        <Input
                            ref={node => {
                                this.searchInput = node;
                            }}
                            placeholder={`搜索 ${title}`}
                            value={selectedKeys ? selectedKeys[0] : ''}
                            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                            style={{ width: 188, marginBottom: 8, display: 'block' }}
                        />
                    )
                }
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    搜索
                </Button>
                <Button onClick={() => this.handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    重置
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                // setTimeout(() => this.searchInput.select());
            }
        },
        render: text => text
    });
    handleSearch = (selectedKeys, confirm) => {
        confirm();
    };
    handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
    };

    columns = [
        {
            title: '合同名称',
            dataIndex: 'contract_name',
            key: 'contract_name',
            ...this.getColumnSearchProps('contract_name', '合同名称'),
            onCell: tools.columnItemStyle
        },
        {
            title: '合同类型',
            dataIndex: 'contract_type',
            key: 'contract_type',
            filters: CONTRACT_HEAD_TYPE_ALL,
            filterMultiple: false,
            render: (text) => (
                text || '-'
            )
        },
        {
            title: '合同编号',
            dataIndex: 'contract_number',
            key: 'contract_number',
            ...this.getColumnSearchProps('contract_number', '合同编号'),
        },
        {
            title: '合同签署日期',
            dataIndex: 'sign_date',
            key: 'sign_date',
            ...this.getColumnSearchProps('sign_date', '合同签署日期'),
        },
        {
            title: '合同金额',
            dataIndex: 'contract_amount',
            key: 'contract_amount'
        },
        {
            title: '纸质合同存放位置',
            dataIndex: 'paper_contract_position',
            key: 'paper_contract_position',
            ...this.getColumnSearchProps('paper_contract_position', '纸质合同存放位置'),
        },
        {
            title: '所属客户',
            dataIndex: 'customer_name',
            key: 'customer_name',
            onCell: tools.columnItemStyle,
            ...this.getColumnSearchProps('customer_name', '客户名称'),
        },
        {
            title: '销售',
            dataIndex: 'seller_name',
            key: 'seller_name',
            ...this.getColumnSearchProps('seller_name', '销售'),
        },
        {
            title: "风险项",
            dataIndex: "risky",
            key: "risky",
            exportKey: "risky",
            filters: CONTRACT_RISKY_TYPE_GROUP,
            render: text => {
                return text?.length ?
                    text.map((value, index) => (<div key={value}><Tag style={{ borderLeft: `4px solid red`, marginBottom: 4 }}>{CONTRACT_RISKY_TYPE_OBJ[value]}</Tag></div>))
                    :
                    "-"
            }
        },
        {
            title: '添加人',
            dataIndex: 'creator_name',
            key: 'creator_name',
            ...this.getColumnSearchProps('creator_name', '添加人'),
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time'
        },
        {
            title: '操作',
            dataIndex: 'option',
            valueType: 'option',
            render: (_, record) => {
                return (
                    <Link to={`/moa/contract/detail/${record.key}`}>详情</Link>
                );
            }
        },
    ];

    getContractList = async (params = {}) => {
        if (this.params.sign_date && this.params.sign_date.length) {
            params.sign_date = [this.params.sign_date[0]._d, this.params.sign_date[1]._d];
        }
        this.setState({
            loading: true
        });
        const response = await reqContractList(params);
        const pagination = { ...this.state.pagination };
        const data = response.data;
        pagination.total = data.page.total;
        pagination.pageSize = data.page.node;
        let newData = [];
        for (let i = 0; i < data.data.length; i++) {
            const item = data.data[i];
            let obj = {
                key: item._id,
                contract_name: <Link to={`/moa/contract/detail/${item._id}`} title={item.contract_name} >{item.contract_name}</Link>,
                contract_number: item.contract_number,
                sign_date: item.sign_date ? tools.unixToTime((new Date(item.sign_date)) / 1000, true) : '-',
                contract_amount: (item.contract_amount || 0) + '元',
                contract_startdate: item.contract_startdate && item.contract_enddate ?
                    (`${tools.unixToTime((new Date(item.contract_startdate)) / 1000, true)} - ${tools.unixToTime((new Date(item.contract_enddate)) / 1000, true)}`) : '-',
                create_time: tools.unixToTime((new Date(item.create_at)).getTime() / 1000),
                contract_type: item.contract_type ? CONTRACT_HEAD_TYPE_ALL_OBJ[item.contract_type] : '-',
                paper_contract_position: item.paper_contract_position || '-',
                creator_name: item.creator && item.creator.name ? item.creator.name : '-',
                seller_name: item.seller && item.seller.name ? item.seller.name : '-',
                customer_name: item.customer_name ? <span title={item.customer_name} >{item.customer_name}</span> : '-',
                risky: item.risky || [],
            };
            newData.push(obj);
        }
        this.setState({
            loading: false,
            data: newData,
            pagination
        });
    };

    componentDidMount() {
        this.getContractList({
            page: 1,
            node: PAGE_SIZE,
        });
    }

    render() {
        return (
            <Card
                title="合同列表"
                bordered={false}
                extra={<LinkButton to="/moa/contract/add" txt="添加合同" />}
            >
                <Table
                    className="small-table-style"
                    columns={this.columns}
                    dataSource={this.state.data}
                    pagination={this.state.pagination}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                    size={'small'}
                />
            </Card>
        );
    }
}