import React from "react";
import {
    Card,
    Table,
    Button,
    Spin,
    Empty,
    Tag
} from "antd";
import moment from 'moment';
import { InfoCircleFilled } from '@ant-design/icons';

import memory from "../../../../../utils/memory";
import {moaApi} from "../../../../../api";
import tools from "../../../../../utils/tools";

const {
    reqTaskSelf
} = moaApi;

export default class MyTaskHistory extends React.Component {
    state = {
        data: [],
        loading: false,
    };
    columns = [
        {
            title: '重要度',
            dataIndex: 'important',
            key: 'important',
            render: (value) => {
                if(value === 0) {
                    return <InfoCircleFilled style={{color: 'red', fontSize: 20}} />;
                }else if(value === 1) {
                    return <InfoCircleFilled style={{color: '#ffc000', fontSize: 20}} />;
                }else {
                    return <InfoCircleFilled style={{color: '#ccc', fontSize: 20}} />;
                }
            }
        },
        {
            title: '目标任务',
            dataIndex: 'content',
            key: 'content'
        },
        {
            title: '交付时间',
            dataIndex: 'deadline',
            key: 'deadline'
        },
        {
            title: '完成时间',
            dataIndex: 'complete_at',
            key: 'complete_at'
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (value) => {
                if(value === 1) {
                    return <Tag color="#87d068">完成</Tag>;
                }else if(value === 2) {
                    return <Tag color="#f00">失败</Tag>;
                }else if(value === 3) {
                    return <Tag color="#fa8c16">中止</Tag>;
                }else {
                    return <Tag color="#108ee9">进行中</Tag>;
                }
            }
        },
        {
            title: '备注',
            dataIndex: 'remarks',
            key: 'remarks'
        },
    ];

    getTaskList = async (params = {}) => {
        this.setState({
            loading: true
        });
        const response = await reqTaskSelf(params);
        if(response && response.data) {
            const data = response.data || [];
            let newData = [];
            let index = -1;
            let year = '';
            let month = '';
            for(let i = 0; i < data.length; i++) {
                const item = data[i];
                const itemYear = (new Date(item.create_at)).getFullYear();
                const itemMonth = (new Date(item.create_at)).getMonth();
                if(itemYear !== year || itemMonth !== month) {
                    year = itemYear;
                    month = itemMonth;
                    index = index + 1;
                    newData[index] = [];
                }
                newData[index].push({
                    key: item._id,
                    important: item.weight,
                    content: item.tcontent,
                    deadline: moment(item.end_at).isAfter(moment()) || item.status ?
                        tools.unixToTime((new Date(item.end_at)).getTime()/1000) :
                        <Tag color="#f00" style={{fontSize: 14}}>{tools.unixToTime((new Date(item.end_at)).getTime()/1000)}</Tag>,
                    status: item.status,
                    remarks: item.remarks || '-',
                    complete_at: item.status === 1 && item.complete_at ? tools.unixToTime((new Date(item.complete_at)).getTime()/1000) : '-'
                });
            }
            this.setState({
                loading: false,
                data: newData,
            });
        }
    };

    componentDidMount() {
        this.getTaskList();
    }

    render() {
        return this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) :  (
            <>
                <Button
                    type="link"
                    icon="left"
                    onClick={() => {
                        this.props.history.goBack();
                    }}
                >返回</Button>

                <Card
                    title="我的历史记录"
                    bordered={false}
                >
                    {
                        this.state.data.length ? (
                            this.state.data.map((value, index) => {
                                return (
                                    <Table
                                        key={index}
                                        columns={this.columns}
                                        dataSource={value}
                                        pagination={false}
                                        bordered
                                        title={() => <span style={{fontSize: 18, fontWeight: 700}}>{moment(value[0].create_at).format('YYYY年M月')}</span>}
                                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                        size={memory.is_mobile ? 'small' : 'middle'}
                                        style={{
                                            marginBottom: 24
                                        }}
                                    />
                                );
                            })
                        ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                </Card>
            </>
        );
    }
}