import React from "react";
import {
    Spin,
    Form,
    Card,
    Row,
    Col,
    Input,
    Button,
    message,
    Popconfirm,
    Select,
} from "antd";

import { adminApi } from "../../../../../api";
const {
    reqVersionAdd,
} = adminApi;

class VersionDetail extends React.Component {
    state = {
        loading: false,
        detail: {},
    };
    id = this.props.match.params.id;

    onFinish = async (values) => {
        const response = await reqVersionAdd(values);
        if (response && response.data) {
            message.success('添加成功');
        }
    };

    render() {
        return this.id && this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <Form onFinish={this.onFinish}>
                <Card
                    title={"APP版本信息"}
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                    extra={
                        this.id ? (
                            <Popconfirm
                                title="确定删除该角色吗?"
                                onConfirm={this.delRole}
                                okText="确定"
                                cancelText="取消"
                            >
                                <Button>删除</Button>
                            </Popconfirm>
                        ) : null
                    }
                >
                    <Card
                        title="销售打卡APP"
                        bordered={false}
                        type="inner"
                    >
                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="APP类型"
                                    name="type"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择APP类型',
                                        },
                                    ]}
                                >
                                    <Select placeholder="请选择APP类型">
                                        <Select.Option key={2} value={2} >前台应用</Select.Option>
                                        <Select.Option key={1} value={1} >后台服务</Select.Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="版本号"
                                    name="version"
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入版本号',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入版本号" />
                                </Form.Item>
                                <Form.Item
                                    label="版本介绍"
                                    name="version_introduction"
                                >
                                    <Input placeholder="请输入版本号" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Card>

                <div className="submit-footer">
                    <Button type="primary" htmlType="submit">
                        {
                            this.id ? '保存' : '提交'
                        }
                    </Button>
                </div>
            </Form>
        );
    }
}

export default VersionDetail;