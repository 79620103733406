import React from "react";
import {
    Button,
    message,
} from "antd";

import { crmApi } from "../../../../../../../api";
import "./index.less";
import BasicDetail from '../../basic-detail'
import ApproveProgress from "../../approve-progress";
import PackageItem from '../../package-item'
const {
    reqApplyDetail,
    reqInvoicePackageEdit
} = crmApi;

class InvoiceApprove extends React.Component {
    state = {
        detail: {},
        fileList: [],
        submitLoading: false,
        fileLoading: false,
    };
    clientid = null;//客户id
    cid = null; //合同id
    id = this.props.match.params.id; //发票id
    contract = null;
    client = null;
    detail = {};

    validate = async (params, callback) => {
        const response = await reqInvoicePackageEdit(params);
        if (response && response.data) {
            message.success('录入成功');
            this.props.history.push(`/moa/invoice/package/list`);
        }
        if (callback) callback();
    }

    getInvoiceDetail = async (params = {}) => {
        const response = await reqApplyDetail(params);
        if (response && response.data) {
            this.detail = response.data;
            this.setState({});
        }
    }

    componentDidMount() {
        this.getInvoiceDetail({ id: this.id });
    }

    render() {
        return (
            <>
                <Button
                    type="link"
                    icon="left"
                    onClick={() => {
                        this.props.history.goBack();
                    }}
                >返回</Button>

                <BasicDetail
                    detail={this.detail}
                    type='approve'
                />

                <ApproveProgress
                    detail={this.detail}
                    type='approve'
                />

                {
                    this.detail && this.detail._id ?
                        <PackageItem
                            detail={this.detail}
                            validate={this.validate}
                            type='approve'
                            id={this.id}
                        />
                        :
                        null
                }
            </>

        );
    }
}

export default InvoiceApprove;