import React from "react";
import {Avatar, Modal} from "antd";

export default class PhotoShow extends React.Component {
    state = {
        visible: this.props.visible
    };
    handleCancel = () => this.setState({ visible: false });

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            visible: nextProps.visible
        })
    }

    render() {
        const {previewImage} = this.props;
        return (
            <Modal visible={this.state.visible} footer={null} onCancel={this.handleCancel}>
                <img alt="图片" style={{ width: '100%' }} src={previewImage} />
            </Modal>
        );
    }
}