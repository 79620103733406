import React from 'react'
import {
    Button,
    Descriptions,
    Tooltip,
    Modal,
    Form,
    Input,
    message,
} from 'antd';
import moment from "moment";
import { withRouter } from 'react-router-dom';
import { PaperClipOutlined } from '@ant-design/icons';

import './index.less'
import tools from '../../utils/tools';
import memory from '../../utils/memory';
import { crmApi } from "../../api";
import { ROLES } from '../../utils/constant';

const { reqFileApply } = crmApi;

class DownloadLink extends React.Component {

    isDownloadRole = !!memory.user.roles.find(o => o.type === ROLES.MOAADMIN || o.type === ROLES.CONTRACTADMIN || o.type === ROLES.PRESELLER)

    getModal = (params = {}) => {
        let content = "";
        const handleContentChange = (e) => {
            content = e.target.value;
        }

        const handleSubmit = async () => {
            if (content) {
                params.content = content
            }
            const response = await reqFileApply(params);
            if (response?.data) {
                message.success("申请成功");
            }
        }

        Modal.confirm({
            title: <span>合同文件<span style={{ fontWeight: "bolder" }}>{params.permission_type === 1 ? "查看" : "下载"}</span>申请</span>,
            icon: null,
            content: (
                <Form
                >
                    <Form.Item label={"请填写申请理由"}>
                        <Input placeholder='请填写申请理由' onChange={handleContentChange} />
                    </Form.Item>
                </Form>
            ),
            okText: "确定",
            cancelText: "取消",
            onOk: handleSubmit,
        });
    }
    render() {
        let { title, file, isContract, contract } = this.props;
        return (
            <div className='download-link'>
                <Descriptions
                    title={title}
                >
                </Descriptions>
                {
                    file && file.length && file[0].name ? (
                        <>
                            {
                                file.map((file, index) => (
                                    <div key={file.name + index} style={{ display: 'flex', justifyContent: 'space-between', marginTop: index ? 8 : 0, ...(this.props?.style || {}) }}>
                                        <span>
                                            <PaperClipOutlined className="attachment-item-icon" />
                                            <Button
                                                type="link"
                                                className="attachment-item-content"
                                                onClick={() => {
                                                    tools.downloadFile(this.props.history, file, `${memory.user.dd_info.name} ${moment().format("YYYY/MM/DD")}`, !!isContract, false).then(//有返回值表示文件无查看权限，需要发起申请查看
                                                        res => res?.data && this.getModal({ filename: file.name, filepath: file.url, permission_type: file?.name?.substring(file?.name?.lastIndexOf(".")).toLowerCase() === ".pdf" ? 1 : 2, contract: contract })
                                                    )
                                                }}
                                            >
                                                {file.name || '-'}
                                            </Button>
                                        </span>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {
                                                isContract ?
                                                    <>
                                                        <Button
                                                            type='primary'
                                                            size='small'
                                                            onClick={() => {
                                                                tools.downloadFile(this.props.history, file, `${memory.user.dd_info.name} ${moment().format("YYYY/MM/DD")}`, !!isContract, false).then(//有返回值表示文件无查看权限，需要发起申请查看
                                                                    res => res?.data && this.getModal({ filename: file.name, filepath: file.url, permission_type: 1, contract: contract })
                                                                )
                                                            }}
                                                            style={{
                                                                marginRight: 4
                                                            }}
                                                            disabled={!file?.name?.substring(file?.name?.lastIndexOf(".")).toLowerCase() === ".pdf"}
                                                        >
                                                            查看
                                                        </Button>
                                                        {
                                                            this.isDownloadRole ?
                                                                <>
                                                                    <Tooltip
                                                                        title='点击此按钮会直接下载文件，并且文件名与左边显示的一致。'
                                                                        arrowPointAtCenter
                                                                        getPopupContainer={(trigger) => trigger.parentElement}
                                                                        placement={'topLeft'}
                                                                    >
                                                                        <Button
                                                                            type='primary'
                                                                            size='small'
                                                                            onClick={() => {
                                                                                tools.downloadFile(this.props.history, file, "", !!isContract, true, true).then(//有返回值表示文件无查看权限，需要发起申请查看
                                                                                    res => res?.data && this.getModal({ filename: file.name, filepath: file.url, permission_type: 2, contract: contract, watermark: true })
                                                                                )
                                                                            }}
                                                                            style={{
                                                                                marginRight: 4
                                                                            }}
                                                                        >
                                                                            下载有水印
                                                                        </Button>
                                                                    </Tooltip>
                                                                    <Tooltip
                                                                        title='点击此按钮会直接下载文件，并且文件名与左边显示的一致。'
                                                                        arrowPointAtCenter
                                                                        getPopupContainer={(trigger) => trigger.parentElement}
                                                                        placement={'topLeft'}
                                                                    >
                                                                        <Button
                                                                            type='primary'
                                                                            size='small'
                                                                            onClick={() => {
                                                                                tools.downloadFile(this.props.history, file, "", !!isContract, true, false).then(//有返回值表示文件无查看权限，需要发起申请查看
                                                                                    res => res?.data && this.getModal({ filename: file.name, filepath: file.url, permission_type: 2, contract: contract, watermark: false })
                                                                                )
                                                                            }}
                                                                        >
                                                                            下载无水印
                                                                        </Button>
                                                                    </Tooltip>
                                                                </>
                                                                :
                                                                null
                                                        }
                                                    </>
                                                    :
                                                    <Tooltip
                                                        title='点击此按钮会直接下载文件，并且文件名与左边显示的一致。'
                                                        arrowPointAtCenter
                                                        getPopupContainer={(trigger) => trigger.parentElement}
                                                        placement={'topLeft'}
                                                    >
                                                        <Button
                                                            type='primary'
                                                            size='small'
                                                            onClick={() => {
                                                                tools.downloadFile(this.props.history, file, "", !!isContract, true).then(//有返回值表示文件无查看权限，需要发起申请查看
                                                                    res => res?.data && this.getModal({ filename: file.name, filepath: file.url, permission_type: 2, contract: contract })
                                                                )
                                                            }}
                                                        >
                                                            下载
                                                        </Button>
                                                    </Tooltip>
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </>
                    ) : '无'
                }
            </div>
        )
    }
}

export default withRouter(DownloadLink);