import React from "react";
import {
    Card,
    Table,
    Input,
    Icon,
    Button,
} from "antd";
import {
    Link,
} from "react-router-dom";
import { SearchOutlined } from '@ant-design/icons';

import tools from "../../../../../../utils/tools";
import {crmApi} from "../../../../../../api";
import LinkButton from "../../../../components/link-button";
import {
    PAGE_SIZE
} from '../../../../../../utils/constant'
import getListStatus from "../list-status";

const {
    reqApplyList
} = crmApi;

export default class InvoiceApplyList extends React.Component {
    state = {
        data: [],
        pagination: {
            showSizeChanger: true,
            showTotal: total => `总数${total}条`,
            pageSizeOptions: ["10","20","50","100","200"],
            pageSize: PAGE_SIZE,
        },
        loading: false
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        pager.pageSize = pagination.pageSize;
        this.setState({
            pagination: pager
        });
        let tmpFilters = {...filters};
        for(let item in tmpFilters) {
            if(tmpFilters[item]) {
                if(typeof(tmpFilters[item]) !== 'string' && item !== 'tags') {
                    tmpFilters[item] = tmpFilters[item][0];
                }
            }
        }
        if(tmpFilters.customer_name){
            tmpFilters.company_name = tmpFilters.customer_name;
            delete tmpFilters.customer_name;
        }
        let params = {
            page: pagination.current,
            node: pagination.pageSize,
            ...tmpFilters,
            type: 10,
            stage:5,
            is_history: false
        };
        this.getApplyList(params);
    }

    /*
    *搜索相关
    * */
    getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`搜索 ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    搜索
                </Button>
                <Button onClick={() => this.handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    重置
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => text
    });
    handleSearch = (selectedKeys, confirm) => {
        confirm();
    };
    handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
    };

    columns = [
        {
            title: '发票编号',
            dataIndex: 'Invoice_number',
            key: 'Invoice_number',
            ...this.getColumnSearchProps('Invoice_number', '项目名')
        },
        {
            title: '合同名称',
            dataIndex: 'contract_name',
            key: 'contract_name',
            ...this.getColumnSearchProps('contract_name', '合同名称')
        },
        {
            title: '批次',
            dataIndex: 'batch',
            key: 'batch'
        },
        {
            title: '审批状态',
            dataIndex: 'is_history',
            key: 'is_history',
            render: (data, record) => {
                return (
                    getListStatus(record.stage,data,record.is_examined)
                );
            }
        },
        {
            title: '申请时间',
            dataIndex: 'create_time',
            key: 'create_time',
        },
        {
            title: '操作',
            dataIndex: 'option',
            valueType: 'option',
            render: (_, record, index) => {
                return (
                    <Link to={`/moa/invoice/package/detail/${record.key}`}>详情</Link>
                );
            }
        },
    ];

    getApplyList = async (params = {}) => {
        this.setState({
            loading: true
        });
        const response = await reqApplyList(params);
        const pagination = {...this.state.pagination};
        const data = response.data;
        pagination.total = data.page.total;
        pagination.pageSize = data.page.node;
        let newData = [];
        for(let i = 0; i < data.data.length; i++) {
            const item = data.data[i];
            let obj = {
                key: item._id,
                Invoice_number:item.data && item.data.Invoice_number?item.data.Invoice_number : '-',
                contract_name:item.data && item.data.contract && item.data.contract.name?item.data.contract.name : '-',
                batch:item.data && item.data.contract && item.data.contract.batch?`第${item.data.contract.batch}批` : '-',
                creator_name: item.creator.name,
                is_history: item.is_history || false,
                is_examined: item.is_examined || false,
                stage:item.data && item.data.stage?item.data.stage : null,
                create_time: tools.unixToTime((new Date(item.create_at)).getTime()/1000),
            };
            newData.push(obj);
        }

        this.setState({
            loading: false,
            data: newData,
            pagination
        });
    };

    componentDidMount() {
        this.getApplyList({
            page: 1,
            node: PAGE_SIZE,
            type: 10,
            is_history: false,
            stage:5,
        });
    }

    render() {
        return (
            <Card
                title="审批列表"
                bordered={false}
                extra={
                    <LinkButton to={`/moa/invoice/package/history`} txt="历史记录" style={{fontSize: 12}} />
                }
            >
                <Table
                    className="small-table-style"
                    columns={this.columns}
                    dataSource={this.state.data}
                    pagination={this.state.pagination}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                    size={'small'}
                />
            </Card>
        );
    }
}