import React from "react";
import {
    Spin,
    Card,
    Form,
    Row,
    Col,
    Input,
    Select,
    Button,
    message,
    Popconfirm,
    DatePicker
} from "antd";

import { moaApi } from "../../../../../api";
import {
    AWARDQUALITY,
    AWARDTYPE,
    SOURCE_TYPE,
    AWARDTARGET,
} from "../../../../../utils/constant";
import moment from "moment";

const { Option } = Select;
const { TextArea } = Input;
const {
    reqAwardsAdd,
    reqUserList,
    reqAwardsDetail,
    reqAwardsEdit,
    reqAwardsDel,
    reqAgencyList,
    reqPlatformList
} = moaApi;

class AwardsAdd extends React.Component {
    state = {
        loading: true,
        detail: {},
        agency: [],
        platform: [],
    };
    id = this.props.match.params.id;
    yearOptions = [];
    userOptions = [];
    provideOptions = [
        {
            value: 1,
            label: '人员',
            children: []
        },
        {
            value: 2,
            label: '代理机构',
            children: []
        }
    ];

    validate = async (values) => {
        if (values.data_deadline) {
            values.data_deadline = new Date(values.data_deadline._d);
        }
        let response;
        if (!this.id) {
            for (let i in values) {
                if (!values[i]) {
                    delete values[i];
                }
            }
        }
        values.award_date = values.award_date.toString();
        if (this.id) {
            values.id = this.id;
            response = await reqAwardsEdit(values);
            if (response && response.data) {
                message.success('修改成功');
                this.props.history.goBack();
            }
        } else {
            response = await reqAwardsAdd(values);
            if (response && response.data) {
                message.success('添加成功');
            }
        }
    };

    getUserList = async (params = {}) => {
        const resposne = await reqUserList(params);
        if (resposne && resposne.data) {
            const data = resposne.data;
            for (let i = 0; i < data.data.length; i++) {
                this.userOptions.push({
                    value: data.data[i]._id,
                    text: data.data[i].dd_info.name
                });
                this.provideOptions[0].children.push({
                    value: data.data[i]._id,
                    label: data.data[i].dd_info.name,
                });
            }
            this.setState({
                loading: false,
            });
        }
    };

    getCapitalDetail = async (params = {}) => {
        const response = await reqAwardsDetail(params);
        if (response && response.data) {
            this.setState({
                detail: response.data
            });
        }
    };

    delCapital = async () => {
        const response = await reqAwardsDel({
            id: this.id
        });
        if (response && response.data) {
            message.success('删除成功');
            this.props.history.replace('/moa/awards/list');
        }
    };

    getAgencyList = async (params = {}) => {
        const response = await reqAgencyList(params);
        const data = response.data;
        let newData = [];
        for (let i = 0; i < data.data.length; i++) {
            const item = data.data[i];
            const obj = {
                key: item._id,
                value: item._id,
                text: item.name,
            };
            newData.push(obj);
            this.provideOptions[1].children.push({
                value: item._id,
                label: item.name,
            });
        }
        this.setState({
            agency: newData
        });
    };

    getPlatformList = async (params = {}) => {
        const response = await reqPlatformList(params);
        const data = response.data;
        let newData = [];
        for (let i = 0; i < data.data.length; i++) {
            const item = data.data[i];
            const obj = {
                key: item._id,
                value: item._id,
                text: item.name,
            };
            newData.push(obj);
        }
        this.setState({
            platform: newData
        });
    };

    componentWillMount() {
        for (let i = 2010; i <= (new Date()).getFullYear() + 10; i++) {
            this.yearOptions.push({
                key: i,
                value: i,
                text: i
            });
        }
    }

    componentDidMount = () => {
        if (this.id) {
            // 获取详情
            this.getCapitalDetail({
                id: this.id
            });
        }
        this.getUserList({
            node: 999
        });
        this.getAgencyList({
            node: 999,
            type: 2
        });
        this.getPlatformList({
            node: 999
        });
    }

    render() {
        const { detail } = this.state;
        let workers = [];
        let response = [];
        if (detail.team_worker && detail.team_worker.length) {
            for (let j = 0; j < detail.team_worker.length; j++) {
                workers.push(detail.team_worker[j].id);
            }
        }
        if (detail.response_unit && detail.response_unit.length) {
            for (let j = 0; j < detail.response_unit.length; j++) {
                response.push(detail.response_unit[j].id);
            }
        }

        return this.id && this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <Form onFinish={this.validate} layout="vertical">
                <Card
                    title={
                        this.id ? "修改奖项信息" : "添加奖项"
                    }
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                    extra={
                        this.id ? (
                            <Popconfirm
                                title="确定删除该奖项信息吗?"
                                onConfirm={this.delCapital}
                                okText="确定"
                                cancelText="取消"
                            >
                                <Button>删除</Button>
                            </Popconfirm>
                        ) : null
                    }
                >
                    <Card
                        title="奖项基本信息"
                        bordered={false}
                        type="inner"
                    >
                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="奖项申报年份"
                                    name="award_date"
                                    initialValue={detail.award_date ? detail.award_date : null}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择奖项申报年份',
                                        },
                                    ]}
                                >
                                    <Select placeholder="请选择奖项申报年份">
                                        {
                                            this.yearOptions.map((item, index) => (
                                                <Option key={item.key} value={item.value}>
                                                    {item.text}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="奖项名称"
                                    name="award_name"
                                    initialValue={detail.award_name || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入奖项名称',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入奖项名称" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="奖项平台"
                                    name="platform"
                                    initialValue={detail.platform && detail.platform.id ? detail.platform.id : null}
                                >
                                    <Select
                                        placeholder="请选择奖项平台"
                                    >
                                        {
                                            this.state.platform.map((item, index) => (
                                                <Option key={item.key} value={item.value}>
                                                    {item.text}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="奖项信息链接"
                                    name="official_website"
                                    initialValue={detail.official_website || ''}
                                    rules={[
                                        {
                                            type: 'url',
                                            message: '请输入完整链接'
                                        }
                                    ]}
                                >
                                    <Input placeholder="请输入奖项信息链接" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="奖项质量"
                                    name="award_quality"
                                    initialValue={detail.award_quality || null}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择奖项质量',
                                        },
                                    ]}
                                >
                                    <Select placeholder="请选择奖项质量">
                                        {
                                            AWARDQUALITY.map((item, index) => (
                                                <Option key={item.value} value={item.value}>
                                                    {item.text}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="奖项性质"
                                    name="award_type"
                                    initialValue={detail.award_type || null}
                                >
                                    <Select placeholder="请选择奖项性质">
                                        {
                                            AWARDTYPE.map((item, index) => (
                                                <Option key={item.value} value={item.value}>
                                                    {item.text}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="面向对象"
                                    name="oriented_target"
                                    initialValue={detail.oriented_target ? parseInt(detail.oriented_target) : null}
                                >
                                    <Select placeholder="请选择奖项面向对象">
                                        {
                                            AWARDTARGET.map((item, index) => (
                                                <Option key={item.value} value={item.value}>
                                                    {item.text}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="项目经理"
                                    name="manager"
                                    initialValue={detail.manager && detail.manager.id ? detail.manager.id : null}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择项目经理',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="请选择项目经理"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            this.userOptions.map((item, index) => (
                                                <Option key={item.value} value={item.value}>
                                                    {item.text}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="协作者"
                                    name="team_worker"
                                    initialValue={workers.length ? workers : null}
                                >
                                    <Select
                                        showSearch
                                        placeholder="请选择协作者"
                                        mode="multiple"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            this.userOptions.map((item, index) => (
                                                <Option key={item.value} value={item.value}>
                                                    {item.text}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="申请截止时间"
                                    name="data_deadline"
                                    initialValue={detail.data_deadline ? moment(detail.data_deadline) : null}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请选择申请截止时间" showTime />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="代理机构"
                                    name="agency"
                                    initialValue={detail.agency && detail.agency.id ? detail.agency.id : null}
                                >
                                    <Select
                                        placeholder="请选择代理机构"
                                    >
                                        {
                                            this.state.agency.map((item, index) => (
                                                <Option key={item.key} value={item.value}>
                                                    {item.text}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="备注"
                                    name="remark"
                                    initialValue={detail.remark || ''}
                                >
                                    <Input placeholder="备注" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item
                                    label="奖项简介"
                                    name="brief"
                                    initialValue={detail.brief || ''}
                                >
                                    <TextArea rows={4} placeholder="请填写奖项简介" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item
                                    label="申报条件"
                                    name="declare_condition"
                                    initialValue={detail.declare_condition || ''}
                                >
                                    <TextArea rows={4} placeholder="请填写申报条件" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item
                                    label="申报步骤"
                                    name="declare_step"
                                    initialValue={detail.declare_step || ''}
                                >
                                    <TextArea rows={4} placeholder="请填写申报步骤" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item
                                    label="评奖标准"
                                    name="standard_of_review"
                                    initialValue={detail.standard_of_review || ''}
                                >
                                    <TextArea rows={4} placeholder="请填写评奖标准" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>

                    <Card
                        title="奖项信息来源"
                        bordered={false}
                        type="inner"
                    >
                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="来源类型"
                                    name="source_type"
                                    initialValue={detail.source_type || null}
                                >
                                    <Select placeholder="请选择来源类型">
                                        {SOURCE_TYPE.map((item, index) => (
                                            <Option key={index} value={item.key}>
                                                {item.value}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="来源渠道"
                                    name="source_channel"
                                    initialValue={detail.source_channel || null}
                                >
                                    <Input placeholder="请输入来源渠道" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Card>

                <div className="submit-footer">
                    <Button type="primary" onClick={this.validate}>
                        {
                            this.id ? '保存' : '提交'
                        }
                    </Button>
                </div>
            </Form>
        );
    }
}

export default AwardsAdd;