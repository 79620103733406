import React from "react";
import {
    ConfigProvider,
    Spin,
    Layout,
    Popover,
    Avatar,
    Button,
    message,
} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import { Link, Route, Switch } from "react-router-dom";
import * as dd from 'dingtalk-jsapi';
import isMobile from "ismobilejs";
import moment from 'moment';
import 'moment/locale/zh-cn';
import { UnorderedListOutlined, SafetyOutlined, HomeOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';

import "./index.less";
import memory from "../../utils/memory";
import LeftNav from "./components/left-nav/LeftNav";
import {
    AUTH,
    AVATAR,
    CORPID,
    ROLES,
    MenuSide
} from "../../utils/constant";
import {
    reqSelfInfo,
    reqSignout,
    repositoryApi,
    reqDepartmentList
} from "../../api";
import storage from "../../utils/storage";

import Home from "./routes/home";
import ArticleAdd from "./routes/article/add";
import ArticleDetail from "./routes/article/detail";
import ClassifyAdd from "./routes/classify/add";
import ModuleAdd from "./routes/module/add";
import MyClassifyList from "./routes/classify/my";
import Catalogue from "./routes/catalogue";

moment.locale('zh-cn');
const { Header, Footer, Sider } = Layout;
const { department_obj } = memory;
const {
    reqLogin,
    reqAllClassifies,
    reqArticleList,
} = repositoryApi;
const {
    RIGHTS
} = ROLES;

export default class RepositoryIndex extends React.Component {
    state = {
        loading: true,
        collapsed: isMobile(navigator.userAgent).any ? true : false,
        menu_list: [],
        classifies: [],
        articles: [],
    };

    userInfoHeader = () => {
        return (
            <div className="pop-header">
                <Avatar size="small" className="pop-avatar" src={memory.user.dd_info.avatar} />
                <span className="pop-name">{memory.user.dd_info.name}</span>
            </div>
        );
    }
    userInfoContent = () => {
        return (
            <div className="pop-content">
                <Link to="/repository/classify/my" className="pop-content-item">
                    <UnorderedListOutlined className="icon" />
                    <span className="item-name">我的分类</span>
                </Link>
                {
                    memory.user.role_type.indexOf(RIGHTS) > -1 ? (
                        <Link to="/admin" className="pop-content-item">
                            <SafetyOutlined className="icon" />
                            <span className="item-name">后台管理</span>
                        </Link>
                    ) : null
                }
                <div className="pop-content-info">
                    <div className="info-item">
                        <label className="info-item-label">部门：</label>
                        <span className="info-item-name">{department_obj[memory.user.dd_info.department[0]]}</span>
                    </div>
                </div>
                {
                    dd.env.platform !== 'notInDingTalk' ? null : (
                        <footer className="pop-content-footer">
                            <Button type="link" onClick={this.handleSignout}>退出登录</Button>
                        </footer>
                    )
                }
            </div>
        );
    }

    getMenuNodes = (arr, classifyObj, articleObj) => {
        if (arr) {
            for (let i = 0; i < arr.length; i++) {
                if (classifyObj[arr[i].id] || articleObj[arr[i].id]) {
                    if (classifyObj[arr[i].id]) {
                        arr[i].children = classifyObj[arr[i].id];
                    }

                    if (articleObj[arr[i].id]) {
                        if (arr[i].children) {
                            arr[i].children = arr[i].children.concat(articleObj[arr[i].id]);
                        } else {
                            arr[i].children = articleObj[arr[i].id];
                        }
                    }
                    this.getMenuNodes(classifyObj[arr[i].id], classifyObj, articleObj);
                }
            }
            return arr;
        }
    }
    getMenu = () => {
        (async () => {
            const response = await reqAllClassifies();
            const articles = await reqArticleList();
            let articleObj = {}; //key是分类id，value是文章数组
            if (articles.data && response.data) {
                for (let i = 0; i < articles.data.length; i++) {
                    let item = articles.data[i];
                    if (item && item.classify_id) {
                        for (let j = 0; j < item.classify_id.length; j++) {
                            let classify = item.classify_id[j];
                            if (!articleObj[classify]) {
                                articleObj[classify] = [];
                            }
                            articleObj[classify].push({
                                id: item._id,
                                title: item.title
                            });
                        }
                    }
                }

                let classifyObj = {}; //key是上级分类id，value是下级分类数组
                let menus = [];
                for (let i = 0; i < response.data.length; i++) {
                    let item = response.data[i];
                    if (item.parent_id) {
                        if (!classifyObj[item.parent_id]) {
                            classifyObj[item.parent_id] = [];
                        }
                        classifyObj[item.parent_id].push({
                            id: item._id,
                            name: item.name
                        });
                    } else {
                        menus.push({
                            id: item._id,
                            name: item.name
                        });
                    }
                }
                const result = this.getMenuNodes(menus, classifyObj, articleObj);
                this.setState({
                    menu_list: result,
                    classifies: response.data,
                    articles: articles.data
                });
            }
        })();
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };
    handleSignout = () => {
        (async () => {
            const response = await reqSignout();
            if (response) {
                message.success('账号已退出');
                storage.removeStore(AUTH);
                this.props.history.replace('/');
            }
        })();
    }

    toLogin = async (code) => {
        const response = await reqLogin({
            code: code,
        });
        const auth = response.data.token;
        storage.saveStore(AUTH, auth);
        message.success('登录成功');

        // 获取个人信息
        const self = await reqSelfInfo();
        if (self && self.data) {
            memory.user = self.data;
            memory.user.dd_info.avatar = memory.user.dd_info.avatar || AVATAR;
            memory.user.role_type = [];
            if (self.data.roles && self.data.roles.length) {
                for (let i = 0; i < self.data.roles.length; i++) {
                    memory.user.role_type.push(self.data.roles[i].type);
                }
            }
            // 获取部门信息
            const dep = await reqDepartmentList();
            if (dep) {
                memory.departments = dep.data;
                for (let i = 0; i < dep.data.length; i++) {
                    memory.department_obj[dep.data[i].id] = dep.data[i].name;
                }
            }
            this.getMenu();
            this.setState({
                loading: false
            });
        }
    }

    componentDidMount = () => {
        const that = this;
        if (dd.env.platform !== 'notInDingTalk') {
            dd.ready(function () {
                dd.runtime.permission.requestAuthCode({
                    corpId: CORPID, // 企业id
                    onSuccess: function (info) {
                        const code = info.code; // 通过该免登授权码可以获取用户身份
                        that.toLogin(code);
                    },
                    onFail: function (err) {
                        message.error('requestAuthCode error: ' + JSON.stringify(err));
                    }
                });
            });
            dd.error(function (error) {
                message.error('dd error: ' + JSON.stringify(error));
            });
        } else {
            this.getMenu();
            this.setState({
                loading: false
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (memory.is_mobile) {
            if (this.props.location.pathname !== prevProps.location.pathname) {
                this.setState({
                    collapsed: true
                });
            }
        }
    }

    render() {
        let winWidth = 200;

        return (
            <MenuSide.Provider value={this.getMenu}>
                <Layout className="crm">
                    {
                        this.state.loading ? (
                            <div className="loading-container">
                                <Spin size="large" />
                            </div>
                        ) : (
                            <ConfigProvider locale={zhCN}>
                                <Sider
                                    className="repository-menu"
                                    breakpoint="lg"
                                    collapsedWidth={memory.is_mobile ? 0 : 80}
                                    trigger={null}
                                    collapsible
                                    collapsed={this.state.collapsed}
                                    style={{
                                        overflow: 'auto',
                                        height: '100%',
                                        position: 'fixed',
                                        left: 0,
                                        zIndex: 99,
                                    }}
                                >
                                    <div className="crm-logo" title="首页">
                                        <Link to="/repository">
                                            <HomeOutlined className="logo-icon" style={{ color: '#ccc', fontSize: 26 }} />
                                            {
                                                memory.is_mobile ? (
                                                    <span className="logo-name" style={{ color: '#ccc', fontSize: 26, marginLeft: 20 }}>知识库</span>
                                                ) : (
                                                    this.state.collapsed ? null : (
                                                        <span className="logo-name" style={{ color: '#ccc', fontSize: 26, marginLeft: 20 }}>知识库</span>
                                                    )
                                                )
                                            }
                                        </Link>
                                    </div>
                                    {
                                        memory.is_mobile ? (
                                            <LeftNav menu_list={this.state.menu_list} classifies={this.state.classifies} articles={this.state.articles} />
                                        ) : (
                                            this.state.collapsed ? null : (
                                                <LeftNav menu_list={this.state.menu_list} classifies={this.state.classifies} articles={this.state.articles} />
                                            )
                                        )
                                    }
                                </Sider>

                                <Layout
                                    style={{
                                        marginLeft: memory.is_mobile ? 0 : (this.state.collapsed ? 80 : winWidth)
                                    }}
                                >
                                    <Header
                                        className="crm-header"
                                        style={{
                                            position: 'fixed',
                                            zIndex: 97,
                                            left: 0,
                                            right: 0,
                                            marginLeft: memory.is_mobile ? 0 : (this.state.collapsed ? 80 : winWidth)
                                        }}
                                    >
                                        {
                                            this.state.collapsed ? <MenuUnfoldOutlined className="crm-trigger" onClick={this.toggle} /> : <MenuFoldOutlined className="crm-trigger" onClick={this.toggle} />
                                        }
                                        <div className="user">
                                            <Popover className="popover" content={this.userInfoContent()} title={this.userInfoHeader()} placement="bottomRight">
                                                <Avatar className="avatar" src={memory.user.dd_info.avatar} />
                                            </Popover>
                                            <Link to="/repository/article/add" className="report">
                                                <Button type="primary">发帖</Button>
                                            </Link>
                                        </div>
                                    </Header>

                                    <Switch>
                                        <Route path="/repository/classify/my" component={MyClassifyList}></Route>
                                        <Route path="/repository/module/edit/:id" component={ModuleAdd}></Route>
                                        <Route path="/repository/module/add" component={ModuleAdd}></Route>
                                        <Route path="/repository/classify/edit/:id" component={ClassifyAdd}></Route>
                                        <Route path="/repository/classify/add" component={ClassifyAdd}></Route>
                                        <Route path="/repository/article/detail/:id" component={ArticleDetail}></Route>
                                        <Route path="/repository/article/edit/:id" component={ArticleAdd}></Route>
                                        <Route path="/repository/article/add" component={ArticleAdd}></Route>
                                        <Route path="/repository/catalogue/:id" component={Catalogue}></Route>
                                        <Route path="/repository" component={Home}></Route>
                                    </Switch>

                                    <Footer style={{ textAlign: 'center' }}>谋乐网络科技 ©{moment().year()}</Footer>
                                    <div
                                        className="mask-layout"
                                        style={{
                                            display: memory.is_mobile ? (this.state.collapsed ? 'none' : 'block') : 'none'
                                        }}
                                        onClick={() => {
                                            this.setState({
                                                collapsed: true
                                            });
                                        }}
                                    >
                                    </div>
                                </Layout>
                            </ConfigProvider>
                        )
                    }
                </Layout>
            </MenuSide.Provider>
        );
    }
}