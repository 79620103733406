import React from 'react'
import {Table,Card} from 'antd'
import moment from 'moment'
import getListStatus from '../list-status';

export default class ApproveProgress extends React.Component {

    state = {
        schedule:[],
    }

    column = [
        {
            key:'create_at',
            dataIndex:'create_at',
            title:'时间',
            render : (text) => (
                text?moment(text).format('YYYY/MM/DD HH:mm:ss'):'-'
            )
        },
        {
            key:'remark',
            dataIndex:'remark',
            title:'进度内容',
            render: (text) => (
                text || '-'
            )
        },
        {
            key:'application',
            dataIndex:'application',
            title:'原因',
            render: (text) => (
                text || '-'
            )
        },
    ]

    componentWillReceiveProps(newProps){
        let {detail} = newProps;
        let stage = detail && detail.data && detail.data.stage?detail.data.stage:null;
        let is_examined = detail&& detail.is_examined != null?detail.is_examined:null;
        let is_history = detail && detail.is_history != null?detail.is_history:null;
        let next_progress = {
            create_at:detail && detail.data && detail.data.schedule && detail.data.schedule.length?detail.data.schedule[detail.data.schedule.length-1].create_at:null,
            remark:getListStatus(stage,is_history,is_examined,true)
        }
        if(detail && detail.data && detail.data.schedule){
            detail.data.schedule.push(next_progress);
            for(let i in detail.data.schedule){
                detail.data.schedule[i].key = i;
            } 
            this.setState({
                schedule:detail.data.schedule
            })
        }
    }

    componentDidMount(){
    }

    render(){
        let {detail} = this.props;
        return(
            <Card
                title={'申请进度'}
                bordered={false}
                style={{
                    marginBottom:24
                }}
            >
                <Table
                    columns={this.column}
                    dataSource={this.state.schedule || []}
                    pagination={false}
                />
            </Card>
        )
    }
}