import React from "react";
import {
    Spin,
    Card,
    Form,
    Row,
    Col,
    Input,
    Button,
    message,
    Popconfirm,
    DatePicker,
} from "antd";
import moment from "moment";

import { moaApi } from "../../../../../../api";
import NumberInput from "../../../../../crm/components/number-input";

const {
    reqExpenseAdd,
    reqExpenseDel,
    reqExpenseEdit,
    reqExpenseDetail
} = moaApi;

class ExpenseAdd extends React.Component {
    state = {
        loading: true,
        detail: {},
    };
    cid = this.props.match.params.cid;
    id = this.props.match.params.id;

    validate = async (values) => {
        values.expenses_time = new Date(values.expenses_time._d);
        values.fund_id = this.cid;
        let response;
        if (!this.id) {
            for (let i in values) {
                if (!values[i]) {
                    delete values[i];
                }
            }
        }
        if (this.id) {
            values.id = this.id;
            response = await reqExpenseEdit(values);
            if (response && response.data) {
                message.success('修改成功');
                this.props.history.goBack();
            }
        } else {
            response = await reqExpenseAdd(values);
            if (response && response.data) {
                message.success('添加成功');
            }
        }
    };

    delExpense = async () => {
        const response = await reqExpenseDel({
            id: this.id
        });
        if (response && response.data) {
            message.success('删除成功');
            this.props.history.replace(`/moa/capital/detail/${this.state.detail.fund.id}`);
        }
    };

    getExpenseDetail = async (params = {}) => {
        const response = await reqExpenseDetail(params);
        if (response && response.data) {
            this.setState({
                detail: response.data,
                loading: false
            });
        }
    };

    componentDidMount() {
        if (this.id) {
            // 获取详情
            this.getExpenseDetail({
                id: this.id
            });
        }
    }

    render() {
        const { detail } = this.state;

        return this.id && this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <Form onFinish={this.validate}>
                <Button
                    type="link"
                    icon="left"
                    onClick={() => {
                        this.props.history.goBack();
                    }}
                >返回</Button>

                <Card
                    title={
                        this.id ? "修改支出资金信息" : "添加支出资金"
                    }
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                    extra={
                        this.id ? (
                            <Popconfirm
                                title="确定删除该支出资金信息吗?"
                                onConfirm={this.delExpense}
                                okText="确定"
                                cancelText="取消"
                            >
                                <Button>删除</Button>
                            </Popconfirm>
                        ) : null
                    }
                >
                    <Card
                        title="支出资金基本信息"
                        bordered={false}
                        type="inner"
                    >
                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="支出金额"
                                    name="amount"
                                    initialValue={detail.amount || 0}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入支出金额',
                                        },
                                    ]}
                                >
                                    <NumberInput
                                        addonAfter="元"
                                        placeholder="请输入支出金额"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="支出时间"
                                    name="expenses_time"
                                    initialValue={detail.expenses_time ? moment(detail.expenses_time) : null}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择支出时间',
                                        },
                                    ]}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请选择到账时间" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="支出资金说明"
                                    name="remark"
                                    initialValue={detail.remark || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入支出资金说明',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入支出资金说明" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Card>

                <div className="submit-footer">
                    <Button type="primary" htmlType="submit">
                        {
                            this.id ? '保存' : '提交'
                        }
                    </Button>
                </div>
            </Form>
        );
    }
}

export default ExpenseAdd;