import React from "react";
import {List,Avatar,Spin,Button} from "antd";
import InfiniteScroll  from "react-infinite-scroller";
import moment from "moment";
import { EditOutlined, MailOutlined } from '@ant-design/icons';

import "./index.less";
import memory from "../../../../utils/memory";
import { siteMessageApi } from "../../../../api";
import { REDIRECT_URI, UserInfoContext } from "../../../../utils/constant";

const {
    reqMessageList,
    reqMessageStatusChange,
    reqUnreadMessageAmount
} = siteMessageApi;

export default class NoticeList extends React.Component{
    static contextType = UserInfoContext;
    state={
        data : [],
        hasMore : false,
        loading : true,
    };

    handleInfiniteOnLoad = () => {
    }

    handleRead = async(params = {}) => {
        if(params.status === 0){
            const response = await reqMessageStatusChange({ids : [params.key],status : 1});
            if(response?.data){
                this.getData({
                    page : 1,
                    node : 50,
                });
                this.getAmount();
            }
        }
    }

    getAmount = async() => {
        const response = await reqUnreadMessageAmount();
        if(response?.data){
            this.context.setState({unreadMessageAmount : response.data.msg});
        }
    }

    getData = async(params = {}) => {
        const response = await reqMessageList(params);
        if(response?.data){
            this.context.setState({siteMessageList : response.data.data});
        }
    }

    getAvatar = (item) => {
        if(item.url){
            return <Avatar className="notice-icon" icon={<EditOutlined />} style={{backgroundColor : "rgb(51,145,229)"}} />
        }else{
            return <Avatar className="notice-icon" icon={<MailOutlined />} style={{backgroundColor : "rgb(254,93,88)"}} />
        }
    }

    handleAll = () => {
        this.props.history.push("/crm/system/message/list");
    }
    handleClick = (item) => {
        if(item.url){
            let url = item.url.replace(REDIRECT_URI,"/");
            this.props.history.push(`${url}`);
            this.handleRead(item);
        }else{
            this.props.history.push(`/crm/system/message/detail/${item.key}`);
        }
    }

    handleReadAll = async() => {
        const response = await reqMessageList({node : 9999,status : 0});
        if(response?.data){
            let ids = response.data.data.map((value) => value._id);
            if(ids.length){
                const response1 = await reqMessageStatusChange({ids : ids,status : 1});
                if(response1?.data){
                    this.getData({
                        page : 1,
                        node : 50,
                    });
                    this.getAmount();
                }
            }
        }
    }

    componentDidMount(){
    }

    render(){
        return(
            <div className="notice-list need-scroll" style={{minWidth : memory.is_mobile ? 240 : 300,height : 200,overflow : "auto",marginBottom : 60}}>
                <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={this.handleInfiniteOnLoad}
                hasMore={!this.state.loading && this.state.hasMore}
                useWindow={false}
                >
                    <List
                        size="small"
                        dataSource={this.context.siteMessageList.map(value => ({
                            key : value._id,
                            send_at : value.send_at,
                            creator_name : value?.sender?.name || "-",
                            title : value?.message?.title || "-",
                            content : value?.message?.content || "-",
                            id : value?.message?._id,
                            url : value?.message?.link,
                            status : value.status
                        }))}
                        renderItem={item => (
                            <List.Item
                                key={item.key} 
                                style={{
                                    border : "none",
                                    margin : "0 2px 16px 2px", 
                                    padding : "0 0 0 4px",
                                    opacity : item.status === 1 ? 0.4 : 1,
                                    cursor : "pointer",
                                }}
                                // onClick={() => this.handleRead(item)}
                                onClick={() => this.handleClick(item)}
                            >
                                <List.Item.Meta
                                    avatar={this.getAvatar(item)}
                                    title={`${item.title}`}
                                    description={`${item.creator_name} ${item.send_at ? moment(item.send_at).format("YYYY/MM/DD HH:mm:ss") : "-"}`}
                                    style={{alignItems : "center"}}
                                />
                            </List.Item>
                        )}
                    >
                        {this.state.loading && this.state.hasMore && (
                            <div className="demo-loading-container">
                                <Spin />
                            </div>
                        )}
                    </List>
                    <div
                        style={{
                            textAlign: 'center',
                            marginTop: 12,
                            height: 32,
                            lineHeight: '32px',
                            position:"absolute",
                            left : 0,
                            bottom : 16,
                            width : "100%"
                        }}
                    >
                        <Button onClick={this.handleAll}>查看全部</Button>
                        <Button type="link" style={{position : "absolute", right : 0}} onClick={this.handleReadAll} >一键已读</Button>
                    </div>
                </InfiniteScroll>
            </div>
        );
    }
}