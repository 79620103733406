import React from "react";
import {
    Card,
    Table,
    Input,
    Button,
} from "antd";
import { SearchOutlined } from '@ant-design/icons';

import memory from "../../../../utils/memory";
import {adminApi} from "../../../../api";
import LinkButton from "../../../crm/components/link-button";

const {reqVersionList} = adminApi;

export default class VersionList extends React.Component {
    state = {
        data: [],
        pagination: {},
        loading: false,
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        });
        let tmpFilters = {...filters};
        for(let item in tmpFilters) {
            if(tmpFilters[item]) {
                if(typeof(tmpFilters[item]) !== 'string' && item !== 'tags') {
                    tmpFilters[item] = tmpFilters[item][0];
                }
            }
        }
        let params = {
            page: pagination.current,
            ...tmpFilters,
        };
        this.getVersionList(params);
    }
    /*
    *搜索相关
    * */
    getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`搜索 ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    搜索
                </Button>
                <Button onClick={() => this.handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    重置
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => text
    });
    handleSearch = (selectedKeys, confirm) => {
        confirm();
    };
    handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
    };

    columns = [
        {
            title: 'APP类型',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: '版本',
            dataIndex: 'version',
            key: 'version',
        },
        {
            title: '版本说明',
            dataIndex: 'version_introduction',
            key: 'version_introduction',
        },
    ];

    getVersionList = async (params = {}) => {
        this.setState({
            loading: true
        });
        const response = await reqVersionList(params);
        if(response?.data?.msg){
            const pagination = {...this.state.pagination};
            const data = response.data.msg;
            pagination.total = data.page.total;
            pagination.pageSize = data.page.node;
            let newData = [];
            for(let i = 0; i < data.data.length; i++) {
                const item = data.data[i];
                const obj = {
                    key: item._id,
                    type : item.type == 1 ? "后台服务" : item.type == 2 ? "前台应用" : "-",
                    version : item.version || "-",
                    version_introduction : item.version_introduction || "-",
                };
                newData.push(obj);
            }
            this.setState({
                loading: false,
                data: newData,
                pagination
            });
        }
    };

    componentDidMount() {
        this.getVersionList({
            page: 1,
        });
    }

    render() {
        return (
            <Card
                title="版本列表"
                bordered={false}
                extra={<LinkButton to="/admin/version/add" txt="添加版本" />}
            >
                <Table
                    columns={this.columns}
                    dataSource={this.state.data}
                    pagination={this.state.pagination}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                    size={memory.is_mobile ? 'small' : 'middle'}
                />
            </Card>
        );
    }
}