import React from "react";
import { message } from "antd";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import '@react-pdf-viewer/core/lib/styles/index.css';
import tools from "../../utils/tools";

const renderError = (error) => {
    let message = '';
    switch (error.name) {
        case 'InvalidPDFException':
            message = '该文件类型非PDF，请检查文件类型';
            break;
        case 'MissingPDFException':
            message = '未找到文件';
            break;
        case 'UnexpectedResponseException':
            message = '文件已失效';
            break;
        default:
            message = '无法加载该文件';
            break;
    }

    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
            }}
        >
            <div
                style={{
                    backgroundColor: '#e53e3e',
                    borderRadius: '0.25rem',
                    color: '#fff',
                    padding: '0.5rem',
                }}
            >
                {message}
            </div>
        </div>
    );
};

export default class PdfView extends React.Component {

    file = tools.queryString("file");

    componentDidMount() {
        if (!this.file) {
            message.success("请带上文件地址");
            setTimeout(() => {
                this.props.history.goBack();
            }, 1000);
        }
    }

    render() {
        return (
            <div className="pdf-view">
                {
                    this.file ?
                        <Worker workerUrl={`/js/pdf.worker.js`} >
                            <Viewer
                                fileUrl={/*"/file/ueditor/contract.pdf"*/this.file}
                                theme={"dark"}
                                renderPage={(props) => (
                                    <>
                                        {props.canvasLayer.children}
                                        <div style={{ display: "none" }}>
                                            {props.textLayer.children}
                                        </div>
                                    </>
                                )}
                                renderError={renderError}
                            />
                        </Worker>
                        :
                        null
                }
            </div>
        )
    }
}