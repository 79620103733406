import React, {useState, useRef, useContext, useEffect} from "react";
import {
    Card,
    Table,
    Button,
    Input,
    Form,
    message
} from "antd";

import { moaApi } from "../../../../../../api";
import './index.less';
import memory from "../../../../../../utils/memory";

const {
    reqAbilityList,
    reqAbilityEdit
} = moaApi;

const EditableContext = React.createContext(null);
const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};
const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
        }
    }, [editing]);
    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({
            [dataIndex]: record[dataIndex],
        });
    };
    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({
                ...record,
                ...values,
            });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };
    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0,
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24,
                }}
                onClick={toggleEdit}
            >
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};

export default class AbilityEdit extends React.Component {
    state = {
        loading: false,
        data: [],
    };
    columns = [
        {
            title: '姓名',
            dataIndex: 'name',
        },
        {
            title: '能力总分',
            dataIndex: 'total_score',
            width: '14%',
        },
        {
            title: '价值观',
            dataIndex: 'score1',
            width: '14%',
        },
        {
            title: '搞关系',
            dataIndex: 'score2',
            width: '14%',
        },
        {
            title: '专业度',
            dataIndex: 'score3',
            width: '14%',
        },
        {
            title: '绕阻抗',
            dataIndex: 'score4',
            width: '14%',
        },
        {
            title: '资源',
            dataIndex: 'score5',
            width: '14%',
        },
    ];
    type = 1;
    id = this.props.match.params.id;
    departmentId = {
        1: '98357337', // 商务部
        2: '104596501', // 安服部
    };
    abilities = [];

    handleSave = (row) => {
        const newData = [...this.state.data];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        let total = 0;
        for (let i in row) {
            if (this.abilities.indexOf(i) > -1) {
                const score = row[i] ? Number(row[i]) : 0;
                total += score;
            }
        }
        row.total_score = total;
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        this.setState({ data: newData });
    };

    validate = async () => {
        let response;
        response = await reqAbilityEdit({
            appointings: this.state.data
        });
        if (response && response.data) {
            message.success('保存成功');
            this.props.history.replace('/moa/admin/ability/list');
        }
    };

    getAbilityList = async (params) => {
        this.setState({
            loading: true
        });
        const response = await reqAbilityList(params);
        if (response && response.data) {
            if (response.data.length) {
                const ability = response.data[0].ability;
                if (ability.length) {
                    let columns = [
                        {
                            title: '姓名',
                            dataIndex: 'name',
                        },
                        {
                            title: '能力总分',
                            dataIndex: 'total_score',
                        }
                    ];
                    for (let i = 0; i < ability.length; i++) {
                        columns.push({
                            title: ability[i].item,
                            dataIndex: ability[i].item,
                            width: '14%',
                            editable: true,
                        });
                        this.abilities.push(ability[i].item);
                    }
                    this.columns = columns;
                }
                let newData = [];
                for (let i = 0; i < response.data.length; i++) {
                    const item = response.data[i];
                    let obj = {
                        key: item.uid,
                        name: item.uname,
                        total_score: item.total_score || 0,
                        identity: item.identity
                    };
                    for (let j = 0; j < item.ability.length; j++) {
                        const item2 = item.ability[j];
                        obj[item2.item] = item2.score;
                    }
                    newData.push(obj);
                }
                this.setState({
                    data: newData
                });
            }
            this.setState({
                loading: false,
            });
        }
    };

    componentDidMount() {
        this.getAbilityList({
            department: this.id
        });
    }

    render() {
        const { data } = this.state;
        const components = {
            body: {
                row: EditableRow,
                cell: EditableCell,
            },
        };
        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });

        return (
            <>
                <Button
                    type="link"
                    icon="left"
                    onClick={() => {
                        this.props.history.goBack();
                    }}
                >返回</Button>

                <Card
                    title="点将台"
                    bordered={false}
                    className="ability"
                >
                    <Table
                        components={components}
                        rowClassName={() => 'editable-row'}
                        bordered
                        dataSource={data}
                        columns={columns}
                        pagination={false}
                        loading={this.state.loading}
                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                        size={memory.is_mobile ? 'small' : 'middle'}
                    />
                </Card>

                <div className="submit-footer">
                    <Button type="primary" onClick={this.validate}>保存</Button>
                </div>
            </>
        );
    }
}