import React from 'react';
import { Radio, Card, Table, Button, Popconfirm, message, Input } from 'antd'
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';

import tools from '../../../../../utils/tools';
import memory from '../../../../../utils/memory';
import { moaApi } from '../../../../../api/index';
import DownloadLink from '../../../../../components/download-link';
import { SEAL_LIST } from '../../../../../utils/constant';

const { reqAdminSealList, reqAdminSealConfirm } = moaApi

export default class AdminSealList extends React.Component {

    state = {
        data: [],
        type: tools.queryString('type') || 'pending',
        pagination: {},
        loading: false,
    }
    filters = {};

    /*
    *搜索相关
    * */
    getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`搜索 ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    搜索
                </Button>
                <Button onClick={() => this.handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    重置
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => text
    });
    handleSearch = (selectedKeys, confirm) => {
        confirm();
    };
    handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
    };

    columns = [
        {
            dataIndex: 'key',
            key: 'key',
            title: '',
            width: 50,
            render: () => {
                return ('');
            }
        },
        {
            dataIndex: 'name',
            key: 'name',
            title: '印章名称',
            filters: SEAL_LIST,
            filterMultiple: false
        },
        {
            dataIndex: 'uname',
            key: 'uname',
            title: '申请人',
            ...this.getColumnSearchProps('uname', '申请人')
        },
        {
            dataIndex: 'create_at',
            key: 'create_at',
            title: '申请时间',
        },
        {
            dataIndex: 'department',
            key: 'department',
            title: '所在部门',
        },
        {
            dataIndex: 'file_name',
            key: 'file_name',
            title: '用印文件名',
            onCell: tools.columnItemStyle
        },
        {
            dataIndex: 'file_number',
            key: 'file_number',
            title: '已上传文件数量',
        },
        {
            dataIndex: 'option',
            key: 'option',
            title: '操作',
            render: (text, record) => {
                return (
                    record.is_upload ?
                        <Button disabled size='small'>已确认</Button>
                        :
                        (
                            !(record.file && record.file.length) ?
                                <Button disabled size='small'>未上传</Button>
                                :
                                <Popconfirm
                                    title='确定所有文件上传完毕？'
                                    okText='确认'
                                    cancelText='取消'
                                    onConfirm={(e) => { e.stopPropagation(); this.handleConfirm(record.key) }}
                                    onCancel={(e) => e.stopPropagation()}
                                >
                                    <Button type='primary' size='small' onClick={(e) => e.stopPropagation()}>确认</Button>
                                </Popconfirm>
                        )
                )
            }
        }
    ];

    expand = (record, index) => {
        return (
            /*<DownloadLink title='文件信息' file={record.file} />*/
            <div>
                <DownloadLink title='文件信息' file={record.file} isContract={false} />
            </div>
        )
    }

    handleConfirm = async (id) => {
        const response = await reqAdminSealConfirm({ id, is_upload: true });
        if (response && response.data) {
            message.success('确认成功');
            this.handleTableChange(this.state.pagination, this.filters);
        }
    }

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        });

        let tmpFilters = { ...filters };
        for (let item in tmpFilters) {
            if (tmpFilters[item]) {
                if (typeof (tmpFilters[item]) !== 'string') {
                    tmpFilters[item] = tmpFilters[item][0];
                }
            }
        }
        this.filters = tmpFilters;
        let params = {
            page: pagination.current,
            ...tmpFilters,
        };
        this.getList({ ...params, is_upload: this.state.type == 'already' });
    }

    handleRadioChange = (e) => {
        this.props.history.push(`/moa/seal/admin/list?type=${e.target.value}`);
    }

    getList = async (params = {}, callback) => {
        this.setState({
            loading: true,
        })
        const response = await reqAdminSealList(params);
        if (response && response.data) {
            const pagination = { ...this.state.pagination };
            pagination.total = response.data.page.total;
            pagination.pageSize = response.data.page.node;
            let arr = [];
            if (response.data.data && response.data.data.length) {
                for (let i = 0; i < response.data.data.length; i++) {
                    let item = response.data.data[i];
                    let obj = {
                        key: item._id,
                        name: item.Seal_Name || '-',
                        department: item.Printing_Department || '-',
                        number: item.Number_Seals || 0,
                        Specifications: item.Specifications || '-',
                        file_number: item.After_Document && item.After_Document.length ? item.After_Document.length : '未上传',
                        file: item.After_Document,
                        file_name: item.Name_Document ? <span title={item.Name_Document} >{item.Name_Document}</span> : '-',
                        uname: item.creator && item.creator.name ? item.creator.name : '-',
                        is_upload: item.is_upload,
                        num: i,
                        create_at: item.create_at ? moment(item.create_at).format('YYYY/MM/DD HH:mm:SS') : '-'
                    };
                    arr.push(obj);
                }
            }
            this.setState({
                data: arr,
                pagination
            })
            if (callback) callback();
        }
        this.setState({
            loading: false
        })
    }

    componentWillReceiveProps(newprops) {
        let type = tools.queryString('type', newprops.location.search);
        if (this.state.type != type) {
            this.setState({
                type: type || 'pending'
            }, () => {
                this.handleTableChange(this.state.pagination, this.filters);
            })
        }
    }

    componentDidMount() {
        this.getList({
            is_upload: this.state.type == 'already',
            node: 10
        })
    }

    render() {
        return (
            <Card
                title="印章确认列表"
                bordered={false}
            //extra={<LinkButton to="/moa/contract/add" icon="plus" txt="添加印章" />}
            >
                <Radio.Group value={this.state.type} onChange={this.handleRadioChange} style={{ marginBottom: 16 }}>
                    <Radio.Button value='pending' key='pending'>待确认</Radio.Button>
                    <Radio.Button value='already' key='already'>已确认</Radio.Button>
                </Radio.Group>
                <Table
                    columns={this.columns}
                    dataSource={this.state.data}
                    pagination={this.state.pagination}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    scroll={{ x: 800 }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                    size={memory.is_mobile ? 'small' : 'middle'}
                    expandedRowRender={this.expand}
                    expandIconAsCell={false}
                    rowKey='num'
                    defaultExpandedRowKeys={[0]}
                    expandRowByClick
                />
            </Card>
        );
    }
}