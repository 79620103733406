import React from "react";
import {
    Breadcrumb,
    Button,
    Card, Divider, message, Popconfirm,
    Table,
    Layout,
} from "antd";
import {Link} from "react-router-dom";

import memory from "../../../../../utils/memory";
import {repositoryApi} from "../../../../../api";
import tools from "../../../../../utils/tools";
import {
    MenuSide
} from "../../../../../utils/constant";

const {Content} = Layout;
const {
    reqMyClassifies,
    reqClassifyDelete
} = repositoryApi;

export default class MyClassifyList extends React.Component {
    static contextType = MenuSide;

    state = {
        data: [],
        loading: false,
    };
    columns = [
        {
            title: '分类名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: '备注',
            dataIndex: 'note',
            key: 'note',
        },
        {
            title: '添加时间',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record) => (
                <span>
                    {
                        record.is_module ? (
                            <Link to={`/repository/module/edit/${text.key}`}>编辑</Link>
                        ) : (
                            <Link to={`/repository/classify/edit/${text.key}`}>编辑</Link>
                        )
                    }
                    <Divider type="vertical" />
                    <Popconfirm
                        title="确认删除这条回答吗？"
                        onConfirm={() => this.handleDeleteClassify(text.key)}
                        okText="确认"
                        cancelText="取消"
                    >
                        <Button type="link">删除</Button>
                    </Popconfirm>
                </span>
            ),
        },
    ];

    handleDeleteClassify = async (id) => {
        const response = await reqClassifyDelete({
            id: id,
            is_deleted: true
        });
        if(response) {
            message.success('删除成功');
            this.context();
            this.getClassifies();
        }
    }

    getClassifies = async () => {
        this.setState({
            loading: true
        });
        const response = await reqMyClassifies();
        if(response && response.data) {
            const data = response.data;
            let newData = [];
            for(let i = 0; i < data.length; i++) {
                const item = data[i];
                let type = '';
                if(item.is_module) {
                    if(item.type === 1) {
                        type = '公开模块';
                    }else if(item.type === 2) {
                        type = '部门模块';
                    }else if(item.type === 3) {
                        type = '私人模块';
                    }
                }else {
                    type = '普通分类';
                }
                const obj = {
                    key: item._id,
                    name: item.name,
                    type: type,
                    note: item.desc || '-',
                    time: tools.unixToTime(new Date(item.create_at)/1000),
                    is_module: item.is_module
                };
                newData.push(obj);
            }
            this.setState({
                loading: false,
                data: newData
            });
        }
    }

    componentDidMount() {
        this.getClassifies();
    }

    render() {
        return (
            <>
                <Breadcrumb
                    style={{
                        padding: '24px 24px 0',
                        marginTop: '64px',
                    }}>
                    <Breadcrumb.Item>
                        <Link to="/repository">首页</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        我的分类
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Content style={{margin: '24px 16px 0'}}>
                    <Button
                        type="link"
                        icon="left"
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                    >返回</Button>

                    <Card
                        title="我的分类"
                        bordered={false}
                    >
                        <Table
                            columns={this.columns}
                            dataSource={this.state.data}
                            pagination={false}
                            loading={this.state.loading}
                            scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                            size={memory.is_mobile ? 'small' : 'middle'}
                        />
                    </Card>
                </Content>
            </>
        );
    }
}