const menuList = [
    {
        title: '首页',
        key: '/manage',
        icon: 'radar-chart'
    },
    {
        title: '点将台',
        key: '/manage/ability/list',
        icon: 'radar-chart'
    },
    {
        title: '行业触达率',
        key: '/manage/industry',
        icon: 'radar-chart'
    },
    {
        title: '销售业绩',
        key: '/manage/seller',
        icon: 'radar-chart'
    },
    {
        title: '客户管理',
        key: '/manage/client-manage/list',
        icon: 'table',
    },
];

export default menuList;