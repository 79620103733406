import React from "react";
import {
    Spin,
    Card,
    Descriptions,
    Button,
    Icon,
    Popconfirm,
    message,
} from "antd";

import memory from "../../../../../utils/memory";
import { crmApi } from "../../../../../api";
import tools from "../../../../../utils/tools";
import {
    CONTRACT_HEAD_TYPE_ALL_OBJ,
    CONTRACT_STANDARD_OBJ,
    CONTRACT_SUB_TYPE,
    CONTRACT_SUB_TYPE_OBJ
} from "../../../../../utils/constant";
import DownloadLink from "../../../../../components/download-link";

const {
    reqApplyDetail,
    reqDownload,
    reqApplyCheck,
    reqVendorDetail,
} = crmApi;

export default class PendingContractDetail extends React.Component {
    state = {
        loading: true,
        detail: {},
        vendorDetail: {},
    };
    id = this.props.match.params.id;
    type = 0
    role = [0, 0, 0]

    confirm = async () => {
        const response = await reqApplyCheck({ id: this.id, is_examined: true })
        if (response && response.data) {
            message.success('审核成功')
            this.props.history.push('/moa/contract/pending/list');
        }
    }

    getContractDetail = async (params = {}) => {
        this.setState({
            loading: true
        });
        const response = await reqApplyDetail(params);
        if (response && response.data) {
            if (response.data?.data?.vendor?.id) {
                this.getVendorDetail({
                    id: response.data.data.vendor.id,
                })
            }
            this.setState({
                detail: response.data,
                loading: false,
            });
        }
    };

    getVendorDetail = async (params = {}) => {
        const response = await reqVendorDetail(params);
        if (response?.data) {
            this.setState({
                vendorDetail: response.data.msg,
            })
        }
    }

    sendMessage = async () => {
        const response = await reqDownload({ aid: this.id })
        if (response && response.data) {

        }
    }

    getRole = () => {
        let roles = memory.user.roles
        for (let i in roles) {
            if (roles[i].type === 3) {
                this.role = 3;
                this.type = 3
                break;
            }
            switch (roles[i].type) {
                case 26: this.role[0] = 3; break;
                case 28: this.role[1] = 4; break;
                case 27: this.role[2] = 5; break;
            }
            this.type = this.role[0] || this.role[1] || this.role[2]
        }
    }

    componentDidMount() {
        this.getRole()
        // 获取详情
        this.getContractDetail({
            id: this.id,
        });
    }

    render() {
        const { detail, vendorDetail } = this.state;

        return this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <>
                <Card
                    title="合同详情"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                >
                    <Descriptions
                        title="合同信息"
                    >
                        <Descriptions.Item label="合同名称">
                            {detail.data.contract_name || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="所属项目">
                            {detail.data.business_name ? detail.data.business_name : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="所属客户">
                            {detail.data.customer_name ? detail.data.customer_name : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<span style={{ fontWeight: 'bolder' }}>是否是标准合同</span>}>
                            <span style={{ fontWeight: 'bolder' }}>{detail.data.specifications && CONTRACT_STANDARD_OBJ[detail.data.specifications] ? CONTRACT_STANDARD_OBJ[detail.data.specifications] : '-'}</span>
                        </Descriptions.Item>
                        <Descriptions.Item label="合同类型">
                            {detail.data.contract_type !== undefined && CONTRACT_HEAD_TYPE_ALL_OBJ[detail.data.contract_type] ? CONTRACT_HEAD_TYPE_ALL_OBJ[detail.data.contract_type] : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="合同性质">
                            {detail.data.contract_stype !== undefined && CONTRACT_SUB_TYPE_OBJ[detail.data.contract_stype] ? CONTRACT_SUB_TYPE_OBJ[detail.data.contract_stype].text : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="合同编号">
                            {detail.data.contract_number || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="合同签署日期">
                            {detail.data.sign_date ? tools.unixToTime((new Date(detail.data.sign_date)) / 1000, true) : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="合同金额">
                            {(detail.data.contract_amount || 0) + '元'}
                        </Descriptions.Item>
                        <Descriptions.Item label="服务起止日期">
                            {detail.data.contract_startdate && detail.data.contract_enddate ?
                                (`${tools.unixToTime((new Date(detail.data.contract_startdate)) / 1000, true)} - ${tools.unixToTime((new Date(detail.data.contract_enddate)) / 1000, true)}`) : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="创建者">
                            {detail.creator.name || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="合同打印份数">
                            {detail.data.printing_number || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="合同寄送信息">
                            {detail.data.address || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="是否由客户盖章寄出">
                            {detail.data.is_customer_launch === true ? '是' : detail.data.is_customer_launch === false ? '否' : '-'}
                        </Descriptions.Item>
                        {
                            detail.data.mode && detail.data.mode.receiving_mode ?
                                (
                                    detail.data.mode.receiving_mode === 2 ?
                                        <>
                                            <Descriptions.Item label="合同寄送方式">合同管理员寄送</Descriptions.Item>
                                            <Descriptions.Item label="快递单号">{detail.data.mode.express_number == null ? '-' : detail.data.mode.express_number}</Descriptions.Item>
                                        </>
                                        :
                                        <Descriptions.Item label="合同寄送方式">销售自取</Descriptions.Item>
                                )
                                :
                                null
                        }
                        <Descriptions.Item label="备注">
                            {detail.data.remarks || '-'}
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        column={1}
                    >
                        <Descriptions.Item label="合同盖章说明">
                            {detail.data.seal_explain || '-'}
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        style={{
                            marginBottom: 24,
                        }}
                        column={1}
                    >
                        <Descriptions.Item label="合同说明">
                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                {detail.data.contract_survey || '-'}
                            </div>
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        title="供应商信息"
                        style={{
                            marginBottom: 24,
                        }}
                    >
                        {
                            detail?.data?.vendor?.id && vendorDetail._id ?
                                <>
                                    <Descriptions.Item label={"名称"} >{vendorDetail.name || "-"}</Descriptions.Item>
                                    <Descriptions.Item label={"社会信用码"} >{vendorDetail.social_credit_code || "-"}</Descriptions.Item>
                                    <Descriptions.Item label={"地址"} >{vendorDetail.address || "-"}</Descriptions.Item>
                                    <Descriptions.Item label={"电话"} >{vendorDetail.telephone || "-"}</Descriptions.Item>
                                    <Descriptions.Item label={"开户银行"} >{vendorDetail.bank || "-"}</Descriptions.Item>
                                    <Descriptions.Item label={"开户银行账号"} >{vendorDetail.bank_account || "-"}</Descriptions.Item>
                                </>
                                :
                                <Descriptions.Item>无</Descriptions.Item>
                        }
                    </Descriptions>

                    {this.role === 3 || this.role[0] === 3 || detail.data.stage <= 5 ?
                        <DownloadLink title={'合同原文件'} file={detail.data.contract_file} isContract={true} contract={this.id} />
                        :
                        null
                    }
                    {(this.role === 3 || this.role[2] === 5) && detail.data.stage > 4 ?
                        <>
                            <DownloadLink title={'合同单章文件'} file={detail.data.moa_contract_file_single} isContract={true} contract={this.id} />
                            <DownloadLink title={'合同双章文件'} file={detail.data.moa_contract_file_double} isContract={true} contract={this.id} />
                        </>
                        :
                        null
                    }
                </Card>
                {(this.role === 3 || this.role[2] === 5) && detail.data.stage === 6 ?
                    <div className="submit-footer">
                        <Popconfirm
                            title="确定该合同无误?"
                            onConfirm={this.confirm}
                            okText="确定"
                            cancelText="取消"
                        >
                            <Button type='primary'>确认</Button>
                        </Popconfirm>
                    </div>
                    : null
                }
            </>
        )
    }
}