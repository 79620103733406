import React from "react";
import {
    Button,
    Card,
    Col,
    Descriptions,
    Form,
    Input,
    message,
    Radio,
    Row,
    Spin,
    Table,
} from "antd";
import { Link } from "react-router-dom";

import tools from "../../../../../utils/tools";
import { crmApi } from "../../../../../api";
import {
    CONTRACT_HEAD_TYPE_ALL_OBJ,
    CONTRACT_SUB_TYPE_OBJ,
    CONTRACT_STANDARD_OBJ
} from "../../../../../utils/constant";
import memory from "../../../../../utils/memory";
import DownloadLink from "../../../../../components/download-link";

const {
    reqApplyDetail,
    reqApplyCheck,
    reqVendorDetail,
} = crmApi;

const { TextArea } = Input;

class PendingContractApproveNew extends React.Component {
    state = {
        loading: true,
        detail: {},
        submitLoading: false,
        schedule: [],
        vendorDetail: {},
    };
    id = this.props.match.params.id;

    validate = async (values) => {
        this.setState({ submitLoading: true })
        if (!values.examined_content) {
            delete values.examined_content;
        }
        values.id = this.id;
        const response = await reqApplyCheck(values);
        if (response && response.data) {
            message.success('审批成功');
            this.props.history.push('/moa/contract/pending/list');
        } else {
            this.setState({ submitLoading: false })
        }
    };
    scheduleColumns = [
        {
            title: '时间',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: '进度内容',
            dataIndex: 'content',
            key: 'content',
        },
        {
            title: '原因',
            dataIndex: 'application',
            key: 'application',
        },
    ]

    getApplyDetail = async (params = {}) => {
        const response = await reqApplyDetail(params);
        if (response && response.data) {
            let schedule = []
            response.data.data.schedule.map((item, index) => {
                let data = {
                    time: item.create_at || '-',
                    content: item.remark || '-',
                    is_examined: item.is_examined,
                    type: item.type == null ? null : item.type,
                    application: item.application || '-',
                }
                schedule.push(data)
            })
            if (schedule[schedule.length - 1].is_examined !== false && schedule[schedule.length - 1].type !== 7) {
                if (schedule[schedule.length - 1].type == 2) {
                    let arr = [];
                    if (response.data.data.security_is_examined == null) {
                        arr.push('技术')
                    }
                    if (response.data.data && response.data.data.product_is_examined != null && response.data.data.product_is_examined != -1 && response.data.data.product_is_examined != true) {
                        arr.push('产品');
                    }
                    if (response.data.data && response.data.data.services_is_examined != null && response.data.data.services_is_examined != -1 && response.data.data.services_is_examined != true) {
                        arr.push('服务');
                    }
                    if (response.data.data && response.data.data.operate_is_examined != null && response.data.data.operate_is_examined != -1 && response.data.data.operate_is_examined != true) {
                        arr.push('运营');
                    }
                    if (response.data.data && response.data.data.other_is_examined != null && response.data.data.other_is_examined != -1 && response.data.data.other_is_examined != true) {
                        arr.push('其他武器');
                    }
                    if (tools.getNextState(schedule[schedule.length - 1], arr)) schedule.push(tools.getNextState(schedule[schedule.length - 1], arr));
                } else {
                    if (tools.getNextState(schedule[schedule.length - 1])) schedule.push(tools.getNextState(schedule[schedule.length - 1]))
                }
            }
            if (response.data?.data?.vendor?.id) {
                this.getVendorDetail({
                    id: response.data.data.vendor.id,
                })
            }
            this.setState({
                detail: response.data,
                schedule: schedule,
                loading: false
            });
        }
    };

    getVendorDetail = async (params = {}) => {
        const response = await reqVendorDetail(params);
        if (response?.data) {
            this.setState({
                vendorDetail: response.data.msg,
            })
        }
    }

    componentDidMount() {
        // 获取详情
        this.getApplyDetail({
            id: this.id
        });
    }

    render() {
        const { detail, vendorDetail } = this.state;

        return this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <Form onFinish={this.validate}>
                <Button
                    type="link"
                    icon="left"
                    onClick={() => {
                        this.props.history.goBack();
                    }}
                >返回</Button>

                <Card
                    title="合同审批详情"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                >
                    <Descriptions
                        title="合同信息"
                    >
                        <Descriptions.Item label="合同名称">
                            {detail.data.contract_name || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="所属项目">
                            {detail.data.business_name ? <Link to={`/crm/client/business-detail/${detail.data.business}`}>{detail.data.business_name}</Link> : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="所属客户">
                            {detail.data.customer_name ? <Link to={`/crm/client/detail/${detail.data.customer}`}>{detail.data.customer_name}</Link> : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<span style={{ fontWeight: 'bolder' }}>是否是标准合同</span>}>
                            <span style={{ fontWeight: 'bolder' }}>{detail.data.specifications && CONTRACT_STANDARD_OBJ[detail.data.specifications] ? CONTRACT_STANDARD_OBJ[detail.data.specifications] : '-'}</span>
                        </Descriptions.Item>
                        <Descriptions.Item label="合同类型">
                            {detail.data.contract_type !== undefined && CONTRACT_HEAD_TYPE_ALL_OBJ[detail.data.contract_type] ? CONTRACT_HEAD_TYPE_ALL_OBJ[detail.data.contract_type] : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="合同性质">
                            {detail.data.contract_stype !== undefined && CONTRACT_SUB_TYPE_OBJ[detail.data.contract_stype] ? CONTRACT_SUB_TYPE_OBJ[detail.data.contract_stype].text : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="合同编号">
                            {detail.data.contract_number || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="合同签署日期">
                            {detail.data.sign_date ? tools.unixToTime((new Date(detail.data.sign_date)) / 1000, true) : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="合同金额">
                            {(detail.data.contract_amount || 0) + '元'}
                        </Descriptions.Item>
                        <Descriptions.Item label="服务起止日期">
                            {detail.data.contract_startdate && detail.data.contract_enddate ?
                                (`${tools.unixToTime((new Date(detail.data.contract_startdate)) / 1000, true)} - ${tools.unixToTime((new Date(detail.data.contract_enddate)) / 1000, true)}`) : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="创建者">
                            {detail.creator.name || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="合同打印份数">
                            {detail.data.printing_number || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="合同寄送信息">
                            {detail.data.address || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="是否由客户盖章寄出">
                            {detail.data.is_customer_launch === true ? '是' : detail.data.is_customer_launch === false ? '否' : '-'}
                        </Descriptions.Item>
                        {
                            detail.data.mode && detail.data.mode.receiving_mode ?
                                (
                                    detail.data.mode.receiving_mode === 2 ?
                                        <>
                                            <Descriptions.Item label="合同寄送方式">合同管理员寄送</Descriptions.Item>
                                            <Descriptions.Item label="快递单号">{detail.data.mode.express_number == null ? '-' : detail.data.mode.express_number}</Descriptions.Item>
                                        </>
                                        :
                                        <Descriptions.Item label="合同寄送方式">销售自取</Descriptions.Item>
                                )
                                :
                                null
                        }
                        <Descriptions.Item label="备注">
                            {detail.data.remarks || '-'}
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        column={1}
                    >
                        <Descriptions.Item label="合同盖章说明">
                            {detail.data.seal_explain || '-'}
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        style={{
                            marginBottom: 24,
                        }}
                        column={1}
                    >
                        <Descriptions.Item label="合同说明">
                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                {detail.data.contract_survey || '-'}
                            </div>
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        title="供应商信息"
                        style={{
                            marginBottom: 24,
                        }}
                    >
                        {
                            detail?.data?.vendor?.id && vendorDetail._id ?
                                <>
                                    <Descriptions.Item label={"名称"} >{vendorDetail.name || "-"}</Descriptions.Item>
                                    <Descriptions.Item label={"社会信用码"} >{vendorDetail.social_credit_code || "-"}</Descriptions.Item>
                                    <Descriptions.Item label={"地址"} >{vendorDetail.address || "-"}</Descriptions.Item>
                                    <Descriptions.Item label={"电话"} >{vendorDetail.telephone || "-"}</Descriptions.Item>
                                    <Descriptions.Item label={"开户银行"} >{vendorDetail.bank || "-"}</Descriptions.Item>
                                    <Descriptions.Item label={"开户银行账号"} >{vendorDetail.bank_account || "-"}</Descriptions.Item>
                                </>
                                :
                                <Descriptions.Item>无</Descriptions.Item>
                        }
                    </Descriptions>

                    <DownloadLink title={'合同原文件'} file={detail.data.contract_file} isContract={true} contract={this.id} />
                </Card>
                <Card
                    title="申请进度"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                >
                    <Table
                        columns={this.scheduleColumns}
                        dataSource={this.state.schedule}
                        pagination={false}
                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                        size={memory.is_mobile ? 'small' : 'middle'}
                    />
                </Card>

                <Card
                    title="申请审批"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                >
                    <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <Form.Item
                                label="是否同意"
                                name="is_examined"
                                initialValue={detail.data.stage >= 2 ? detail.data.format_is_examined : null}
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择是否同意',
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Radio value={true}>通过</Radio>
                                    <Radio value={false}>驳回</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <Form.Item
                                label="备注"
                                name="examined_content"
                                initialValue={detail.data.stage >= 2 && detail.data.format_examined_content ? detail.data.format_examined_content : ''}
                            >
                                <TextArea rows={4} placeholder="审批备注" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <div className="submit-footer">
                    {
                        detail.data.stage >= 2 ? (
                            <Button type="primary" disabled>已审批</Button>
                        ) : (
                            <Button type="primary" loading={this.state.submitLoading} htmlType="submit">审批</Button>
                        )
                    }
                </div>
            </Form>
        );
    }
}

export default PendingContractApproveNew;