export const UPDATE_LIST = (ROLES) => [
  {
    content: [
      {
        title: '项目报备',
        content:
          "在销售漏斗中项目立项改由销售进行立项。",
        role: [
          ROLES.SELLER,
          ROLES.PRESELLER,
        ], //该条公告要给看的角色
        type: "规则更新", //尽量少一点，因为取了content数组里的所有不同的type,会显示不下
        key: "14-1",
      },
      {
        title: '报价单审批',
        content:
          "销售漏斗“项目报备”阶段新增推进条件，必须要上传报价单并且审批通过后才能推进。",
        role: [
          ROLES.PRESELLER
        ], //该条公告要给看的角色
        type: "功能新增", //尽量少一点，因为取了content数组里的所有不同的type,会显示不下
        key: "14-2",
      },
      {
        title: '日报行动项',
        content:
          "现在行动项“报价单出具”会自动生成，不需要手动填写。",
        role: [
          ROLES.PRESELLER
        ], //该条公告要给看的角色
        type: "功能更新", //尽量少一点，因为取了content数组里的所有不同的type,会显示不下
        key: "14-3",
      },
    ],
    update_at: "2023/04/04 21:00",//一般写晚上九点
    hide: false, //很久没有更新了，又想把最新一条隐藏设置为true
    key: "14",
  },
  {
    content: [
      {
        title: '外出申请',
        content:
          "PC端支持填写外出申请，在“销售工作——>我的外出申请”点击“外出申请”按钮即可，规则与APP端一致。",
        role: [
          ROLES.SELLER
        ], //该条公告要给看的角色
        type: "功能新增", //尽量少一点，因为取了content数组里的所有不同的type,会显示不下
        key: "13-1",
      },
      {
        title: '外出申请',
        content:
          "PC端能撤回外出申请，在“销售工作——>我的外出申请”点击“撤回”按钮即可，也可以点进“详情”中撤回。",
        role: [
          ROLES.SELLER
        ], //该条公告要给看的角色
        type: "功能新增", //尽量少一点，因为取了content数组里的所有不同的type,会显示不下
        key: "13-2",
      },
    ],
    update_at: "2023/02/06 21:00",//一般写晚上九点
    hide: false, //很久没有更新了，又想把最新一条隐藏设置为true
    key: "13",
  },
  {
    content: [
      {
        title: '项目备注',
        content:
          "销售和项目经理可在项目详情里添加或查看项目备注，具体更新内容请在左侧导航栏点击“系统通知——>系统更新记录”并进入详情查看。",
        role: [
          ROLES.SELLER,
          ROLES.PRESELLER,
        ], //该条公告要给看的角色
        type: "功能新增", //尽量少一点，因为取了content数组里的所有不同的type,会显示不下
        steps: [
          {
            content: "路径：“销售工作——>我的项目——>详情”。",
            img: "https://oa.moule.cn/img/ueditor/1613514727080726528.png",
          },
          {
            content: "在项目详情里点击“添加备注”按钮可以添加备注，填写备注内容并上传附件(附件非必需)然后提交即可",
            img: "https://oa.moule.cn/img/ueditor/1613515681670762496.png",
          },
          {
            content: "在项目详情里可以查看到所有人对该项目的备注。",
            img: "https://oa.moule.cn/img/ueditor/1613515750797086720.png",
          },
        ],
        key: "12-2",
      },
      {
        title: '查看回款信息',
        content:
          "项目回款批次信息可在“销售工作——>我的回款列表——>详情”里查看，具体更新内容请在左侧导航栏点击“系统通知——>系统更新记录”并进入详情查看。",
        role: [
          ROLES.SELLER
        ], //该条公告要给看的角色
        type: "内容更新", //尽量少一点，因为取了content数组里的所有不同的type,会显示不下
        steps: [
          {
            content: "在“销售工作——>我的回款列表”可以查看到每个项目的已回款金额、待回款金额等回款信息。",
            img: "https://oa.moule.cn/img/ueditor/1613513037652824064.png",
          },
          {
            content: "在详情里可查看到回款批次信息，包括每一批次的回款金额、回款时间、预计回款时间等",
            img: "https://oa.moule.cn/img/ueditor/1613514257691971584.png",
          },
        ],
        key: "12-1",
      },
    ],
    update_at: "2023/1/12 21:00",
    hide: false, //很久没有更新了，又想把最新一条隐藏设置为true
    key: "12",
  },
  {
    content: [
      {
        title: '额外费用申请',
        content: '额外费用申请最后一步“销售确认给出”改为由额外费用最终审批员确认。',
        role: [ROLES.SELLER, ROLES.MARKETCOST_FINAL],//该条公告要给看的角色
        type: '规则变更',//尽量少一点，因为取了content数组里的所有不同的type,会显示不下
      },
    ],
    update_at: '2022/02/28 21:00',
    hide: false,//很久没有更新了，又想把最新一条隐藏设置为true
    key: '11',
  },
  {
    content: [
      {
        title: '工作日志',
        content: '完善客户卡、特殊卡工作日志要选择给客户介绍的自营产品及其介绍情况。',
        role: [ROLES.SELLER],//该条公告要给看的角色
        type: '规则新增',//尽量少一点，因为取了content数组里的所有不同的type,会显示不下
      },
    ],
    update_at: '2021/11/13 21:00',
    hide: false,//很久没有更新了，又想把最新一条隐藏设置为true
    key: '10',
  },
  {
    content: [
      {
        title: '成本审批',
        content: '成本审批在终审前增加销售总监审批。',
        role: [ROLES.SELLER, ROLES.FINAL_APPROVE, ROLES.DEPARTMENT_ADMIN],//该条公告要给看的角色
        type: '规则新增',//尽量少一点，因为取了content数组里的所有不同的type,会显示不下
      },
    ],
    update_at: '2021/11/10 21:00',
    hide: false,//很久没有更新了，又想把最新一条隐藏设置为true
    key: '9',
  },
  {
    content: [
      {
        title: '额外费用申请(审批人)',
        content:
          "钉钉额外费用申请模块移至CRM系统内，具体内容请在左侧导航栏点击“查看历史公告”并进入详情查看。",
        role: [
          /*ROLES.MARKET_ADMIN,
          ROLES.MARKET_FINANCE,
          ROLES.MARKET_BUSINESS,*/
        ], //该条公告要给看的角色
        type: "功能新增", //尽量少一点，因为取了content数组里的所有不同的type,会显示不下
        steps: [
          {
            content: "流程描述：销售发起额外费用申请——>总监审批——>副总裁审批——>财务审批——>销售确认给出。",
          },
          {
            content: "1、可在相应审批列表查看销售提交的审批。",
            img: "https://oa.moule.cn/img/ueditor/1457551624418496512.png",
          },
          {
            content: "2、审批详情里可查看项目的详细信息、回款信息和目前进度。",
            img: "https://oa.moule.cn/img/ueditor/1457986907534397440.png",
          },
        ],
        key: "8-2",
      },
      {
        title: "额外费用申请",
        content:
          "钉钉额外费用申请模块移至CRM系统内，具体内容请在左侧导航栏点击“查看历史公告”并进入详情查看。",
        role: [
          ROLES.SELLER,
          ROLES.MARKET_ADMIN,
          ROLES.MARKET_FINANCE,
          ROLES.MARKET_BUSINESS,
        ], //该条公告要给看的角色
        type: "功能新增", //尽量少一点，因为取了content数组里的所有不同的type,会显示不下
        steps: [
          {
            content:
              "流程描述：销售发起额外费用申请——>总监审批——>副总裁审批——>财务审批——>销售确认给出。",
          },
          {
            content: "1、前提条件：在“销售工作——>我的项目——>详情里有额外费用和已回款金额”。",
            img: "https://oa.moule.cn/img/ueditor/1457527903699996672.png",
          },
          {
            content: "2、项目详情最下方点击额外费用申请按钮。",
            img: "https://oa.moule.cn/img/ueditor/1457529027337261056.png",
          },
          {
            content: "3、申请界面查看、确认并填写相关信息，完成后提交。",
            img: "https://oa.moule.cn/img/ueditor/1457979211091415040.png",
          },
          {
            content: "4、申请提交后，可在项目详情额外费用记录里查看(与步骤2额外费用申请按钮位置一致)",
            img: "https://oa.moule.cn/img/ueditor/1457539785773158400.png",
          },
          {
            content: "5、也可在“销售工作——>我的额外费用申请”里查看",
            img: "https://oa.moule.cn/img/ueditor/1457541653903577088.png",
          },
          {
            content: "6、进入详情，可以在申请进度列表里查看进度",
            img: "https://oa.moule.cn/img/ueditor/1457544394046574592.png",
          },
          {
            content: "7、财务审批完后，需要申请人确认给出(确认给出额外费用后点击)",
            img: "https://oa.moule.cn/img/ueditor/1458273688616046592.png",
          },
          {
            content: "8、在财务审批完之前，申请人都可以撤回申请",
            img: "https://oa.moule.cn/img/ueditor/1457546886897602560.png",
          },
        ],
        key: "8-1",
      },
    ],
    update_at: "2021/11/8 21:00",
    hide: false, //很久没有更新了，又想把最新一条隐藏设置为true
    key: "8",
  },
  {
    content: [
      {
        title: '工作打卡',
        content: '办公室卡可打卡范围进行调整。',
        role: [ROLES.SELLER],//该条公告要给看的角色
        type: '规则更新',//尽量少一点，因为取了content数组里的所有不同的type,会显示不下
      },
    ],
    update_at: '2021/10/28 21:00',
    hide: false,//很久没有更新了，又想把最新一条隐藏设置为true
    key: '7',
  },
  {
    content: [
      {
        title: '成本审批',
        content: '成本审批在两个工作日内未审完会被自动驳回，申请人可在“销售工作——>我的成本审批”里再次申请，并联系相关审批人。',
        role: [ROLES.SELLER],//该条公告要给看的角色
        type: '规则更新',//尽量少一点，因为取了content数组里的所有不同的type,会显示不下
      },
      {
        title: '成本审批',
        content: '成本审批在两个工作日内未审完会被自动驳回，驳回后将不能再审批，销售再次申请后方可再审。',
        role: [ROLES.PRODUCT, ROLES.SERVICE, ROLES.OPERATING, ROLES.OTHER_WEAPON, ROLES.DEPARTMENT_ADMIN, ROLES.COST_FINAL_ADMIN],//该条公告要给看的角色
        type: '规则更新',//尽量少一点，因为取了content数组里的所有不同的type,会显示不下
      }
    ],
    update_at: '2021/10/21 21:00',
    hide: false,//很久没有更新了，又想把最新一条隐藏设置为true
    key: '6',
  },
  {
    content: [
      {
        title: "奖金赛",
        content:
          "奖金赛功能更新，新增赛制功能(常规赛、复仇赛)，具体流程请点击“查看历史公告”并进入详情查看。",
        role: [ROLES.SELLER], //该条公告要给看的角色
        type: "功能新增", //尽量少一点，因为取了content数组里的所有不同的type,会显示不下
        steps: [
          {
            content:
              "奖金赛新增赛制功能(常规赛、复仇赛)，具体改动及使用规则如下(赛制规则细节等请询问相关负责人，系统不做描述)",
          },
          {
            content: "1、新增常规赛、复仇赛切换按钮。",
            img: "https://oa.moule.cn/img/ueditor/1447792942617071616.png",
          },
          {
            content: "2、赛制切换只影响对战信息模块",
          },
          {
            content: "3、常规赛以团建经费作为奖金，且奖金固定不能押注。",
            img: "https://oa.moule.cn/img/ueditor/1447792959058743296.png",
          },
          {
            content: "4、复仇赛不自动挑战，需先发起挑战才能下注。",
            img: "https://oa.moule.cn/img/ueditor/1447792986246221824.png",
          },
        ],
        key: "5-1",
      },
    ],
    update_at: "2021/10/12 21:00",
    hide: false, //很久没有更新了，又想把最新一条隐藏设置为true
    key: "5",
  },
  {
    content: [
      {
        title: "成本审批",
        content:
          "成本审批人员增加驳回功能，具体流程请点击“查看历史公告”并进入详情查看。",
        role: [
          ROLES.SELLER,
          ROLES.PRODUCT,
          ROLES.SERVICE,
          ROLES.OPERATING,
          ROLES.OTHER_WEAPON,
          ROLES.DEPARTMENT_ADMIN,
          ROLES.COST_FINAL_ADMIN,
        ], //该条公告要给看的角色
        type: "功能新增、规则更新", //尽量少一点，因为取了content数组里的所有不同的type,会显示不下
        steps: [
          {
            content:
              "功能描述：成本审批人员增加驳回功能，驳回后销售需要提交说明。销售提交说明后，审批人员可以再审（每个审批模块互不影响）。详情请至“我是销售—>我的成本审批->详情”",
          },
          {
            content: "1、通过我的成本审批列表进入详情。",
            img: "https://oa.moule.cn/img/ueditor/1443050632847167488.png",
          },
          {
            content: "2、审批人点击驳回并填写驳回原因。",
            img: "https://oa.moule.cn/img/ueditor/1442671424409047040.png",
          },
          {
            content: "3、销售填写说明。",
            img: "https://oa.moule.cn/img/ueditor/1443047288313352192.png",
          },
          {
            content: "4、销售提交说明后，审批人可以再审批。",
            img: "https://oa.moule.cn/img/ueditor/1442683609185128448.png",
          },
          {
            content:
              "5、每个审批区域互不影响，比如开拓费用被驳回，其他块可以继续审批。",
            img: "https://oa.moule.cn/img/ueditor/1442683834964512768.png",
          },
        ],
        key: "4-1",
      },
    ],
    update_at: "2021/09/27 21:00",
    hide: false, //很久没有更新了，又想把最新一条隐藏设置为true
    key: "4",
  },
  {
    content: [
      {
        title: "发票系统",
        content:
          "新增发票系统，与合同、回款相关，具体流程请点击“查看历史公告”并进入详情查看。",
        role: [ROLES.SELLER], //该条公告要给看的角色
        type: "功能新增", //尽量少一点，因为取了content数组里的所有不同的type,会显示不下
        steps: [
          {
            content: "前提条件：合同必须是销售合同，并且合同审批通过。",
          },
          {
            content: "1、在项目详情合同管理里点击“确认回款批次”按钮。",
            img: "https://oa.moule.cn/img/ueditor/1425987272821575680.png",
          },
          {
            content: "2、填写回款批次信息，并提交。",
            img: "https://oa.moule.cn/img/ueditor/1425996011557490688.png",
          },
          {
            content:
              "3、回款批次提交完，“确认回款批次”变成“发票申请”，请点击进行发票申请。",
            img: "https://oa.moule.cn/img/ueditor/1425987510001078272.png",
          },
          {
            content: "4、填写发票申请信息，并提交。",
            img: "https://oa.moule.cn/img/ueditor/1426002129901981696.png",
          },
          {
            content:
              "5、发票申请过程中可以在“我是销售—>我的发票申请”里查看，点击进入详情可查看审批过程。",
          },
          {
            content:
              "6、发票申请被驳回可在“我是销售—>我的发票申请”重新提交申请。",
          },
          {
            content: "7、上一批次的发票审批成功后才能申请下一批次。",
          },
        ],
        key: "3-1",
      },
    ],
    update_at: "2021/08/12 21:00",
    hide: false, //很久没有更新了，又想把最新一条隐藏设置为true
    key: "3",
  },
  {
    content: [
      {
        title: "pipeline",
        content:
          "在首页销售漏斗处新增本月派发的客户触达量考核及自身跟进的客户触达量考核完成情况的显示",
        role: [ROLES.SELLER], //该条公告要给看的角色
        type: "数据显示", //尽量少一点，因为取了content数组里的所有不同的type,会显示不下
      },
    ],
    update_at: "2021/07/23 21:00",
    hide: true, //很久没有更新了，又想把最新一条隐藏设置为true
    key: "2",
  },
  {
    content: [
      {
        title: "项目报备",
        content: "pipeine需要有对应客户和KP的拜访记录才能报备项目。",
        role: [ROLES.SELLER], //该条公告要给看的角色
        type: "规则更新", //尽量少一点，因为取了content数组里的所有不同的type,会显示不下
      },
    ],
    update_at: "2021/06/22 19:42",
    hide: false, //很久没有更新了，又想把最新一条隐藏设置为true
    key: "1",
  },
];