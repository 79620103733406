import {
    Breadcrumb,
    Tree,
    Layout,
    Button,
    Card,
    Spin,
} from 'antd';
import {Link} from "react-router-dom";
import React from "react";
import { ReadOutlined } from '@ant-design/icons';

import './index.less';

import {
    repositoryApi,
} from "../../../../api";

const { TreeNode, DirectoryTree } = Tree;
const {Content} = Layout;
const {
    reqAllClassifies,
    reqArticleList,
} = repositoryApi;

export default class Catalogue extends React.Component {
    state = {
        loading: true,
        menu_list: [],
        show: false
    };
    id = this.props.match.params.id;
    classifyObj = {}; //key是上级分类id，value是下级分类数组
    articleObj = {}; //key是分类id，value是文章数组
    idToName = {};
    menus = [];

    showMenuList = (menuList) => {
        this.setState({
            show: false
        });
        setTimeout(() => {
            this.setState({
                show: true
            });
        }, 200);
        return menuList.map((item, index) => {
            if(!item.children) {
                if(item.title) { // 文章
                    return (
                        <TreeNode icon={<ReadOutlined />} title={<Link to={`/repository/article/detail/${item.id}`}>{item.title}</Link>} key={item.id + '_' + index} />
                    );
                }else { // 分类
                    return (
                        <TreeNode title={item.name} key={item.id + '_' + index} />
                    );
                }
            }else {
                return (
                    <TreeNode title={item.name} key={item.id + '_' + index}>
                        {this.showMenuList(item.children)}
                    </TreeNode>
                );
            }
        });
    }

    getMenuNodes = (arr, classifyObj, articleObj) => {
        if(arr) {
            for(let i = 0; i < arr.length; i++) {
                if(classifyObj[arr[i].id] || articleObj[arr[i].id]) {
                    if(classifyObj[arr[i].id]) {
                        arr[i].children = [...classifyObj[arr[i].id]];
                    }

                    if(articleObj[arr[i].id]) {
                        if(arr[i].children) {
                            let tpmArr = [...arr[i].children];
                            tpmArr = tpmArr.concat(articleObj[arr[i].id]);
                            arr[i].children = tpmArr;
                        }else {
                            arr[i].children = [...articleObj[arr[i].id]];
                        }
                    }
                    this.getMenuNodes(arr[i].children, classifyObj, articleObj);
                }
            }
            return arr;
        }
    }
    getMenu = async () => {
        const response = await reqAllClassifies();
        const articles = await reqArticleList();
        if(articles.data && response.data) {
            for(let i = 0; i < articles.data.length; i++) {
                let item = articles.data[i];
                if(item && item.classify_id) {
                    for(let j = 0; j < item.classify_id.length; j++) {
                        let classify = item.classify_id[j];
                        if(!this.articleObj[classify]) {
                            this.articleObj[classify] = [];
                        }
                        this.articleObj[classify].push({
                            id: item._id,
                            title: item.title
                        });
                    }
                }
            }

            for(let i = 0; i < response.data.length; i++) {
                let item = response.data[i];
                if(item.parent_id) {
                    if(!this.classifyObj[item.parent_id]) {
                        this.classifyObj[item.parent_id] = [];
                    }
                    this.classifyObj[item.parent_id].push({
                        id: item._id,
                        name: item.name
                    });
                }else {
                    this.menus.push({
                        id: item._id,
                        name: item.name
                    });
                }
                this.idToName[item._id] = item.name;
            }
            let result = [];
            if(this.id) {
                let tmpMenu = [{
                    id: this.id,
                    name: this.idToName[this.id]
                }];
                result = this.getMenuNodes(tmpMenu, this.classifyObj, this.articleObj);
            }else {
                result = this.getMenuNodes(this.menus, this.classifyObj, this.articleObj);
            }
            this.setState({
                menu_list: this.showMenuList(result)
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.location.pathname !== prevProps.location.pathname) {
            this.id = this.props.match.params.id;
            let tmpMenu = [{
                id: this.id,
                name: this.idToName[this.id]
            }];
            const result = this.getMenuNodes(tmpMenu, this.classifyObj, this.articleObj);
            this.setState({
                menu_list: this.showMenuList(result)
            });
        }
    }

    componentDidMount =() => {
        this.getMenu();
        this.setState({
            loading: false
        });
    };

    render() {
        return this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <>
                <Breadcrumb
                    style={{
                        padding: '24px 24px 0',
                        marginTop: '64px',
                    }}>
                    <Breadcrumb.Item>
                        <Link to="/repository">首页</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        索引页
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Content style={{margin: '24px 16px 0'}} className="catalogue">
                    <Button
                        type="link"
                        icon="left"
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                    >返回</Button>

                    <Card
                        style={{
                            marginBottom: 24,
                        }}
                        bordered={false}
                    >
                        {
                            this.state.show ? (
                                <DirectoryTree
                                    defaultExpandAll
                                    selectable={false}
                                >
                                    {this.state.menu_list}
                                </DirectoryTree>
                            ) : (
                                <div className="loading-container">
                                    <Spin size="large" />
                                </div>
                            )
                        }
                    </Card>
                </Content>
            </>
        );
    }
}