import React from "react";
import {
    Spin,
    Card,
    Table,
    Descriptions,
    Button,
} from "antd";

import {moaApi} from "../../../../../../api";
import {
    AGENCYCHARGE
} from "../../../../../../utils/constant";
import LinkButton from "../../../../../../components/link-button";
import tools from "../../../../../../utils/tools";
import memory from "../../../../../../utils/memory";
import {Link} from "react-router-dom";

const {
    reqAgencyDetail,
    reqContactsList
} = moaApi;

export default class AwardsAgencyDetail extends React.Component {
    state = {
        loading: true,
        detail: {},
        contacts: [],
    };
    id = this.props.match.params.id;
    contactsColumns = [
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '职务',
            dataIndex: 'post',
            key: 'post',
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: '微信号',
            dataIndex: 'wechat',
            key: 'wechat',
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: '办公座机',
            dataIndex: 'telephone',
            key: 'telephone',
        },
        {
            title: '地址',
            dataIndex: 'place',
            key: 'place',
        },
        {
            title: '操作',
            dataIndex: 'option',
            valueType: 'option',
            render: (_, record, index) => {
                return (
                    <Link to={`/moa/awards/agency/contacts/detail/${record.key}`}>详情</Link>
                );
            }
        },
    ];

    getContactsList = async (params = {}) => {
        const response = await reqContactsList(params);
        if(response && response.data) {
            const data = response.data;
            const newData = [];
            for(let i = 0; i < data.data.length; i++) {
                const item = data.data[i];
                let obj = {
                    key: item._id,
                    name: item.name,
                    post: item.post || '-',
                    mobile: item.mobile || '-',
                    wechat: item.wechat || '-',
                    email: item.email || '-',
                    telephone: item.telephone || '-',
                    place: item.place || '-',
                    create_time: tools.unixToTime((new Date(item.create_at)).getTime()/1000),
                };
                newData.push(obj);
            }
            this.setState({
                contacts: newData,
                loading: false
            });
        }
    };

    getAgencyDetail = async (params = {}) => {
        const response = await reqAgencyDetail(params);
        if(response && response.data) {
            this.setState({
                detail: response.data,
            });
        }

        // 获取联系人
        this.getContactsList({
            agency: this.id,
            page: 1,
        });
    };

    componentDidMount() {
        // 获取详情
        this.getAgencyDetail({
            id: this.id
        });
    }

    render() {
        const {detail} = this.state;

        return this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <>
                <Button
                    type="link"
                    icon="left"
                    onClick={() => {
                        this.props.history.goBack();
                    }}
                >返回</Button>

                <Card
                    title="代理机构信息"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                    extra={<LinkButton to={`/moa/awards/agency/edit/${this.id}`} txt="编辑" />}
                >
                    <Descriptions
                        style={{
                            marginBottom: 24,
                        }}
                        title="基本信息"
                    >
                        <Descriptions.Item label="名称">
                            {detail.name || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="官网">
                            {
                                detail.official_website ?
                                    <a href={detail.official_website} target='_blank'>{detail.official_website}</a> : '-'
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="收费级别">
                            {detail.charge_level ? AGENCYCHARGE[detail.charge_level-1].text : '未分类'}
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        style={{
                            marginBottom: 24,
                        }}
                        column={1}
                    >
                        <Descriptions.Item label="简介">
                            <div style={{whiteSpace: 'pre-wrap'}}>
                                {detail.brief || '无'}
                            </div>
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        title="创建信息"
                        style={{
                            marginBottom: 24,
                        }}
                    >
                        <Descriptions.Item label="创建人">
                            {detail.creator && detail.creator.name ? detail.creator.name : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="创建时间">
                            {detail.create_at
                                ? tools.unixToTime(new Date(detail.create_at).getTime() / 1000)
                                : '-'}
                        </Descriptions.Item>
                    </Descriptions>
                </Card>

                <Card
                    title="联系人管理"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                    extra={<LinkButton to={`/moa/awards/agency/contacts/add/${this.id}`} icon="plus" txt="添加联系人" />}
                >
                    <Table
                        columns={this.contactsColumns}
                        dataSource={this.state.contacts}
                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                        size={memory.is_mobile ? 'small' : 'middle'}
                    />
                </Card>
            </>
        );
    }
}