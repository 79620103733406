import React from "react";
import {
    Button,
    Card,
    Input,
    Table,
} from "antd";
import {Link} from "react-router-dom";
import { SearchOutlined } from '@ant-design/icons';

import memory from "../../../../../utils/memory";
import LinkButton from "../../../../../components/link-button";
import {moaApi} from "../../../../../api";
import {
    AWARDQUALITY,
    THREADSTATUS
} from "../../../../../utils/constant";
import tools from "../../../../../utils/tools";

const {
    reqAwardsList
} = moaApi;

export default class AwardsThreadList extends React.Component {
    state = {
        data: [],
        pagination: {},
        loading: false,
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        });
        let tmpFilters = {...filters};
        for(let item in tmpFilters) {
            if(tmpFilters[item]) {
                if(typeof(tmpFilters[item]) !== 'string') {
                    tmpFilters[item] = tmpFilters[item][0];
                }
            }
        }
        let params = {
            page: pagination.current,
            ...tmpFilters,
        };
        if(sorter && sorter.field) {
            if(sorter.order === 'ascend') {
                params[sorter.field] = -1;
            }else if(sorter.order === 'descend') {
                params[sorter.field] = 1;
            }
        }
        this.getAwardsList(params);
    }

    /*
    *搜索相关
    * */
    getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`搜索 ${title}`}
                    value={selectedKeys ? selectedKeys[0] : ''}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    搜索
                </Button>
                <Button onClick={() => this.handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    重置
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => text
    });
    handleSearch = (selectedKeys, confirm) => {
        confirm();
    };
    handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
    };

    getAwardsList = async (params = {}) => {
        this.setState({
            loading: true
        });
        const response = await reqAwardsList(params);
        const pagination = {...this.state.pagination};
        const data = response.data;
        pagination.total = data.page.total;
        pagination.pageSize = data.page.node;
        let newData = [];
        for(let i = 0; i < data.data.length; i++) {
            const item = data.data[i];
            const obj = {
                key: item._id,
                award_date: item.award_date,
                award_name: item.award_name,
                award_quality: item.award_quality ? AWARDQUALITY[item.award_quality-1].text : '-',
                platform: item.platform && item.platform.name ? item.platform.name : '-',
                provider: item.clue_provider && item.clue_provider.name ? item.clue_provider.name : '-',
                create_time: tools.unixToTime((new Date(item.create_at)).getTime()/1000),
                status: item.status ? THREADSTATUS[item.status-1].text : '-'
            };
            newData.push(obj);
        }
        this.setState({
            loading: false,
            data: newData,
            pagination
        });
    };

    columns = [
        {
            title: '奖项申报年份',
            dataIndex: 'award_date',
            key: 'award_date',
            sorter: (a, b) => a - b,
        },
        {
            title: '奖项名称',
            dataIndex: 'award_name',
            key: 'award_name',
            ...this.getColumnSearchProps('award_name', '奖项名称'),
            render: (text, record) => {
                return (
                    <Link to={`/moa/awards/thread/detail/${record.key}`}>
                        <span className="text-overflow" style={{maxWidth: 500}} title={text}>{text}</span>
                    </Link>
                );
            }
        },
        {
            title: '奖项质量',
            dataIndex: 'award_quality',
            key: 'award_quality',
            filters: AWARDQUALITY,
            filterMultiple: false,
        },
        {
            title: '奖项平台',
            dataIndex: 'platform',
            key: 'platform',
        },
        {
            title: '线索提供人',
            dataIndex: 'provider',
            key: 'provider',
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
        },
        {
            title: '线索状态',
            dataIndex: 'status',
            key: 'status',
            filters: THREADSTATUS,
            filterMultiple: false,
        },
        {
            title: '操作',
            dataIndex: 'option',
            valueType: 'option',
            render: (_, record) => {
                return (
                    <Link to={`/moa/awards/thread/detail/${record.key}`}>详情</Link>
                );
            }
        },
    ];

    componentDidMount() {
        this.getAwardsList({
            page: 1,
        });
    }

    render() {
        return (
            <Card
                title="线索列表"
                bordered={false}
                extra={<LinkButton to="/moa/awards/thread/add" icon="plus" txt="添加线索" />}
            >
                <Table
                    columns={this.columns}
                    dataSource={this.state.data}
                    pagination={this.state.pagination}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                    size={memory.is_mobile ? 'small' : 'middle'}
                />
            </Card>
        );
    }
}