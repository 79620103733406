import React from "react";
import {
    Layout,
    Breadcrumb,
    Icon,
    Avatar,
    Button,
    Popover,
    message,
} from 'antd';
import {Link, Route, Switch, Redirect} from "react-router-dom";
import isMobile from "ismobilejs";
import * as dd from "dingtalk-jsapi";

import LeftNav from "./components/left-nav/LeftNav";
import memory from "../../utils/memory";
import menuList from "./config/menu-config";
import {
    AUTH,
    ROLES
} from "../../utils/constant";
import {reqSignout} from "../../api";
import storage from "../../utils/storage";
import moment from 'moment'

import AdminHome from "./routes/home";
import RightList from "./routes/authority/right";
import RightAdd from "./routes/authority/right/add";
import RoleList from "./routes/authority/role";
import RoleAdd from "./routes/authority/role/add";
import RoleRightList from "./routes/authority/role/right";
import UserList from "./routes/users";
import UserRoleList from "./routes/users/role";

import DeviceList from "./routes/device";
import DeviceAdd from "./routes/device/add";

import VersionList from "./routes/version";
import VersionAdd from "./routes/version/add";

const { Content, Sider, Header, Footer } = Layout;
const {department_obj} = memory;
const {
    RIGHTS
} = ROLES;

export default class Admin extends React.Component{
    state = {
        loading: true,
        collapsed: isMobile(navigator.userAgent).any ? true : false,
    };
    breadcrumb = [];

    userInfoHeader = () => {
        return (
            <div className="pop-header">
                <Avatar size="small" className="pop-avatar" src={memory.user.dd_info.avatar} />
                <span className="pop-name">{memory.user.dd_info.name}</span>
            </div>
        );
    }
    userInfoContent = () => {
        return (
            <div className="pop-content">
                <Link to="/crm/" className="pop-content-item">
                    <Icon className="icon" type="unordered-list" />
                    <span className="item-name">个人中心</span>
                </Link>
                {
                    memory.user.role_type.indexOf(RIGHTS) > -1 ? (
                        <Link to="/admin" className="pop-content-item">
                            <Icon className="icon" type="safety" />
                            <span className="item-name">后台管理</span>
                        </Link>
                    ) : null
                }
                <div className="pop-content-info">
                    <div className="info-item">
                        <label className="info-item-label">部门：</label>
                        <span className="info-item-name">{department_obj[memory.user.dd_info.department[0]]}</span>
                    </div>
                </div>
                {
                    dd.env.platform !== 'notInDingTalk' ? null : (
                        <footer className="pop-content-footer">
                            <Button type="link" onClick={this.handleSignout}>退出登录</Button>
                        </footer>
                    )
                }
            </div>
        );
    }
    breadcrumbComponent = (menuArr, pathname) => {
        for(let i = 0; i < menuArr.length; i++) {
            if(pathname.indexOf(menuArr[i].key) === 0) {
                if(menuArr[i].key === '/crm') {
                    this.breadcrumb.push(
                        <Breadcrumb.Item key={menuArr[i].key}>
                            <Link to="/crm">
                                {menuArr[i].title}
                            </Link>
                        </Breadcrumb.Item>
                    );
                }else {
                    this.breadcrumb.push(
                        <Breadcrumb.Item key={menuArr[i].key}>
                            {menuArr[i].title}
                        </Breadcrumb.Item>
                    );
                }
                if(menuArr[i].children) {
                    this.breadcrumbComponent(menuArr[i].children, pathname);
                }
            }
        }
        return this.breadcrumb;
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    handleSignout = () => {
        (async () => {
            const response = await reqSignout();
            if(response) {
                message.success('账号已退出');
                storage.removeStore(AUTH);
                this.props.history.replace('/');
            }
        })();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(memory.is_mobile) {
            if(this.props.location.pathname !== prevProps.location.pathname) {
                this.setState({
                    collapsed: true
                });
            }
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        this.breadcrumb = [];
    }

    render() {
        const pathname = this.props.location.pathname;
        return (
            <Layout className="crm">
                <Sider
                    breakpoint="lg"
                    collapsedWidth={memory.is_mobile ? 0 : 80}
                    trigger={null}
                    collapsible
                    collapsed={this.state.collapsed}
                    style={{
                        overflow: 'auto',
                        height: '100%',
                        position: 'fixed',
                        left: 0,
                        zIndex: 99,
                    }}
                >
                    <div className="crm-logo" title="首页">
                        <Link to="/admin">
                            <Icon className="logo-icon" type="home" style={{color: '#ccc', fontSize: 26}} />
                            {
                                memory.is_mobile ? (
                                    <span className="logo-name" style={{color: '#ccc', fontSize: 26, marginLeft: 20}}>ADMIN</span>
                                ) : (
                                    this.state.collapsed ? null : (
                                        <span className="logo-name" style={{color: '#ccc', fontSize: 26, marginLeft: 20}}>ADMIN</span>
                                    )
                                )
                            }
                        </Link>
                    </div>
                    {<LeftNav />}
                </Sider>

                <Layout
                    style={{
                        marginLeft: memory.is_mobile ? 0 : (this.state.collapsed ? 80 : 200),
                    }}
                >
                    <Header
                        className="crm-header"
                        style={{
                            position: 'fixed',
                            zIndex: 97,
                            left: 0,
                            right: 0,
                            marginLeft: memory.is_mobile ? 0 : (this.state.collapsed ? 80 : 200)
                        }}
                    >
                        <Icon
                            className="crm-trigger"
                            type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                            onClick={this.toggle}
                        />

                        <div className="user">
                            <Popover className="popover" content={this.userInfoContent()} title={this.userInfoHeader()} placement="bottomRight">
                                <Avatar className="avatar" src={memory.user.dd_info.avatar} />
                            </Popover>
                        </div>
                    </Header>

                    <Breadcrumb
                        style={{
                            padding: '24px 24px 0',
                            marginTop: '64px',
                        }}>
                        {this.breadcrumbComponent(menuList, pathname)}
                    </Breadcrumb>

                    <Content style={{ margin: '24px 16px 0' }}>
                        <Switch>
                            <Route path={"/admin/device/edit/:id"} component={DeviceAdd} ></Route>
                            <Route path={"/admin/device/add"} component={DeviceAdd} ></Route>
                            <Route path={"/admin/version/list"} component={VersionList} ></Route>
                            <Route path={"/admin/version/add"} component={VersionAdd} ></Route>
                            <Route path="/admin/device/list" component={DeviceList} ></Route>
                            <Route path="/admin/users/role/:id" component={UserRoleList} exact></Route>
                            <Route path="/admin/users/list" component={UserList} exact></Route>
                            <Route path="/admin/authority/role/right/:id" component={RoleRightList} exact></Route>
                            <Route path="/admin/authority/role/edit/:id" component={RoleAdd}></Route>
                            <Route path="/admin/authority/role/add" component={RoleAdd}></Route>
                            <Route path="/admin/authority/role/list" component={RoleList} exact></Route>
                            <Route path="/admin/authority/right/edit/:id" component={RightAdd}></Route>
                            <Route path="/admin/authority/right/add" component={RightAdd}></Route>
                            <Route path="/admin/authority/right/list" component={RightList} exact></Route>
                            <Route path="/admin" component={AdminHome} exact></Route>
                        </Switch>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>谋乐网络科技 ©{moment().year()}</Footer>
                    <div
                        className="mask-layout"
                        style={{
                            display: memory.is_mobile ? (this.state.collapsed ? 'none' : 'block') : 'none'
                        }}
                        onClick={() => {
                            this.setState({
                                collapsed: true
                            });
                        }}
                    >
                    </div>
                </Layout>
            </Layout>
        );
    }
}