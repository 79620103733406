import React from "react";
import {
    Card,
    Table,
    Button,
    message
} from "antd";

import memory from "../../../../../utils/memory";
import tools from "../../../../../utils/tools";
import { adminApi } from "../../../../../api";

const {
    reqRoleList,
    reqUserDetail,
    reqUserRoleEdit
} = adminApi;

export default class UserRoleList extends React.Component {
    state = {
        selectedRowKeys: [],
        detail: {},
        data: [],
        pagination: {},
        loading: false
    };
    id = this.props.match.params.id;

    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        });
        let tmpFilters = { ...filters };
        for (let item in tmpFilters) {
            if (tmpFilters[item]) {
                if (typeof (tmpFilters[item]) !== 'string' && item !== 'tags') {
                    tmpFilters[item] = tmpFilters[item][0];
                }
            }
        }
        let params = {
            page: pagination.current,
            ...tmpFilters,
        };
        this.getRoleList(params);
    }

    columns = [
        {
            title: 'No.',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: '角色',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'type',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: '权限数',
            dataIndex: 'rights',
            key: 'rights'
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
        },
    ];

    validate = async () => {
        let response;
        if(this.id) {
            let params = {
                uid: this.id,
                rids: this.state.selectedRowKeys
            };
            response = await reqUserRoleEdit(params);
            if(response && response.data) {
                message.success('修改成功');
                this.props.history.goBack();
            }
        }
    };

    getRoleList = async (params = {}) => {
        this.setState({
            loading: true
        });
        const response = await reqRoleList(params);
        const pagination = { ...this.state.pagination };
        const data = response.data;
        pagination.total = data.page.total;
        pagination.pageSize = data.page.node;
        let newData = [];
        for (let i = 0; i < data.data.length; i++) {
            const item = data.data[i];
            let obj = {
                key: item._id,
                number: (data.page.current - 1) * data.page.node + i + 1,
                name: item.name,
                type: item.type,
                rights: item.rights || 0,
                create_time: tools.unixToTime((new Date(item.create_at)).getTime() / 1000),
            };
            newData.push(obj);
        }

        this.setState({
            loading: false,
            data: newData,
            pagination
        });
    };

    getUserDetail = async (params = {}) => {
        const response = await reqUserDetail(params);
        if (response && response.data) {
            let roles = [];
            for (let i = 0; i < response.data.roles.length; i++) {
                roles.push(response.data.roles[i].id);
            }
            this.setState({
                selectedRowKeys: roles,
                detail: response.data
            });
            this.getRoleList({
                page: 1,
            });
        }
    };

    componentDidMount() {
        // 获取详情
        this.getUserDetail({
            id: this.id
        });
    }

    render() {
        const { selectedRowKeys, detail } = this.state;
        const rowSelection = {
            preserveSelectedRowKeys: true,
            selectedRowKeys,
            onChange: this.onSelectChange
        };

        return (
            <>
                <Card
                    title={detail.dd_info ? `角色列表-${detail.dd_info.name}` : '角色列表'}
                    bordered={false}
                >
                    <Table
                        rowSelection={rowSelection}
                        columns={this.columns}
                        dataSource={this.state.data}
                        pagination={this.state.pagination}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                        size={memory.is_mobile ? 'small' : 'middle'}
                    />

                    <div className="submit-footer">
                        <Button type="primary" onClick={this.validate}>保存</Button>
                    </div>
                </Card>
            </>
        );
    }
}