import React from "react";
import {
    Spin,
    Card,
    Table,
    Descriptions,
    Button,
    Input,
} from "antd";
import {Link} from "react-router-dom";
import { SearchOutlined } from '@ant-design/icons';

import {moaApi, crmApi} from "../../../../../api";
import {
    MEETING_ACTION,
    MEETING_GROUP,
    MEETING_TYPE,
    MEETING_WAY,
    MEETING_QUALITY
} from "../../../../../utils/constant";
import LinkButton from "../../../../crm/components/link-button";
import tools from "../../../../../utils/tools";
import memory from "../../../../../utils/memory";
import Photo from "../../../../crm/components/photo";
import PhotoShow from "../../../../crm/components/photo-show";

const {
    reqMeetingDetail,
} = moaApi;
const {
    reqWorkLogList
} = crmApi;

export default class MeetingDetail extends React.Component {
    state = {
        loading: true,
        detail: {},
        previewVisible: false,
        contacts: [],
        workLogs: [],
        paginationWorkLog: {}, // 工作日志分页
    };
    id = this.props.match.params.id;
    contactsColumns = [
        {
            title: '联系人',
            dataIndex: 'person',
            key: 'person',
        },
        {
            title: '联系方式',
            dataIndex: 'info',
            key: 'info',
        },
        {
            title: '创建时间',
            dataIndex: 'create_at',
            key: 'create_at',
        },
        {
            title: '操作',
            dataIndex: 'option',
            valueType: 'option',
            render: (_, record, index) => {
                return (
                    <Link to={`/moa/meeting/contacts/detail/${this.id}/${record.key}`}>详情</Link>
                );
            }
        },
    ];

    handleVisible = () => {
        this.setState({
            previewVisible: true
        })
    };

    handleTableChangeWorkLog = (pagination, filters, sorter) => {
        const pager = {...this.state.paginationWorkLog};
        pager.current = pagination.current;
        this.setState({
            paginationWorkLog: pager
        });
        let params = {
            mid: this.id,
            page: pagination.current,
            ...this.state.searchObj,
        };
        this.getWorkLogList(params);
    }
    /*
    *搜索相关
    * */
    getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`搜索 ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    搜索
                </Button>
                <Button onClick={() => this.handleReset(clearFilters, dataIndex, confirm)} size="small" style={{ width: 90 }}>
                    重置
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => text
    });
    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        let obj = {...this.state.searchObj};
        obj[dataIndex] = selectedKeys[0];
        this.setState({
            searchObj: obj,
        });
    };
    handleReset = (clearFilters, dataIndex, confirm) => {
        clearFilters();
        let obj = {...this.state.searchObj};
        delete obj[dataIndex];
        this.setState({
            searchObj: obj,
        });
        confirm();
    };

    getMeetingDetail = async (params = {}) => {
        const response = await reqMeetingDetail(params);
        if(response && response.data) {
            let contacts = [];
            if(response.data.contact && response.data.contact.length) {
                for(let i = 0; i < response.data.contact.length; i++) {
                    const item = response.data.contact[i];
                    contacts.push({
                        key: item._id,
                        person: item.person,
                        info: item.info,
                        create_at: tools.unixToTime((new Date(item.create_at)).getTime()/1000),
                    });
                }
            }
            this.setState({
                detail: response.data,
                contacts: contacts,
            });
        }

        // 获取工作日志
        this.getWorkLogList({
            mid: this.id,
            page: 1,
        });
    };

    getWorkLogList = async (params = {}) => {
        const response = await reqWorkLogList(params);
        let pagination = {...this.state.paginationWorkLog};
        if(response && response.data) {
            const data = response.data;
            pagination.total = data.page.total;
            pagination.pageSize = data.page.node;
            const newData = [];
            for(let i = 0; i < data.data.length; i++) {
                const item = data.data[i];
                let obj = {
                    key: item._id,
                    create_time: tools.unixToTime(item.time/1000),
                    place: item.address,
                    user: item.creator.name,
                    quality: item.meeting.quality !== undefined ? MEETING_QUALITY[item.meeting.quality-1].text : '-'
                };
                newData.push(obj);
            }
            this.setState({
                workLogs: newData,
                paginationWorkLog: pagination,
                loading: false
            });
        }
    };

    visitColumns = [
        {
            title: '打卡时间',
            dataIndex: 'create_time',
            key: 'create_time',
        },
        {
            title: '打卡地点',
            dataIndex: 'place',
            key: 'place',
        },
        {
            title: '姓名',
            dataIndex: 'user',
            key: 'user',
            ...this.getColumnSearchProps('name', '姓名')
        },
        {
            title: '会议质量',
            dataIndex: 'quality',
            key: 'quality',
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record) => {
                return (
                    <Link to={`/crm/salesman/log-detail/${record.key}`}>详情</Link>
                );
            },
        }
    ];

    componentDidMount() {
        // 获取详情
        this.getMeetingDetail({
            id: this.id
        });
    }

    render() {
        const {detail} = this.state;
        let actions = [];
        let guests = [];
        if(detail.action && detail.action.length) {
            for(let j = 0; j < detail.action.length; j++) {
                actions.push(MEETING_ACTION[detail.action[j]-1].text);
            }
        }
        if(detail.guest && detail.guest.length) {
            for(let j = 0; j < detail.guest.length; j++) {
                guests.push(detail.guest[j].name);
            }
        }

        return this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <>
                <Card
                    title="会议信息"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                    extra={<LinkButton to={`/moa/meeting/edit/${this.id}`} txt="编辑" />}
                >
                    <Descriptions
                        style={{
                            marginBottom: 24,
                        }}
                        title="基本信息"
                    >
                        <Descriptions.Item label="名称">
                            {detail.name || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="主办方">
                            {detail.sponsor || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="会议时间">
                            {tools.unixToTime((new Date(detail.date)).getTime()/1000)}
                        </Descriptions.Item>
                        <Descriptions.Item label="报名截止时间">
                            {tools.unixToTime((new Date(detail.registration_deadline)).getTime()/1000)}
                        </Descriptions.Item>
                        <Descriptions.Item label="地点">
                            {detail.place || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="类别">
                            {MEETING_TYPE[detail.type - 1].text}
                        </Descriptions.Item>
                        <Descriptions.Item label="方式">
                            {MEETING_WAY[detail.way - 1].text}
                        </Descriptions.Item>
                        <Descriptions.Item label="行为">
                            {actions.length ? actions.join('、') : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="参会人群">
                            {MEETING_GROUP[detail.way - 1] ? MEETING_GROUP[detail.way - 1].text : '其他'}
                        </Descriptions.Item>
                        <Descriptions.Item label="链接">
                            {
                                detail.link ?
                                    <a href={detail.link} target='_blank'>{detail.link}</a> : '-'
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="嘉宾">
                            {guests.length ? guests.join('、') : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="备注">
                            {detail.remark || '-'}
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        style={{
                            marginBottom: 24,
                        }}
                        title="会议海报"
                    >
                        <Descriptions.Item>
                            {
                                detail.poster ? (
                                    <>
                                        <Photo url={detail.poster} size={200} onClick={this.handleVisible} />
                                        <PhotoShow visible={this.state.previewVisible} previewImage={detail.poster} />
                                    </>
                                ) : '无'
                            }
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        title="创建信息"
                        style={{
                            marginBottom: 24,
                        }}
                    >
                        <Descriptions.Item label="创建人">
                            {detail.creator && detail.creator.name ? detail.creator.name : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="创建时间">
                            {detail.create_at
                                ? tools.unixToTime(new Date(detail.create_at).getTime() / 1000)
                                : '-'}
                        </Descriptions.Item>
                    </Descriptions>
                </Card>

                <Card
                    title="联系人管理"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                    extra={<LinkButton to={`/moa/meeting/contacts/add/${this.id}`} txt="添加联系人" />}
                >
                    <Table
                        columns={this.contactsColumns}
                        dataSource={this.state.contacts}
                        pagination={false}
                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                        size={memory.is_mobile ? 'small' : 'middle'}
                    />
                </Card>

                <Card
                    title="工作日志管理"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                >
                    <Table
                        columns={this.visitColumns}
                        dataSource={this.state.workLogs}
                        pagination={this.state.paginationWorkLog}
                        onChange={this.handleTableChangeWorkLog}
                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                        size={memory.is_mobile ? 'small' : 'middle'}
                    />
                </Card>
            </>
        );
    }
}