import React from "react";
import {Link} from "react-router-dom";
import {Button} from "antd";

export default class LinkButton extends React.Component {
    render() {
        const {to, icon, txt} = this.props;
        return (
            <Link to={to}>
                <Button {...this.props} type="primary" icon={icon}>{txt}</Button>
            </Link>
        );
    }
}