import React from "react";
import {
    Spin,
    Card,
    Form,
    Row,
    Col,
    Alert,
    message,
    Descriptions,
    Button,
    Steps,
    Popconfirm,
    Radio, TreeSelect,
} from "antd";

import LinkButton from "../../../../../crm/components/link-button";
import {
    CAPITALLEVEL,
    SOURCE_TYPE_OBJ,
    THREADSTATUS
} from "../../../../../../utils/constant";
import tools from "../../../../../../utils/tools";
import { moaApi } from "../../../../../../api";
import memory from "../../../../../../utils/memory";

const { unixToTime } = tools;
const {
    reqFundDetail,
    reqUserList,
    reqFundEdit,
} = moaApi;
const { Step } = Steps;
const stepStyle = {
    marginBottom: 60,
    boxShadow: '0px -1px 0 0 #e8e8e8 inset',
};

class ThreadDetail extends React.Component {
    state = {
        loading: true,
        detail: {},
        current: 0,
        threadStatus: 3,
        treeData: [], //用户节点
    };
    formRef = React.createRef();
    id = this.props.match.params.id;

    onChange = current => {
        this.setState({ current });
    };

    handleRadioChange = (e) => {
        this.setState({
            threadStatus: e.target.value
        });
    };

    handleStatusChange = async (status) => {
        if (status === 4) {
            this.validate();
        } else {
            const response = await reqFundEdit({
                id: this.id,
                status: status,
            });
            if (response && response.data) {
                message.success('线索状态变更成功');
                this.getCapitalDetail({
                    id: this.id
                });
            }
        }
    };

    validate = () => {
        const { validateFields } = this.formRef.current;
        validateFields()
        .then(async (values) => {
            values.id = this.id;
            values.is_followed = true;
            values.status = 4;
            const response = await reqFundEdit(values);
            if (response && response.data) {
                message.success('进入资金管理流程');
                this.props.history.replace(`/moa/capital/detail/${this.id}`);
            }
        });
    };

    getUserList = async (params = {}) => {
        const response = await reqUserList(params);
        if (response && response.data) {
            const data = response.data;
            const treeData = [...this.state.treeData];
            if (data.data.length) {
                for (let i = 0; i < data.data.length; i++) {
                    for (let j = 0; j < data.data[i].dd_info.department.length; j++) {
                        for (let k = 0; k < treeData.length; k++) {
                            if (data.data[i].dd_info.department[j] === treeData[k].value) {
                                treeData[k].children.push({
                                    key: data.data[i]._id,
                                    value: data.data[i]._id,
                                    title: data.data[i].dd_info.name
                                });
                                continue;
                            }
                        }
                    }
                }
                this.setState({ treeData });
            }
        }
    };

    getCapitalDetail = async (params = {}) => {
        const response = await reqFundDetail(params);
        if (response && response.data) {
            this.setState({
                detail: response.data,
                loading: false,
            });
        }
    };

    componentWillMount() {
        let result = [];
        for (let i = 0; i < memory.departments.length; i++) {
            const item = memory.departments[i];
            result.push({
                key: item.id,
                value: item.id,
                title: item.name,
                selectable: false,
                children: []
            });
        }
        this.setState({
            treeData: result,
        })
    }

    componentDidMount() {
        this.getUserList({
            node: 999
        });
        // 获取详情
        this.getCapitalDetail({
            id: this.id
        });
    }

    render() {
        const { detail, current, treeData } = this.state;
        let workers = [];
        let response = [];
        if (detail.team_worker && detail.team_worker.length) {
            for (let j = 0; j < detail.team_worker.length; j++) {
                workers.push(detail.team_worker[j].name);
            }
        }
        if (detail.response_unit && detail.response_unit.length) {
            for (let j = 0; j < detail.response_unit.length; j++) {
                response.push(detail.response_unit[j].name);
            }
        }

        return this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <>
                <Button
                    type="link"
                    icon="left"
                    onClick={() => {
                        this.props.history.goBack();
                    }}
                >返回</Button>

                <Card
                    title="线索信息"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                    extra={<LinkButton to={`/moa/capital/thread/edit/${this.id}`} txt="编辑" />}
                >
                    <Descriptions
                        title="基本信息"
                    >
                        <Descriptions.Item label="资金申报年份">
                            {detail.fund_date || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="资金名称">
                            {detail.fund_name || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="负责单位">
                            {response.length ? response.join('、') : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="资金信息链接">
                            {
                                detail.official_website ?
                                    <a href={detail.official_website} target='_blank'>{detail.official_website}</a> : '-'
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="资金量级">
                            {detail.fund_grade ? CAPITALLEVEL[detail.fund_grade - 1].text : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="线索提供人">
                            {detail.clue_provider && detail.clue_provider.name ? detail.clue_provider.name : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="线索状态">
                            {detail.status ? THREADSTATUS[detail.status - 1].text : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="项目名称">
                            {detail.project_name || '无'}
                        </Descriptions.Item>
                        <Descriptions.Item label="备注">
                            {detail.remark || '-'}
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        style={{
                            marginBottom: 24,
                        }}
                        column={1}
                    >
                        <Descriptions.Item label="资金简介">
                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                {detail.brief || '无'}
                            </div>
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        title="创建信息"
                        style={{
                            marginBottom: 24,
                        }}
                    >
                        <Descriptions.Item label="创建人">
                            {detail.creator && detail.creator.name ? detail.creator.name : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="创建时间">
                            {detail.create_at
                                ? unixToTime(new Date(detail.create_at).getTime() / 1000)
                                : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="信息来源类型">
                            {detail.source_type ? SOURCE_TYPE_OBJ[detail.source_type] : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="信息来源渠道">
                            {detail.source_channel || '-'}
                        </Descriptions.Item>
                    </Descriptions>
                </Card>

                <Card
                    title="进度管理"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                >
                    <Steps type="navigation" current={current} style={stepStyle}>
                        {
                            detail.status > 1 ? (
                                <Step status="finish" title="已评估" />
                            ) : (
                                <Step status="process" title="评估阶段" />
                            )
                        }
                        <Step status="wait" title="申报阶段" />
                        <Step status="wait" title="评审阶段" />
                        <Step status="wait" title="立项阶段" />
                        <Step status="wait" title="建设阶段" />
                        <Step status="wait" title="验收阶段" />
                        <Step status="wait" title="结项阶段" />
                        <Step status="wait" title="总结阶段" />
                    </Steps>

                    {
                        detail.status === 1 ? (
                            <>
                                <Radio.Group value={this.state.threadStatus} onChange={this.handleRadioChange}>
                                    <Radio value={3}>判定有效</Radio>
                                    <Radio value={2}>判定无效</Radio>
                                </Radio.Group>
                                <div style={{ marginTop: 24 }}>
                                    <Popconfirm
                                        title={this.state.threadStatus === 3 ? "确定把该线索设为有效吗?" : "确定把该线索设为无效吗?"}
                                        onConfirm={() => this.handleStatusChange(this.state.threadStatus)}
                                        okText="确定"
                                        cancelText="取消"
                                    >
                                        <Button type="primary" style={{ marginRight: 12 }}>确定</Button>
                                    </Popconfirm>
                                </div>
                            </>

                        ) : null
                    }

                    {
                        detail.status === 2 ? (
                            <Alert message="已判定为无效线索" type="error" showIcon />
                        ) : null
                    }

                    {
                        detail.status === 3 ? (
                            <>
                                <Alert message="已判定为有效线索，请设置项目经理和协作者" type="success" style={{ marginBottom: 12 }} showIcon />

                                <Form ref={this.formRef}>
                                    <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                            <Form.Item
                                                label="项目经理"
                                                name="manager"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: '请选择项目经理',
                                                    },
                                                ]}
                                            >
                                                <TreeSelect
                                                    allowClear
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    placeholder="请选择项目经理"
                                                    treeData={treeData}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                            <Form.Item
                                                label="协作者"
                                                name="team_worker"
                                            >
                                                <TreeSelect
                                                    allowClear
                                                    multiple
                                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                    placeholder="请选择协作者"
                                                    treeData={treeData}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>

                                <Popconfirm
                                    title="确定放弃该线索吗?"
                                    onConfirm={() => this.handleStatusChange(5)}
                                    okText="确定"
                                    cancelText="取消"
                                >
                                    <Button style={{ marginRight: 12 }}>放弃</Button>
                                </Popconfirm>
                                <Popconfirm
                                    title="确定开始申报吗?"
                                    onConfirm={() => this.handleStatusChange(4)}
                                    okText="确定"
                                    cancelText="取消"
                                >
                                    <Button type="primary">开始申报</Button>
                                </Popconfirm>
                            </>

                        ) : null
                    }

                    {
                        detail.status === 4 ? (
                            <Alert message={<><span>已立项，前往查看：</span><a href={`/moa/capital/detail/${this.id}`}>{detail.fund_name}</a></>} type="success" showIcon />
                        ) : null
                    }

                    {
                        detail.status === 5 ? (
                            <Alert message="已放弃该线索" type="warning" showIcon />
                        ) : null
                    }
                </Card>
            </>
        );
    }
}

export default ThreadDetail;