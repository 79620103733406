export const BUSINESS_CLASSIFICATION = [
    {
        key : 1,
        value : 1,
        text : "渗透测试",
    },
    {
        key : 2,
        value : 2,
        text : "红蓝对抗",
    },
    {
        key : 3,
        value : 3,
        text : "等级保护",
    },
    {
        key : 4,
        value : 4,
        text : "一云",
    },
    {
        key : 5,
        value : 5,
        text : "漏洞之眼2.0",
    },
    {
        key : 6,
        value : 6,
        text : "漏洞之眼3.0",
    },
    {
        key : 7,
        value : 7,
        text : "城域主动防御中心",
    },
    {
        key : 8,
        value : 8,
        text : "红蓝攻防平台",
    },
    {
        key : 9,
        value : 9,
        text : "定制开发",
    },
    {
        key : 10,
        value : 10,
        text : "安全运维保障",
    },
    {
        key : 11,
        value : 11,
        text : "风险评估",
    },
    {
        key : 12,
        value : 12,
        text : "应急响应",
    },
    {
        key : 13,
        value : 13,
        text : "主机安全巡检",
    },
    {
        key : 14,
        value : 14,
        text : "APP安全审计",
    },
    {
        key : 15,
        value : 15,
        text : "日志审计",
    },
    {
        key : 16,
        value : 16,
        text : "定制安全服务",
    },
    {
        key : 17,
        value : 17,
        text : "云灾备",
    },
    {
        key : 18,
        value : 18,
        text : "压力测试",
    },
    {
        key : 19,
        value : 19,
        text : "流量分析",
    },
    {
        key : 20,
        value : 20,
        text : "硬件集成",
    },
    {
        key : 21,
        value : 21,
        text : "综合集成",
    },
    {
        key : 22,
        value : 22,
        text : "市场合作",
    },
];
export const BUSINESS_CLASSIFICATION_OBJ = {
    1 : "渗透测试",
    2 : "红蓝对抗",
    3 : "等级保护",
    4 : "一云",
    5 : "漏洞之眼2.0",
    6 : "漏洞之眼3.0",
    7 : "城域主动防御中心",
    8 : "红蓝攻防平台",
    9 : "定制开发",
    10 : "安全运维保障",
    11 : "风险评估",
    12 : "应急响应",
    13 : "主机安全巡检",
    14 : "APP安全审计",
    15 : "日志审计",
    16 : "定制安全服务",
    17 : "云灾备",
    18 : "压力测试",
    19 : "流量分析",
    20 : "硬件集成",
    21 : "综合集成",
    22 : "市场合作",
}
export const INVOICE_TYPE = [
    {
        key : 1,
        value : 1,
        text : '增值税普通发票',
    },
    {
        key : 2,
        value : 2,
        text : '增值税专用发票',
    }
];
export const INVOICE_TYPE_OBJ = {
    1 : "增值税普通发票",
    2 : "增值税专用发票"
}