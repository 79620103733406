import React from "react";
import { StackedColumn } from "@ant-design/charts"
import {crmApi} from "../../../../api"
import { Card } from "antd";

const {reqIntroduceAll,reqIntroduceToday,reqWeaponList} = crmApi;

const config = {
    title: {
      visible: false,
    },
    forceFit: true,
    padding: 'auto',
    xField: 'name',
    yField: 'value',
    yAxis: { min: 0 },
    color: ['#ae331b', '#1a6179'],
    stackField: 'type',
    xAxis:{
        title:{
            text:"产品名称",
        },
        label:{
            formatter:(text) => {
                if(typeof(text) === "string" && text?.length > 4){
                    return text.slice(0,3) + "..."
                }
                return text;
            }
        }
    },
    yAxis:{title:{text:"数量"}}
};

export default class WinChart extends React.Component{

    state = {
        data : [],
    }

    getAllData = async(params = {},callback) => {
        const response = await reqIntroduceAll(params);
        if(response?.data?.msg){
            this.allData = response.data.msg;
            if(callback) callback();
        }
    }

    getTodayData = async(params = {},callback) => {
        const response = await reqIntroduceToday(params);
        if(response?.data?.msg){
            this.todayData = response.data.msg;
            if(callback) callback();
        }
    }

    getWeaponList = async(params) => {
        const response = await reqWeaponList(params);
        if(response?.data){
            let arr = [];
            response.data.data.map((value,index) => {
                let item = {
                    value : value.complete_number || 0,
                    name : value.productname,
                    type : "全部"
                };
                arr.push(item);
            })
            this.setState({
                data : arr,
            })
        }
    }

    componentDidMount(){
        this.getWeaponList({is_deleted:false});
    }

    render(){
        return(
            <Card
                title="产品已售量"
                style={{
                    marginBottom: 16,
                }}
                bordered={false}
            >
                <StackedColumn {...config} data={this.state.data} />
            </Card>
        )
    }
}