import React from 'react'
import { Form, Row, Col, Card, Input, Button } from 'antd'

class ApproveItem extends React.Component {
    state = {
        is_examined: null,
        method: null,
    }

    is_examined = null;
    examined_content = null;

    validate = async (values) => {
        this.setState({ submitLoading: true });
        let { id } = this.props
        values.id = id;
        this.props.validate(
            values,
            () => this.setState({ submitLoading: false })
        );
    }

    componentDidMount() {
        let { detail } = this.props;
        if (detail && detail.data && detail.data.message) {
            this.setState({
                fileList: detail.data.message
            })
        }
        if (detail && detail.data && detail.data.method) {
            this.setState({
                method: detail.data.method
            })
        }
    }

    render() {
        let { detail } = this.props;
        return (
            <Form onFinish={this.validate}>
                <Card
                    title='快递信息录入'
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                >
                    <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <Form.Item
                                label="快递单号"
                                name="express_number"
                                initialValue={detail && detail.data && detail.data.express_number ? detail.data.express_number : undefined}
                                rules={[
                                    {
                                        required: true,
                                        message: "请填写快递单号"
                                    }
                                ]}
                            >
                                <Input placeholder="请填写快递单号，没有请填无" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <div className="submit-footer">
                    {
                        detail && detail.is_history === false ?
                            <Button type="primary" loading={this.state.submitLoading} htmlType="submit">
                                {
                                    '提交'
                                }
                            </Button>
                            :
                            <Button type='primary' disabled>
                                {
                                    '已提交'
                                }
                            </Button>
                    }
                </div>
            </Form>
        )
    }
}

export default ApproveItem;