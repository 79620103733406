import React from "react";
import {
    Spin,
    Form,
    Card,
    Row,
    Col,
    Input,
    Select,
    Button,
    message,
    Popconfirm,
    DatePicker,
} from "antd";

import { moaApi } from "../../../../../../api";
import {
    MOBILE_OWNER
} from "../../../../../../utils/constant";
import moment from "moment";
import UserSelector from "../../../../../../components/user-selector";

const { Option } = Select;
const {
    reqMobileAdd,
    reqMobileDetail,
    reqMobileEdit,
    reqMobileDel
} = moaApi;

class MobileAdd extends React.Component {
    state = {
        loading: true,
        detail: {},
    };
    id = this.props.match.params.id;

    validate = async (values) => {
        values.open_account_time = new Date(values.open_account_time._d);
        let response;
        if (!this.id) {
            for (let i in values) {
                if (!values[i]) {
                    delete values[i];
                }
            }
        }
        if (this.id) {
            values.id = this.id;
            response = await reqMobileEdit(values);
            if (response && response.data) {
                message.success('修改成功');
                this.props.history.goBack();
            }
        } else {
            response = await reqMobileAdd(values);
            if (response && response.data) {
                message.success('添加成功');
            }
        }
    };

    delMobile = async () => {
        const response = await reqMobileDel({
            id: this.id
        });
        if (response && response.data) {
            message.success('删除成功');
            this.props.history.replace('/moa/account/mobile/list');
        }
    };

    getMobileDetail = async (params = {}) => {
        const response = await reqMobileDetail(params);
        if (response && response.data) {
            this.setState({
                detail: response.data,
                loading: false
            });
        }
    };

    componentDidMount() {
        if (this.id) {
            // 获取详情
            this.getMobileDetail({
                id: this.id
            });
        }
    }

    render() {
        const { detail } = this.state;

        return this.id && this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <Form onFinish={this.validate} layout="vertical">
                <Card
                    title={
                        this.id ? "修改手机号信息" : "添加手机号"
                    }
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                    extra={
                        this.id ? (
                            <Popconfirm
                                title="确定删除该手机号信息吗?"
                                onConfirm={this.delMobile}
                                okText="确定"
                                cancelText="取消"
                            >
                                <Button>删除</Button>
                            </Popconfirm>
                        ) : null
                    }
                >
                    <Card
                        title="手机号基本信息"
                        bordered={false}
                        type="inner"
                    >
                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="手机号码"
                                    name="bound_mobile"
                                    initialValue={detail.bound_mobile || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入手机号码',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入手机号码" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="开户时间"
                                    name="open_account_time"
                                    initialValue={detail.open_account_time ? moment(detail.open_account_time) : null}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择开户时间',
                                        },
                                    ]}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请选择开户时间" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="绑定人"
                                    name="bound_person"
                                    initialValue={detail.bound_person && detail.bound_person.id ? detail.bound_person.id : []}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择绑定人',
                                        },
                                    ]}
                                >
                                    <UserSelector
                                        placeholder="请选择绑定人"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="手机归属"
                                    name="bound_mobile_owner_type"
                                    initialValue={detail.bound_mobile_owner_type !== undefined ? detail.bound_mobile_owner_type : null}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择手机归属',
                                        },
                                    ]}
                                >
                                    <Select placeholder="请选择手机归属">
                                        {MOBILE_OWNER.map((item, index) => (
                                            <Option key={item.key} value={item.value}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="手机保管人"
                                    name="bound_mobile_custodian"
                                    initialValue={detail.bound_mobile_custodian && detail.bound_mobile_custodian.id ? detail.bound_mobile_custodian.id : []}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择手机保管人',
                                        },
                                    ]}
                                >
                                    <UserSelector
                                        placeholder="请选择手机保管人"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Card>

                <div className="submit-footer">
                    <Button type="primary" htmlType="submit">
                        {
                            this.id ? '保存' : '提交'
                        }
                    </Button>
                </div>
            </Form>
        );
    }
}

export default MobileAdd;