import React from "react";
import {
    Card,
    message,
    Radio,
    Table,
    Popconfirm,
    Input,
    Button,
} from "antd";
import {
    Link,
} from "react-router-dom";
import moment from "moment";
import { SearchOutlined } from '@ant-design/icons';

import LinkButton from "../../../crm/components/link-button";
import memory from "../../../../utils/memory";
import { crmApi } from "../../../../api";
import StateTag from '../../../../components/state-tag'

const {
    reqApplyList,
    reqUpload,
} = crmApi;

export default class PendingContractList extends React.Component {
    state = {
        data: [],
        pagination: {},
        loading: false,
    };
    type = 0
    role = [0, 0, 0]

    /*
    *搜索相关
    * */
    getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`搜索 ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    搜索
                </Button>
                <Button onClick={() => this.handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    重置
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => text
    });
    handleSearch = (selectedKeys, confirm) => {
        confirm();
    };
    handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
    };

    getListHead = () => {
        switch (this.type) {
            case 4: return "待下载";
            case 5: return "待上传";
            case 6: return "待确认";
            case 7: return "待寄出";
        }
        return "待处理";
    }

    confirm = async (id) => {
        const response = await reqUpload({ aid: id })
        if (response) {
            message.success('确认成功')
            /*const params = {
                type:8,
                stage:this.type,
                is_history:false,
                page:1
            };
            this.getApplyList(params);*/
            window.location.reload();
        }
    }

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        });
        let tmpFilters = { ...filters };
        for (let item in tmpFilters) {
            if (tmpFilters[item]) {
                if (typeof (tmpFilters[item]) !== 'string' && item !== 'tags') {
                    tmpFilters[item] = tmpFilters[item][0];
                }
            }
        }
        let params = {
            page: pagination.current,
            type: 8,
            stage: this.type,
            is_history: false,
            ...tmpFilters,
        };
        this.getApplyList(params);
    }
    handleRadioChange = e => {
        this.type = e.target.value;
        const params = {
            type: 8,
            stage: e.target.value,
            is_history: false,
            page: 1
        };
        this.getApplyList(params);
    };
    columns = [
        {
            title: '名称',
            dataIndex: 'contract_name',
            key: 'contract_name',
            ...this.getColumnSearchProps('contract_name', '名称'),
        },
        {
            title: '编号',
            dataIndex: 'contract_number',
            key: 'contract_number',
            ...this.getColumnSearchProps('contract_number', '合同编号'),
        },
        {
            title: '签署日期',
            dataIndex: 'sign_date',
            key: 'sign_date',
            render: (text) => moment(text).format('YYYY/MM/DD')
        },
        {
            title: '所属客户',
            dataIndex: 'company_name',
            key: 'company_name',
            onCell: () => {
                return {
                    style: {
                        maxWidth: 300,
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        cursor: 'pointer'
                    }
                }
            },
            ...this.getColumnSearchProps('company_name', '客户名称'),
        },
        {
            title: '所属项目',
            dataIndex: 'business_name',
            key: 'business_name',
            ...this.getColumnSearchProps('business_name', '项目名称'),
        },
        {
            title: '创建人',
            dataIndex: 'creator_name',
            key: 'creator_name',
            ...this.getColumnSearchProps('creator_name', '姓名'),
        },
        {
            title: '进度',
            dataIndex: 'is_examined',
            key: 'is_examined',
            render: (text, record) => {
                return <StateTag stage={record.stage} is_history={record.is_history} />
            },
        },
        {
            title: '操作',
            dataIndex: 'option',
            valueType: 'option',
            render: (_, record, index) => {
                switch (record.stage) {
                    case 4: return <Link to={`/moa/contract/pending/approve/${record.id}`}>详情</Link>
                    case 5: return <>
                        <Link to={`/moa/contract/pending/edit/${record.id}`}>详情</Link>
                        <span> | </span>
                        <Popconfirm
                            title="确定已上传双章合同?"
                            onConfirm={() => this.confirm(record.id)}
                            okText="确定"
                            cancelText="取消"
                        >
                            <Link>结束上传</Link>
                        </Popconfirm>
                    </>
                    case 7: if (record.is_history === false) {
                        return <Link to={`/moa/contract/pending/edit/${record.id}`}>详情</Link>
                    } else {
                        return <Link to={`/moa/contract/pending/detail/${record.id}`}>详情</Link>
                    }
                    default: return <Link to={`/moa/contract/pending/detail/${record.id}`}>详情</Link>
                }
            }
        },
    ];
    getApplyList = async (params = {}) => {
        this.setState({ loading: true })
        const response = await reqApplyList(params)
        if (response && response.data) {
            const pagination = { ...this.state.pagination };
            pagination.total = response.data.page.total;
            pagination.pageSize = response.data.page.node;
            pagination.current = response.data.page.current;
            let data = []
            response.data.data.map((item, index) => {
                let obj = {
                    id: item._id || '-',
                    contract_name: item.data.contract_name || '-',
                    contract_number: item.data.contract_number || '-',
                    sign_date: item.data.sign_date || '-',
                    business_name: item.data.business_name || '-',
                    is_examined: item.is_examined,
                    stage: item.data.stage,
                    is_history: item.is_history,
                    creator_name: item.creator && item.creator.name ? item.creator.name : '-',
                    company_name: item.data && item.data.customer_name ? item.data.customer_name : '-'
                }
                data.push(obj)
            })
            this.setState({
                data: data,
                pagination: pagination,
                loading: false,
            })
        }
    }

    getRole = () => {
        let roles = memory.user.roles
        for (let i in roles) {
            if (roles[i].type === 3) {
                this.role = 3;
                this.type = 1
                break;
            }
            switch (roles[i].type) {
                case 26: this.role[0] = 4; break;
                case 28: this.role[1] = 5; break;
                case 27: this.role[2] = 6; break;
            }
            this.type = this.role[0] || this.role[1] || this.role[2]
        }
    }

    componentDidMount() {
        this.getRole()
        this.getApplyList({
            type: 8,
            stage: this.type,
            is_history: false,
            page: 1
        })
    }
    render() {
        return (
            <Card
                title={this.getListHead() + "列表"}
                bordered={false}
                style={{ marginBottom: 24 }}
                extra={
                    <LinkButton to={`/moa/contract/pending/history`} txt="历史记录" style={{ fontSize: 12 }} />
                }
            >
                {this.role === 3 ?
                    <div className="table-operations" style={{ marginBottom: 24 }}>
                        <Radio.Group value={this.type} onChange={this.handleRadioChange}>
                            <Radio.Button value={4}>待下载</Radio.Button>
                            <Radio.Button value={5}>待补充</Radio.Button>
                            <Radio.Button value={6}>待确认</Radio.Button>
                            <Radio.Button value={7}>待寄出</Radio.Button>
                        </Radio.Group>
                    </div>
                    :
                    <div className="table-operations" style={{ marginBottom: 24 }}>
                        <Radio.Group value={this.type} onChange={this.handleRadioChange}>
                            {
                                this.role.map((value, index) => {
                                    switch (value) {
                                        case 4: return <Radio.Button value={4}>待下载</Radio.Button>;
                                        case 5: return <Radio.Button value={5}>待补充</Radio.Button>;
                                        case 6: return <Radio.Button value={6}>待确认</Radio.Button>;
                                    }
                                })
                            }
                            {
                                this.role[1] && this.role[1] === 5 ? <Radio.Button value={7}>待寄出</Radio.Button> : null
                            }
                        </Radio.Group>
                    </div>
                }
                <Table
                    columns={this.columns}
                    dataSource={this.state.data}
                    pagination={this.state.pagination}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                    size={memory.is_mobile ? 'small' : 'middle'}
                />
            </Card>
        );
    }
}