import React from "react";
import {Card} from "antd";

export default class AdminHome extends React.Component{
    render() {
        return (
            <Card
                title="后台管理系统"
                bordered={false}
            ></Card>
        );
    }
}