import React from "react";
import {
    Card,
    Table,
    Input,
    Button,
    Switch,
    message
} from "antd";
import {Link} from "react-router-dom";
import { SearchOutlined } from '@ant-design/icons';

import memory from "../../../../utils/memory";
import {adminApi} from "../../../../api";
import LinkButton from "../../../crm/components/link-button";

const {reqDeviceList,reqDeviceEdit} = adminApi;

export default class DeviceList extends React.Component {
    state = {
        data: [],
        pagination: {},
        loading: false,
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        });
        let tmpFilters = {...filters};
        for(let item in tmpFilters) {
            if(tmpFilters[item]) {
                if(typeof(tmpFilters[item]) !== 'string' && item !== 'tags') {
                    tmpFilters[item] = tmpFilters[item][0];
                }
            }
        }
        let params = {
            page: pagination.current,
            ...tmpFilters,
        };
        this.getDeviceList(params);
    }
    /*
    *搜索相关
    * */
    getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`搜索 ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    搜索
                </Button>
                <Button onClick={() => this.handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    重置
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => text
    });
    handleSearch = (selectedKeys, confirm) => {
        confirm();
    };
    handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
    };

    columns = [
        {
            title: '设备编号',
            dataIndex: 'zhongbiao_code',
            key: 'zhongbiao_code',
        },
        {
            title: '设备码',
            dataIndex: 'equipment_code',
            key: 'equipment_code',
        },
        {
            title: '前台版本号',
            dataIndex: 'version2',
            key: 'version2',
        },
        {
            title: '后台版本号',
            dataIndex: 'version1',
            key: 'version1',
        },
        {
            title: '使用者',
            dataIndex: 'user',
            key: 'user',
        },
        {
            title: "手机号",
            dataIndex: "mobile",
            key: "mobile"
        },  
        {
            title: '是否禁用',
            dataIndex: 'is_blocked',
            key: 'is_blocked',
            render: (text,record) => {
                return(
                    <Switch checkedChildren={"已禁用"} unCheckedChildren={"禁用"} checked={text} onChange={(value) => this.handleSwitchChange(value,record)} />
                )
            }
        },
        {
            title: '操作',
            dataIndex: 'option',
            valueType: 'option',
            render: (_, record, index) => {
                return (
                    <Link to={`./edit/${record.key}`}>编辑</Link>
                );
            }
        },
    ];

    handleSwitchChange = async(value,record) => {
        const response = await reqDeviceEdit({
            equipment_id : record.key,
            is_blocked : value,
            version1 : record.version1,
            version2 : record.version2,
        });
        if(response?.data){
            message.success("更改成功");
            this.getDeviceList({
                page: this.state.pagination.current
            });
        }
    }

    getDeviceList = async (params = {}) => {
        this.setState({
            loading: true
        });
        const response = await reqDeviceList(params);
        if(response?.data?.msg){
            const pagination = {...this.state.pagination};
            const data = response.data.msg;
            pagination.total = data.page.total;
            pagination.pageSize = data.page.node;
            pagination.current = data.page.current;
            let newData = [];
            for(let i = 0; i < data.data.length; i++) {
                const item = data.data[i];
                const obj = {
                    key: item._id,
                    zhongbiao_code : item.zhongbiao_code || "-",
                    equipment_code : item.equipment_code || "-",
                    version1 : item.version1 || "-",
                    version2 : item.version2 || "-",
                    user : item?.user?.name || '-',
                    mobile : item.mobile || '-',
                    is_blocked : item.is_blocked
                };
                newData.push(obj);
            }
            this.setState({
                loading: false,
                data: newData,
                pagination
            });
        }
    };

    componentDidMount() {
        this.getDeviceList({
            page: 1,
        });
    }

    render() {
        return (
            <Card
                title="设备列表"
                bordered={false}
                extra={<LinkButton to="./add" txt="添加设备" />}
            >
                <Table
                    columns={this.columns}
                    dataSource={this.state.data}
                    pagination={this.state.pagination}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                    size={memory.is_mobile ? 'small' : 'middle'}
                />
            </Card>
        );
    }
}