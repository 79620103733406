import React from "react";
import {
    Card,
    Table,
} from "antd";
import {Link} from "react-router-dom";

import memory from "../../../../../utils/memory";
import LinkButton from "../../../../crm/components/link-button";
import {moaApi} from "../../../../../api";
import tools from "../../../../../utils/tools";

const {
    reqResponseList,
} = moaApi;

export default class ResponseList extends React.Component {
    state = {
        data: [],
        pagination: {},
        loading: false,
    };
    columns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '官网',
            dataIndex: 'official_website',
            key: 'official_website'
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
        },
        {
            title: '创建人',
            dataIndex: 'creator',
            key: 'creator',
        },
        {
            title: '操作',
            dataIndex: 'option',
            valueType: 'option',
            render: (_, record) => {
                return (
                    <Link to={`/moa/capital/response/detail/${record.key}`}>详情</Link>
                );
            }
        },
    ];

    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        });
        let tmpFilters = {...filters};
        for(let item in tmpFilters) {
            if(tmpFilters[item]) {
                if(typeof(tmpFilters[item]) !== 'string' && item !== 'tags') {
                    tmpFilters[item] = tmpFilters[item][0];
                }
            }
        }
        let params = {
            page: pagination.current,
            //...this.state.searchObj,
            ...tmpFilters,
        };
        this.getResponseList(params);
    }

    getResponseList = async (params = {}) => {
        this.setState({
            loading: true
        });
        const response = await reqResponseList(params);
        const pagination = {...this.state.pagination};
        const data = response.data;
        pagination.total = data.page.total;
        pagination.pageSize = data.page.node;
        let newData = [];
        for(let i = 0; i < data.data.length; i++) {
            const item = data.data[i];
            const obj = {
                key: item._id,
                name: <Link to={`/moa/capital/response/detail/${item._id}`}>{item.name}</Link>,
                official_website: item.official_website || '-',
                create_time: tools.unixToTime((new Date(item.create_at)).getTime()/1000),
                creator: item.creator.name || '-'
            };
            newData.push(obj);
        }
        this.setState({
            loading: false,
            data: newData,
            pagination
        });
    };

    componentDidMount() {
        this.getResponseList({
            page: 1,
        });
    }

    render() {
        return (
            <Card
                title="负责单位列表"
                bordered={false}
                extra={<LinkButton to="/moa/capital/response/add" txt="添加负责单位" />}
            >
                <Table
                    columns={this.columns}
                    dataSource={this.state.data}
                    pagination={this.state.pagination}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                    size={memory.is_mobile ? 'small' : 'middle'}
                />
            </Card>
        );
    }
}