import React from "react";
import {
    Button,
    Card,
    message,
    Radio,
    Table,
    Tag,
    Modal,
} from "antd";
import {
    Link,
} from "react-router-dom";
import moment from "moment";

import memory from "../../../../utils/memory";
import tools from "../../../../utils/tools";
import {crmApi} from "../../../../api";
import StateTag from '../../../../components/state-tag'
import LinkButton from '../../components/link-button'
import { PAGE_SIZE } from "../../../../utils/constant";

const {
    reqApplyList,
    reqMyApplyList
} = crmApi;

export default class MyContractList extends React.Component {
    state = {
        data: [],
        pagination: {
            showSizeChanger: true,
            showTotal: total => `总数${total}条`,
            pageSizeOptions: ["10","20","50","100","200"],
            pageSize: PAGE_SIZE,
        },
        loading: false
    };

    confirm = () => {
        Modal.confirm({
            title:'请确保待提交的合同申请不会跟已申请及被驳回的合同申请重复',
            okText:'留下确认',
            cancelText:'继续合同申请',
            onCancel:()=>{
                this.props.history.push('/moa/contract/apply/add')
            }
        })
    }

    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        pager.pageSize = pagination.pageSize;
        this.setState({
            pagination: pager
        });
        let tmpFilters = {...filters};
        for(let item in tmpFilters) {
            if(tmpFilters[item]) {
                if(typeof(tmpFilters[item]) !== 'string' && item !== 'tags') {
                    tmpFilters[item] = tmpFilters[item][0];
                }
            }
        }
        let params = {
            page: pagination.current,
            node: pagination.pageSize,
            type:8,
            ...tmpFilters,
        };
        this.getApplyList(params);
    }
    columns = [
        {
            title: 'No.',
            dataIndex: 'index',
            key: 'index',
        },
        {
            title: '名称',
            dataIndex: 'contract_name',
            key: 'contract_name'
        },
        {
            title: '编号',
            dataIndex: 'contract_number',
            key: 'contract_number',
        },
        {
            title: '签署日期',
            dataIndex: 'sign_date',
            key: 'sign_date',
            render:(text)=>moment(text).format('YYYY/MM/DD')
        },
        {
            title: '进度',
            dataIndex: 'is_examined',
            key: 'is_examined',
            render: (text, record) => {
                return <StateTag stage={record.stage} is_examined={text} is_history={record.is_history}/>
            },
        },
        {
            title: '操作',
            dataIndex: 'option',
            valueType: 'option',
            render: (_, record, index) => {
                return (
                    record.is_examined===false?
                        <Link to={`/moa/contract/apply/detail/${record.id}`} style={{color:"red"}}>完善</Link>
                        :
                        <Link to={`/moa/contract/apply/detail/${record.id}`}>详情</Link>
                    )
            }
        },
    ];
    getApplyList = async (params={}) => {
        this.setState({loading:true});
        const response = await reqMyApplyList(params)
        if(response && response.data){
            const pagination = {...this.state.pagination};
            pagination.total = response.data.page.total;
            pagination.pageSize = response.data.page.node;
            pagination.current = response.data.page.current;
            let data=[]
            response.data.data.map((item,index)=>{
                let obj={
                    id:item._id,
                    index:index+1,
                    contract_name:item.data.contract_name || '-',
                    contract_number:item.data.contract_number || '*',
                    sign_date:item.data.sign_date || '-',
                    /*business_name:item.data.business_name || '-',*/
                    is_examined:item.is_examined === false?false:null,
                    stage:item.data.stage,
                    is_history:item.is_history,
                }
                data.push(obj)
            })
            this.setState({
                data:data,
                pagination:pagination,
                loading:false,
            })
        }
    } 
    componentDidMount(){
        this.getApplyList({
            type:8,
            page:1,
            node : PAGE_SIZE
        })
    }
    render(){
        return (
            <Card
                title="我的申请列表"
                bordered={false}
                style={{marginBottom:24}}
                extra={
                    <Button type='primary' onClick={this.confirm}>合同申请</Button>
                }
            >
                <Table
                    className="small-table-style"
                    columns={this.columns}
                    dataSource={this.state.data}
                    pagination={this.state.pagination}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                    size={'small'}
                />
            </Card>
        );
    }
}