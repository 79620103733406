import React, { forwardRef, useImperativeHandle } from "react";
import { Card, Table, Input, Button, Icon, Descriptions, message, Popover, Tag, Modal, Timeline } from "antd";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import moment from "moment";
import { SearchOutlined, TagsFilled } from '@ant-design/icons';

import tools from "../../../../../utils/tools";
import { manageApi, crmApi } from "../../../../../api";
import { ACTION_ITEM_OBJ, CUSTOMER_LEVEL, CUSTOMER_TYPE, CUSTOMER_TYPE_OBJ, PAGE_SIZE, REPEAT_ORDER } from "../../../../../utils/constant";

import "./index.less";

const { reqClientManageList, reqExportExcel } = manageApi;
const { reqClientTagList } = crmApi;

const FullScreenButton = forwardRef((props, ref) => {//全屏需要函数组件

    const handle = useFullScreenHandle();
    const handleFullScreen = () => {
        handle.enter();
    }
    useImperativeHandle(ref, () => ({ handle: handle }));
    return (
        <Button type="primary" onClick={handleFullScreen}>全屏</Button>
    )
})

export default class ClientManageList extends React.Component {
    state = {
        data: [],
        pagination: {
            showSizeChanger: true,
            showTotal: total => `总数${total}条`,
            pageSizeOptions: ["10", "20", "50", "100", "200"],
            pageSize: PAGE_SIZE,
        },
        loading: false,
        handle: null,
        buttonLoading: false,
        tags: [],
    };

    sortObj = {};

    handleTableChange = (pagination, filters, sorter) => {
        //分页
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        pager.pageSize = pagination.pageSize;
        this.setState({
            pagination: pager
        });
        //筛选
        let tmpFilters = { ...filters };
        for (let item in tmpFilters) {
            if (tmpFilters[item]) {
                if (typeof (tmpFilters[item]) !== 'string' && item !== 'tags') {
                    tmpFilters[item] = tmpFilters[item][0];
                }
            }
        }
        //排序
        let sortObj = {};
        if (sorter?.order) {
            let str = "";
            if (sorter.order === "descend") {
                str = "-";
            }
            sortObj.sort = str + sorter.columnKey;
        }
        //排序发生变化，分页重置
        if (sortObj.sort !== this.sortObj.sort) {
            sortObj.page = 1;
        }
        this.sortObj = sortObj;
        let params = {
            page: pagination.current,
            node: pagination.pageSize,
            ...tmpFilters,
            ...sortObj,
        };
        this.getList(params);
    }

    /*
    *搜索相关
    * */
    getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`搜索 ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    搜索
                </Button>
                <Button onClick={() => this.handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    重置
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => text
    });
    handleSearch = (selectedKeys, confirm) => {
        confirm();
    };
    handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
    };

    getTagList = async (params = {}, callback) => {
        const response = await reqClientTagList(params);
        if (response?.data) {
            const data = response.data;
            let newData = [];
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                newData.push(item);
            }
            this.setState({
                tags: newData,
            });
            if (callback) callback();
        }
    };

    getList = async (params = {}) => {
        this.setState({
            loading: true
        });
        if (params?.tags?.length) {
            params.tags = params.tags.join(",");
        } else if (params?.tags?.length === 0) {
            delete params.tags;
        }
        const response = await reqClientManageList(params);
        if (response?.data) {
            const pagination = { ...this.state.pagination };
            const data = response.data;
            pagination.current = data.page.current;
            pagination.total = data.page.total;
            pagination.pageSize = data.page.node;
            let newData = [];
            for (let i = 0; i < data.data.length; i++) {
                const item = data.data[i];
                const obj = {
                    key: item._id,
                    name: item.name || "-",
                    level: item.level || "-",
                    contractTotal: item.contractTotal,
                    profitTotal: item.profitTotal,
                    marketTotal: item.marketTotal,
                    contract2022: item.contract2022,
                    profit2022: item.profit2022,
                    market2022: item.market2022,
                    contract2023: item.contract2023,
                    profit2023: item.profit2023,
                    market2023: item.market2023,
                    marketList: item.marketList,
                    repeatOrder: item.repeatOrder,
                    contact: item.contact,
                    kpNum: item.kpNum,
                    kp: item.kp,
                    seller: item.seller,
                    winSeller: item.winSeller,
                    currentSeller: item.currentSeller,
                    tags: item.tags || [],
                    category: item.category,
                    pm_name: item?.project_managers?.length ? item.project_managers.join(",") : "-",
                    actions: item.actions,
                };
                newData.push(obj);
            }
            this.setState({
                loading: false,
                data: newData,
                pagination
            });
        }
    };

    actionsDetail = (actions) => {
        Modal.info({
            title: "行动项详情",
            icon: null,
            content: (
                <Timeline
                    style={{
                        maxHeight: 600,
                        overflow: "auto",
                        paddingTop: 4
                    }}
                >
                    {
                        actions.map((value, index) => (
                            <Timeline.Item key={index}>
                                <div>
                                    {`${value.user} ${value.date} ${value.action ? ACTION_ITEM_OBJ[value.action] : "-"}`}
                                </div>
                                <div>
                                    {`内容:${value.content || "-"}`}
                                </div>
                            </Timeline.Item>
                        ))
                    }
                </Timeline>
            ),
        })
    }

    expandedRender = (record, index) => {
        return (
            <div>
                <Descriptions title="合同列表">
                    <Descriptions.Item>
                        {
                            record?.contact?.length ?
                                record.contact.map((value, index) => (
                                    <div key={index}>{`签约时间：${value.date},合同名称：${value.name},金额：${value.money ? tools.formatNumber(value.money) + "元" : "0元"}。`}</div>
                                ))
                                :
                                "-"
                        }
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions title="KP">
                    <Descriptions.Item>
                        {record?.kp?.length ? record.kp.map((value, index) => <div key={index}>{`${value.name},手机号：${value.mobile || "无"},微信号：${value.wechat || "无"}。`}</div>) : "-"}
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions title="历史跟进销售">
                    <Descriptions.Item>
                        {record.seller?.length ? record.seller.join(",") : "-"}
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions title="赢单销售">
                    <Descriptions.Item>
                        {record.winSeller?.length ? record.winSeller.join(",") : "-"}
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions title="行动项">
                    <Descriptions.Item>
                        <span>{`${record?.actions?.length || 0}项`}</span>
                        {
                            record?.actions?.length ?
                                <Button
                                    type="link"
                                    onClick={() => this.actionsDetail(record.actions)}
                                >
                                    详情
                                </Button>
                                :
                                null
                        }
                    </Descriptions.Item>
                </Descriptions>
            </div>
        )
    }

    handleExport = async () => {
        this.setState({
            buttonLoading: true,
        });
        const response = await reqExportExcel({
            exclude: 'marketTotal,marketList,market2023,market2022'
        });
        if (response?.data) {
            const blob = new Blob([response.data]);
            const fileReader = new FileReader();
            fileReader.readAsText(blob);
            fileReader.onload = (e) => {
                if (e.target.result.indexOf('"statusCode"') >= 0) {
                    message.error(JSON.parse(e.target.result).message);
                } else {
                    const fileName = `${moment().format("YYYY/MM/DD HH:mm:ss")}客户数据.xlsx`
                    if ('download' in document.createElement('a')) {
                        message.success('导出成功')
                        const link = document.createElement('a')
                        link.download = fileName
                        link.style.display = 'none'
                        link.href = URL.createObjectURL(blob)
                        document.body.appendChild(link)
                        link.click();
                        URL.revokeObjectURL(link)
                        document.body.removeChild(link)
                    } else {
                        navigator.msSaveBlob(blob, fileName)
                    }
                }
                this.setState({
                    buttonLoading: false,
                });
            }
        } else {
            this.setState({
                buttonLoading: false,
            });
        }
    }

    componentDidMount() {
        // 获取详情
        this.getList({
            page: 1,
            node: PAGE_SIZE,
            refresh: 1,
        });
        this.getTagList({});
        this.setState({
            handle: this.handle,
            onChange: this.onChange
        })
    }

    render() {
        const columns = [
            {
                title: '客户',
                dataIndex: 'name',
                key: 'name',
                width: 100,
                ...this.getColumnSearchProps("name", "客户名称"),
            },
            {
                title: '客户级别',
                dataIndex: 'level',
                key: 'level',
                width: 100,
                filterMultiple: false,
                filters: CUSTOMER_LEVEL,
            },
            {
                title: '客户类型',
                dataIndex: 'category',
                key: 'category',
                width: 100,
                filterMultiple: false,
                filters: CUSTOMER_TYPE,
                render: text => text != null ? CUSTOMER_TYPE_OBJ[text] : "-",
            },
            {
                title: '总合同金额',
                dataIndex: 'contractTotal',
                key: 'contractTotal',
                sorter: true,
                sortDirections: ['descend', 'ascend'],
                width: 100,
                render: text => text ? tools.formatNumber(text) + "元" : "0元"
            },
            {
                title: '总毛利',
                dataIndex: 'profitTotal',
                key: 'profitTotal',
                sorter: true,
                sortDirections: ['descend', 'ascend'],
                width: 100,
                render: text => text ? tools.formatNumber(text) + "元" : "0元"
            },
            {
                title: '2022年合同金额',
                dataIndex: 'contract2022',
                key: 'contract2022',
                sorter: true,
                sortDirections: ['descend', 'ascend'],
                width: 100,
                render: text => text ? tools.formatNumber(text) + "元" : "0元"
            },
            {
                title: '2022年毛利',
                dataIndex: 'profit2022',
                key: 'profit2022',
                sorter: true,
                sortDirections: ['descend', 'ascend'],
                width: 100,
                render: text => text ? tools.formatNumber(text) + "元" : "0元"
            },
            {
                title: '2023年合同金额',
                dataIndex: 'contract2023',
                key: 'contract2023',
                sorter: true,
                sortDirections: ['descend', 'ascend'],
                width: 100,
                render: text => text ? tools.formatNumber(text) + "元" : "0元"
            },
            {
                title: '2023年毛利',
                dataIndex: 'profit2023',
                key: 'profit2023',
                sorter: true,
                sortDirections: ['descend', 'ascend'],
                width: 100,
                render: text => text ? tools.formatNumber(text) + "元" : "0元"
            },
            {
                title: '续单状况',
                dataIndex: 'repeatOrder',
                key: 'repeatOrder',
                width: 100,
                filters: REPEAT_ORDER,
                filterMultiple: false,
            },
            {
                title: 'KP数量',
                dataIndex: 'kpNum',
                key: 'kpNum',
                width: 100,
            },
            {
                title: '项目经理',
                dataIndex: 'pm_name',
                key: 'pm_name',
                width: 100,
                ...this.getColumnSearchProps("pm_name", "项目经理"),
            },
            // {
            //     title: '曾经跟进销售',
            //     dataIndex: 'seller',
            //     key: 'seller',
            //     ...this.getColumnSearchProps("sellers","曾经跟进销售"),
            //     width: 100,
            //     render: (text) => text?.length ? text.join(",") : "-",
            // },
            // {
            //     title: '赢单销售',
            //     dataIndex: 'winSeller',
            //     key: 'winSeller',
            //     ...this.getColumnSearchProps("win_sellers","赢单销售"),
            //     width: 100,
            //     render: (text) => text?.length ? text.join(",") : "-",
            // },
            {
                title: '当前跟进销售',
                dataIndex: 'currentSeller',
                key: 'currentSeller',
                ...this.getColumnSearchProps("currentSeller", "当前跟进销售"),
                width: 100,
                render: (text) => text?.length ? text.join(",") : "-",
            },
            {
                title: '客户标签',
                dataIndex: 'tags',
                key: 'tags',
                width: 200,
                filters: this.state.tags.map(value => ({ text: value, value: value })),
                render: (text) => (
                    text?.length ?
                        text.length > 1 ?
                            <Popover
                                title="标签"
                                content={
                                    <div style={{ padding: 16 }}>
                                        {
                                            text.map((value, index) => <Tag key={index} style={{ borderLeft: `4px solid blue` }}>{value}</Tag>)
                                        }
                                    </div>
                                }
                                trigger={"click"}
                                getPopupContainer={(trigger) => trigger.parentNode}
                            >
                                <Tag style={{ cursor: "pointer" }} onClick={(e) => e.stopPropagation()}>
                                    <span>
                                        <TagsFilled style={{ marginRight: 8 }} />
                                        {text.length}
                                    </span>
                                </Tag>
                            </Popover>
                            :
                            text.map((value, index) => <Tag key={index} style={{ borderLeft: `4px solid blue` }}>{value}</Tag>)
                        :
                        "-"
                )
            },
        ];
        return (
            <Card
                className="client-manage-list"
                title="客户管理列表"
                style={{
                    marginBottom: 24,
                }}
                bordered={false}
                extra={
                    <>
                        <Button type="primary" loading={this.state.buttonLoading} onClick={this.handleExport} style={{ marginRight: 4 }}>导出</Button>
                        <FullScreenButton ref={(el) => { this.handle = el?.handle; this.onChange = el?.onChange }} setState={() => this.setState({})} />
                    </>
                }
            >
                {
                    this.state.handle ?
                        <FullScreen handle={this.state.handle} >
                            <Table
                                className="small-table-style"
                                columns={columns}
                                dataSource={this.state.data}
                                pagination={this.state.pagination}
                                loading={this.state.loading}
                                onChange={this.handleTableChange}
                                scroll={{ x: 'max-content', y: "90vh" }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                size={'small'}
                                expandedRowRender={this.expandedRender}
                                expandRowByClick={true}
                            />
                        </FullScreen>
                        :
                        null
                }
            </Card>
        );
    }
}