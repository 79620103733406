import React from "react"
import Remarks from "../../components/remarks"

const basicAmount = (
    <div>
        <div style={{marginBottom : 8}}>本人项目：
            <div>&nbsp;&nbsp;&nbsp;&nbsp;销售：5%</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;总监：8%</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;总经理：10%</div>
        </div>
        <div>非本人项目:
            <div>&nbsp;&nbsp;&nbsp;&nbsp;总监：3%</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;总经理：2%</div>
        </div>
    </div>
)

const extraAmount = (
    <div>
        <div style={{marginBottom : 8}}>产品：
            <div>&nbsp;&nbsp;&nbsp;&nbsp;毛利率≥60%：10%</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;毛利率≥55%：7%</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;毛利率≥50%：5%</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;毛利率{'<'}50%：0</div>
        </div>
        <div>服务:
            <div>&nbsp;&nbsp;&nbsp;&nbsp;毛利率≥60%：3.5%</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;毛利率≥55%：3%</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;毛利率≥50%：2.5%</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;毛利率{'<'}50%：0</div>
        </div>
    </div>
)

const performanceSection = (
    <div>
        <div style={{marginBottom : 8}}>新客户：
            <div>&nbsp;&nbsp;&nbsp;&nbsp;季度完成率≥60%：100%</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;季度完成率≥40%：50%</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;季度完成率{'<'}40%：0</div>
        </div>
        <div style={{marginBottom : 8}}>老客户：
            <div>&nbsp;&nbsp;&nbsp;&nbsp;年度客户毛利增长≥10%：100%</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;年度客户毛利增长{'<'}10%：0</div>
        </div>
    </div>
)

const performanceSection1 = (
    <div>
        <div style={{marginBottom : 8}}>年度、季度完成率：
            <div>&nbsp;&nbsp;&nbsp;&nbsp;其中一个≥60%：100%</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;其中一个≥40%：50%</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;完成率都{'<'}40%：0</div>
        </div>
    </div>
)

const COMMISSION_FORMULA1 = [
    <div>1.总提成金额=项目提成金额 + 自营类额外提成金额(项目类型是自营且是本人项目才计算) + 业绩超额提成金额(季度超额提成，是本人项目才计算)</div>,
    <div>2.项目提成金额=项目毛利×回款比例<Remarks title={"已回款金额/合同金额"} />× (提成系数 × 翻倍系数 + 特殊制度提成系数<Remarks title="本人项目，公司季度设定的特殊奖励制度，如业绩花红奖等" />)</div>,
    <div>3.自营类额外提成金额=自营类毛利1×回款比例×额外提成比例1<Remarks title={extraAmount} /> + 自营类毛利2×回款比例×额外提成比例2 + ...(这里表示该项目有两个自营类，有更多以此类推)</div>,
    <div>4.业绩超额提成金额 = 超额毛利×回款比例×提成系数×0.5(ps:超额系数)</div>,
    <div>5.提成系数=基础提成系数<Remarks title={basicAmount} />×[ 60%(无责部分) + 40% × 业绩考核比例<Remarks title={performanceSection} /> ]</div>,
    <div>6.翻倍系数=1（基础倍数）+1（新客户首单加成，是本人项目才计算）+1（公司指定战略客户，是本人项目才计算）；PS：不满足括号里的说明，则不用加</div>,
]

const COMMISSION_FORMULA2 = [
    <div>1.总提成金额=项目提成金额 + 自营类额外提成金额(项目类型是自营才计算) + 业绩超额提成金额(季度团队超额提成)</div>,
    <div>2.项目提成金额=项目毛利×回款比例<Remarks title={"已回款金额/合同金额"} />×(提成系数 + 特殊提成系数 + 特殊制度提成系数<Remarks title="本人项目，公司季度设定的特殊奖励制度，如业绩花红奖等" />)</div>,
    <div>3.自营类额外提成金额=自营类毛利1×回款比例×额外提成比例1<Remarks title={extraAmount} /> + 自营类毛利2×回款比例×额外提成比例2 + ...(这里表示该项目有两个自营类，有更多以此类推)</div>,
    <div>4.业绩超额提成金额=超额毛利×回款比例×提成系数×0.8(ps:超额系数)</div>,
    <div>5.提成系数=基础提成系数<Remarks title={basicAmount} />×[ 60%(无责部分) + 40% × 业绩考核比例<Remarks title={performanceSection} /> ]</div>,
    <div>6.特殊提成系数=15%（新客户首单加成）+5%（公司指定战略客户）；PS：不满足括号里的说明，则不用加</div>,
]

const COMMISSION_FORMULA3 = [
    <div>1.总提成金额=项目毛利×回款比例<Remarks title={"已回款金额/合同金额"} />×提成系数</div>,
    <div>2.提成系数=10%(基础提成系数)×[ 60%(无责部分) + 40% × 业绩考核比例<Remarks title={performanceSection1} /> ]</div>,
]

export const COMMISSION_FORMULA = [COMMISSION_FORMULA1,COMMISSION_FORMULA2,COMMISSION_FORMULA3];

export const AMOUNT_KEY = "MoneyField-JSZML716";//金额
export const CONTRACT_KEY = "TextField_1E2FP2OADLS00";//合同编号
export const DEPARTMENT_KEY = "DDSelectField_SJAZWYDR0000";//申请人类别
export const TYPE_KEY = "DDSelectField-JSZML715";//费用种类
export const DATE_KEY = "DDDateField-JSX14ABC";//实际业务日期
export const PROJECTNAME_KEY = "DDSelectField-JT8BS17Y";//项目名称及代码
export const SUMMARY_KEY = "费用明细";//摘要
export const CUSTOMER_KEY = "TextField_238K8AEFP90G0";//客户（或项目）名称
export const INVOICE_KEY = "DDSelectField-JUWFA9G0";//是否有发票