import React from "react";
import {
    Spin,
    Card,
    Descriptions,
    Button, Tag,
} from "antd";
import moment from "moment";
import {Link} from "react-router-dom";

import {moaApi} from "../../../../../api";
import LinkButton from "../../../../crm/components/link-button";
import tools from "../../../../../utils/tools";
import {ACCOUNT_PLATFORM_TYPE} from "../../../../../utils/constant";

const {
    reqAccountDetail,
} = moaApi;

export default class AccountDetail extends React.Component {
    state = {
        loading: true,
        detail: {},
    };
    id = this.props.match.params.id;

    getAccountDetail = async (params = {}) => {
        const response = await reqAccountDetail(params);
        if(response && response.data) {
            this.setState({
                detail: response.data,
                loading: false
            });
        }
    };

    componentDidMount() {
        // 获取详情
        this.getAccountDetail({
            id: this.id
        });
    }

    render() {
        const {detail} = this.state;
        let status = 1; // 正常
        let diff = '';
        if(detail.certification_deadline) {
            diff = moment(detail.certification_deadline).diff(moment(), 'months');
            if(diff === 0) {
                status = 2; // 待认证
            }else if(diff < 0) {
                status = 3; // 已过期
            }
        }

        return this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <>
                <Button
                    type="link"
                    icon="left"
                    onClick={() => {
                        this.props.history.goBack();
                    }}
                >返回</Button>

                <Card
                    title="账号信息"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                    extra={<LinkButton to={`/moa/account/edit/${this.id}`} txt="编辑" />}
                >
                    <Descriptions
                        style={{
                            marginBottom: 24,
                        }}
                        title="基本信息"
                    >
                        <Descriptions.Item label="注册时间">
                            {tools.unixToTime((new Date(detail.register_time)).getTime()/1000, true)}
                        </Descriptions.Item>
                        <Descriptions.Item label="注册人">
                            {detail.registrant.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="更新时间">
                            {tools.unixToTime((new Date(detail.update_at)).getTime()/1000, true)}
                        </Descriptions.Item>
                        <Descriptions.Item label="更新人">
                            {detail.update_person.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="平台">
                            {detail.platform}
                        </Descriptions.Item>
                        <Descriptions.Item label="平台类别">
                            {ACCOUNT_PLATFORM_TYPE[detail.platform_type-1] ? ACCOUNT_PLATFORM_TYPE[detail.platform_type-1].text : '其它'}
                        </Descriptions.Item>
                        <Descriptions.Item label="用途">
                            {detail.use}
                        </Descriptions.Item>
                        <Descriptions.Item label="用户名">
                            {detail.user_name}
                        </Descriptions.Item>
                        <Descriptions.Item label="账号">
                            {detail.account}
                        </Descriptions.Item>
                        <Descriptions.Item label="密码">
                            {detail.password}
                        </Descriptions.Item>
                        <Descriptions.Item label="维护人">
                            {detail.maintenance_man.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="绑定手机">
                            <Link to={`/moa/account/mobile/detail/${detail.mobile.id}`}>{detail.mobile.bound_mobile}</Link>
                        </Descriptions.Item>
                        <Descriptions.Item label="绑定邮箱">
                            {detail.bound_email || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="绑定邮箱归属人">
                            {detail.bound_email_owner && detail.bound_email_owner.name ? detail.bound_email_owner.name : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="实名信息">
                            {detail.real_name || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="认证信息">
                            {detail.certification_info || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="最后认证完成时间">
                            {detail.final_certification_time ? tools.unixToTime((new Date(detail.final_certification_time)).getTime()/1000, true) : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="认证有效期">
                            {detail.certification_period ? detail.certification_period+'月' : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="认证截止日期">
                            {detail.certification_deadline ? tools.unixToTime((new Date(detail.certification_deadline)).getTime()/1000, true) : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="认证预警">
                            {status === 2 ? <Tag color="#f50">待认证</Tag> : (status === 3 ? <Tag color="#f00">已过期</Tag> : <Tag color="#87d068">正常</Tag>)}
                        </Descriptions.Item>
                        <Descriptions.Item label="主体">
                            {detail.main_body || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="备注">
                            {detail.remark || '-'}
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        title="创建信息"
                        style={{
                            marginBottom: 24,
                        }}
                    >
                        <Descriptions.Item label="创建人">
                            {detail.creator && detail.creator.name ? detail.creator.name : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="创建时间">
                            {detail.create_at
                                ? tools.unixToTime(new Date(detail.create_at).getTime() / 1000)
                                : '-'}
                        </Descriptions.Item>
                    </Descriptions>
                </Card>
            </>
        );
    }
}