/*
* 用来在内存中保存一些数据的工具模块
* */

export default {
    user: {},
    seller: {},
    departments: [],
    department_obj: {},
    crm_global: {}, //所在行业对象
    is_mobile: false,
    sign: '', // 钉钉签名
    nonceStr: '',
    unreadMessageAmount : 0,
    siteMessageList : [],
    font: undefined,
    menuCount: {

    },
}