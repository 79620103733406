/*
* 应用的根组件
* 获取个人信息和部门信息
* */
import React from 'react';
import { Layout, message, Spin, BackTop } from "antd";
import { Route, Switch } from "react-router-dom";
import * as dd from 'dingtalk-jsapi';
import isMobile from "ismobilejs";
import ReactWaterMark from 'react-watermark-component';
import moment from 'moment';

import Home from "./pages/home/Home";
import RepositoryIndex from "./pages/repository";
import Index from "./pages/crm/";
import Admin from "./pages/admin/";
import MoaIndex from './pages/moa/'
import TrainIndex from "./pages/train";
import ManageIndex from "./pages/manage";
import GptIndex from "./pages/gpt";
import GptAdminIndex from "./pages/gpt-admin";
// import Arena from './pages/arena';
import './App.less';
import storage from "./utils/storage";
import memory from "./utils/memory";
import { AUTH, UserInfoContext, AVATAR } from "./utils/constant";
import { reqLogin, reqSelfInfo, reqDepartmentList } from "./api";
import tools from "./utils/tools";
import SwitchAccount from './pages/switch-account';
import PdfView from './pages/pdf-view';

// import AiVoice from './pages/ai-voice';
export default class App extends React.Component {
    state = {
        loading: true,
        user_name: '',
        user_ava: '',
        user_department: '',
        unreadMessageAmount: 0,
        siteMessageList: []
    };
    options = {
        chunkWidth: 400,
        chunkHeight: 100,
        textAlign: 'left',
        textBaseline: 'bottom',
        globalAlpha: 0.17,
        font: '16px Microsoft Yahei',
        rotateAngle: -0.26,
        fillStyle: '#666'
    }

    componentWillMount = () => {
        
        memory.is_mobile = isMobile(navigator.userAgent).any;
        const auth = storage.getStore(AUTH);
        if (dd.env.platform !== 'notInDingTalk') {
            this.setState({
                loading: false
            });
        } else {
            if (auth) {
                (async () => {
                    // 获取个人信息
                    const response = await reqSelfInfo();
                    if (response) {
                        memory.user = response.data;
                        memory.user.dd_info.avatar = memory.user.dd_info.avatar || AVATAR;
                        memory.user.role_type = [];
                        if (response.data.roles && response.data.roles.length) {
                            for (let i = 0; i < response.data.roles.length; i++) {
                                memory.user.role_type.push(response.data.roles[i].type);
                            }
                        }
                        // 获取部门信息
                        const dep = await reqDepartmentList();
                        if (dep) {
                            memory.departments = dep.data;
                            for (let i = 0; i < dep.data.length; i++) {
                                memory.department_obj[dep.data[i].id] = dep.data[i].name;
                            }
                        }

                        this.setState({
                            user_id: response.data._id,
                            user_name: response.data.dd_info.name,
                            user_ava: response.data.dd_info.avatar || AVATAR,
                            user_department: response.data.dd_info.department,
                            loading: false
                        });
                    } else {
                        storage.removeStore(AUTH);
                        this.props.history.replace('/');
                        this.setState({
                            loading: false
                        });
                    }
                })();
            } else {
                const code = tools.queryString('code');
                if (code) {
                    (async () => {
                        const res = await reqLogin({ code });
                        const auth = res.data.token;
                        storage.saveStore(AUTH, auth);
                        this.props.history.replace('/');
                        message.success('登录成功');

                        const response = await reqSelfInfo();
                        if (response) {
                            memory.user = response.data;
                            memory.user.dd_info.avatar = memory.user.dd_info.avatar || AVATAR;
                            memory.user.role_type = [];
                            if (response.data.roles && response.data.roles.length) {
                                for (let i = 0; i < response.data.roles.length; i++) {
                                    memory.user.role_type.push(response.data.roles[i].type);
                                }
                            }
                            // 获取部门信息
                            const dep = await reqDepartmentList();
                            if (dep) {
                                memory.departments = dep.data;
                                for (let i = 0; i < dep.data.length; i++) {
                                    memory.department_obj[dep.data[i].id] = dep.data[i].name;
                                }
                            }

                            this.setState({
                                user_id: response.data._id,
                                user_name: response.data.dd_info.name,
                                user_ava: response.data.dd_info.avatar || AVATAR,
                                user_department: response.data.dd_info.department,
                                loading: false
                            });
                        }
                    })();
                } else {
                    this.setState({
                        loading: false
                    });
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const auth = storage.getStore(AUTH);
        if (!auth && this.state.user_name) {
            this.setState({
                user_name: '',
                user_ava: AVATAR,
                user_department: ''
            });
        }
    }

    componentDidMount() {
        /* window.onerror = async function (errorMessage, scriptURI, lineNumber, columnNumber, errorObj) {
            const response = await reqFrontError({
                errorMessage: errorMessage,
                scriptURI: scriptURI,
                lineNumber: lineNumber,
                columnNumber: columnNumber,
                errorObj: errorObj
            });
            return true;
        } */
    }

    setMessageState = (params = {}) => {
        this.setState(params);
    }

    render() {
        return (
            <UserInfoContext.Provider value={{ ...this.state, setState: this.setMessageState }}>
                {
                    this.state.loading ? (
                        <div className="loading-container">
                            <Spin size="large" />
                        </div>
                    ) : (
                        <Layout className="home">
                            <ReactWaterMark
                                waterMarkText={`${memory?.user?.dd_info?.name || ''} ${memory?.user?.dd_info?.jobnumber || ''} ${moment().format('YYYY-MM-DD')}`}
                                openSecurityDefense
                                options={this.options}
                            >
                                <Switch>
                                    {/* <Route path="/ai-voice" component={AiVoice}></Route> */}
                                    {/* <Route path="/arena" component={Arena}></Route> */}
                                    <Route path="/pdf-view" component={PdfView}></Route>
                                    <Route path="/gpt-admin" component={GptAdminIndex}></Route>
                                    <Route path="/gpt/history/:id" component={GptIndex}></Route>
                                    <Route path="/gpt" component={GptIndex}></Route>
                                    <Route path="/admin" component={Admin}></Route>
                                    <Route path="/manage" component={ManageIndex}></Route>
                                    <Route path="/repository" component={RepositoryIndex}></Route>
                                    <Route path="/crm" component={Index}></Route>
                                    <Route path="/moa" component={MoaIndex}></Route>
                                    <Route path="/train" component={TrainIndex}></Route>
                                    {/* <Route path='/arena-admin' component={Arena}></Route> */}
                                    <Route path='/switch/account' component={SwitchAccount}></Route>
                                    <Route path="/" component={Home}></Route>
                                </Switch>
                            </ReactWaterMark>
                            {/*<Footer className="footer">© 2013-{(new Date()).getFullYear()} 漏洞银行</Footer>*/}
                            <BackTop style={{ bottom: 100, right: 20 }} />
                        </Layout>
                    )
                }
            </UserInfoContext.Provider>
        );
    }
}
