import store from 'store';

export default {
    saveStore(key, data) {
        store.set(key, data);
    },
    getStore(key) {
        return store.get(key);
    },
    removeStore(key) {
        store.remove(key);
    }
}