import React from "react";
import {
    Card,
    Table,
} from "antd";
import {Link} from "react-router-dom";

import LinkButton from "../../../crm/components/link-button";
import memory from "../../../../utils/memory";
import {moaApi} from "../../../../api";
import tools from "../../../../utils/tools";
import {
    MEETING_TYPE,
    MEETING_WAY,
    MEETING_ACTION,
    MEETING_GROUP
} from "../../../../utils/constant";

const {
    reqMeetingList
} = moaApi;

export default class MeetingList extends React.Component {
    state = {
        data: [],
        pagination: {},
        loading: false,
    };
    columns = [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '主办方',
            dataIndex: 'sponsor',
            key: 'sponsor'
        },
        {
            title: '会议时间',
            dataIndex: 'date',
            key: 'date'
        },
        {
            title: '报名截止时间',
            dataIndex: 'registration_deadline',
            key: 'registration_deadline'
        },
        {
            title: '地点',
            dataIndex: 'place',
            key: 'place'
        },
        {
            title: '类别',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: '方式',
            dataIndex: 'way',
            key: 'way'
        },
        {
            title: '行为',
            dataIndex: 'action',
            key: 'action'
        },
        {
            title: '参会人群',
            dataIndex: 'participating_group',
            key: 'participating_group'
        },
        {
            title: '链接',
            dataIndex: 'link',
            key: 'link'
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time'
        },
        {
            title: '操作',
            dataIndex: 'option',
            valueType: 'option',
            render: (_, record) => {
                return (
                    <Link to={`/moa/meeting/detail/${record.key}`}>详情</Link>
                );
            }
        },
    ];

    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        });
        let tmpFilters = {...filters};
        for(let item in tmpFilters) {
            if(tmpFilters[item]) {
                if(typeof(tmpFilters[item]) !== 'string' && item !== 'tags') {
                    tmpFilters[item] = tmpFilters[item][0];
                }
            }
        }
        let params = {
            page: pagination.current,
            ...tmpFilters,
        };
        this.getMeetingList(params);
    }

    getMeetingList = async (params = {}) => {
        this.setState({
            loading: true
        });
        const response = await reqMeetingList(params);
        const pagination = {...this.state.pagination};
        const data = response.data;
        pagination.total = data.page.total;
        pagination.pageSize = data.page.node;
        let newData = [];
        for(let i = 0; i < data.data.length; i++) {
            const item = data.data[i];
            let actions = [];
            if(item.action && item.action.length) {
                for(let j = 0; j < item.action.length; j++) {
                    actions.push(MEETING_ACTION[item.action[j]-1].text);
                }
            }
            let obj = {
                key: item._id,
                name: <Link to={`/moa/meeting/detail/${item._id}`}>{item.name}</Link>,
                sponsor: item.sponsor,
                date: tools.unixToTime((new Date(item.date)).getTime()/1000),
                registration_deadline: tools.unixToTime((new Date(item.registration_deadline)).getTime()/1000),
                place: item.place,
                type: MEETING_TYPE[item.type - 1].text,
                way: MEETING_WAY[item.way - 1].text,
                action: actions.length ? actions.join('、') : '-',
                participating_group: MEETING_GROUP[item.way - 1] ? MEETING_GROUP[item.way - 1].text : '其他',
                link: item.link || '-',
                create_time: tools.unixToTime((new Date(item.create_at)).getTime()/1000),
            };
            newData.push(obj);
        }
        this.setState({
            loading: false,
            data: newData,
            pagination
        });
    };

    componentDidMount() {
        this.getMeetingList({
            page: 1,
        });
    }

    render() {
        return (
            <Card
                title="会议列表"
                bordered={false}
                extra={<LinkButton to="/moa/meeting/add" txt="添加会议" />}
            >
                <Table
                    columns={this.columns}
                    dataSource={this.state.data}
                    pagination={this.state.pagination}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                    size={memory.is_mobile ? 'small' : 'middle'}
                />
            </Card>
        );
    }
}