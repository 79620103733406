import React from "react";
import {
    TreeSelect,
} from "antd";

import {reqUserList} from "../../api";
import memory from "../../utils/memory";

export default class UserSelector extends React.Component {
    state = {
        treeData: [],
        value: []
    };
    type = this.props.type || 1; // 使用用户_id为1，使用钉钉userId为2
    userObj = {};
    departmentObj = {};
    menus = [];

    getTreeNodes = (arr, departmentObj, userObj) => {
        if(arr) {
            for(let i = 0; i < arr.length; i++) {
                if(departmentObj[arr[i].id] || userObj[arr[i].id]) {
                    if(departmentObj[arr[i].id]) {
                        arr[i].children = [...departmentObj[arr[i].id]];
                    }

                    if(userObj[arr[i].id]) {
                        if(arr[i].children) {
                            let tmpArr = [...arr[i].children];
                            tmpArr = tmpArr.concat(userObj[arr[i].id]);
                            arr[i].children = tmpArr;
                        }else {
                            arr[i].children = [...userObj[arr[i].id]];
                        }
                    }
                    this.getTreeNodes(arr[i].children, departmentObj, userObj);
                }
            }
            return arr;
        }
    };

    getUserList = async (params = {}) => {
        const users = await reqUserList(params);
        if(users && users.data) {
            for(let i = 0; i < memory.departments.length; i++) {
                let item = memory.departments[i];
                if(!item.parentid) {
                    continue;
                }
                if(item.parentid !== 1) {
                    if(!this.departmentObj[item.parentid]) {
                        this.departmentObj[item.parentid] = [];
                    }
                    this.departmentObj[item.parentid].push({
                        id: item.id,
                        value: item.id,
                        title: item.name,
                        selectable: false,
                    });
                }else {
                    this.menus.push({
                        id: item.id,
                        value: item.id,
                        title: item.name,
                        selectable: false
                    });
                }
            }

            for(let i = 0; i < users.data.data.length; i++) {
                let item = users.data.data[i];
                if(item && item.dd_info.department) {
                    for(let j = 0; j < item.dd_info.department.length; j++) {
                        let department = item.dd_info.department[j];
                        if(department === 1) {
                            if(this.type === 1) {
                                this.menus.push({
                                    id: item._id,
                                    value: item._id,
                                    title: item.dd_info.name
                                });
                            }else {
                                this.menus.push({
                                    id: item.dd_info.userid,
                                    value: item.dd_info.userid,
                                    title: item.dd_info.name
                                });
                            }
                        }else {
                            if(!this.userObj[department]) {
                                this.userObj[department] = [];
                            }
                            if(this.type === 1) {
                                this.userObj[department].push({
                                    id: item._id,
                                    value: item._id,
                                    title: item.dd_info.name
                                });
                            }else {
                                this.userObj[department].push({
                                    id: item.dd_info.userid,
                                    value: item.dd_info.userid,
                                    title: item.dd_info.name
                                });
                            }
                        }
                    }
                }
            }

            const result = this.getTreeNodes(this.menus, this.departmentObj, this.userObj);
            this.setState({
                treeData: result,
                value: this.props.value || []
            });
        }


        if(this.id) {
            // 获取详情
            this.getSellerDetail({
                id: this.id
            });
        }
    };

    handleChange = (value) => {
        this.setState({value});
        this.props.onChange(value);
    };

    componentDidMount() {
        this.getUserList({
            node: 999,
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const { value } = nextProps;
        this.setState({value});
    }

    render() {
        const {treeData} = this.state;

        return (
            <TreeSelect
                {...this.props}
                treeNodeFilterProp={"title"}
                value={this.state.value}
                allowClear
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeData={treeData}
                onChange={this.handleChange}
            />
        );
    }
}