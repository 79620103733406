import React from "react";
import {
    ConfigProvider,
    Spin,
    Layout,
    Popover,
    Avatar,
    Button,
    message,
    Breadcrumb,
    Badge,
} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import {Link, Route, Switch} from "react-router-dom";
import * as dd from 'dingtalk-jsapi';
import isMobile from "ismobilejs";
import moment from 'moment';
import 'moment/locale/zh-cn';
import { BellOutlined, MenuUnfoldOutlined, MenuFoldOutlined, HomeOutlined } from '@ant-design/icons';

import "./index.less";
import memory from "../../utils/memory";
import LeftNav from "./components/left-nav/LeftNav";
import {AUTH, AVATAR, CORPID, ROLES, UserInfoContext} from "../../utils/constant";
import {reqSelfInfo, reqSignout, moaApi, reqDepartmentList,siteMessageApi} from "../../api";
import storage from "../../utils/storage";
import menuList from "./config/menu-config";

import Home from "./routes/home";
import CapitalList from "./routes/capital";
import CapitalDetail from "./routes/capital/detail";
import CapitalAdd from "./routes/capital/add";
import AgencyList from "./routes/capital/agency";
import AgencyAdd from "./routes/capital/agency/add";
import AgencyDetail from "./routes/capital/agency/detail";
import ArriveAdd from "./routes/capital/arrive/add";
import ArriveDetail from "./routes/capital/arrive/detail";
import ExpenseAdd from "./routes/capital/expense/add";
import ExpenseDetail from "./routes/capital/expense/detail";
import ContactsAdd from "./routes/capital/contacts/add";
import ContactsDetail from "./routes/capital/contacts/detail";
import AgencyContactsAdd from "./routes/capital/agency/contacts/add";
import AgencyContactsDetail from "./routes/capital/agency/contacts/detail";
import ResponseList from "./routes/capital/response";
import ResponseAdd from "./routes/capital/response/add";
import ResponseDetail from "./routes/capital/response/detail";
import ThreadList from "./routes/capital/thread";
import ThreadAdd from "./routes/capital/thread/add";
import ThreadDetail from "./routes/capital/thread/detail";

import AccountList from "./routes/account";
import AccountAdd from "./routes/account/add";
import AccountDetail from "./routes/account/detail";
import MobileList from "./routes/account/mobile";
import MobileAdd from "./routes/account/mobile/add";
import MobileDetail from "./routes/account/mobile/detail";

import MeetingList from "./routes/meeting";
import MeetingAdd from "./routes/meeting/add";
import MeetingDetail from "./routes/meeting/detail";

import MeetingContactsAdd from "./routes/meeting/contacts/add";
import MeetingContactsDetail from "./routes/meeting/contacts/detail";

import AwardsThreadList from "./routes/awards/thread";
import AwardsThreadAdd from "./routes/awards/thread/add";
import AwardsThreadDetail from "./routes/awards/thread/detail";
import AwardsList from "./routes/awards";
import AwardsDetail from "./routes/awards/detail";
import AwardsAdd from "./routes/awards/add";
import AwardsAgencyList from "./routes/awards/agency";
import AwardsAgencyAdd from "./routes/awards/agency/add";
import AwardsAgencyDetail from "./routes/awards/agency/detail";
import AwardsAgencyContactsAdd from "./routes/awards/agency/contacts/add";
import AwardsAgencyContactsDetail from "./routes/awards/agency/contacts/detail";
import AwardsPlatformList from "./routes/awards/platform";
import AwardsPlatformAdd from "./routes/awards/platform/add";
import AwardsPlatformDetail from "./routes/awards/platform/detail";
import AwardsPlatformContactsAdd from "./routes/awards/platform/contacts/add";
import AwardsPlatformContactsDetail from "./routes/awards/platform/contacts/detail";
import AwardsContactsAdd from "./routes/awards/contacts/add";
import AwardsContactsDetail from "./routes/awards/contacts/detail";

import ContractList from "./routes/contract";
import ContractAdd from "./routes/contract/add";
import ContractDetail from "./routes/contract/detail";
import PendingContractList from "./routes/pending-contract"
import PendingContractHistory from "./routes/pending-contract/history"
import PendingContractDetail from "./routes/pending-contract/detail"
import PendingContractEdit from "./routes/pending-contract/edit"
import PendingContractApprove from "./routes/pending-contract/approve"
import PendingContractApproveNew from "./routes/pending-contract/new-approve"
import MyContractList from "./routes/contract-apply"
import MyContractDetail from "./routes/contract-apply/detail"
import MyContractAdd from "./routes/contract-apply/add"

import MyTaskHistory from "./routes/task/history";

import AbilityList from "./routes/admin/ability";
import AbilityEdit from "./routes/admin/ability/edit";

import AdminSealList from "./routes/seal/admin";
import MySealList from "./routes/seal/user";

import InvoicePackageList from '../crm/routes/client/invoice/package-list'
import InvoicePackageHistoryList from '../crm/routes/client/invoice/package-list/history'
import InvoicePackageDetail from '../crm/routes/client/invoice/package-list/detail'

import BusinessDetail from '../crm/routes/client/business-detail'
import NoticeList from "../crm/components/notice-list";

moment.locale('zh-cn'); 
const { Header, Content, Footer, Sider } = Layout;
const {department_obj} = memory;
const {reqLogin} = moaApi;
const {reqUnreadMessageAmount,reqMessageList} = siteMessageApi
const {
    RIGHTS
} = ROLES;

export default class MoaIndex extends React.Component {
    static contextType = UserInfoContext;
    state = {
        loading: true,
        collapsed: isMobile(navigator.userAgent).any ? true : false,
    };
    breadcrumb = [];

    userInfoHeader = () => {
        return (
            <div className="pop-header">
                <Avatar size="small" className="pop-avatar" src={memory.user.dd_info.avatar} />
                <span className="pop-name">{memory.user.dd_info.name}</span>
            </div>
        );
    }
    userInfoContent = () => {
        return (
            <div className="pop-content">
                <Link to="/crm/" className="pop-content-item">
                    <span className="item-name">个人中心</span>
                </Link>
                {
                    memory.user.role_type.indexOf(RIGHTS) > -1 ? (
                        <Link to="/admin" className="pop-content-item">
                            <span className="item-name">后台管理</span>
                        </Link>
                    ) : null
                }
                <div className="pop-content-info">
                    <div className="info-item">
                        <label className="info-item-label">部门：</label>
                        <span className="info-item-name">{department_obj[memory.user.dd_info.department[0]]}</span>
                    </div>
                </div>
                {
                    dd.env.platform !== 'notInDingTalk' ? null : (
                        <footer className="pop-content-footer">
                            <Button type="link" onClick={this.handleSignout}>退出登录</Button>
                        </footer>
                    )
                }
            </div>
        );
    }
    breadcrumbComponent = (menuArr, pathname) => {
        for(let i = 0; i < menuArr.length; i++) {
            if(pathname.indexOf(menuArr[i].key) === 0) {
                if(menuArr[i].key === '/moa') {
                    this.breadcrumb.push(
                        <Breadcrumb.Item key={menuArr[i].key}>
                            <Link to="/moa">
                                {menuArr[i].title}
                            </Link>
                        </Breadcrumb.Item>
                    );
                }else {
                    this.breadcrumb.push(
                        <Breadcrumb.Item key={menuArr[i].key}>
                            {menuArr[i].title}
                        </Breadcrumb.Item>
                    );
                }
                if(menuArr[i].children) {
                    this.breadcrumbComponent(menuArr[i].children, pathname);
                }
            }
        }
        return this.breadcrumb;
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };
    handleSignout = () => {
        (async () => {
            const response = await reqSignout();
            if(response) {
                message.success('账号已退出');
                storage.removeStore(AUTH);
                this.props.history.replace('/');
            }
        })();
    }

    toLogin = async (code) => {
        const response = await reqLogin({
            code: code,
        });
        const auth = response.data.token;
        storage.saveStore(AUTH, auth);
        message.success('登录成功');

        // 获取个人信息
        const self = await reqSelfInfo();
        if(self && self.data) {
            memory.user = self.data;
            memory.user.dd_info.avatar = memory.user.dd_info.avatar || AVATAR;
            memory.user.role_type = [];
            if(self.data.roles && self.data.roles.length) {
                for(let i = 0; i < self.data.roles.length; i++) {
                    memory.user.role_type.push(self.data.roles[i].type);
                }
            }
            // 获取部门信息
            const dep = await reqDepartmentList();
            if(dep) {
                memory.departments = dep.data;
                for(let i = 0; i < dep.data.length; i++) {
                    memory.department_obj[dep.data[i].id] = dep.data[i].name;
                }
            }
            this.getNoticeAmount();
            this.getNoticeList();
            this.setState({
                loading: false
            });
        }
    }

    getNoticeAmount = async() => {
        const response = await reqUnreadMessageAmount();
        if(response?.data){
            this.context.setState({unreadMessageAmount : response.data.msg});
        }
    }

    getNoticeList = async() => {
        const response = await reqMessageList({page : 1,node : 50});
        if(response?.data){
            this.context.setState({siteMessageList : response.data.data});
        }
    }

    componentDidMount =() => {
        const that = this;
        if(dd.env.platform !== 'notInDingTalk') {
            dd.ready(function() {
                dd.runtime.permission.requestAuthCode({
                    corpId: CORPID, // 企业id
                    onSuccess: function (info) {
                        const code = info.code; // 通过该免登授权码可以获取用户身份
                        that.toLogin(code);
                    },
                    onFail: function (err) {
                        message.error('requestAuthCode error: ' + JSON.stringify(err));
                    }
                });
            });
            dd.error(function(error){
                message.error('dd error: ' + JSON.stringify(error));
            });
        }else {
            this.getNoticeAmount();
            this.getNoticeList();
            this.setState({
                loading: false
            });
        }
    }

    componentDidUpdate(prevProps) {
        if(memory.is_mobile) {
            if(this.props.location.pathname !== prevProps.location.pathname) {
                this.setState({
                    collapsed: true
                });
            }
        }
    }

    componentWillUpdate() {
        this.breadcrumb = [];
    }

    render() {
        const pathname = this.props.location.pathname;

        return (
            <Layout className="crm">
                {
                    this.state.loading ? (
                        <div className="loading-container">
                            <Spin size="large" />
                        </div>
                    ) : (
                        <ConfigProvider locale={zhCN}>
                            <Sider
                                breakpoint="lg"
                                collapsedWidth={memory.is_mobile ? 0: 80}
                                trigger={null}
                                collapsible
                                collapsed={this.state.collapsed}
                                style={{
                                    overflow: 'auto',
                                    height: '100%',
                                    position: 'fixed',
                                    left: 0,
                                    zIndex: 99,
                                }}
                            >
                                <div className="crm-logo" title="首页">
                                    <Link to="/moa">
                                        <HomeOutlined className="logo-icon" style={{color: '#ccc', fontSize: 26}} />
                                        {
                                            memory.is_mobile ? (
                                                <span className="logo-name" style={{color: '#ccc', fontSize: 26, marginLeft: 20}}>MOA</span>
                                            ) : (
                                                this.state.collapsed ? null : (
                                                    <span className="logo-name" style={{color: '#ccc', fontSize: 26, marginLeft: 20}}>MOA</span>
                                                )
                                            )
                                        }
                                    </Link>
                                </div>
                                {<LeftNav setState={() => this.setState({})} />}
                            </Sider>

                            <Layout
                                style={{
                                    marginLeft: memory.is_mobile ? 0 : (this.state.collapsed ? 80 : 200)
                                }}
                            >
                                <Header
                                    className="crm-header"
                                    style={{
                                        position: 'fixed',
                                        zIndex: 97,
                                        left: 0,
                                        right: 0,
                                        marginLeft: memory.is_mobile ? 0 : (this.state.collapsed ? 80 : 200)
                                    }}
                                >
                                    {
                                        this.state.collapsed ? <MenuUnfoldOutlined className="crm-trigger" onClick={this.toggle} /> : <MenuFoldOutlined className="crm-trigger" onClick={this.toggle} />
                                    }
                                    <div className="user">
                                        <Popover trigger="click" arrowPointAtCenter={true} placement="bottomRight" content={<NoticeList history={this.props.history} setState={() => this.setState({})} />}>
                                            <Badge className='notice' count={this.context.unreadMessageAmount} style={{height : 16, lineHeight : "16px", padding : 0,cursor : "pointer"}}>
                                                <BellOutlined style={{ fontSize: 20 }} />
                                            </Badge>
                                        </Popover>
                                        <Popover className="popover" content={this.userInfoContent()} title={this.userInfoHeader()} placement="bottomRight">
                                            <Avatar className="avatar" src={memory.user.dd_info.avatar} />
                                        </Popover>
                                    </div>
                                </Header>

                                <Breadcrumb
                                    style={{
                                        padding: '24px 24px 0',
                                        marginTop: '64px',
                                    }}>
                                    {this.breadcrumbComponent(menuList(memory), pathname)}
                                </Breadcrumb>

                                <Content style={{margin: '24px 16px 0'}}>
                                    <Switch>
                                        <Route path='/moa/business/detail/:id' component={BusinessDetail}></Route>
                                        {/*<Route path="/moa/admin/seal/detail/:id" component={AdminSealDetail}></Route>*/}
                                        <Route path="/moa/seal/my/list" component={MySealList}></Route>
                                        <Route path="/moa/seal/admin/list" component={AdminSealList}></Route>
                                        <Route path="/moa/admin/ability/edit/:id" component={AbilityEdit}></Route>
                                        <Route path="/moa/admin/ability/list" component={AbilityList}></Route>

                                        <Route path="/moa/contract/apply/edit/:id" component={MyContractAdd}></Route>
                                        <Route path="/moa/contract/apply/detail/:id" component={MyContractDetail}></Route>
                                        <Route path="/moa/contract/apply/add" component={MyContractAdd}></Route>
                                        <Route path="/moa/contract/apply/List" component={MyContractList}></Route>
                                        <Route path='/moa/contract/pending/edit/:id' component={PendingContractEdit}></Route>
                                        <Route path='/moa/contract/pending/detail/:id' component={PendingContractDetail}></Route>
                                        <Route path='/moa/contract/pending/approve/:id' component={PendingContractApprove}></Route>
                                        <Route path='/moa/contract/pending/new/approve/:id' component={PendingContractApproveNew}></Route>
                                        <Route path='/moa/contract/pending/history' component={PendingContractHistory}></Route>
                                        <Route path='/moa/contract/pending/list' component={PendingContractList}></Route>
                                        <Route path="/moa/contract/detail/:id" component={ContractDetail}></Route>
                                        <Route path="/moa/contract/edit/:id" component={ContractAdd}></Route>
                                        <Route path="/moa/contract/add" component={ContractAdd}></Route>
                                        <Route path="/moa/contract/list" component={ContractList}></Route>
                                        <Route path='/moa/invoice/package/list' component={InvoicePackageList}></Route>
                                        <Route path='/moa/invoice/package/history' component={InvoicePackageHistoryList}></Route>
                                        <Route path='/moa/invoice/package/detail/:id' component={InvoicePackageDetail}></Route>

                                        <Route path="/moa/task/history" component={MyTaskHistory}></Route>

                                        <Route path="/moa/meeting/contacts/detail/:mid/:id" component={MeetingContactsDetail}></Route>
                                        <Route path="/moa/meeting/contacts/edit/:mid/:id" component={MeetingContactsAdd}></Route>
                                        <Route path="/moa/meeting/contacts/add/:mid" component={MeetingContactsAdd}></Route>

                                        <Route path="/moa/meeting/detail/:id" component={MeetingDetail}></Route>
                                        <Route path="/moa/meeting/edit/:id" component={MeetingAdd}></Route>
                                        <Route path="/moa/meeting/add" component={MeetingAdd}></Route>
                                        <Route path="/moa/meeting/list" component={MeetingList}></Route>

                                        <Route path="/moa/account/mobile/detail/:id" component={MobileDetail}></Route>
                                        <Route path="/moa/account/mobile/edit/:id" component={MobileAdd}></Route>
                                        <Route path="/moa/account/mobile/add" component={MobileAdd}></Route>
                                        <Route path="/moa/account/mobile/list" component={MobileList}></Route>

                                        <Route path="/moa/account/detail/:id" component={AccountDetail}></Route>
                                        <Route path="/moa/account/edit/:id" component={AccountAdd}></Route>
                                        <Route path="/moa/account/add" component={AccountAdd}></Route>
                                        <Route path="/moa/account/list" component={AccountList}></Route>

                                        <Route path="/moa/awards/contacts/detail/:id" component={AwardsContactsDetail}></Route>
                                        <Route path="/moa/awards/contacts/edit/:id" component={AwardsContactsAdd}></Route>
                                        <Route path="/moa/awards/contacts/add/:cid" component={AwardsContactsAdd}></Route>
                                        <Route path="/moa/awards/platform/contacts/detail/:id" component={AwardsPlatformContactsDetail}></Route>
                                        <Route path="/moa/awards/platform/contacts/edit/:id" component={AwardsPlatformContactsAdd}></Route>
                                        <Route path="/moa/awards/platform/contacts/add/:aid" component={AwardsPlatformContactsAdd}></Route>
                                        <Route path="/moa/awards/platform/detail/:id" component={AwardsPlatformDetail}></Route>
                                        <Route path="/moa/awards/platform/edit/:id" component={AwardsPlatformAdd}></Route>
                                        <Route path="/moa/awards/platform/add" component={AwardsPlatformAdd}></Route>
                                        <Route path="/moa/awards/platform/list" component={AwardsPlatformList}></Route>
                                        <Route path="/moa/awards/agency/contacts/detail/:id" component={AwardsAgencyContactsDetail}></Route>
                                        <Route path="/moa/awards/agency/contacts/edit/:id" component={AwardsAgencyContactsAdd}></Route>
                                        <Route path="/moa/awards/agency/contacts/add/:aid" component={AwardsAgencyContactsAdd}></Route>
                                        <Route path="/moa/awards/agency/detail/:id" component={AwardsAgencyDetail}></Route>
                                        <Route path="/moa/awards/agency/edit/:id" component={AwardsAgencyAdd}></Route>
                                        <Route path="/moa/awards/agency/add" component={AwardsAgencyAdd}></Route>
                                        <Route path="/moa/awards/agency/list" component={AwardsAgencyList}></Route>
                                        <Route path="/moa/awards/edit/:id" component={AwardsAdd}></Route>
                                        <Route path="/moa/awards/add" component={AwardsAdd}></Route>
                                        <Route path="/moa/awards/detail/:id" component={AwardsDetail}></Route>
                                        <Route path="/moa/awards/list" component={AwardsList}></Route>
                                        <Route path="/moa/awards/thread/detail/:id" component={AwardsThreadDetail}></Route>
                                        <Route path="/moa/awards/thread/edit/:id" component={AwardsThreadAdd}></Route>
                                        <Route path="/moa/awards/thread/add" component={AwardsThreadAdd}></Route>
                                        <Route path="/moa/awards/thread/list" component={AwardsThreadList}></Route>

                                        <Route path="/moa/capital/thread/edit/:id" component={ThreadAdd}></Route>
                                        <Route path="/moa/capital/thread/add" component={ThreadAdd}></Route>
                                        <Route path="/moa/capital/thread/detail/:id" component={ThreadDetail}></Route>
                                        <Route path="/moa/capital/thread/list" component={ThreadList}></Route>

                                        <Route path="/moa/capital/response/detail/:id" component={ResponseDetail}></Route>
                                        <Route path="/moa/capital/response/edit/:id" component={ResponseAdd}></Route>
                                        <Route path="/moa/capital/response/add" component={ResponseAdd}></Route>
                                        <Route path="/moa/capital/response/list" component={ResponseList}></Route>

                                        <Route path="/moa/capital/agency/contacts/detail/:id" component={AgencyContactsDetail}></Route>
                                        <Route path="/moa/capital/agency/contacts/edit/:id" component={AgencyContactsAdd}></Route>
                                        <Route path="/moa/capital/agency/contacts/add/:aid" component={AgencyContactsAdd}></Route>

                                        <Route path="/moa/capital/contacts/detail/:id" component={ContactsDetail}></Route>
                                        <Route path="/moa/capital/contacts/edit/:id" component={ContactsAdd}></Route>
                                        <Route path="/moa/capital/contacts/add/:cid" component={ContactsAdd}></Route>

                                        <Route path="/moa/capital/expense/detail/:id" component={ExpenseDetail}></Route>
                                        <Route path="/moa/capital/expense/edit/:id" component={ExpenseAdd}></Route>
                                        <Route path="/moa/capital/expense/add/:cid" component={ExpenseAdd}></Route>

                                        <Route path="/moa/capital/arrive/detail/:id" component={ArriveDetail}></Route>
                                        <Route path="/moa/capital/arrive/edit/:id" component={ArriveAdd}></Route>
                                        <Route path="/moa/capital/arrive/add/:cid" component={ArriveAdd}></Route>

                                        <Route path="/moa/capital/agency/detail/:id" component={AgencyDetail}></Route>
                                        <Route path="/moa/capital/agency/edit/:id" component={AgencyAdd}></Route>
                                        <Route path="/moa/capital/agency/add" component={AgencyAdd}></Route>
                                        <Route path="/moa/capital/agency/list" component={AgencyList}></Route>

                                        <Route path="/moa/capital/edit/:id" component={CapitalAdd}></Route>
                                        <Route path="/moa/capital/add" component={CapitalAdd}></Route>
                                        <Route path="/moa/capital/detail/:id" component={CapitalDetail}></Route>
                                        <Route path="/moa/capital/list" component={CapitalList}></Route>
                                        <Route path="/moa" component={Home}></Route>
                                    </Switch>
                                </Content>

                                <Footer style={{ textAlign: 'center' }}>谋乐网络科技 ©{moment().year()}</Footer>
                                <div
                                    className="mask-layout"
                                    style={{
                                        display: memory.is_mobile ? (this.state.collapsed ? 'none' : 'block') : 'none'
                                    }}
                                    onClick={() => {
                                        this.setState({
                                            collapsed: true
                                        });
                                    }}
                                >
                                </div>
                            </Layout>
                        </ConfigProvider>
                    )
                }
            </Layout>
        );
    }
}