import React from "react";
import {
    Button,
    Card,
    Icon,
    Input,
    Table,
    Tag,
} from "antd";
import {Link} from "react-router-dom";
import { SearchOutlined } from '@ant-design/icons';
import moment from "moment";

import memory from "../../../../utils/memory";
import {moaApi} from "../../../../api";
import {
    AWARDQUALITY,
    AWARDMILESTONE,
    AWARDSTATE,
    ROLES,
} from "../../../../utils/constant";
import tools from "../../../../utils/tools";
import LinkButton from "../../../../components/link-button";

const {
    reqAwardsList,
    reqMyAwardsList,
} = moaApi;

export default class AwardsList extends React.Component {
    state = {
        data: [],
        pagination: {},
        loading: false,
    };

    is_award_admin = memory.user.roles.find(o => o.type == ROLES.MOAADMIN || o.type == ROLES.AWARD)?true:false;

    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        });
        let tmpFilters = {...filters};
        for(let item in tmpFilters) {
            if(tmpFilters[item]) {
                if(typeof(tmpFilters[item]) !== 'string') {
                    tmpFilters[item] = tmpFilters[item][0];
                }
            }
        }
        let params = {
            page: pagination.current,
            ...tmpFilters,
        };
        if(sorter && sorter.field) {
            if(sorter.order === 'ascend') {
                params[sorter.field] = -1;
            }else if(sorter.order === 'descend') {
                params[sorter.field] = 1;
            }
        }
        this.getAwardsList(params);
    }

    /*
    *搜索相关
    * */
    getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`搜索 ${title}`}
                    value={selectedKeys ? selectedKeys[0] : ''}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    搜索
                </Button>
                <Button onClick={() => this.handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    重置
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => text
    });
    handleSearch = (selectedKeys, confirm) => {
        confirm();
    };
    handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
    };

    columns = [
        {
            title: '奖项申报年份',
            dataIndex: 'award_date',
            key: 'award_date',
            sorter: (a, b) => a - b,
        },
        {
            title: '奖项名称',
            dataIndex: 'award_name',
            key: 'award_name',
            ...this.getColumnSearchProps('award_name', '奖项名称'),
            render: (text, record) => {
                return (
                    <Link to={`/moa/awards/detail/${record.key}`}>
                        <span className="text-overflow" style={{maxWidth: 500}} title={text}>{text}</span>
                    </Link>
                );
            }
        },
        {
            title: '奖项质量',
            dataIndex: 'award_quality',
            key: 'award_quality',
            filters: AWARDQUALITY,
            filterMultiple: false,
        },
        {
            title: '奖项平台',
            dataIndex: 'platform',
            key: 'platform',
        },
        {
            title: '代理机构',
            dataIndex: 'agency',
            key: 'agency',
        },
        {
            title: '项目经理',
            dataIndex: 'manager',
            key: 'manager',
            filters: [],
            filterMultiple: false,
        },
        {
            title: '协作人数',
            dataIndex: 'worker',
            key: 'worker',
        },
        {
            title: '里程碑',
            dataIndex: 'milestone',
            key: 'milestone',
            filters: AWARDMILESTONE,
            filterMultiple: false,
        },
        {
            title: '所处阶段',
            dataIndex: 'stage',
            key: 'stage',
            filters: AWARDSTATE,
            filterMultiple: false,
        },
        {
            title: '奖项金额',
            dataIndex: 'capital_all',
            key: 'capital_all',
        },
        {
            title: '申请截止时间',
            dataIndex: 'data_deadline',
            key: 'data_deadline',
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
        },
        {
            title: '操作',
            dataIndex: 'option',
            valueType: 'option',
            render: (_, record) => {
                return (
                    <Link to={`/moa/awards/detail/${record.key}`}>详情</Link>
                );
            }
        },
    ];

    getAwardsList = async (params = {}) => {
        this.setState({
            loading: true
        });
        let response;
        if(this.is_award_admin){
            response = await reqAwardsList(params);
        }else{
            response = await reqMyAwardsList(params);
        }
        const pagination = {...this.state.pagination};
        const data = response.data;
        pagination.total = data.page.total;
        pagination.pageSize = data.page.node;
        let newData = [];
        for(let i = 0; i < data.data.length; i++) {
            const item = data.data[i];
            let worker = [];
            if(item.team_worker && item.team_worker.length) {
                for(let j = 0; j < item.team_worker.length; j++) {
                    worker.push(item.team_worker[j].name);
                }
            }
            const obj = {
                key: item._id,
                award_date: item.award_date,
                award_name: item.award_name,
                award_quality: AWARDQUALITY[item.award_quality-1].text,
                platform: item.platform && item.platform.name ? item.platform.name : '-',
                agency: item.agency ? <a href={`/moa/awards/agency/detail/${item.agency.id}`} target="_blank">{item.agency.name}</a> : '-',
                manager: item.manager && item.manager.name ? item.manager.name : '-',
                worker: worker.length,
                milestone: item.is_wastage ? '已流失' : AWARDMILESTONE[item.milestone-1].text,
                stage: item.is_wastage ? '流失阶段' : AWARDSTATE[item.stage-1].text,
                capital_all: item.award_amount ? (item.award_amount + '万元') : '-',
                data_deadline: '-',
                create_time: tools.unixToTime((new Date(item.create_at)).getTime()/1000)
            };
            if(item.data_deadline) {
                const diff = moment(item.data_deadline).diff(moment(), 'weeks');
                if(diff === 0) {
                    obj.data_deadline = <Tag color="#f50">{tools.unixToTime((new Date(item.data_deadline)).getTime()/1000)}</Tag>; // 待认证
                }else if(diff < 0) {
                    obj.data_deadline = <Tag color="#f00">{tools.unixToTime((new Date(item.data_deadline)).getTime()/1000)}</Tag>; // 待认证
                }else {
                    obj.data_deadline = <Tag color="#87d068">{tools.unixToTime((new Date(item.data_deadline)).getTime()/1000)}</Tag>; // 待认证
                }
            }
            newData.push(obj);
        }
        if(data.list) {
            let manager = [];
            for(let i = 0; i < data.list.managers.length; i++) {
                manager.push({
                    value: data.list.managers[i].id,
                    text: data.list.managers[i].name
                });
            }
            this.columns[4].filters = manager; // 项目经理筛选
        }

        this.setState({
            loading: false,
            data: newData,
            pagination
        });
    };

    componentDidMount() {
        this.getAwardsList({
            page: 1,
        });
    }

    render() {
        return (
            <Card
                title="奖项列表"
                bordered={false}
                extra={<LinkButton to="/moa/awards/add" txt="添加奖项" />}
            >
                <Table
                    columns={this.columns}
                    dataSource={this.state.data}
                    pagination={this.state.pagination}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                    size={memory.is_mobile ? 'small' : 'middle'}
                />
            </Card>
        );
    }
}