import React from "react";
import {
    Card,
    Table,
    Tag,
} from "antd";
import {Link} from "react-router-dom";
import moment from 'moment';

import LinkButton from "../../../crm/components/link-button";
import memory from "../../../../utils/memory";
import {moaApi} from "../../../../api";
import tools from "../../../../utils/tools";
import {
    ACCOUNT_PLATFORM_TYPE,
} from "../../../../utils/constant";

const {
    reqAccountList
} = moaApi;

export default class AccountList extends React.Component {
    state = {
        data: [],
        pagination: {},
        loading: false,
    };
    columns = [
        {
            title: '注册时间',
            dataIndex: 'register_time',
            key: 'register_time'
        },
        {
            title: '注册人',
            dataIndex: 'registrant',
            key: 'registrant',
            filters: [],
            filterMultiple: false,
        },
        {
            title: '更新时间',
            dataIndex: 'update_at',
            key: 'update_at',
            sorter: (a, b) => a - b,
        },
        {
            title: '更新人',
            dataIndex: 'update_person',
            key: 'update_person'
        },
        {
            title: '平台',
            dataIndex: 'platform',
            key: 'platform',
            filters: [],
            filterMultiple: false,
        },
        {
            title: '平台类别',
            dataIndex: 'platform_type',
            key: 'platform_type'
        },
        {
            title: '用途',
            dataIndex: 'use',
            key: 'use'
        },
        {
            title: '用户名',
            dataIndex: 'user_name',
            key: 'user_name'
        },
        {
            title: '维护人',
            dataIndex: 'maintenance_man',
            key: 'maintenance_man',
            filters: [],
            filterMultiple: false,
        },
        {
            title: '绑定手机',
            dataIndex: 'mobile',
            key: 'mobile'
        },
        {
            title: '认证信息',
            dataIndex: 'certification_info',
            key: 'certification_info'
        },
        {
            title: '认证预警',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if(text === 2) {
                    return <Tag color="#f50">待认证</Tag>
                }else if(text === 3) {
                    return <Tag color="#f00">已过期</Tag>
                }else {
                    return <Tag color="#87d068">正常</Tag>
                }
            },
            filters: [
                {
                    value: 1,
                    text: '正常'
                },
                {
                    value: 2,
                    text: '待认证'
                },
                {
                    value: 3,
                    text: '已过期'
                },
            ],
            filterMultiple: false,
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time'
        },
        {
            title: '操作',
            dataIndex: 'option',
            valueType: 'option',
            render: (_, record) => {
                return (
                    <Link to={`/moa/account/detail/${record.key}`}>详情</Link>
                );
            }
        },
    ];

    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        });
        let tmpFilters = {...filters};
        for(let item in tmpFilters) {
            if(tmpFilters[item]) {
                if(typeof(tmpFilters[item]) !== 'string') {
                    tmpFilters[item] = tmpFilters[item][0];
                }
            }
        }
        let params = {
            page: pagination.current,
            ...tmpFilters,
        };
        if(sorter && sorter.field) {
            if(sorter.order === 'ascend') {
                params[sorter.field] = -1;
            }else if(sorter.order === 'descend') {
                params[sorter.field] = 1;
            }
        }
        this.getAccountList(params);
    }

    getAccountList = async (params = {}) => {
        this.setState({
            loading: true
        });
        const response = await reqAccountList(params);
        const pagination = {...this.state.pagination};
        const data = response.data;
        pagination.total = data.page.total;
        pagination.pageSize = data.page.node;
        let newData = [];
        for(let i = 0; i < data.data.length; i++) {
            const item = data.data[i];
            let status = 1; // 正常
            let diff = '';
            if(item.certification_deadline) {
                diff = moment(item.certification_deadline).diff(moment(), 'months');
                if(diff === 0) {
                    status = 2; // 待认证
                }else if(diff < 0) {
                    status = 3; // 已过期
                }
            }
            const obj = {
                key: item._id,
                register_time: tools.unixToTime((new Date(item.register_time)).getTime()/1000, true),
                registrant: item.registrant.name,
                update_at: tools.unixToTime((new Date(item.update_at)).getTime()/1000, true),
                update_person: item.update_person.name,
                platform: item.platform,
                platform_type: ACCOUNT_PLATFORM_TYPE[item.platform_type-1] ? ACCOUNT_PLATFORM_TYPE[item.platform_type-1].text : '其它',
                use: item.use,
                user_name: item.user_name,
                maintenance_man: item.maintenance_man.name,
                mobile: item.mobile.bound_mobile,
                certification_info: item.certification_info || '-',
                status: status,
                create_time: tools.unixToTime((new Date(item.create_at)).getTime()/1000),
            };
            newData.push(obj);
        }
        let registrantArr = [];
        if(data.list && data.list.registrants) {
            for(let i = 0; i < data.list.registrants.length; i++) {
                registrantArr.push({
                    value: data.list.registrants[i].id,
                    text: data.list.registrants[i].name
                });
            }
        }
        this.columns[1].filters = registrantArr; // 注册人筛选
        let platform = [];
        if(data.list && data.list.platforms) {
            for(let i = 0; i < data.list.platforms.length; i++) {
                platform.push({
                    value: data.list.platforms[i],
                    text: data.list.platforms[i]
                });
            }
        }
        this.columns[4].filters = platform; // 平台筛选
        let maintenance = [];
        if(data.list && data.list.maintenance_mans) {
            for(let i = 0; i < data.list.maintenance_mans.length; i++) {
                maintenance.push({
                    value: data.list.maintenance_mans[i].id,
                    text: data.list.maintenance_mans[i].name
                });
            }
        }
        this.columns[8].filters = maintenance; // 维护人筛选

        this.setState({
            loading: false,
            data: newData,
            pagination
        });
    };

    componentDidMount() {
        this.getAccountList({
            page: 1,
        });
    }

    render() {
        return (
            <Card
                title="账户列表"
                bordered={false}
                extra={<LinkButton to="/moa/account/add" txt="添加账户" />}
            >
                <Table
                    columns={this.columns}
                    dataSource={this.state.data}
                    pagination={this.state.pagination}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                    size={memory.is_mobile ? 'small' : 'middle'}
                />
            </Card>
        );
    }
}