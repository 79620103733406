import React from "react";
import {
    Card,
    Form,
    Row,
    Col,
    Input,
    Button,
    message,
    Spin,
    Popconfirm,
} from "antd";

import { moaApi } from "../../../../../../api";

const {
    reqMeetingContactAdd,
    reqMeetingContactDel,
    reqMeetingContactEdit,
    reqMeetingContactDetail
} = moaApi;

class MeetingContactsAdd extends React.Component {
    state = {
        loading: true,
        detail: {},
    };
    mid = this.props.match.params.mid;
    id = this.props.match.params.id;

    validate = async (values) => {
        values.id = this.mid; // 会议id
        let response;
        if (!this.id) {
            for (let i in values) {
                if (!values[i]) {
                    delete values[i];
                }
            }
        }
        if (this.id) {
            values.contact_id = this.id;
            response = await reqMeetingContactEdit(values);
            if (response && response.data) {
                message.success('修改成功');
                this.props.history.goBack();
            }
        } else {
            response = await reqMeetingContactAdd(values);
            if (response && response.data) {
                message.success('添加成功');
            }
        }
    };

    delContacts = async () => {
        const response = await reqMeetingContactDel({
            id: this.mid,
            contact_id: this.id
        });
        if (response && response.data) {
            message.success('删除成功');
            this.props.history.replace(`/moa/meeting/detail/${this.mid}`);
        }
    };

    getContactsDetail = async (params = {}) => {
        const response = await reqMeetingContactDetail(params);
        if (response && response.data) {
            this.setState({
                detail: response.data,
                loading: false
            });
        }
    };

    componentDidMount() {
        if (this.id) {
            // 获取详情
            this.getContactsDetail({
                id: this.mid,
                contact_id: this.id
            });
        }
    }

    render() {
        const { detail } = this.state;

        return this.id && this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <Form onFinish={this.validate} layout="vertical">
                <Card
                    title={
                        this.id ? "修改联系人信息" : "添加联系人"
                    }
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                    extra={
                        this.id ? (
                            <Popconfirm
                                title="确定删除该联系人信息吗?"
                                onConfirm={this.delContacts}
                                okText="确定"
                                cancelText="取消"
                            >
                                <Button>删除</Button>
                            </Popconfirm>
                        ) : null
                    }
                >

                    <Card
                        title="联系人基本信息"
                        bordered={false}
                        type="inner"
                    >
                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="联系人"
                                    name="person"
                                    initialValue={detail.person || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入联系人姓名',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入联系人姓名" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="联系方式"
                                    name="info"
                                    initialValue={detail.info || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入联系人的联系方式',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入联系人的联系方式" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Card>

                <div className="submit-footer">
                    <Button type="primary" htmlType="submit">
                        {
                            this.id ? '保存' : '提交'
                        }
                    </Button>
                </div>
            </Form>
        );
    }
}

export default MeetingContactsAdd;