import React from "react";
import {
    Button,
    Card,
    Col,
    Descriptions,
    Form,
    Input,
    message,
    Radio,
    Row,
    Spin,

} from "antd";
import moment from "moment";

import tools from "../../../../../../utils/tools";
import { crmApi } from "../../../../../../api";
import {
    BUSINESSTYPE_OBJ,
    BUSINESSNATURE
} from "../../../../../../utils/constant";

const {
    reqApplyDetail,
    reqApplyProductCheck,
    reqApplyOtherCheck,
    reqApplySecurityServiceCheck,
    reqApplyOperateCheck,
    reqApplyBusinessCheck,
    reqApplyProduct1Check,
    reqBusinessDetail,
    reqWeaponList
} = crmApi;

const { TextArea } = Input;

class DepartmentApproveDetail extends React.Component {
    state = {
        loading: true,
        detail: {},
        submitLoading: false,
        schedule: [],
        business: {}
    };
    id = this.props.match.params.id;
    type = this.props.location.pathname.replace("/train/approve/department/detail/", "").replace(`/${this.id}`, "");
    weaponListObj = {};

    validate = async (values) => {
        this.setState({ submitLoading: true })
        if (!values.examined_content) {
            delete values.examined_content;
        }
        values.id = this.id;
        let response;
        switch (this.type) {
            case "other": response = await reqApplyOtherCheck(values); break;
            case "product": response = await reqApplyProductCheck(values); break;
            case "service": response = await reqApplySecurityServiceCheck(values); break;
            case "operate": response = await reqApplyOperateCheck(values); break;
            case "business": response = await reqApplyBusinessCheck(values); break;
            case "product1": response = await reqApplyProduct1Check(values); break;
        }
        if (response?.data) {
            message.success('审批成功');
            this.props.history.push(`/train/approve/department/${this.type}`);
        } else {
            this.setState({ submitLoading: false })
        }
    };

    getApplyDetail = async (params = {}) => {
        let business = {};
        const response = await reqApplyDetail(params);
        if (response?.data) {
            if (response?.data?.data?.business_id) {
                const response1 = await reqBusinessDetail({ id: response.data.data.business_id });
                if (response1?.data) {
                    business = response1.data;
                }
            }
            this.setState({
                business,
                detail: response.data,
                loading: false
            });
        }
    };

    getWeaponList = async (params, cb) => {
        const response = await reqWeaponList(params);
        if (response && response.data) {
            response.data.data.map((value, index) => {
                this.weaponListObj[value._id] = value.productname;
            })
            cb({
                id: this.id
            })
        }
    }

    componentDidMount() {
        this.getWeaponList({
            node: 9999
        }, () => {
            this.getApplyDetail({ id: this.id });
        });
    }

    render() {
        const { detail, business } = this.state;
        let tmp = [];
        if (business?.business_type?.length) {
            if (!business.business_type.find(o => tools.isNumber(o) === false)) {
                for (let j = 0; j < business.business_type.length; j++) {
                    tmp.push(BUSINESSTYPE_OBJ[business.business_type[j]]);
                }
            } else {
                for (let j = 0; j < business.business_type.length; j++) {
                    tmp.push(this.weaponListObj[business.business_type[j]]);
                }
            }
        }

        let initial;
        switch (this.type) {
            case "other": initial = "QT"; break;
            case "product": initial = "CP2"; break;
            case "service": initial = "AQFW"; break;
            case "operate": initial = "YY"; break;
        }

        return this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <Form onFinish={this.validate}>
                <Button
                    type="link"
                    icon="left"
                    onClick={() => {
                        this.props.history.goBack();
                    }}
                >返回</Button>

                <Card
                    title="基本信息"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                >
                    <Descriptions
                        title="项目信息"
                    >
                        <Descriptions.Item label="项目名称">
                            {business?.project_name ? <a href={`/crm/client/business-detail/${business._id}`} target="blank">{business.project_name}</a> : "-"}
                        </Descriptions.Item>
                        <Descriptions.Item label="所属客户">
                            {business?.customer_name ? <a href={`/crm/client/detail/${business.customer}`} target="blank">{business.customer_name}</a> : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="项目分类">
                            {tmp.length ? tmp.join('，') : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="项目性质">
                            {BUSINESSNATURE[business.project_type - 1].text || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="合同金额">
                            {
                                (business.contract_amount ? tools.formatNumber(business.contract_amount) : 0) + '元'
                            }
                        </Descriptions.Item>
                        <Descriptions.Item label="申请人">
                            {detail?.data?.creator?.name || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="申请时间">
                            {detail?.create_at ? moment(detail.create_at).format("YYYY/MM/DD HH:mm:ss") : "-"}
                        </Descriptions.Item>
                    </Descriptions>
                </Card>

                <Card
                    title="申请审批"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                >
                    <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <Form.Item
                                label="是否同意"
                                name="is_examined"
                                initialValue={detail?.data && detail.data[initial]}
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择是否同意',
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    <Radio value={true}>通过</Radio>
                                    <Radio value={false}>驳回</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <Form.Item
                                label="备注"
                                name="examined_content"
                                initialValue={detail?.data && detail.data[initial + "_examined_content"] ? detail.data[initial + "_examined_content"] : ""}
                            >
                                <TextArea rows={4} placeholder="审批备注" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <div className="submit-footer">
                    {
                        (detail?.data && detail.data[initial] != null) ? (
                            <Button type="primary" disabled>已审批</Button>
                        ) : (
                            <Button type="primary" loading={this.state.submitLoading} htmlType="submit">审批</Button>
                        )
                    }
                </div>
            </Form>
        );
    }
}

export default DepartmentApproveDetail;