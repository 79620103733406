/*
* 应用的入口文件
* */
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route} from "react-router-dom";

import App from './App';

ReactDOM.render((
    <BrowserRouter>
        <Route path="/" component={App}></Route>
    </BrowserRouter>
), document.getElementById('root'));
