export const AUTH = "OAAUTH";

//export const AGENTID = "343936172"; //test
export const AGENTID = '486719727';
//export const CORPID = "ding03a9be158d5f33e535c2f4657eb6378f"; //test
export const CORPID = 'ding167f0cec437ef0ce35c2f4657eb6378f';

//export const APPID = "dingoaq3f5gvigdukjvmco"; //test
//export const APPID = 'dingoat65q8um9m5vpqa2c'; //oa-test
export const APPID = 'dingoaw07kg5qb692hsbgu';
//export const REDIRECT_URI = 'http://10.1.1.18:3000/'; //test-server
//export const REDIRECT_URI = "http://localhost:3000/"; //test
//export const REDIRECT_URI = 'http://oa-test.moule.cn/'; //oa-test
export const REDIRECT_URI = 'https://oa.moule.cn/';

export const AMAP_KEY = "94360fd62c4b17bd7bc45fe48b557a17";