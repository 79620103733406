import React, { createRef } from "react";
import {
    Card,
    Table,
    Col,
    Row,
    Empty,
    Tooltip
} from "antd";
import {
    Funnel,
    Line,
} from '@ant-design/charts';
import { Link } from "react-router-dom";
import isMobile from "ismobilejs";
import { QuestionCircleOutlined } from '@ant-design/icons';

import { crmApi } from "../../../../api";
import memory from "../../../../utils/memory";
import tools from "../../../../utils/tools";

const {
    reqFunnelAdmin,
    reqWinCount,
} = crmApi;
const textToStage = {
    '总客': 0,
    '初步': 1,
    '需求': 2,
    '方案': 3,
    '报价': 4,
    '谈判': 5,
    '赢单': 6,
    '输单': 7,
};

export default class Home extends React.Component {
    state = {
        funnel: [],
        amountData: [],
        statistics: [],
        result: [],
    };
    lost = 0; // 输单数
    columns = [
        {
            title: '年份',
            dataIndex: 'year',
            key: 'year',
        },
        {
            title: <span>年度合同金额(上传) <Tooltip title="所有当年上传了合同的总合同金额"><QuestionCircleOutlined style={{ fontSize: 13.5, color: 'gray' }} /></Tooltip></span>,
            dataIndex: 'contract',
            key: 'contract',
        },
        {
            title: <span>年度合同金额(赢单) <Tooltip title="所有当年赢单的总合同金额"><QuestionCircleOutlined style={{ fontSize: 13.5, color: 'gray' }} /></Tooltip></span>,
            dataIndex: 'winConstract',
            key: 'winConstract',
        },
        {
            title: '供应商合同金额',
            dataIndex: 'upstream',
            key: 'upstream',
        },
        {
            title: <span>年度总毛利 <Tooltip title="所有当年赢单的项目的总毛利"><QuestionCircleOutlined style={{ fontSize: 13.5, color: 'gray' }} /></Tooltip></span>,
            dataIndex: 'gross',
            key: 'gross',
        },
        {
            title: '年度毛利目标',
            dataIndex: 'target',
            key: 'target',
        },
        {
            title: '完成率',
            dataIndex: 'percentage',
            key: 'percentage',
        },
    ];
    columns1 = [
        {
            title: ' ',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: 'A类',
            dataIndex: 'a',
            key: 'a'
        },
        {
            title: 'B类',
            dataIndex: 'b',
            key: 'b'
        },
        {
            title: 'C类',
            dataIndex: 'c',
            key: 'c'
        },
        {
            title: '其他',
            dataIndex: 'other',
            key: 'other'
        },
    ];
    ref = createRef();
    interval = null;

    getFunnel = async (params = {}) => {
        const response = await reqFunnelAdmin(params);
        if (response && response.data && response.data.length) {
            const data = response.data;
            let funnel = [];
            const actions = ['总客户', '初步接洽', '需求确定', '方案阶段', '报价阶段', '谈判阶段', '赢单'];
            for (let i = 0; i < actions.length; i++) {
                funnel.push({
                    key: i,
                    action: actions[i],
                    count: 0,
                    stage: i,
                });
            }
            for (let i = 0; i < data.length; i++) {
                if (data[i]._id === 7) {
                    this.lost = data[i].count || 0;
                } else {
                    funnel[data[i]._id].count = data[i].count || 0;
                }
            }
            this.setState({
                funnel: funnel
            });
        } else {
            this.setState({
                funnel: []
            });
        }
    };

    getWinCount = async (params = {}) => {
        const response = await reqWinCount(params);
        if (response && response.data) {
            const monthly = response.data.monthly;
            const totally = response.data.totally;
            const classify = response.data.classify;
            const amount = response.data.amount;
            let amountData = [];
            let statistics = [];
            let result = [];
            const nowYear = (new Date()).getFullYear();
            for (let i = 0; i < monthly.length; i++) {
                amountData.push({
                    date: i + 1 + '',
                    value: monthly[i][0] ? Number(Number(monthly[i][0]).toFixed(2)) : 0,
                    type: nowYear + '合同金额'
                });
                amountData.push({
                    date: i + 1 + '',
                    value: monthly[i][1] ? Number(Number(monthly[i][1]).toFixed(2)) : 0,
                    type: nowYear + '毛利'
                });
                amountData.push({
                    date: i + 1 + '',
                    value: monthly[i][2] ? Number(Number(monthly[i][2]).toFixed(2)) : 0,
                    type: nowYear - 1 + '合同金额'
                });
                amountData.push({
                    date: i + 1 + '',
                    value: monthly[i][3] ? Number(Number(monthly[i][3]).toFixed(2)) : 0,
                    type: nowYear - 1 + '毛利'
                });
                amountData.push({
                    date: i + 1 + '',
                    value: monthly[i][4] ? Number(Number(monthly[i][4]).toFixed(2)) : 0,
                    type: nowYear - 2 + '合同金额'
                });
                amountData.push({
                    date: i + 1 + '',
                    value: monthly[i][5] ? Number(Number(monthly[i][5]).toFixed(2)) : (monthly[i][4] ? Number(Number(monthly[i][4] * 0.4).toFixed(2)) : 0),
                    type: nowYear - 2 + '毛利'
                });
                amountData.push({
                    date: i + 1 + '',
                    value: monthly[i][6] ? Number(Number(monthly[i][6]).toFixed(2)) : 0,
                    type: nowYear - 3 + '合同金额'
                });
                amountData.push({
                    date: i + 1 + '',
                    value: monthly[i][7] ? Number(Number(monthly[i][7]).toFixed(2)) : (monthly[i][6] ? Number(Number(monthly[i][6] * 0.4).toFixed(2)) : 0),
                    type: nowYear - 3 + '毛利'
                });
            }
            statistics.push({
                year: (new Date()).getFullYear(),
                contract: tools.formatNumber(amount[0].toFixed(2)),
                winConstract: <Link to="/manage/business/list">{tools.formatNumber(totally[0].toFixed(2))}</Link>,
                gross: tools.formatNumber(totally[1].toFixed(2)),
                target: tools.formatNumber(totally[2]),
                percentage: totally[3] ? totally[3] + '%' : 0,
                upstream: tools.formatNumber(totally[16].toFixed(2))
            }, {
                year: (new Date()).getFullYear() - 1,
                contract: tools.formatNumber(amount[1].toFixed(2)),
                winConstract: <Link to="/manage/business/list">{tools.formatNumber(totally[4].toFixed(2))}</Link>,
                gross: tools.formatNumber(totally[5].toFixed(2)),
                target: tools.formatNumber(totally[6]),
                percentage: totally[7] ? totally[7] + '%' : 0,
                upstream: tools.formatNumber(totally[17].toFixed(2))
            }, {
                year: (new Date()).getFullYear() - 2,
                contract: tools.formatNumber(amount[2].toFixed(2)),
                winConstract: <Link to="/manage/business/list">{tools.formatNumber(totally[8].toFixed(2))}</Link>,
                gross: totally[9] === 0 ? tools.formatNumber(totally[8] * 0.4) : tools.formatNumber(totally[9].toFixed(2)),
                target: tools.formatNumber(totally[10]),
                percentage: totally[11] ? totally[11] + '%' : 0,
                upstream: tools.formatNumber(totally[18].toFixed(2))
            }, {
                year: (new Date()).getFullYear() - 3,
                contract: tools.formatNumber(amount[3].toFixed(2)),
                winConstract: <Link to="/manage/business/list">{tools.formatNumber(totally[12].toFixed(2))}</Link>,
                gross: totally[13] === 0 ? tools.formatNumber(totally[12] * 0.4) : tools.formatNumber(totally[13].toFixed(2)),
                target: tools.formatNumber(totally[14]),
                percentage: totally[15] ? totally[15] + '%' : 0,
                upstream: tools.formatNumber(totally[19].toFixed(2))
            });
            result = [
                {
                    type: '目标',
                    a: classify[1][0],
                    b: classify[2][0],
                    c: classify[3][0],
                    other: classify[0][0],
                },
                {
                    type: '签单',
                    a: classify[1][1],
                    b: classify[2][1],
                    c: classify[3][1],
                    other: classify[0][1],
                },
            ];
            this.setState({
                amountData: amountData,
                statistics: statistics,
                result: result,
            });
        }
    };

    componentDidMount = () => {
        this.getFunnel({
            is_customer: true,
            is_important: true,
            stock: 1
        });
        this.getWinCount();
    };
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.interval) {
            this.interval = setInterval(() => {
                if (this.ref && this.ref.current) {
                    this.ref.current.off('click');
                    this.ref.current.on('click', (ev) => {
                        const text = ev.target.attrs.text || '';
                        let stage;
                        if (text) {
                            stage = textToStage[text.slice(0, 2)] || -1;
                            if (stage !== -1) {
                                this.props.history.push('/manage/client/list/' + stage);
                            }
                        } else {
                            const y = ev.y;
                            if (y <= 90) {
                                stage = 0;
                            } else if (y <= 135) {
                                stage = 1;
                            } else if (y <= 180) {
                                stage = 2;
                            } else if (y <= 225) {
                                stage = 3;
                            } else if (y <= 270) {
                                stage = 4;
                            } else if (y <= 315) {
                                stage = 5;
                            } else if (y <= 360) {
                                stage = 6;
                            } else {
                                stage = 7;
                            }
                            this.props.history.push('/manage/client/list/' + stage);
                        }
                    });
                    clearInterval(this.interval);
                }
            }, 1000);
        }
    }

    render() {
        const config = {
            data: this.state.funnel,
            xField: 'action',
            yField: 'count',
            description: {
                visible: true,
                text: `输单数：${this.lost}`,
            },
        };

        const config1 = {
            padding: 'auto',
            forceFit: true,
            data: this.state.amountData,
            xField: 'date',
            yField: 'value',
            yAxis: { label: { formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`) } },
            legend: isMobile(navigator.userAgent).any ? { position: 'top-left' } : { position: 'bottom-center' },
            seriesField: 'type',
            //color: ['#2D71E7', '#93D072', '#7d7878', '#a49f9f', '#beb8b8', '#ccc'],
            responsive: true,
        };

        return (
            <>
                <Card
                    title="销售数据管理"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                >
                    <Row gutter={16}>
                        <Col lg={12} md={24}>
                            <Card
                                title="客户存量漏斗"
                                style={{
                                    marginBottom: 24,
                                }}
                                bordered={false}
                            >
                                {
                                    this.state.funnel.length ? (
                                        <Funnel {...config} chartRef={this.ref} />
                                    ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                }
                            </Card>
                        </Col>

                        <Col lg={12} md={24}>
                            <Card
                                title="年度合同金额/项目毛利"
                                style={{
                                    marginBottom: 24,
                                }}
                                bordered={false}
                            >
                                {
                                    this.state.amountData.length ? (
                                        <Line {...config1} />
                                    ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                }
                            </Card>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col lg={24} md={24}>
                            <Card
                                title="年度统计"
                                style={{
                                    marginBottom: 24,
                                }}
                                bordered={false}
                            >
                                <Table
                                    columns={this.columns}
                                    dataSource={this.state.statistics}
                                    pagination={false}
                                    scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                                    size={memory.is_mobile ? 'small' : 'middle'}
                                />
                            </Card>
                        </Col>
                    </Row>
                </Card>
            </>
        );
    }
}