import React from "react";
import {Tag} from 'antd';

export default class MatketStateTag extends React.Component{
    render(){
        let {stage,is_examined,is_history}=this.props
        if(is_examined!=null){
            switch(stage){
                case 1 : return '-' ;
                case 1.1 : return is_examined===true?<Tag color="#87d068">总监审批已通过</Tag>:<Tag color="#808080">总监审批未通过</Tag>;
                case 2 : return is_examined===true?<Tag color="#87d068">商务总经理审批已通过</Tag>:<Tag color="#808080">商务总经理审批未通过</Tag>;
                case 3 : return is_examined===true?<Tag color="#87d068">副总裁审批已通过</Tag>:<Tag color="#808080">副总裁审批未通过</Tag>;
                case 4 : return is_examined===true?<Tag color="#87d068">财务审批已通过</Tag>:<Tag color="#808080">财务审批未通过</Tag>;
                case 5 : return is_examined===true?<Tag color="#87d068">费用申请已完成</Tag>:<Tag color="#808080">最终审批员未确认</Tag>;;
            }
        }
        switch(stage){
            case 1: return <Tag color="#f50">等待总监审批</Tag>;
            case 1.1: return <Tag color="#f50">等待商务总经理审批</Tag>;
            case 2: return <Tag color="#f50">等待副总裁审批</Tag>;
            case 3: return <Tag color="#f50">等待财务审批</Tag>;
            case 4: return <Tag color="#f50">等待最终审批员确认</Tag>;
        }
         return <span>-</span>
    }
}