import React from "react";
import {Table, Card, Input, Button, Icon} from "antd";
import { Link } from "react-router-dom";
import { SearchOutlined } from '@ant-design/icons';

import memory from "../../../../../utils/memory";
import {adminApi} from "../../../../../api";
import tools from "../../../../../utils/tools";
import LinkButton from "../../../../../components/link-button";

const {reqRightList} = adminApi;

export default class RightList extends React.Component{
    state = {
        data: [],
        pagination: {},
        loading: false,
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        });
        let tmpFilters = {...filters};
        for(let item in tmpFilters) {
            if(tmpFilters[item]) {
                if(typeof(tmpFilters[item]) !== 'string' && item !== 'tags') {
                    tmpFilters[item] = tmpFilters[item][0];
                }
            }
        }
        let params = {
            page: pagination.current,
            ...tmpFilters,
        };
        this.getRightList(params);
    }

    /*
    *搜索相关
    * */
    getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`搜索 ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    搜索
                </Button>
                <Button onClick={() => this.handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    重置
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => text
    });
    handleSearch = (selectedKeys, confirm) => {
        confirm();
    };
    handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
    };

    columns = [
        {
            title: 'No.',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: '权限',
            dataIndex: 'name',
            key: 'name',
            ...this.getColumnSearchProps('name', '权限')
        },
        {
            title: 'method',
            dataIndex: 'method',
            key: 'method',
        },
        {
            title: 'url',
            dataIndex: 'url',
            key: 'url',
            ...this.getColumnSearchProps('url', 'url')
        },
        {
            title: '类型',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: '创建时间',
            dataIndex: 'time',
            key: 'time',
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record) => (
                <Link to={`/admin/authority/right/edit/${record.key}`}>详情</Link>
            ),
        },
    ];

    getRightList = async (params = {}) => {
        this.setState({
            loading: true
        });
        const response = await reqRightList(params);
        const pagination = {...this.state.pagination};
        const data = response.data;
        pagination.total = data.page.total;
        pagination.pageSize = data.page.node;
        let newData = [];
        for(let i = 0; i < data.data.length; i++) {
            const item = data.data[i];
            const obj = {
                key: item._id,
                number: (data.page.current-1)*data.page.node + i + 1,
                name: item.name,
                method: item.method,
                url: item.url,
                type: item.type === 1 ? '管理员权限' : '普通权限',
                time: tools.unixToTime((new Date(item.create_at)).getTime()/1000)
            }
            newData.push(obj);
        }
        this.setState({
            loading: false,
            data: newData,
            pagination
        });
    };

    componentDidMount() {
        this.getRightList({
            page: 1,
        });
    }

    render() {
        return (
            <Card
                title="权限列表"
                bordered={false}
                extra={<LinkButton to="/admin/authority/right/add" txt="添加权限" />}
            >
                <Table
                    columns={this.columns}
                    dataSource={this.state.data}
                    pagination={this.state.pagination}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                    size={memory.is_mobile ? 'small' : 'middle'}
                />
            </Card>
        );
    }
}