import React from 'react';
import {Tag} from 'antd';

export default function getListStatus(stage,is_history,is_examined,text_only){
    if(text_only){
        if(is_history === true){
            if(stage == 2){
                return '总监审批未通过,请重新修改申请'
            }else if(stage == 3){
                return '安服审批未通过,请重新修改申请'
            }else if(stage == 4){
                return '财务审批未通过,请重新修改申请'
            }else if(stage == 5){
                if(is_examined === false){
                    return '发票信息录入失败'
                }else return '发票申请已完成'
            }
        }else{
            if(stage == 1){
                return '等待总监审批'
            }else if(stage == 2){
                return '等待安服审批'
            }else if(stage == 3){
                return '等待财务审批'
            }else if(stage == 4){
                return '等待开票员录入发票信息'
            }else if(stage == 5){
                return '等待录入快递信息'
            }
        }
    }else{
        if(is_history === true){
            if(stage == 2){
                return <Tag color="#808080">总监审批未通过</Tag>
            }else if(stage == 3){
                return <Tag color="#808080">安服审批未通过</Tag>
            }else if(stage == 4){
                return <Tag color="#808080">财务审批未通过</Tag>
            }else if(stage == 5){
                if(is_examined === false){
                    return <Tag color="#808080">发票信息录入失败</Tag>
                }else{
                    return <Tag color="#87d068">发票申请已完成</Tag>
                }
            }
        }else{
            if(stage == 1){
                return <Tag color="#f50">等待总监审批</Tag>
            }else if(stage == 2){
                return <Tag color="#f50">等待安服审批</Tag>
            }else if(stage == 3){
                return <Tag color="#f50">等待财务审批</Tag>
            }else if(stage == 4){
                return <Tag color="#f50">等待开票员录入发票信息</Tag>
            }else if(stage == 5){
                return <Tag color="#f50">等待录入快递信息</Tag>
            }
        }
    }
    return '-'
}