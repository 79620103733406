import React from "react";
import { Menu, Input, Popconfirm, Modal } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import {  DeleteOutlined,EditOutlined, PlusOutlined,MessageOutlined  } from '@ant-design/icons';
import menuList from "../../config/menu-config";
import memory from "../../../../utils/memory";
import { aiApi } from "../../../../api";

import "./index.less";

const { SubMenu } = Menu;
const { reqConversationEdit, reqConversationDelete } = aiApi;
//
class LeftNav extends React.Component {

    state = {
        menuList: [...menuList],
        editText: "",
    }

    inputRef = [];

    handleTitleEditChange = (e) => {
        this.setState({
            editText: e.target.value,
        });
    }

    handleTitleEdit = (e, index) => {
        e.stopPropagation();
        const arr = [...this.state.menuList];
        arr[index].edit = true;
        this.setState({
            menuList: arr,
            editText: arr[index].title,
        }, () => {
            this.inputRef[index].focus();
        });
    }

    handleTitleDeleteOk = async (arr, index) => {
        const id = arr[index].id;
        let response = await reqConversationDelete({ id });
        if (response?.data) {
            arr.splice(index, 1);
            this.setState({
                menuList: arr,
            });
            if (id === this.props.match.params.id) {
                if (arr[index]) {
                    this.props.history.replace(arr[index].key);
                    if (arr[index].id?.length < 24) {
                        this.props.setState({
                            conversationMessageList: [],
                        });
                    }
                } else {
                    this.props.history.replace(arr[index - 1].key);
                    if (arr[index - 1].id?.length < 24) {
                        this.props.setState({
                            conversationMessageList: [],
                        });
                    }
                }
            }
            this.props.getConversationList({ node: 20 });
        }
    }

    handleTitleDelete = async (e, index) => {
        e.stopPropagation();
        const arr = [...this.state.menuList];
        if (arr[index].id.length === 24) {
            Modal.confirm({
                title: "是否删除该会话",
                onOk: () => this.handleTitleDeleteOk(arr, index)
            })
        } else {
            const id = arr[index].id;
            arr.splice(index, 1);
            this.setState({
                menuList: arr,
            });
            if (id === this.props.match.params.id) {
                if (arr[index]) {
                    this.props.history.replace(arr[index].key);
                    if (arr[index].id?.length < 24) {
                        this.props.setState({
                            conversationMessageList: [],
                        });
                    }
                } else {
                    this.props.history.replace(arr[index - 1].key);
                    if (arr[index - 1].id?.length < 24) {
                        this.props.setState({
                            conversationMessageList: [],
                        });
                    }
                }
            }
        }
    }

    handleTitleBlur = async (index) => {
        const arr = [...this.state.menuList];
        arr[index].edit = false;
        if (this.state.editText) {
            if (arr[index].id.length === 24) {
                if (arr[index].title !== this.state.editText) {
                    let response = await reqConversationEdit({
                        id: arr[index].id,
                        title: this.state.editText,
                    });
                    if (response?.data) {
                        arr[index].title = this.state.editText;
                        this.setState({
                            menuList: arr,
                            editText: "",
                        });
                        if (arr[index].id === this.props.match.params.id) {
                            this.props.setState({
                                detail: arr[index],
                            })
                        }
                    } else {
                        this.setState({
                            menuList: arr,
                            editText: "",
                        });
                    }
                } else {
                    this.setState({
                        menuList: arr,
                        editText: "",
                    });
                }
            } else {
                arr[index].title = this.state.editText;
                this.setState({
                    menuList: arr,
                    editText: "",
                });
                if (arr[index].id === this.props.match.params.id) {
                    this.props.setState({
                        detail: arr[index],
                    })
                }
            }
        } else {
            this.setState({
                editText: "",
            })
        }
    }

    handleTitleAdd = () => {
        const arr = [...this.state.menuList];
        let title = "";
        let i = 1;
        while (!title) {
            if (arr.findIndex(o => o.title === `新建对话${i}`) > -1) {
                i++;
            } else {
                title = `新建对话${i}`;
            }
        }
        arr.splice(1, 0, {
            title: title,
            key: `/gpt/history/${arr.length}`,
            id: arr.length + "",
            icon: this.props.toolsSelected[0] === "Chat" ? "message" : "picture",
            history: true,
            temporary: true,
        });
        this.setState({
            menuList: arr,
        }, () => {
            this.props.history.push(`/gpt/history/${arr.length - 1}`);
            this.props.setState({
                conversationMessageList: [],
            });
        });
        this.props.addCallback()
    }
    handleClick = (item) => {
        this.props.history.push(item.key);
        try{
            this.props.interrupt()
        }catch{}
        if (item.id?.length < 24) {
            this.props.setState({
                conversationMessageList: [],
            });
        }
    }
    updateState=(newMenuList,callBack)=>{
        this.setState({
            menuList: newMenuList,
        }, () => {
            callBack()
        });
    }
    render() {
        const path = this.props.location.pathname;
        let selectedKey;
        if (path.split('/')[path.split('/').length - 1] === 'add') {
            selectedKey = path.replace('add', 'list');
        } else {
            selectedKey = path;
        }
        const openKey = this.openKey;
        return (
            <Menu
                className="gpt-left-nav"
                theme="dark"
                mode="inline"
                style={{ borderRight: 0 }}
                selectedKeys={[selectedKey]}
                defaultOpenKeys={[openKey]}
            >
                {
                    this.state.menuList.map((item, index) => (
                        <Menu.Item className="menu-item" key={item.key}>
                            {
                                item.history ?
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} onClick={() => this.handleClick(item)}>
                                        <div>
                                            <MessageOutlined />
                                            {
                                                item.edit ?
                                                    <Input value={this.state.editText} ref={ref => this.inputRef[index] = ref} onBlur={() => this.handleTitleBlur(index)} onPressEnter={() => this.handleTitleBlur(index)} onChange={this.handleTitleEditChange} />
                                                    :
                                                    <span>{
                                                        item.title?.length > 8 ?
                                                            item.title.slice(0, 8) + "..."
                                                            :
                                                            item.title
                                                    }</span>
                                            }
                                        </div>
                                        {
                                            !this.props.collapsed ?
                                                <div>
                                                    {
                                                        item.temporary ?
                                                            null
                                                            :
                                                            <EditOutlined  theme="outlined" type="edit" onClick={(e) => this.handleTitleEdit(e, index)} />
                                                    }
                                                    <DeleteOutlined type="delete" theme="outlined" onClick={(e) => this.handleTitleDelete(e, index)} />
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                    :
                                    <div onClick={this.handleTitleAdd}>
                                        <div>
                                            <PlusOutlined theme="outlined"/>
                                            <span>{item.title}</span>
                                        </div>
                                    </div>
                            }
                        </Menu.Item>
                    ))
                }
            </Menu>
        );
    }
}

export default withRouter(LeftNav);