import React from "react";
import {
    Card,
    Form,
    Select,
    Input,
    Radio,
    Button,
    message,
    Popconfirm,
    Icon,
} from "antd";

import { adminApi } from "../../../../../../api";

const {
    reqRightAdd,
    reqRightDetail,
    reqRightEdit,
    reqRightDel
} = adminApi;

const { Option } = Select;

class RightAdd extends React.Component {
    state = {
        detail: {}
    };
    id = this.props.match.params.id;

    onFinish = async(values) => {
        let response;
        values.url = values.url.trim();
        if (this.id) {
            values.id = this.id;
            response = await reqRightEdit(values);
        } else {
            response = await reqRightAdd(values);
        }
        if (response && response.data) {
            if (this.id) {
                message.success('修改成功');
                this.props.history.goBack();
            } else {
                message.success('添加成功');
            }
        }
    };

    handleDelete = async () => {
        const response = await reqRightDel({
            id: this.id,
        });
        if (response && response.data) {
            message.success('删除成功');
            this.props.history.goBack();
        }
    };

    getRightDetail = async (params = {}) => {
        const response = await reqRightDetail(params);
        if (response) {
            this.setState({
                detail: response.data,
            });
        }
    };

    componentDidMount() {
        if (this.id) {
            // 获取详情
            this.getRightDetail({
                id: this.id
            });
        }
    }

    render() {
        const { detail } = this.state;
        const formItemLayout = {
            labelCol: { span: 2 },
            wrapperCol: { span: 18 },
        };

        return (
            <Card
                title={
                    this.id ? "修改权限" : "添加权限"
                }
                bordered={false}
            >
                <Form {...formItemLayout} onFinish={this.onFinish}>
                    <Form.Item
                        label="URL"
                        name="url"
                        initialValue={detail.url || ''}
                        rules={[
                            {
                                required: true,
                                message: '请输入请求资源'
                            }
                        ]}
                    >
                        <Input placeholder="请输入请求资源" />
                    </Form.Item>
                    <Form.Item
                        label="权限名称"
                        name="name"
                        initialValue={detail.name || ''}
                        rules={[
                            {
                                required: true,
                                message: '请输入权限名称'
                            }
                        ]}
                    >
                        <Input placeholder="请输入权限名称" />
                    </Form.Item>
                    <Form.Item
                        label="描述"
                        desc="desc"
                        initialValue={detail.desc || ''}
                    >
                        <Input placeholder="请输入权限描述" />
                    </Form.Item>
                    <Form.Item
                        label="请求方法"
                        name="method"
                        initialValue={detail.method || 'GET'}
                        rules={[
                            {
                                required: true,
                            }
                        ]}
                    >
                        <Select>
                            <Option value="GET">GET</Option>
                            <Option value="POST">POST</Option>
                            <Option value="PUT">PUT</Option>
                            <Option value="PATCH">PATCH</Option>
                            <Option value="DELETE">DELETE</Option>
                            <Option value="OPTIONS">OPTIONS</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="权限类型"
                        name="type"
                        initialValue={detail.type || 2}
                    >
                        <Radio.Group>
                            <Radio value={1}>管理员权限</Radio>
                            <Radio value={2}>普通权限</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 12, offset: 2 }}>
                        <Button type="primary" htmlType="submit">
                            {
                                this.id ? '保存' : '添加'
                            }
                        </Button>
                        {
                            this.id ? (
                                <Popconfirm
                                    title="确定删除此权限吗？"
                                    icon={<Icon type="question-circle-o" style={{ color: 'red' }} />}
                                    cancelText="取消"
                                    okText="确定"
                                    onConfirm={this.handleDelete}
                                >
                                    <Button style={{ marginLeft: 16 }}>删除</Button>
                                </Popconfirm>
                            ) : null
                        }
                    </Form.Item>
                </Form>
            </Card>
        );
    }
}

export default RightAdd;