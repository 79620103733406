import React from 'react'
import {
    Descriptions,
    Card,
    Button,
} from 'antd'
import moment from 'moment';

import './index.less'
import { BUSINESS_CLASSIFICATION_OBJ, DELIVERY_METHOD_OBJ, INVOICE_TYPE_OBJ } from '../../../../../../utils/constant';
import tools from '../../../../../../utils/tools';
import memory from '../../../../../../utils/memory';
import DownloadLink from '../../../../../../components/download-link';

export default class BasicDetail extends React.Component {
    render() {
        let { contract, type, detail } = this.props;
        return (
            <Card
                className='basic-detail'
                title={'基本信息'}
                bordered={false}
                style={{
                    marginBottom: 24
                }}
                extra={
                    type == 'admin-detail' ?
                        (
                            <Button
                                type='primary'
                                onClick={() => this.props.history.push(`/crm/admin/contract/invoice/edit/${detail.data._id}`)}
                            >
                                编辑
                            </Button>
                        )
                        :
                        (
                            type == 'my-detail' && detail && detail.is_history == true && detail.is_examined == false ?
                                <Button
                                    type='primary'
                                    onClick={() => this.props.history.push(`/crm/client/invoice-edit/${detail._id}`)}
                                >
                                    编辑
                                </Button>
                                :
                                null
                        )
                }
            >
                {
                    type == 'add' || type == 'edit' ?
                        null
                        :
                        <>
                            <Descriptions title='发票信息' className='des-margin-none' >
                                <Descriptions.Item label='付款方名称'>
                                    {detail?.data?.customer?.name ? detail.data.customer.name : '-'}
                                </Descriptions.Item>
                                <Descriptions.Item label='付款方税号'>
                                    {detail?.data?.customer?.tax_number ? detail.data.customer.tax_number : '-'}
                                </Descriptions.Item>
                                <Descriptions.Item label='付款方地址'>
                                    {detail?.data?.customer?.address ? detail.data.customer.address : '-'}
                                </Descriptions.Item>
                                <Descriptions.Item label='付款方电话'>
                                    {detail?.data?.customer?.telephone ? detail.data.customer.telephone : '-'}
                                </Descriptions.Item>
                                <Descriptions.Item label='付款方开户行'>
                                    {detail?.data?.customer?.bank ? detail.data.customer.bank : '-'}
                                </Descriptions.Item>
                                <Descriptions.Item label='付款方账号'>
                                    {detail?.data?.customer?.bank_account ? detail.data.customer.bank_account : '-'}
                                </Descriptions.Item>
                                <Descriptions.Item label='发票类型'>
                                    {detail?.data?.invoice_type ? INVOICE_TYPE_OBJ[detail.data.invoice_type] : '-'}
                                </Descriptions.Item>
                                <Descriptions.Item label='发票内容'>
                                    {detail?.data?.content ? detail.data.content : '-'}
                                </Descriptions.Item>
                                <Descriptions.Item label='发票金额'>
                                    {detail?.data?.amount ? tools.formatNumber(detail.data.amount) + '元' : '-'}
                                </Descriptions.Item>
                                <Descriptions.Item label='备注内容'>
                                    {detail?.data?.remarks ? detail.data.remarks : '-'}
                                </Descriptions.Item>
                                <Descriptions.Item label='发票号码'>
                                    {detail?.data?.Invoice?.number ? detail.data.Invoice.number : '未录入'}
                                </Descriptions.Item>
                                <Descriptions.Item label='发票代码'>
                                    {detail?.data?.Invoice?.code ? detail.data.Invoice.code : '未录入'}
                                </Descriptions.Item>
                                <Descriptions.Item label='发票日期'>
                                    {detail?.data?.Invoice?.date ? moment(detail.data.Invoice.date).format('YYYY/MM/DD HH:mm:ss') : '未录入'}
                                </Descriptions.Item>
                                <Descriptions.Item label='业务分类'>
                                    {detail?.data?.business_classification ? BUSINESS_CLASSIFICATION_OBJ[detail.data.business_classification] : '未录入'}
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item label='开票员备注'>
                                    {detail?.data?.Invoice?.remark ? detail.data.Invoice.remark : '未录入'}
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions layout='vertical'>
                                <Descriptions.Item label='发票扫描件'>
                                    {
                                        detail?.data?.Invoice?.scanning?.length ?
                                            <DownloadLink title={null} file={detail.data.Invoice.scanning} isContract={false} />
                                            :
                                            '待上传'
                                    }
                                </Descriptions.Item>
                            </Descriptions>
                        </>
                }
                {
                    type == 'add' ?
                        <Descriptions title='合同信息'>
                            <Descriptions.Item label={'合同编号'}>
                                {contract?.contract_number ? contract.contract_number : '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={'合同名称'}>
                                {contract?.contract_name ? contract.contract_name : '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={'合同总额'}>
                                {contract?.contract_amount ? tools.formatNumber(contract.contract_amount) + '元' : '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={'开票批次总数'}>
                                {contract?.collection_times ? contract.collection_times : '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={'申请开票批次'}>
                                {contract?.is_collection_times ? `第${contract.is_collection_times + 1}批` : '第1批'}
                            </Descriptions.Item>
                            <Descriptions.Item label={'申请开票金额'}>
                                {contract?.collection_details ? tools.formatNumber(contract.collection_details[contract && contract.is_collection_times ? contract.is_collection_times : 0].amount) + '元' : '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={'预计回款日期'}>
                                {contract?.collection_details ? moment(contract.collection_details[contract && contract.is_collection_times ? contract.is_collection_times : 0].collection_at).format('YYYY/MM/DD') : '-'}
                            </Descriptions.Item>
                        </Descriptions>
                        :
                        <Descriptions title='合同信息'>
                            <Descriptions.Item label={'合同编号'}>
                                {detail?.data?.contract?.number ? detail.data.contract.number : '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={'合同名称'}>
                                {detail?.data?.contract?.name ? detail.data.contract.name : '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label={'合同总额'}>
                                {detail?.data?.contract?.amount ? tools.formatNumber(detail.data.contract.amount) + '元' : '-'}
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={<span className="font-weight-bolder">开票批次总数</span>}
                            >
                                <span
                                    className="font-weight-bolder"
                                >
                                    {detail?.data?.contract?.collection_times ? detail.data.contract.collection_times : '-'}
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={<span className="font-weight-bolder">申请开票批次</span>}
                            >
                                <span
                                    className="font-weight-bolder"
                                >
                                    {detail?.data?.contract?.batch ? `第${detail.data.contract.batch}批` : '-'}
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item
                                label={<span className="font-weight-bolder">申请开票金额</span>}
                            >
                                <span
                                    className="font-weight-bolder"
                                >
                                    {detail?.data?.contract?.batch_amount ? tools.formatNumber(detail.data.contract.batch_amount) + '元' : '-'}
                                </span>
                            </Descriptions.Item>
                            <Descriptions.Item label={'预计回款日期'}>
                                {detail?.data?.application_at ? moment(detail.data.application_at).format('YYYY/MM/DD HH:mm:ss') : '-'}
                            </Descriptions.Item>
                        </Descriptions>
                }
                {
                    type == 'add' || type == 'edit' || type == 'admin-detail' ?
                        null
                        :
                        <>
                            <Descriptions title='快递信息' className='des-margin-none' >
                                <Descriptions.Item label={'交付方式'}>
                                    {detail?.data?.method ? (detail.data.method == -1 ? detail.other_method : DELIVERY_METHOD_OBJ[detail.data.method]) : '未录入'}
                                </Descriptions.Item>
                                <Descriptions.Item label={'快递信息'}>
                                    {detail?.data?.Telephone || detail?.data?.address ? (detail?.data?.Telephone || '') + " " + (detail?.data?.address || "") : '未录入'}
                                </Descriptions.Item>
                                <Descriptions.Item label={'快递单号'}>
                                    {detail?.data?.express_number ? detail.data.express_number : '未录入'}
                                </Descriptions.Item>
                            </Descriptions>
                        </>
                }
                {
                    type == 'add' || type == 'edit' ?
                        null
                        :
                        (
                            type == 'admin-detail' ?
                                <Descriptions title='申请信息'>
                                    <Descriptions.Item label='申请日期'>
                                        {detail?.data?.create_at ? moment(detail.data.create_at).format('YYYY/MM/DD HH:mm:ss') : '-'}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='审批编号'>
                                        {detail?.data?.Invoice_number ? detail.data.Invoice_number : '-'}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='申请人'>
                                        {detail?.data?.creator?.name ? detail.data.creator.name : '-'}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='申请部门'>
                                        {detail?.data?.user_department ? memory.department_obj[detail.data.user_department] : '-'}
                                    </Descriptions.Item>
                                </Descriptions>
                                :
                                <Descriptions title='申请信息'>
                                    <Descriptions.Item label='申请日期'>
                                        {detail?.create_at ? moment(detail.create_at).format('YYYY/MM/DD HH:mm:ss') : '-'}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='审批编号'>
                                        {detail?.data?.Invoice_number ? detail.data.Invoice_number : '-'}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='申请人'>
                                        {detail?.creator?.name ? detail.creator.name : '-'}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='申请部门'>
                                        {detail?.data?.user_department ? memory.department_obj[detail.data.user_department] : '-'}
                                    </Descriptions.Item>
                                </Descriptions>
                        )
                }
            </Card>
        )
    }
}