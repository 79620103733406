import React from "react";
import {
    Spin,
    Card,
    Descriptions,
    Button,
} from "antd";

import {moaApi} from "../../../../../../api";
import tools from "../../../../../../utils/tools";
import LinkButton from "../../../../../crm/components/link-button";

const {
    reqContactsDetail
} = moaApi;

export default class AwardsContactsDetail extends React.Component {
    state = {
        loading: true,
        detail: {},
    };
    id = this.props.match.params.id;

    getContactsDetail = async (params = {}) => {
        const response = await reqContactsDetail(params);
        if(response && response.data) {
            this.setState({
                detail: response.data,
                loading: false
            });
        }
    };

    componentDidMount() {
        // 获取详情
        this.getContactsDetail({
            id: this.id
        });
    }

    render() {
        const {detail} = this.state;

        return this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <>
                <Button
                    type="link"
                    icon="left"
                    onClick={() => {
                        this.props.history.goBack();
                    }}
                >返回</Button>

                <Card
                    title="申报联络人信息"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                    extra={<LinkButton to={`/moa/awards/contacts/edit/${this.id}`} txt="编辑" />}
                >
                    <Descriptions
                        style={{
                            marginBottom: 24,
                        }}
                        title="基本信息"
                    >
                        <Descriptions.Item label="姓名">
                            {detail.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="职务">
                            {detail.post}
                        </Descriptions.Item>
                        <Descriptions.Item label="手机号">
                            {detail.mobile || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="微信号">
                            {detail.wechat || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="邮箱">
                            {detail.email || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="办公座机">
                            {detail.telephone || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="地址">
                            {detail.place || '-'}
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        title="创建信息"
                        style={{
                            marginBottom: 24,
                        }}
                    >
                        <Descriptions.Item label="创建人">
                            {detail.creator && detail.creator.name ? detail.creator.name : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="创建时间">
                            {detail.create_at
                                ? tools.unixToTime(new Date(detail.create_at).getTime() / 1000)
                                : '-'}
                        </Descriptions.Item>
                    </Descriptions>
                </Card>
            </>
        );
    }
}