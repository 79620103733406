import React from "react";
import { Button, Form, Input, Layout } from "antd";
import { Link } from "react-router-dom";
import { HomeOutlined } from '@ant-design/icons';

import "./index.less";
import { reqLoginSelf } from "../../api";
import storage from "../../utils/storage";
import { AUTH, SWITCH_ACCOUNT_WHITELIST } from "../../utils/constant";
import memory from "../../utils/memory";
import md5 from "md5-js";

React.Component.prototype.$md5 = md5;

const { Header, Content } = Layout;

class SwitchAccount extends React.Component {

    handleLogin = async (values) => {
        let params = {
            account: values.username,
            password: md5(values.password),
        }
        const response = await reqLoginSelf(params);
        if (response?.data) {
            storage.saveStore(AUTH, response.data.token);
            window.location.href = "/";
        }
    }

    componentWillMount() {
        if (!memory?.user?._id || SWITCH_ACCOUNT_WHITELIST.indexOf(memory.user.dd_info.name) === -1) {
            this.props.history.goBack();
        }
    }

    render() {
        return (
            <Layout>
                <Header className="header">
                    <Link to={'/'}>
                        <div className="logo">
                            <HomeOutlined className="logo-icon" />
                            <span className="logo-name">漏洞银行OA系统</span>
                        </div>
                    </Link>
                </Header>
                <Content className="app-content">
                    <Form className="content" layout="vertical" onFinish={this.handleLogin}>
                        <h2 className="content-title" style={{ marginBottom: 24 }}>漏洞银行OA系统</h2>
                        <div
                            className="login-area"
                            style={{
                                boxShadow: "0 0.9px 4px -1px rgb(0 0 0 / 8%), 0 2.6px 8px -1px rgb(0 0 0 / 6%), 0 5.7px 12px -1px rgb(0 0 0 / 5%), 0 15px 15px -1px rgb(0 0 0 / 4%)"
                            }}
                        >
                            <Form.Item
                                label="用户名"
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入用户名',
                                    }
                                ]}
                            >
                                <Input placeholder='请输入用户名' />
                            </Form.Item>
                            <Form.Item
                                label="密码"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入密码',
                                    }
                                ]}
                            >
                                <Input.Password placeholder='请输入密码' />
                            </Form.Item>
                            <Button type="primary" style={{ width: "100%" }} htmlType="submit">登录</Button>
                        </div>
                    </Form>
                </Content>
            </Layout>
        )
    }
}

export default SwitchAccount;