const menuList = [
    {
        title: '首页',
        key: '/admin',
        icon: 'desktop'
    },
    {
        title: '用户管理',
        key: '/admin/users',
        icon: 'user',
        children: [
            {
                title: '用户列表',
                key: '/admin/users/list',
                icon: 'table',
            }
        ]
    },
    {
        title: '权限管理',
        key: '/admin/authority',
        icon: 'laptop',
        children: [
            {
                title: '权限列表',
                key: '/admin/authority/right/list',
                icon: 'table',
            },
            {
                title: '添加权限',
                key: '/admin/authority/right/add',
                icon: 'smile',
                hide: true,
            },
            {
                title: '角色列表',
                key: '/admin/authority/role/list',
                icon: 'table',
            },
        ]
    },
    {
        title: "设备管理",
        key: '/admin/device',
        icon: 'mobile',
        children: [
            {
                title: '设备列表',
                key: '/admin/device/list',
                icon: 'table',
            },
        ]
    },
    {
        title: "版本管理",
        key: '/admin/version',
        icon: 'mobile',
        children: [
            {
                title: 'APP版本列表',
                key: '/admin/version/list',
                icon: 'table',
            },
        ]
    }
];

export default menuList;