import React from 'react';
import { Avatar, Button, Icon, Layout, Popover, message } from "antd";
import {Link} from "react-router-dom";
import { UnorderedListOutlined, SafetyOutlined, HomeOutlined } from '@ant-design/icons';

import './Home.less';
import {
    APPID,
    REDIRECT_URI,
    GOTO,
    UserInfoContext,
    AUTH,
    ROLES,
    SWITCH_ACCOUNT_WHITELIST
} from "../../utils/constant";
import memory from "../../utils/memory";
import {reqSignout} from "../../api";
import storage from "../../utils/storage";
import tools from "../../utils/tools";

const { Header, Content } = Layout;
const {
    RIGHTS,
    ADMIN,
    SELLER,
    CTO,
    SERVICEADMIN,
    FINANCE,
    PRESELLER,
    HRBP,
    BUSINESS,
    CAPTAIN,
    HRBP_ASSISTANT,
    CRMADMIN,
    CLOCK,
    MOAADMIN,
    TASK,
    CONTRACT,
    CAPITAL,
    AWARD,
    ACCOUNT,
    MANAGER,
    STAMP,
    FINAL_APPROVE,
    SCANNING,
    CRM_USER,
} = ROLES;

export default class Home extends React.Component {
    static contextType = UserInfoContext;

    state = {
        show_login: false,
        show_manage: false,
        show_crm: false,
        show_moa: false,
        show_delivery: false,
    };

    showLogin =  () => {
        this.setState({show_login: true})
    };

    userInfoHeader = () => {
        return (
            <div className="pop-header">
                <Avatar size="small" className="pop-avatar" src={this.context.user_ava} />
                <span className="pop-name">{this.context.user_name}</span>
            </div>
        );
    }

    userInfoContent = () => {
        return (
            <div className="pop-content">
                <Link to="" className="pop-content-item">
                    <UnorderedListOutlined className="icon" />
                    <span className="item-name">个人中心</span>
                </Link>
                {
                    memory.user.role_type.indexOf(RIGHTS) > -1 ? (
                        <Link to="/admin" className="pop-content-item">
                            <SafetyOutlined className="icon" />
                            <span className="item-name">后台管理</span>
                        </Link>
                    ) : null
                }
                <div className="pop-content-info">
                    <div className="info-item">
                        <label className="info-item-label">部门：</label>
                        <span className="info-item-name">{memory.department_obj[this.context.user_department[0]]}</span>
                    </div>
                </div>
                <footer className="pop-content-footer">
                    {
                        SWITCH_ACCOUNT_WHITELIST.indexOf(memory.user.dd_info.name) > -1 ?
                        <div>
                            <Button type="link" onClick={() => this.props.history.push("/switch/account")}>切换账号</Button>
                        </div>
                        :
                        null
                    }
                    <div>
                        <Button type="link" onClick={this.handleSignout}>退出登录</Button>
                    </div>
                </footer>
            </div>
        );
    }

    handleSignout = () => {
        (async () => {
            const response = await reqSignout();
            if(response) {
                message.success('账号已退出');
                storage.removeStore(AUTH);
                this.props.history.replace('/');
            }
        })();
    }

    componentDidMount() {
        window.DDLogin({
            id:"login_container",
            goto: GOTO,
            style: "border:none;background-color:#FFFFFF;padding: 0 0 20px 0;",
            width : "365",
            height: "400"
        });

        const handleMessage = function (event) {
            const origin = event.origin;
            if( origin === "https://login.dingtalk.com" ) { //判断是否来自ddLogin扫码事件。
                const loginTmpCode = event.data; //拿到loginTmpCode后就可以在这里构造跳转链接进行跳转了
                window.location.href = `https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=${APPID}&response_type=code&scope=snsapi_login&state=STATE&redirect_uri=${REDIRECT_URI}&loginTmpCode=${loginTmpCode}`
            }
        };
        if (typeof window.addEventListener != 'undefined') {
            window.addEventListener('message', handleMessage, false);
        } else if (typeof window.attachEvent != 'undefined') {
            window.attachEvent('onmessage', handleMessage);
        }
    }

    componentWillMount() {
        if(memory.user && memory.user.role_type) {
            const roles = memory.user.role_type;
            let crmArr = [ADMIN,SELLER,CTO,SERVICEADMIN,FINANCE,PRESELLER,HRBP,BUSINESS,CAPTAIN,HRBP_ASSISTANT,CRMADMIN,CLOCK,CRM_USER];
            let crmIntersect = tools.getIntersect(roles, crmArr);
            if(crmIntersect && crmIntersect.length) {
                this.setState({
                    show_crm: true
                });
            }

            let moaArr = [ADMIN,MOAADMIN,TASK,CONTRACT,CAPITAL,AWARD,ACCOUNT,STAMP,FINAL_APPROVE,SCANNING];
            let moaIntersect = tools.getIntersect(roles, moaArr);
            if(moaIntersect && moaIntersect.length) {
                this.setState({
                    show_moa: true
                });
            }

            let manageArr = [ADMIN,MANAGER];
            let manageIntersect = tools.getIntersect(roles, manageArr);
            if(manageIntersect && manageIntersect.length) {
                this.setState({
                    show_manage: true
                });
            }

            let deliveryArr = [ROLES.ADMIN,ROLES.PRODUCT,ROLES.SERVICE,ROLES.OPERATING,ROLES.OTHER_WEAPON,ROLES.WEAPON_BUSINESS];
            let deliveryIntersect = tools.getIntersect(roles, deliveryArr);
            if(deliveryIntersect?.length){
                this.setState({
                    show_delivery: true,
                })
            }
        }
    }

    render() {
        return (
            <Layout>
                <Header className="header">
                    <Link to={'/'}>
                        <div className="logo">
                            <HomeOutlined className="logo-icon" />
                            <span className="logo-name">漏洞银行OA系统</span>
                        </div>
                    </Link>
                    {
                        this.context.user_name ? (
                            <div className="user">
                                <Popover className="popover" placement="bottomLeft" content={this.userInfoContent()} title={this.userInfoHeader()}>
                                    <Avatar className="avatar" src={this.context.user_ava} />
                                </Popover>
                            </div>
                        ) : (
                            <Button type="primary" onClick={() => {this.setState({show_login: true})}}>登录</Button>
                        )
                    }
                </Header>
                <Content className="app-content">
                    <div className="content">
                        <h2 className="content-title">漏洞银行OA系统</h2>
                        <div className="content-list">
                            <Button type="primary" className="content-button" onClick={() => {
                                if(!this.context || !this.context.user_name) {
                                    this.showLogin();
                                }else {
                                    this.props.history.push('/repository');
                                }
                            }}>知识库</Button>
                            {
                                this.state.show_crm ? (
                                    <Button type="primary" className="content-button" onClick={() => {
                                        if(!this.context || !this.context.user_name) {
                                            this.showLogin();
                                        }else {
                                            this.props.history.push('/crm');
                                        }
                                    }}>CRM</Button>
                                ) : null
                            }
                            <Button type="primary" className="content-button" onClick={() => {
                                if(!this.context || !this.context.user_name) {
                                    this.showLogin();
                                }else {
                                    this.props.history.push('/moa');
                                }
                            }}>MOA</Button>
                           {/* {
                               this.state.show_delivery?
                               <Button type="primary" className="content-button" onClick={() => {
                                    if(!this.context || !this.context.user_name) {
                                        this.showLogin();
                                    }else {
                                        this.props.history.push('/train');
                                    }
                                }}>交付管理</Button>
                                :
                                null
                            } */}
                            {
                                this.state.show_manage ? (
                                    <Button type="primary" className="content-button" onClick={() => {
                                        if(!this.context || !this.context.user_name) {
                                            this.showLogin();
                                        }else {
                                            this.props.history.push('/manage');
                                        }
                                    }}>公司管理</Button>
                                ) : null
                            }
                            <Button type="primary" className="content-button" onClick={() => {
                                if(!this.context || !this.context.user_name) {
                                    this.showLogin();
                                }else {
                                    this.props.history.push('/gpt');
                                }
                            }}>AI工具库</Button>
                            <Button type="primary" className="content-button" onClick={() => {
                                if(!this.context || !this.context.user_name) {
                                    this.showLogin();
                                }else {
                                    window.open('https://tburl.in/NA4i0iLC');
                                }
                            }}>teambition</Button>
                            {/* <Button type="primary" className="content-button" onClick={() => {
                                if(!this.context || !this.context.user_name) {
                                    // this.showLogin();
                                    this.props.history.push('/ai-voice');
                                }else {
                                    this.props.history.push('/ai-voice');
                                }
                            }}>语音演示</Button> */}
                        </div>
                    </div>
                </Content>

                <div id="login_wrapper" className={this.state.show_login ? 'show' : 'hide'} onClick={() => {this.setState({show_login: false})}}>
                    <div id="login_container"></div>
                </div>
            </Layout>
        );
    }
}
