import { ROLES } from "../../../utils/constant";

const menuList = [
    {
        title: '首页',
        key: '/train',
        icon: 'radar-chart'
    },
    {
        title: "日报管理",
        key: "/train/dailywork",
        icon: "calendar",
    },
    {
        title: "项目进度(管理)",
        key: "/train/project/admin",
        icon: "calendar",
        role: [ROLES.ADMIN],
    },
    {
        title: "项目进度(产品)",
        key: "/train/project/product",
        icon: "calendar",
        role: [ROLES.PRODUCT],
    },
    {
        title: "项目进度(服务)",
        key: "/train/project/service",
        icon: "calendar",
        role: [ROLES.SERVICE],
    },
    {
        title: "项目进度(运营)",
        key: "/train/project/operate",
        icon: "calendar",
        role: [ROLES.OPERATING],
    },
    {
        title: "项目进度(其他)",
        key: "/train/project/other",
        icon: "calendar",
        role: [ROLES.OTHER_WEAPON],
    },
    {
        title: "项目进度(安全意识)",
        key: "/train/project/business",
        icon: "calendar",
        role: [ROLES.WEAPON_BUSINESS],
    },
    {
        title: "审批管理",
        key: "/train/approve",
        icon: "calendar",
        children : [
            {
                title: "交付审批(产品)",
                key: "/train/approve/department/product",
                icon: "calendar",
                role: [ROLES.ADMIN,ROLES.PRODUCT],
            },
            {
                title: "交付审批(服务)",
                key: "/train/approve/department/service",
                icon: "calendar",
                role: [ROLES.ADMIN,ROLES.SERVICE],
            },
            {
                title: "交付审批(运营)",
                key: "/train/approve/department/operate",
                icon: "calendar",
                role: [ROLES.ADMIN,ROLES.OPERATING],
            },
            {
                title: "交付审批(其他)",
                key: "/train/approve/department/other",
                icon: "calendar",
                role: [ROLES.ADMIN,ROLES.OTHER_WEAPON],
            },
            {
                title: "交付审批(安全意识)",
                key: "/train/approve/department/business",
                icon: "calendar",
                role: [ROLES.ADMIN,ROLES.WEAPON_BUSINESS],
            },
            {
                title: "交付审批(产品一)",
                key: "/train/approve/department/product1",
                icon: "calendar",
                role: [ROLES.ADMIN,ROLES.PRODUCT1],
            },
        ]
    }
];

export default menuList;