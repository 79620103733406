import React from "react";
import {
    ConfigProvider,
    Spin,
    Layout,
    Icon,
    Popover,
    Avatar,
    Button,
    message,
    Breadcrumb,
} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import {Link, Route, Switch} from "react-router-dom";
import * as dd from 'dingtalk-jsapi';
import isMobile from "ismobilejs";
import moment from 'moment';
import 'moment/locale/zh-cn';

import memory from "../../utils/memory";
import LeftNav from "./components/left-nav/LeftNav";
import {AUTH, ROLES} from "../../utils/constant";
import {reqSignout} from "../../api";
import storage from "../../utils/storage";
import menuList from "./config/menu-config";

import Home from "./routes/home";
import ProjectProgress from "./routes/project/progress";
import DepartmentApproveList from "./routes/approve/department";
import DepartmentApproveHistory from "./routes/approve/department/history"
import DepartmentApproveDetail from "./routes/approve/department/detail"

moment.locale('zh-cn');
const { Header, Content, Footer, Sider } = Layout;
const {department_obj} = memory;
const {
    RIGHTS
} = ROLES;

export default class TrainIndex extends React.Component {
    state = {
        loading: false,
        collapsed: isMobile(navigator.userAgent).any ? true : false,
    };
    breadcrumb = [];

    userInfoHeader = () => {
        return (
            <div className="pop-header">
                <Avatar size="small" className="pop-avatar" src={memory.user.dd_info.avatar} />
                <span className="pop-name">{memory.user.dd_info.name}</span>
            </div>
        );
    }
    userInfoContent = () => {
        return (
            <div className="pop-content">
                <Link to="/crm/" className="pop-content-item">
                    <Icon className="icon" type="unordered-list" />
                    <span className="item-name">个人中心</span>
                </Link>
                {
                    memory.user.role_type.indexOf(RIGHTS) > -1 ? (
                        <Link to="/admin" className="pop-content-item">
                            <Icon className="icon" type="safety" />
                            <span className="item-name">后台管理</span>
                        </Link>
                    ) : null
                }
                <div className="pop-content-info">
                    <div className="info-item">
                        <label className="info-item-label">部门：</label>
                        <span className="info-item-name">{department_obj[memory.user.dd_info.department[0]]}</span>
                    </div>
                </div>
                {
                    dd.env.platform !== 'notInDingTalk' ? null : (
                        <footer className="pop-content-footer">
                            <Button type="link" onClick={this.handleSignout}>退出登录</Button>
                        </footer>
                    )
                }
            </div>
        );
    }
    breadcrumbComponent = (menuArr, pathname) => {
        for(let i = 0; i < menuArr.length; i++) {
            if(pathname.indexOf(menuArr[i].key) === 0) {
                if(menuArr[i].key === '/moa') {
                    this.breadcrumb.push(
                        <Breadcrumb.Item key={menuArr[i].key}>
                            <Link to="/moa">
                                {menuArr[i].title}
                            </Link>
                        </Breadcrumb.Item>
                    );
                }else {
                    this.breadcrumb.push(
                        <Breadcrumb.Item key={menuArr[i].key}>
                            {menuArr[i].title}
                        </Breadcrumb.Item>
                    );
                }
                if(menuArr[i].children) {
                    this.breadcrumbComponent(menuArr[i].children, pathname);
                }
            }
        }
        return this.breadcrumb;
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };
    handleSignout = () => {
        (async () => {
            const response = await reqSignout();
            if(response) {
                message.success('账号已退出');
                storage.removeStore(AUTH);
                this.props.history.replace('/');
            }
        })();
    }

    componentDidMount =() => {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(memory.is_mobile) {
            if(this.props.location.pathname !== prevProps.location.pathname) {
                this.setState({
                    collapsed: true
                });
            }
        }
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        this.breadcrumb = [];
    }

    render() {
        const pathname = this.props.location.pathname;

        return (
            <Layout className="crm">
                {
                    this.state.loading ? (
                        <div className="loading-container">
                            <Spin size="large" />
                        </div>
                    ) : (
                        <ConfigProvider locale={zhCN}>
                            <Sider
                                breakpoint="lg"
                                collapsedWidth={memory.is_mobile ? 0: 80}
                                trigger={null}
                                collapsible
                                collapsed={this.state.collapsed}
                                style={{
                                    overflow: 'auto',
                                    height: '100%',
                                    position: 'fixed',
                                    left: 0,
                                    zIndex: 99,
                                }}
                            >
                                <div className="crm-logo" title="首页">
                                    <Link to="/train">
                                        <Icon className="logo-icon" type="home" style={{color: '#ccc', fontSize: 26}} />
                                        {
                                            memory.is_mobile ? (
                                                <span className="logo-name" style={{color: '#ccc', fontSize: 26, marginLeft: 20}}>交付管理</span>
                                            ) : (
                                                this.state.collapsed ? null : (
                                                    <span className="logo-name" style={{color: '#ccc', fontSize: 26, marginLeft: 20}}>交付管理</span>
                                                )
                                            )
                                        }
                                    </Link>
                                </div>
                                {<LeftNav />}
                            </Sider>

                            <Layout
                                style={{
                                    marginLeft: memory.is_mobile ? 0 : (this.state.collapsed ? 80 : 200)
                                }}
                            >
                                <Header
                                    className="crm-header"
                                    style={{
                                        position: 'fixed',
                                        zIndex: 97,
                                        left: 0,
                                        right: 0,
                                        marginLeft: memory.is_mobile ? 0 : (this.state.collapsed ? 80 : 200)
                                    }}
                                >
                                    <Icon
                                        className="crm-trigger"
                                        type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                                        onClick={this.toggle}
                                    />
                                    <div className="user">
                                        <Popover className="popover" content={this.userInfoContent()} title={this.userInfoHeader()} placement="bottomRight">
                                            <Avatar className="avatar" src={memory.user.dd_info.avatar} />
                                        </Popover>
                                    </div>
                                </Header>

                                {
                                    pathname === '/train'?
                                    null
                                    :
                                    <Breadcrumb
                                        style={{
                                            padding: '24px 24px 0',
                                            marginTop: '64px',
                                        }}>
                                        {this.breadcrumbComponent(menuList, pathname)}
                                    </Breadcrumb>
                                }

                                <Content>
                                    <Switch>
                                        <Route path={"/train/approve/department/detail/product1/:id"} component={DepartmentApproveDetail} key={"product1"} ></Route>
                                        <Route path={"/train/approve/department/detail/business/:id"} component={DepartmentApproveDetail} key={"business"} ></Route>
                                        <Route path={"/train/approve/department/detail/other/:id"} component={DepartmentApproveDetail} key={"other"} ></Route>
                                        <Route path={"/train/approve/department/detail/operate/:id"} component={DepartmentApproveDetail} key={"operate"} ></Route>
                                        <Route path={"/train/approve/department/detail/service/:id"} component={DepartmentApproveDetail} key={"service"} ></Route>
                                        <Route path={"/train/approve/department/detail/product/:id"} component={DepartmentApproveDetail} key={"product"} ></Route>
                                        <Route path={"/train/approve/department/history/product1"} component={DepartmentApproveHistory} key={"product1"}></Route>
                                        <Route path={"/train/approve/department/history/business"} component={DepartmentApproveHistory} key={"business"}></Route>
                                        <Route path={"/train/approve/department/history/other"} component={DepartmentApproveHistory} key={"other"}></Route>
                                        <Route path={"/train/approve/department/history/operate"} component={DepartmentApproveHistory} key={"operate"}></Route>
                                        <Route path={"/train/approve/department/history/service"} component={DepartmentApproveHistory} key={"service"}></Route>
                                        <Route path={"/train/approve/department/history/product"} component={DepartmentApproveHistory} key={"product"}></Route>
                                        <Route path={"/train/approve/department/product1"} component={DepartmentApproveList} key={"product1"}></Route>
                                        <Route path={"/train/approve/department/business"} component={DepartmentApproveList} key={"business"}></Route>
                                        <Route path={"/train/approve/department/other"} component={DepartmentApproveList} key={"other"}></Route>
                                        <Route path={"/train/approve/department/operate"} component={DepartmentApproveList} key={"operate"}></Route>
                                        <Route path={"/train/approve/department/service"} component={DepartmentApproveList} key={"service"}></Route>
                                        <Route path={"/train/approve/department/product"} component={DepartmentApproveList} key={"product"}></Route>
                                        <Route path="/train/project/business" component={ProjectProgress} key={"business"}></Route>
                                        <Route path="/train/project/other" component={ProjectProgress} key={"other"}></Route>
                                        <Route path="/train/project/operate" component={ProjectProgress} key={"operate"}></Route>
                                        <Route path="/train/project/service" component={ProjectProgress} key={"service"}></Route>
                                        <Route path="/train/project/product" component={ProjectProgress} key={"product"}></Route>
                                        <Route path="/train/project/admin" component={ProjectProgress} key={"admin"}></Route>
                                        <Route path="/train" component={Home}></Route>
                                    </Switch>
                                </Content>

                                <Footer style={{ textAlign: 'center' }}>谋乐网络科技 ©{moment().year()}</Footer>
                                <div
                                    className="mask-layout"
                                    style={{
                                        display: memory.is_mobile ? (this.state.collapsed ? 'none' : 'block') : 'none'
                                    }}
                                    onClick={() => {
                                        this.setState({
                                            collapsed: true
                                        });
                                    }}
                                >
                                </div>
                            </Layout>
                        </ConfigProvider>
                    )
                }
            </Layout>
        );
    }
}