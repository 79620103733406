import React from "react";
import {
    Button,
    Card,
    Input,
    Table,
} from "antd";
import {Link} from "react-router-dom";
import { SearchOutlined } from '@ant-design/icons';

import memory from "../../../../utils/memory";
import {moaApi} from "../../../../api";
import {
    CAPITALLEVEL,
    CAPITALLEVELSIMPLE,
    CAPITALMILESTONE,
    CAPITALSTATE,
} from "../../../../utils/constant";
import tools from "../../../../utils/tools";

const {
    reqFundList
} = moaApi;

export default class CapitalList extends React.Component {
    state = {
        data: [],
        pagination: {},
        loading: false,
    };

    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        });
        let tmpFilters = {...filters};
        for(let item in tmpFilters) {
            if(tmpFilters[item]) {
                if(typeof(tmpFilters[item]) !== 'string') {
                    tmpFilters[item] = tmpFilters[item][0];
                }
            }
        }
        let params = {
            page: pagination.current,
            is_followed: true,
            ...tmpFilters,
        };
        if(sorter && sorter.field) {
            if(sorter.order === 'ascend') {
                params[sorter.field] = -1;
            }else if(sorter.order === 'descend') {
                params[sorter.field] = 1;
            }
        }
        this.getFundList(params);
    }

    /*
    *搜索相关
    * */
    getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`搜索 ${title}`}
                    value={selectedKeys ? selectedKeys[0] : ''}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    搜索
                </Button>
                <Button onClick={() => this.handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    重置
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => text
    });
    handleSearch = (selectedKeys, confirm) => {
        confirm();
    };
    handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
    };

    columns = [
        {
            title: '资金申报年份',
            dataIndex: 'fund_date',
            key: 'fund_date',
            sorter: (a, b) => a - b,
        },
        {
            title: '资金名称',
            dataIndex: 'fund_name',
            key: 'fund_name',
            ...this.getColumnSearchProps('fund_name', '资金名称'),
            render: (text, record) => {
                return (
                    <Link to={`/moa/capital/detail/${record.key}`}>
                        <span className="text-overflow" style={{maxWidth: 500}} title={text}>{text}</span>
                    </Link>
                );
            }
        },
        {
            title: '资金量级',
            dataIndex: 'fund_grade',
            key: 'fund_grade',
            filters: CAPITALLEVELSIMPLE,
            filterMultiple: false,
        },
        {
            title: '申报单位',
            dataIndex: 'group',
            key: 'group',
        },
        {
            title: '代理机构',
            dataIndex: 'agency',
            key: 'agency',
        },
        {
            title: '项目经理',
            dataIndex: 'manager',
            key: 'manager',
            filters: [],
            filterMultiple: false,
        },
        {
            title: '协作人数',
            dataIndex: 'worker',
            key: 'worker',
        },
        {
            title: '里程碑',
            dataIndex: 'milestone',
            key: 'milestone',
            filters: CAPITALMILESTONE,
            filterMultiple: false,
        },
        {
            title: '所处阶段',
            dataIndex: 'stage',
            key: 'stage',
            filters: CAPITALSTATE,
            filterMultiple: false,
        },
        {
            title: '资金金额',
            dataIndex: 'capital_all',
            key: 'capital_all',
        },
        {
            title: '到账金额',
            dataIndex: 'capital_in',
            key: 'capital_in',
        },
        {
            title: '费用支出',
            dataIndex: 'payoff',
            key: 'payoff',
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
        },
        {
            title: '操作',
            dataIndex: 'option',
            valueType: 'option',
            render: (_, record) => {
                return (
                    <Link to={`/moa/capital/detail/${record.key}`}>详情</Link>
                );
            }
        },
    ];

    getFundList = async (params = {}) => {
        this.setState({
            loading: true
        });
        const response = await reqFundList(params);
        const pagination = {...this.state.pagination};
        const data = response.data;
        pagination.total = data.page.total;
        pagination.pageSize = data.page.node;
        let newData = [];
        for(let i = 0; i < data.data.length; i++) {
            const item = data.data[i];
            let worker = [];
            if(item.team_worker && item.team_worker.length) {
                for(let j = 0; j < item.team_worker.length; j++) {
                    worker.push(item.team_worker[j].name);
                }
            }
            const obj = {
                key: item._id,
                fund_date: item.fund_date,
                fund_name: item.fund_name,
                fund_grade: CAPITALLEVEL[item.fund_grade-1].text,
                group: item.declare_unit || '-',
                agency: item.agency ? <a href={`/moa/capital/agency/detail/${item.agency.id}`} target="_blank">{item.agency.name}</a> : '-',
                manager: item.manager.name,
                worker: worker.length,
                milestone: item.is_wastage ? '已流失' : CAPITALMILESTONE[item.milestone-1].text,
                stage: item.is_wastage ? '流失阶段' : CAPITALSTATE[item.stage-1].text,
                capital_all: item.fund_amount ? (item.fund_amount + '万元') : '-',
                capital_in: item.arrived_amount + '元',
                payoff: item.expenses_amount + '元',
                create_time: tools.unixToTime((new Date(item.create_at)).getTime()/1000)
            };
            newData.push(obj);
        }
        let manager = [];
        for(let i = 0; i < data.list.managers.length; i++) {
            manager.push({
                value: data.list.managers[i].id,
                text: data.list.managers[i].name
            });
        }
        this.columns[4].filters = manager; // 项目经理筛选

        this.setState({
            loading: false,
            data: newData,
            pagination
        });
    };

    componentDidMount() {
        this.getFundList({
            page: 1,
            is_followed: true
        });
    }

    render() {
        return (
            <Card
                title="资金列表"
                bordered={false}
            >
                <Table
                    columns={this.columns}
                    dataSource={this.state.data}
                    pagination={this.state.pagination}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                    size={memory.is_mobile ? 'small' : 'middle'}
                />
            </Card>
        );
    }
}