import React from "react";
import {
    Spin,
    Card,
    Button,
    Breadcrumb,
    Layout,
    Popover,
    Icon,
    Popconfirm,
    message,
    List,
    Avatar,
    Upload
} from "antd";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from '@taoqf/quill-image-resize-module';
import { ImageDrop } from 'quill-image-drop-module';
import 'react-quill/dist/quill.snow.css'; // ES6
import { Prompt } from 'react-router-dom';
import moment from "moment";
import {
    FormOutlined,
    EditOutlined,
    DeleteOutlined,
    UploadOutlined,
    InfoCircleOutlined,
    EllipsisOutlined,
    PaperClipOutlined
} from '@ant-design/icons';

import {
    repositoryApi,
} from "../../../../../api";
import tools from "../../../../../utils/tools";
import memory from "../../../../../utils/memory";
import { Link } from "react-router-dom";
import avatar from "../../../../../assets/default-avatar.png";
import storage from "../../../../../utils/storage";
import { AUTH } from "../../../../../utils/constant";
import { MenuSide } from "../../../../../utils/constant";
import './index.less';

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDrop', ImageDrop);

const { Content } = Layout;
const {
    reqArticleDetail,
    reqArticleDelete,
    reqAnswerCreate,
    reqAnswerList,
    reqAnswerEdit,
    reqAnswerDelete,
    reqAnswerApprove
} = repositoryApi;

const IconText = ({ type, text, onclick }) => (
    <span onClick={onclick} className={(type === 'like-o' && text.indexOf(memory.user._id) > -1) ? `${type} on` : type}>
        <Icon type={type} style={{ marginRight: 8 }} />
        {type === 'like-o' ? text.length : text}
    </span>
);

export default class ArticleDetail extends React.Component {
    static contextType = MenuSide;

    state = {
        loading: true,
        detail: {},
        show_editor: false,
        editorHtml: '',
        answer_list: [],
        fileObj: {},

        //编辑相关
        show_edit_box: false,
        edit_editorHtml: '',
    };
    quillRef = null;
    reactQuillRef = null;

    //编辑相关
    edit_quillRef = null;
    edit_reactQuillRef = null;
    edit_id = '';

    id = this.props.match.params.id;
    /*富文本编辑器配置项*/
    modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            [{ 'color': [] }, { 'background': [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
        imageResize: {
            displaySize: true
        },
        imageDrop: true
    }
    formats = [
        'header', 'font', 'size', 'color', 'background',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video',
        'alt', 'width', 'height', 'style'
    ]
    attachQuillRefs = () => {
        if (!this.reactQuillRef || (typeof this.reactQuillRef.getEditor !== 'function')) return;
        this.quillRef = this.reactQuillRef.getEditor();

        if (!this.edit_reactQuillRef || (typeof this.edit_reactQuillRef.getEditor !== 'function')) return;
        this.edit_quillRef = this.edit_reactQuillRef.getEditor();
    }

    onChangeFile = async ({ file, fileList }, aid) => {
        let params = [...this.state.fileObj[aid]];
        if (file.status === 'done') {
            params.push({
                uid: file.uid,
                name: file.name,
                status: file.status,
                response: 'Server Error 500',
                url: file.response.url,
                create_at: (new Date()).getTime()
            });

            const response = await reqAnswerEdit({
                id: aid,
                attachment: params,
            });
            if (response && response.data) {
                message.success('附件上传成功');
                this.getAnswers();
            }
        } else if (file.status === 'error') {
            message.error('附件上传失败');
        }
    };
    onRemoveFile = async (id, attachment, index) => {
        let params = [...attachment];
        params.splice(index, 1);
        const response = await reqAnswerEdit({
            id: id,
            attachment: params,
        });
        if (response && response.data) {
            message.success('附件删除成功');
            this.getAnswers();
        }
    };

    handlePreview = async (file) => {
        tools.downloadFile(this.props.history, file, `${memory.user.dd_info.name} ${moment().format("YYYY/MM/DD")}`);
    }

    handleChange = (html) => {
        let tmpHtml = html.replace(/&lt;img.*?(?:>|\/&gt;)/gi, '')
        this.setState({ editorHtml: tmpHtml })
    }
    handleEditChange = (html) => {
        let tmpHtml = html.replace(/&lt;img.*?(?:>|\/&gt;)/gi, '')
        this.setState({ edit_editorHtml: tmpHtml })
    }
    handleShowEditor = () => {
        this.setState({
            show_edit_box: false,
            show_editor: !this.state.show_editor
        });
    }
    handleEditAnswer = (id, content) => {
        this.edit_id = id;
        this.setState({
            show_editor: false,
            edit_editorHtml: content,
            show_edit_box: true,
        });
    }
    handleDeleteAnswer = (id) => {
        (async () => {
            const response = await reqAnswerDelete({ id });
            if (response) {
                message.success('删除成功');
                this.getAnswers();
            }
        })();
    }
    /*删除文章*/
    handleDeleteArticle = () => {
        const id = this.id;
        (async () => {
            const response = await reqArticleDelete({ id });
            if (response) {
                message.success('文章删除成功');
                this.context();
                this.props.history.replace('/repository');
            }
        })();
    }
    /*获取回答列表*/
    createMarkup(detail) {
        return { __html: detail };
    }
    getAnswers = async () => {
        const id = this.props.match.params.id;
        const response = await reqAnswerList({ id: id });
        if (response && response.data.data) {
            const data = response.data.data;
            let result = [];
            let obj = {};
            for (let i = 0; i < data.length; i++) {
                result.push({
                    id: data[i]._id,
                    name: data[i].creator.name,
                    avatar: data[i].creator.avatar || avatar,
                    creator_id: data[i].creator.id,
                    description: memory.department_obj[data[i].creator.department],
                    content: <div dangerouslySetInnerHTML={this.createMarkup(data[i].detail)} />,
                    likes: data[i].agree_ids,
                    detail: data[i].detail,
                    attachment: data[i].attachment || []
                });
                obj[data[i]._id] = data[i].attachment || [];
            }
            this.setState({
                answer_list: result,
                fileObj: obj,
            });
        } else {
            this.setState({
                answer_list: [],
                fileObj: {},
            });
        }
    }
    /*添加回答*/
    createAnswer = async () => {
        if (!this.state.editorHtml) {
            message.warning('请填写回答内容');
            return;
        }
        const id = this.id;
        const response = await reqAnswerCreate({
            article_id: id,
            detail: this.state.editorHtml,
            plain_detail: this.quillRef.getText()
        });
        if (response) {
            message.success('已成功提交回答');
            this.setState({
                editorHtml: '',
                show_editor: false
            });
            this.getAnswers();
        }
    }
    /*修改回答*/
    editAnswer = async () => {
        if (!this.state.edit_editorHtml) {
            message.warning('请填写回答内容');
            return;
        }
        const id = this.edit_id;
        const response = await reqAnswerEdit({
            id: id,
            detail: this.state.edit_editorHtml,
            plain_detail: this.edit_quillRef.getText()
        });
        if (response) {
            message.success('已成功修改回答');
            this.edit_id = '';
            this.setState({
                edit_editorHtml: '',
                show_edit_box: false,
            });
            this.getAnswers();
        }
    }
    /*回答点赞*/
    handleApproveAnswer = (id, index) => {
        const user_id = memory.user._id;
        (async () => {
            const response = await reqAnswerApprove({ id });
            if (response) {
                let answerList = [...this.state.answer_list];
                let likes = answerList[index].likes;
                if (likes.indexOf(user_id) > -1) { //取消点赞
                    likes.splice(likes.findIndex(item => item === user_id), 1);
                } else {
                    likes.push(user_id);
                }
                this.setState({
                    answer_list: answerList
                });
            }
        })();
    }
    /*文章浮窗*/
    articleInfoHeader = () => {
        return (
            <div className="pop-header">
                <span className="pop-name">文章信息：</span>
            </div>
        );
    }
    articleInfoContent = () => {
        return (
            <div className="pop-article">
                <div className="pop-article-item">
                    <label className="pop-article-item-label">作者：</label>
                    <span className="pop-article-item-name">{this.state.detail.creator.name}</span>
                </div>
                <div className="pop-article-item">
                    <label className="pop-article-item-label">创建时间：</label>
                    <span className="pop-article-item-name">{this.state.detail.create_at}</span>
                </div>
                <div className="pop-article-item">
                    <label className="pop-article-item-label">更新时间：</label>
                    <span className="pop-article-item-name">{this.state.detail.update_at}</span>
                </div>
            </div>
        );
    }
    articleActionHeader = () => {
        return (
            <div className="pop-header">
                <span className="pop-name">操作：</span>
            </div>
        );
    }
    articleActionContent = () => {
        const id = this.id;

        return (
            <div className="pop-article">
                <div className="pop-article-item action" onClick={this.handleShowEditor}>
                    <FormOutlined className="pop-article-item-icon" />
                    <span className="pop-article-item-txt">回答</span>
                </div>
                {memory.user._id === this.state.detail.creator.id ? (
                    <React.Fragment>
                        <Link to={`/repository/article/edit/${id}`} className="pop-article-item action">
                            <EditOutlined className="pop-article-item-icon" />
                            <span className="pop-article-item-txt">编辑</span>
                        </Link>
                        <Popconfirm
                            title="确认删除这篇文章吗？"
                            onConfirm={() => this.handleDeleteArticle()}
                            okText="确认"
                            cancelText="取消"
                        >
                            <div className="pop-article-item action">
                                <DeleteOutlined className="pop-article-item-icon" />
                                <span className="pop-article-item-txt">删除</span>
                            </div>
                        </Popconfirm>
                    </React.Fragment>
                ) : null}
            </div>
        );
    }
    answerActionHeader = () => {
        return (
            <div className="pop-header">
                <span className="pop-name">操作：</span>
            </div>
        );
    }
    answerActionContent = (aid, detail, attachment) => {
        let headers = {};
        const auth = storage.getStore(AUTH);
        if (auth) {
            headers = {
                authorization: 'Bearer ' + auth
            };
        }
        const props = {
            action: '/api/files/ue?action=uploadfile',
            headers: headers,
            defaultFileList: attachment || [],
            onPreview: this.handlePreview,
        };

        return (
            <div className="pop-article">
                <div className="edit pop-article-item action" onClick={() => {
                    this.handleEditAnswer(aid, detail);
                }}>
                    <EditOutlined style={{ marginRight: 8 }} />
                    编辑
                </div>
                <Popconfirm
                    title="确认删除这条回答吗？"
                    onConfirm={() => this.handleDeleteAnswer(aid)}
                    okText="确认"
                    cancelText="取消"
                >
                    <div className="pop-article-item action">
                        <DeleteOutlined style={{ marginRight: 8 }} />
                        删除
                    </div>
                </Popconfirm>
                <Upload {...props} showUploadList={false} onChange={(params) => this.onChangeFile(params, aid)}>
                    <div className="pop-article-item action">
                        <UploadOutlined style={{ marginRight: 8 }} />
                        上传附件
                    </div>
                </Upload>
            </div>
        );
    }

    getArticle = async (params = {}) => {
        const id = this.props.match.params.id;
        const response = await reqArticleDetail({ id });
        let createTime = response.data.create_at;
        createTime = tools.unixToTime(new Date(createTime) / 1000);
        response.data.create_at = createTime;
        let updateTime = response.data.update_at || response.data.create_at;
        updateTime = tools.unixToTime(new Date(updateTime) / 1000);
        response.data.update_at = updateTime;
        this.setState({
            detail: response.data,
            loading: false
        });

        this.getAnswers();
    }

    getElementViewTop = (element) => {
        let actualTop = element.offsetTop;
        let current = element.offsetParent;

        while (current !== null) {
            actualTop += current.offsetTop;
            current = current.offsetParent;
        }

        let elementScrollTop;
        if (document.compatMode == "BackCompat") {
            elementScrollTop = document.body.scrollTop;
        } else {
            elementScrollTop = document.documentElement.scrollTop;
        }

        return actualTop - elementScrollTop;
    }

    handleScroll = () => {
        if (this.state.show_editor || this.state.show_edit_box) {
            let el = document.getElementsByClassName('ql-toolbar');
            let el2 = document.getElementsByClassName('ql-container');
            if (this.state.show_editor) {
                el = el[0];
                el2 = el2[0];
            } else {
                el = el[1];
                el2 = el2[1];
            }
            if (this.getElementViewTop(el2) < 106) {
                if (el.className !== 'ql-toolbar ql-snow sticky') {
                    el.className = 'ql-toolbar ql-snow sticky';
                    el.style.width = el2.offsetWidth + 'px';
                }
            } else if (this.getElementViewTop(el2) >= 106) {
                if (el.className !== 'ql-toolbar ql-snow') {
                    el.className = 'ql-toolbar ql-snow';
                }
            }
        }
    };

    componentDidMount() {
        this.getArticle();
        window.addEventListener('scroll', this.handleScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.attachQuillRefs();
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.id = this.props.match.params.id;
            this.setState({
                edit_editorHtml: '',
                editorHtml: '',
                show_edit_box: false,
                show_editor: false,
            });
            this.getArticle();
        }
        if (this.state.show_editor) {
            this.quillRef.focus();
        }
        if (this.state.show_edit_box) {
            this.edit_quillRef.focus();
        }
    }

    render() {
        const { detail } = this.state;

        return this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <>
                <Breadcrumb
                    style={{
                        padding: '24px 24px 0',
                        marginTop: '64px',
                    }}>
                    <Breadcrumb.Item>
                        <Link to="/repository">首页</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        详情
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Content style={{ margin: '24px 16px 0' }}>
                    <Button
                        type="link"
                        icon="left"
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                    >返回</Button>

                    <Card
                        style={{
                            marginBottom: 24,
                        }}
                        bordered={false}
                    >
                        <div className="article">
                            <div className="info-container">
                                <Popover placement="bottomLeft" className="popover" trigger="click" content={this.articleInfoContent()} title={this.articleInfoHeader()}>
                                    <InfoCircleOutlined className="info-icon" />
                                </Popover>
                                <Popover placement="bottomLeft" className="popover" trigger="click" content={this.articleActionContent()} title={this.articleActionHeader()}>
                                    <EllipsisOutlined className="info-icon" />
                                </Popover>
                            </div>
                            <h3 className="title">{detail.title}</h3>

                            {/*添加区域*/}
                            <div className={this.state.show_editor ? "quill-container" : "quill-container hide"}>
                                <ReactQuill
                                    ref={(el) => { this.reactQuillRef = el }}
                                    className="quill"
                                    onChange={this.handleChange}
                                    value={this.state.editorHtml}
                                    modules={this.modules}
                                    formats={this.formats}
                                    bounds={'.app'}
                                    placeholder="请输入回答"
                                />
                                <div className="quill-submit">
                                    <Button type="primary" onClick={this.createAnswer}>提交</Button>
                                </div>
                            </div>

                            {/*编辑区域*/}
                            <div className={this.state.show_edit_box ? "quill-container" : "quill-container hide"}>
                                <ReactQuill
                                    ref={(el) => { this.edit_reactQuillRef = el }}
                                    className="quill"
                                    onChange={this.handleEditChange}
                                    value={this.state.edit_editorHtml}
                                    modules={this.modules}
                                    formats={this.formats}
                                    bounds={'.app'}
                                    placeholder="请输入回答"
                                />
                                <div className="quill-submit">
                                    <Button type="primary" onClick={this.editAnswer}>修改</Button>
                                </div>
                            </div>

                            <List
                                itemLayout="vertical"
                                size="large"
                                footer={<div></div>}
                                dataSource={this.state.show_edit_box ? [] : this.state.answer_list}
                                className="answer-list"
                                locale={this.state.show_edit_box ? { emptyText: ' ' } : {}}
                                renderItem={(item, index) => (
                                    <List.Item
                                        key={item.id}
                                        actions={
                                            memory.user._id === item.creator_id ? (
                                                [
                                                    <IconText type="like-o" text={item.likes} key="list-vertical-like-o" onclick={() => {
                                                        this.handleApproveAnswer(item.id, index);
                                                    }} />,

                                                    <Popover
                                                        placement="bottomLeft"
                                                        className="popover"
                                                        trigger="click"
                                                        content={this.answerActionContent(item.id, item.detail, item.attachment)}
                                                        title={this.answerActionHeader()}
                                                    >
                                                        <EllipsisOutlined className="info-icon" style={{ color: '#1890ff', fontSize: 18 }} />
                                                    </Popover>,
                                                ]
                                            ) : ([
                                                <IconText type="like-o" text={item.likes} key="list-vertical-like-o" onclick={() => {
                                                    this.handleApproveAnswer(item.id, index);
                                                }} />
                                            ]
                                            )
                                        }
                                    >
                                        <List.Item.Meta
                                            avatar={<Avatar src={item.avatar} />}
                                            title={`${item.name}（${item.description}）`}
                                        //description={item.description}
                                        />
                                        {item.content}
                                        {
                                            item.attachment.length ? (
                                                <div className="attachment-area">
                                                    {
                                                        item.attachment.map((file, index) => (
                                                            <div key={file.uid} className="attachment-item">
                                                                <div>
                                                                    <PaperClipOutlined className="attachment-item-icon" />
                                                                    <a href={file.url} className="attachment-item-content">{file.name}</a>
                                                                </div>
                                                                <Popconfirm
                                                                    title="确认删除该附件吗？"
                                                                    onConfirm={() => this.onRemoveFile(item.id, item.attachment, index)}
                                                                    okText="确认"
                                                                    cancelText="取消"
                                                                >
                                                                    <DeleteOutlined className="attachment-item-del" />
                                                                </Popconfirm>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            ) : null
                                        }
                                    </List.Item>
                                )}
                            />
                        </div>
                    </Card>
                </Content>
                {
                    this.state.show_edit_box ? (
                        <Prompt
                            when={true}
                            message={location => '内容尚未保存，确定离开当前页面吗？'}
                        />
                    ) : null
                }
            </>
        );
    }
}