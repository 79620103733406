import React from "react";
import {Avatar, Card, Row, Col, List, Icon, Progress, Pagination} from "antd";
import { AVATAR, DELIVERY_PROGRESS_OBJ, ROLES } from '../../../../utils/constant';
import moment from "moment"

import './index.less';
import IntroduceChart from "./introduce-chart";
import WinChart from "./win-chart";
import memory from "../../../../utils/memory";

const dataSource = [
    {
        business:"测试",
        "creator":"钟彪",
        weapon:"一云,漏洞之眼",
        create_at:moment(),
        step:4,
    },
    {
        business:"测试",
        "creator":"钟彪",
        weapon:"一云,漏洞之眼",
        create_at:moment(),
        step:5,
    },
    {
        business:"测试",
        "creator":"钟彪",
        weapon:"一云,漏洞之眼",
        create_at:moment(),
        step:8,
    },
    {
        business:"测试",
        "creator":"钟彪",
        weapon:"一云,漏洞之眼",
        create_at:moment(),
        step:9,
    },
]

export default class Home extends React.Component{

    isAdmin = !!memory.user.roles.find(o => o.type == ROLES.ADMIN)

    render() {
        return (
            <div className="delivery-dashboard">
                <div className="delivery-header">
                    <Row gutter={16} type="flex" align="middle">
                        <Col lg={16} md={16} sm={24} xs={24}>
                            <div className="user-info">
                                <Avatar src={AVATAR} size={70}></Avatar>
                                <div className="info-main">
                                    <div className="p-interval info-hello">你好，{memory?.user?.dd_info?.name || '-'}，祝你开心每一天</div>
                                    <div>{this.isAdmin ? "管理员" : "交付部门人员"}</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <Row gutter={16} type="flex" align="middle">
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <IntroduceChart />
                    </Col>
                    <Col lg={24} md={24} sm={24} xs={24}>
                        <WinChart />
                    </Col>
                </Row>
            </div>
        );
    }
}