import React from "react";
import { Input, Tooltip } from 'antd';

function formatNumber(value) {
    value += '';
    const list = value.split('.');
    const prefix = list[0].charAt(0) === '-' ? '-' : '';
    let num = prefix ? list[0].slice(1) : list[0];
    let result = '';
    while (num.length > 3) {
        result = `,${num.slice(-3)}${result}`;
        num = num.slice(0, num.length - 3);
    }
    if (num) {
        result = num + result;
    }
    return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
}

export default class NumericInput extends React.Component {//新增格式限制：正整数 is_int设置为true
    onChange = e => {
        let { value } = e.target;
        const { max_num, is_int, min_num } = this.props;
        let reg = /^-?[0-9]*(\.[0-9]*)?$/;
        if (is_int === true) {
            reg = /^[1-9]\d*$/;
        }
        if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
            if (value > max_num) {
                value = max_num.toString();
            }
            if (value < min_num) {
                value = min_num.toString();
            }
            this.props.onChange(value);
        }
    };

    // '.' at the end or only '-' in the input box.
    onBlur = () => {
        let { value, onBlur, onChange } = this.props;
        value = value ? value.toString() : '';
        let valueTemp = value;
        if (value.charAt(value.length - 1) === '.' || value === '-') {
            valueTemp = value.slice(0, -1);
        }
        onChange(valueTemp.replace(/0*(\d+)/, '$1'), "blur");
        if (onBlur) {
            onBlur(valueTemp.replace(/0*(\d+)/, '$1'));
        }
    };

    render() {
        const { value } = this.props;
        const title = value ? (
            <span className="numeric-input-title">{value !== '-' ? formatNumber(value) : '-'}</span>
        ) : (
            '请输入数字'
        );
        return (
            <Tooltip
                trigger={['focus']}
                title={title}
                placement="topLeft"
                overlayClassName="numeric-input"
            >
                <Input
                    {...this.props}
                    inputprops={undefined}
                    {...this.props.inputprops}
                    is_int={undefined}
                    onChange={this.onChange}
                    onBlur={this.props?.inputprops?.onBlur ? this.props.inputprops.onBlur : this.onBlur}
                    maxLength={25}
                />
            </Tooltip>
        );
    }
}