import React from "react";
import {
    Card,
    Table,
    Select
} from "antd";
import {Link} from "react-router-dom";

import {crmApi} from "../../../../api";
import memory from "../../../../utils/memory";
import './index.less';

const {
    Option
} = Select;
const {
    reqCustomerContacted
} = crmApi;

export default class IndustryReport extends React.Component {
    state = {
        contacted: [],
    };
    nowMonth = (new Date()).getMonth()+1;
    contactedColumns = [ // 行业触达率
        {
            title: '行业',
            dataIndex: 'tag',
            key: 'tag',
            render: (value) => {
                const obj = {
                    children: <b>{value}</b>,
                    props: {}
                };
                if(value) {
                    obj.props.rowSpan = 5;
                }else {
                    obj.props.rowSpan = 0;
                }
                return obj;
            }
        },
        {
            title: '指标',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: '2020年度',
            dataIndex: 'year',
            key: 'year'
        },
        {
            title: '1月',
            dataIndex: 'Jan',
            key: 'Jan',
            className: this.nowMonth === 1 ? 'now-month' : ''
        },
        {
            title: '2月',
            dataIndex: 'Feb',
            key: 'Feb',
            className: this.nowMonth === 2 ? 'now-month' : ''
        },
        {
            title: '3月',
            dataIndex: 'Mar',
            key: 'Mar',
            className: this.nowMonth === 3 ? 'now-month' : ''
        },
        {
            title: '4月',
            dataIndex: 'Apr',
            key: 'Apr',
            className: this.nowMonth === 4 ? 'now-month' : ''
        },
        {
            title: '5月',
            dataIndex: 'May',
            key: 'May',
            className: this.nowMonth === 5 ? 'now-month' : ''
        },
        {
            title: '6月',
            dataIndex: 'Jun',
            key: 'Jun',
            className: this.nowMonth === 6 ? 'now-month' : ''
        },
        {
            title: '7月',
            dataIndex: 'Jul',
            key: 'Jul',
            className: this.nowMonth === 7 ? 'now-month' : ''
        },
        {
            title: '8月',
            dataIndex: 'Aug',
            key: 'Aug',
            className: this.nowMonth === 8 ? 'now-month' : ''
        },
        {
            title: '9月',
            dataIndex: 'Sep',
            key: 'Sep',
            className: this.nowMonth === 9 ? 'now-month' : ''
        },
        {
            title: '10月',
            dataIndex: 'Oct',
            key: 'Oct',
            className: this.nowMonth === 10 ? 'now-month' : ''
        },
        {
            title: '11月',
            dataIndex: 'Nov',
            key: 'Nov',
            className: this.nowMonth === 11 ? 'now-month' : ''
        },
        {
            title: '12月',
            dataIndex: 'Dec',
            key: 'Dec',
            className: this.nowMonth === 12 ? 'now-month' : ''
        },
    ];

    handleSortChange = (value) => {
        this.getContacted({
            sort: value
        });
    };

    getContacted = async (params = {}) => {
        const response = await reqCustomerContacted(params);
        if(response && response.data && response.data.length) {
            const data = response.data;
            let contacted = [];
            for(let i = 0; i < data.length; i++) {
                const item = data[i];
                contacted.push({
                    tag: item.tag,
                    type: '总目标',
                    year: <Link to={`/manage/client/list/${item.tag}/1`}>{item.total}</Link>,
                    Jan: item.totals[0],
                    Feb: item.totals[1],
                    Mar: item.totals[2],
                    Apr: item.totals[3],
                    May: item.totals[4],
                    Jun: item.totals[5],
                    Jul: item.totals[6],
                    Aug: item.totals[7],
                    Sep: item.totals[8],
                    Oct: item.totals[9],
                    Nov: item.totals[10],
                    Dec: item.totals[11]
                });
                contacted.push({
                    type: '总触达',
                    year: <Link to={`/manage/client/list/${item.tag}/2`}>{item.total_contacted}</Link>,
                    Jan: item.contacted[0],
                    Feb: item.contacted[1],
                    Mar: item.contacted[2],
                    Apr: item.contacted[3],
                    May: item.contacted[4],
                    Jun: item.contacted[5],
                    Jul: item.contacted[6],
                    Aug: item.contacted[7],
                    Sep: item.contacted[8],
                    Oct: item.contacted[9],
                    Nov: item.contacted[10],
                    Dec: item.contacted[11]
                });
                contacted.push({
                    type: '覆盖率',
                    year: <Link to={`/manage/client/list/${item.tag}/2`}>{item.coverage}</Link>,
                    Jan: item.coverages[0],
                    Feb: item.coverages[1],
                    Mar: item.coverages[2],
                    Apr: item.coverages[3],
                    May: item.coverages[4],
                    Jun: item.coverages[5],
                    Jul: item.coverages[6],
                    Aug: item.coverages[7],
                    Sep: item.coverages[8],
                    Oct: item.coverages[9],
                    Nov: item.coverages[10],
                    Dec: item.coverages[11]
                });
                contacted.push({
                    type: '总转化',
                    year: <Link to={`/manage/client/list/${item.tag}/3`}>{item.total_won}</Link>,
                    Jan: item.won[0],
                    Feb: item.won[1],
                    Mar: item.won[2],
                    Apr: item.won[3],
                    May: item.won[4],
                    Jun: item.won[5],
                    Jul: item.won[6],
                    Aug: item.won[7],
                    Sep: item.won[8],
                    Oct: item.won[9],
                    Nov: item.won[10],
                    Dec: item.won[11]
                });
                contacted.push({
                    type: '转化率',
                    year: <Link to={`/manage/client/list/${item.tag}/3`}>{item.conversion_rate}</Link>,
                    Jan: item.conversion_rates[0],
                    Feb: item.conversion_rates[1],
                    Mar: item.conversion_rates[2],
                    Apr: item.conversion_rates[3],
                    May: item.conversion_rates[4],
                    Jun: item.conversion_rates[5],
                    Jul: item.conversion_rates[6],
                    Aug: item.conversion_rates[7],
                    Sep: item.conversion_rates[8],
                    Oct: item.conversion_rates[9],
                    Nov: item.conversion_rates[10],
                    Dec: item.conversion_rates[11]
                });
            }
            this.setState({
                contacted: contacted
            });
        }
    };

    componentDidMount = () => {
        this.getContacted();
    };

    render() {
        return (
            <>
                <Card
                    title="行业触达率"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                >
                    <div className="table-operations" style={{marginBottom: 24}}>
                        <Select defaultValue={1} onChange={this.handleSortChange}>
                            <Option value={0}>按总目标排序</Option>
                            <Option value={1}>按总触达排序</Option>
                            <Option value={2}>按覆盖率排序</Option>
                            <Option value={3}>按总转化排序</Option>
                            <Option value={4}>按转化率排序</Option>
                        </Select>
                    </div>
                    <Table
                        className="contacted-table"
                        columns={this.contactedColumns}
                        dataSource={this.state.contacted}
                        pagination={false}
                        bordered
                        scroll={{x: 'max-content'}} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                        size={memory.is_mobile ? 'small' : 'middle'}
                    />
                </Card>
            </>
        );
    }
}