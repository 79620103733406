import React from 'react';
import { Input, Tooltip } from 'antd';

function formatNumber(value) {
  value += '';
  const list = value.split('.');
  const prefix = list[0].charAt(0) === '-' ? '-' : '';
  let num = prefix ? list[0].slice(1) : list[0];
  let result = '';
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
}

class NumericInput extends React.Component {
  onChange = e => {
    const { value } = e.target;
    const reg = /^-?[0-9]*(\.[0-9]*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
      this.props.onChange(value);
    }
  };

  // '.' at the end or only '-' in the input box.
  onBlur = () => {
    const { value, onBlur, onChange } = this.props;
    if(value) {
      let valueTemp = value.toString();
      if (value.toString().charAt(value.length - 1) === '.' || value === '-') {
        valueTemp = value.slice(0, -1);
      }
      onChange(valueTemp.replace(/0*(\d+)/, '$1'));
    }
    if (onBlur) {
      onBlur();
    }
  };

  render() {
    const { value } = this.props;
    const title = value ? (
      <span className="numeric-input-title">{value !== '-' ? formatNumber(value) : '-'}</span>
    ) : (
      '请输入数字'
    );
    return (
      <Tooltip
        trigger={['focus']}
        title={title}
        placement="topLeft"
        overlayClassName="numeric-input"
      >
        <Input {...this.props} onChange={this.onChange} onBlur={this.onBlur} maxLength={25} />
      </Tooltip>
    );
  }
}

export default class NumberInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      value: nextProps.value
    })
  }

  onChange = value => {
    this.setState({ value });
    this.props.onChange(value);
  };

  render() {
    return <NumericInput {...this.props} value={this.state.value} onChange={this.onChange} />;
  }
}
