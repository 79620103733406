import React from "react";
import {
    Card,
    Table,
    Icon,
    Tag,
    Result
} from "antd";

import LinkButton from "../../../crm/components/link-button";
import memory from "../../../../utils/memory";
import {moaApi} from "../../../../api";
import tools from "../../../../utils/tools";
import moment from "moment";

const {
    reqTaskSelf
} = moaApi;

export default class MyTaskList extends React.Component {
    state = {
        data: [],
        loading: false,
    };
    /*columns = [
        {
            title: '重要度',
            dataIndex: 'important',
            key: 'important',
            render: (value) => {
                if(value === 0) {
                    return <Icon type="info-circle" theme="filled" style={{color: 'red', fontSize: 20}} />;
                }else if(value === 1) {
                    return <Icon type="info-circle" theme="filled" style={{color: '#ffc000', fontSize: 20}} />;
                }else {
                    return <Icon type="info-circle" theme="filled" style={{color: '#ccc', fontSize: 20}} />;
                }
            }
        },
        {
            title: '目标任务',
            dataIndex: 'content',
            key: 'content'
        },
        {
            title: '交付时间',
            dataIndex: 'deadline',
            key: 'deadline'
        },
    ];

    getTaskList = async (params = {}) => {
        this.setState({
            loading: true
        });
        const response = await reqTaskSelf(params);
        if(response && response.data) {
            const data = response.data || [];
            let newData = [];
            for(let i = 0; i < data.length; i++) {
                const item = data[i];
                newData.push({
                    key: item._id,
                    important: item.weight,
                    content: item.tcontent,
                    deadline: moment(item.end_at).isAfter(moment()) ?
                        tools.unixToTime((new Date(item.end_at)).getTime()/1000) :
                        <Tag color="#f00" style={{fontSize: 14}}>{tools.unixToTime((new Date(item.end_at)).getTime()/1000)}</Tag>,
                });
            }
            this.setState({
                loading: false,
                data: newData,
            });
        }
    };*/

    componentDidMount() {
        /*this.getTaskList({
            status: 0
        });*/
    }

    render() {
        /*return (
            <>
                <Card
                    title="我的任务"
                    bordered={false}
                    extra={
                        <LinkButton to="/moa/task/history" icon="history" txt="历史记录" />
                    }
                    style={{
                        marginBottom: 24
                    }}
                >
                    <Table
                        columns={this.columns}
                        dataSource={this.state.data}
                        pagination={false}
                        loading={this.state.loading}
                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                        size={memory.is_mobile ? 'small' : 'middle'}
                    />
                </Card>
            </>
        );*/
        return (
            <Result
                style={{marginTop:200}}
                title="功能开发中···" 
            />
        )
    }
}