import { ROLES } from "../../../utils/constant";
import {crmApi} from "../../../api";

const {reqApplyList} = crmApi;

const {
    ADMIN,
    MOAADMIN,
    CONTRACT,
    CAPITAL,
    AWARD,
    ACCOUNT,
    STAMP,
    FINAL_APPROVE,
    SCANNING,
    CONTRACTADMIN,
    MEETING,
    PACKAGE,
    SEAL_ADMIN,
    SEAL_FILE_ADMIN,
    COMMON,
    SONG_CLOCK,
    CLOCK_ADMIN,
    AWARD_USER
} = ROLES;

const menuList = (memory) => {

    const isStamp = !!memory.user.roles.find(o => o.type === ROLES.STAMP);
    const isScanning = !!memory.user.roles.find(o => o.type === ROLES.SCANNING);
    const isFinalApprove = !!memory.user.roles.find(o => o.type === ROLES.FINAL_APPROVE);

    let contractApi = [];
    let contractParams =[];
    if(isStamp){
        contractApi.push(reqApplyList);
        contractParams.push({
            type : 8,
            stage : 4,
            count_only: true,
            is_history : false,
        });
    }
    if(isScanning){
        contractApi.push(reqApplyList);
        contractParams.push({
            type : 8,
            stage : 5,
            count_only: true,
            is_history : false,
        });
        contractApi.push(reqApplyList);
        contractParams.push({
            type : 8,
            stage : 7,
            count_only: true,
            is_history : false,
        });
    }
    if(isFinalApprove){
        contractApi.push(reqApplyList);
        contractParams.push({
            type : 8,
            stage : 1,
            count_only: true,
            is_history : false,
        });
        contractApi.push(reqApplyList);
        contractParams.push({
            type : 8,
            stage : 6,
            count_only: true,
            is_history : false,
        });
    }

    return[
    {
        title: '首页',
        key: '/moa',
        icon: 'radar-chart'
    },
    {
        title: '资金管理',
        key: '/moa/capital',
        icon: 'pay-circle',
        role: [ADMIN, MOAADMIN, CAPITAL],
        children: [
            {
                title: '线索列表',
                key: '/moa/capital/thread/list',
                icon: 'table'
            },
            {
                title: '添加线索',
                key: '/moa/capital/thread/add',
                icon: 'smile',
                hide: true
            },
            {
                title: '线索详情',
                key: '/moa/capital/thread/detail/',
                icon: 'smile',
                hide: true
            },
            {
                title: '线索修改',
                key: '/moa/capital/thread/edit/',
                icon: 'smile',
                hide: true
            },
            {
                title: '资金列表',
                key: '/moa/capital/list',
                icon: 'table'
            },
            {
                title: '资金详情',
                key: '/moa/capital/detail/',
                icon: 'smile',
                hide: true
            },
            {
                title: '资金修改',
                key: '/moa/capital/edit/',
                icon: 'smile',
                hide: true
            },
            {
                title: '添加申报联络人',
                key: '/moa/capital/contacts/add',
                icon: 'smile',
                hide: true
            },
            {
                title: '申报联络人详情',
                key: '/moa/capital/contacts/detail/',
                icon: 'smile',
                hide: true
            },
            {
                title: '申报联络人修改',
                key: '/moa/capital/contacts/edit/',
                icon: 'smile',
                hide: true
            },
            {
                title: '代理机构',
                key: '/moa/capital/agency/list',
                icon: 'table'
            },
            {
                title: '添加代理机构',
                key: '/moa/capital/agency/add',
                icon: 'smile',
                hide: true
            },
            {
                title: '代理机构详情',
                key: '/moa/capital/agency/detail/',
                icon: 'smile',
                hide: true
            },
            {
                title: '代理机构修改',
                key: '/moa/capital/agency/edit/',
                icon: 'smile',
                hide: true
            },
            {
                title: '添加联系人',
                key: '/moa/capital/agency/contacts/add',
                icon: 'smile',
                hide: true
            },
            {
                title: '联系人详情',
                key: '/moa/capital/agency/contacts/detail/',
                icon: 'smile',
                hide: true
            },
            {
                title: '联系人修改',
                key: '/moa/capital/agency/contacts/edit/',
                icon: 'smile',
                hide: true
            },
            {
                title: '负责单位',
                key: '/moa/capital/response/list',
                icon: 'table'
            },
            {
                title: '添加负责单位',
                key: '/moa/capital/response/add',
                icon: 'smile',
                hide: true
            },
            {
                title: '负责单位详情',
                key: '/moa/capital/response/detail/',
                icon: 'smile',
                hide: true
            },
            {
                title: '负责单位修改',
                key: '/moa/capital/response/edit/',
                icon: 'smile',
                hide: true
            },
        ]
    },
    {
        title: '奖项管理',
        key: '/moa/awards',
        icon: 'pay-circle',
        role: [ADMIN, MOAADMIN, AWARD, AWARD_USER],
        children: [
            {
                title: '奖项列表',
                key: '/moa/awards/list',
                icon: 'table'
            },
            {
                title: '添加奖项',
                key: '/moa/awards/add',
                icon: 'smile',
                hide: true
            },
            {
                title: '奖项详情',
                key: '/moa/awards/detail/',
                icon: 'smile',
                hide: true
            },
            {
                title: '奖项修改',
                key: '/moa/awards/edit/',
                icon: 'smile',
                hide: true
            },

            {
                title: '代理机构',
                key: '/moa/awards/agency/list',
                icon: 'table'
            },
            {
                title: '添加代理机构',
                key: '/moa/awards/agency/add',
                icon: 'smile',
                hide: true
            },
            {
                title: '代理机构详情',
                key: '/moa/awards/agency/detail/',
                icon: 'smile',
                hide: true
            },
            {
                title: '代理机构修改',
                key: '/moa/awards/agency/edit/',
                icon: 'smile',
                hide: true
            },
            {
                title: '添加联系人',
                key: '/moa/awards/agency/contacts/add',
                icon: 'smile',
                hide: true
            },
            {
                title: '联系人详情',
                key: '/moa/awards/agency/contacts/detail/',
                icon: 'smile',
                hide: true
            },
            {
                title: '联系人修改',
                key: '/moa/awards/agency/contacts/edit/',
                icon: 'smile',
                hide: true
            },
            {
                title: '奖项平台',
                key: '/moa/awards/platform/list',
                icon: 'table'
            },
            {
                title: '添加奖项平台',
                key: '/moa/awards/platform/add',
                icon: 'smile',
                hide: true
            },
            {
                title: '奖项平台详情',
                key: '/moa/awards/platform/detail/',
                icon: 'smile',
                hide: true
            },
            {
                title: '奖项平台修改',
                key: '/moa/awards/platform/edit/',
                icon: 'smile',
                hide: true
            },
            {
                title: '添加联系人',
                key: '/moa/awards/platform/contacts/add',
                icon: 'smile',
                hide: true
            },
            {
                title: '联系人详情',
                key: '/moa/awards/platform/contacts/detail/',
                icon: 'smile',
                hide: true
            },
            {
                title: '联系人修改',
                key: '/moa/awards/platform/contacts/edit/',
                icon: 'smile',
                hide: true
            },
        ]
    },
    {
        title: '合同管理',
        key: '/moa/contract',
        icon: 'audit',
        role: [ADMIN, MOAADMIN, CONTRACT,STAMP,FINAL_APPROVE,SCANNING,CONTRACTADMIN,ROLES.CONTRACT_APPLY],
        children: [
            {
                title: '合同列表',
                key: '/moa/contract/list',
                icon: 'table',
                role: [ADMIN, MOAADMIN, CONTRACT,STAMP,FINAL_APPROVE,SCANNING,CONTRACTADMIN],
            },
            {
                title: '合同申请',
                key: '/moa/contract/apply/list',
                icon: 'smile',
                role: [ADMIN, MOAADMIN, CONTRACT,CONTRACTADMIN,ROLES.CONTRACT_APPLY]
            },
            {
                title: '添加合同',
                key: '/moa/contract/add',
                icon: 'smile',
                hide: true
            },
            {
                title: '合同详情',
                key: '/moa/contract/detail/',
                icon: 'smile',
                hide: true
            },
            {
                title: '合同修改',
                key: '/moa/contract/edit/',
                icon: 'smile',
                hide: true
            },
        ]
    },
    {
        title: '账户管理',
        key: '/moa/account',
        icon: 'user',
        role: [ADMIN, MOAADMIN, ACCOUNT],
        children: [
            {
                title: '账户列表',
                key: '/moa/account/list',
                icon: 'table'
            },
            {
                title: '手机号',
                key: '/moa/account/mobile/list',
                icon: 'table'
            },
        ]
    },
    {
        title: '会议管理',
        key: '/moa/meeting',
        icon: 'notification',
        role: [ADMIN, MOAADMIN, MEETING],
        children: [
            {
                title: '会议列表',
                key: '/moa/meeting/list',
                icon: 'table'
            },
            {
                title: '添加会议',
                key: '/moa/meeting/add',
                icon: 'smile',
                hide: true
            },
            {
                title: '会议详情',
                key: '/moa/meeting/detail/',
                icon: 'smile',
                hide: true
            },
            {
                title: '会议修改',
                key: '/moa/meeting/edit/',
                icon: 'smile',
                hide: true
            },
        ]
    },
    {
        title: '印章管理',
        key:'moa/seal',
        icon:'audit',
        role: [ADMIN, MOAADMIN, SEAL_FILE_ADMIN,COMMON],
        children: [
            {
                title:'上传文件列表',
                key:'/moa/seal/my/list',
                icon:'table',
                role: [ADMIN, MOAADMIN, SEAL_FILE_ADMIN, COMMON],
            },
        ]
    },
    {
        title: '我是管理',
        key: '/moa/admin',
        icon: 'desktop',
        role: [ADMIN, MOAADMIN,STAMP,FINAL_APPROVE,SCANNING,PACKAGE,SEAL_ADMIN],
        children: [
            {
                title: '点将台',
                key: '/moa/admin/ability/list',
                icon: 'radar-chart',
                role: [ADMIN, MOAADMIN],
            },
            {
                title:'待处理合同列表',
                key: '/moa/contract/pending/list',
                role: [ADMIN,MOAADMIN,STAMP,FINAL_APPROVE,SCANNING],
                count: {
                    type : "contractMoa",
                    api : contractApi,
                    params : contractParams,
                },
                icon:'table'
            },
            {
                title:'发票快递录入',
                key: '/moa/invoice/package/list',
                role: [ADMIN,MOAADMIN,PACKAGE],
                count: {
                    type : "invoicePackage",
                    api : [reqApplyList],
                    params : [{
                      type : 10,
                      stage : 5,
                      count_only: true,
                      is_history : false,
                    }],
                },
                icon:'table'
            },
            {
                title:'印章确认列表',
                key:'/moa/seal/admin/list',
                icon:'table',
                role: [ADMIN, MOAADMIN, SEAL_ADMIN],
            },
        ]
    }
]};

export default menuList;