import { reqDownloadFile } from '../api';
import { CUT_LIST, ROLES } from '../utils/constant';
import { message } from 'antd';
import memory from './memory';
import storage from './storage';
import moment from "moment";

let calcNext = (string) => {
    let k = -1;//计算word[0:j]的next值时，从哪个位置开始匹配word[j]。
    let next = [-1];
    //如果匹配失败，则content当前字符和word的哪个位置的字符匹配。
    for (let j = 1; j < string.length; j++) {
        while (k > -1 && string[k + 1] != string[j]) {
            k = next[k];
        }
        if (string[k + 1] == string[j]) {
            k = k + 1;
        }
        next[j] = k;
    }
    return next;
}

const setCookie = (cname, cvalue, exdays) => {
    let d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = `expires=${d.toGMTString()}`;
    document.cookie = `${cname}=${cvalue};${expires};path=/`;
};
const getCookie = (cname) => {
    let name = cname + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};

export default {
    setCookie,
    getCookie,
    /*
    * 时间戳转化为实践
    */
    unixToTime: (unixTime, simple = false) => {
        let timeobj = new Date(unixTime * 1000);
        let year = timeobj.getFullYear();
        let month = timeobj.getMonth() + 1;
        let date = timeobj.getDate();
        let hour = timeobj.getHours();
        let minute = timeobj.getMinutes();
        let seconds = timeobj.getSeconds();

        let timeStr = year + '/';
        timeStr = timeStr + (month < 10 ? '0' : '') + month + '/';
        if (simple) {
            timeStr = timeStr + (date < 10 ? '0' : '') + date;
        } else {
            timeStr = timeStr + (date < 10 ? '0' : '') + date + ' ';
            timeStr = timeStr + (hour < 10 ? '0' : '') + hour + ':';
            timeStr = timeStr + (minute < 10 ? '0' : '') + minute + ':';
            timeStr = timeStr + (seconds < 10 ? '0' : '') + seconds;
        }

        return timeStr;
        //let newDate = new Date();
        //newDate.setTime(unixTime * 1000);
        //return newDate.toLocaleString();
    },
    /*
    * 倒计时
    * */
    timerString: (st) => {
        let nt = Date.parse(new Date()) / 1000;
        let sub = st - nt;
        let str = "剩";
        let np = 0;

        if (sub <= 0) return '已逾期';

        if (sub >= 24 * 60 * 60) {
            let d = parseInt(sub / (24 * 60 * 60));
            str = str + d + "天";
            sub = sub - d * (24 * 60 * 60);
            np++;
        }
        if (sub >= 60 * 60) {
            let h = parseInt(sub / (60 * 60));
            str = str + h + "时";
            sub = sub - h * (60 * 60);
            np++;
        }
        if (np < 2 && sub >= 60) {
            let m = parseInt(sub / 60);
            str = str + m + "分";
            sub = sub - m * 60;
            np++;
        }
        if (np < 2) {
            str = str + sub + "秒";
            return '已逾期'
        }
        return str;
    },
    /*
    * 倒计时精确到分
    * */
    timerString1: (st) => {
        let nt = Date.parse(new Date()) / 1000;
        let sub = st - nt;
        let str = "剩";
        let np = 0;

        if (sub <= 0) return '已逾期';

        if (sub >= 24 * 60 * 60) {
            let d = parseInt(sub / (24 * 60 * 60));
            str = str + d + "天";
            sub = sub - d * (24 * 60 * 60);
            np++;
        }
        if (sub >= 60 * 60) {
            let h = parseInt(sub / (60 * 60));
            str = str + h + "时";
            sub = sub - h * (60 * 60);
            np++;
        }
        if (np < 2 && sub >= 60) {
            let m = parseInt(sub / 60);
            str = str + m + "分";
            sub = sub - m * 60;
            np++;
        }
        if (np < 2) {
            str = str + parseInt(sub) + "秒";
        }
        return str;
    },
    /*
    * 获取地址栏里的参数
    * */
    queryString: (id, url) => {//不传url获取地址栏url
        let e = {};
        let qs = '';
        if (url) {
            qs = url.split('?')[1].split('&');
        } else {
            try {
                qs = document.URL.split('?')[1].split('&');
            } catch (e) {
                return null;
            }
        }
        if (!isNaN(id)) return e[qs[id].split('=')[1]];
        for (let i = 0; i < qs.length; i++) {
            e[qs[i].split('=')[0].toLowerCase()] = qs[i].split('=')[1];
        }
        if (id) {
            return e[id.toLowerCase()];
        }
        return e;
    },
    /*
    * 数字格式化
    * */
    formatNumber: (value) => {
        value += '';
        const list = value.split('.');
        const prefix = list[0].charAt(0) === '-' ? '-' : '';
        let num = prefix ? list[0].slice(1) : list[0];
        let result = '';
        while (num.length > 3) {
            result = `,${num.slice(-3)}${result}`;
            num = num.slice(0, num.length - 3);
        }
        if (num) {
            result = num + result;
        }
        return `${prefix}${result}${list[1] ? `.${list[1]}` : ''}`;
    },
    /*
    * 取数组交集
    * */
    getIntersect: (a, b) => {
        let sa = new Set(a);
        let sb = new Set(b);
        let intersect = a.filter(x => sb.has(x));
        return intersect;
    },
    /*
    * 得到合同申请流程的下一个审批
    * */
    getNextState: (log, arr) => {
        if (log.is_examined === false) return null;
        switch (log.type) {
            case 1: return { time: log.time, type: log.type, content: '合同内容审批中...' };
            case 2: return { time: log.time, type: log.type, content: (arr ? arr.join('、') : '') + '审批中...' };
            case 3: return { time: log.time, type: log.type, content: '法务审批中...' };
            case 4: return { time: log.time, type: log.type, content: '合同下载审批中...' };
            case 5: return { time: log.time, type: log.type, content: '合同管理员上传合同盖章扫描件中...' };
            case 6: return { time: log.time, type: log.type, content: '合同管理员最终确认中...' };
            case 7: return null;
        }
        return null;

    },

    getMarketCostNextState: (log, is_examined) => {
        if (is_examined === false) return null;
        switch (log.type) {
            case 1: return { time: log.create_at, type: log.type, content: '总监 / 商务部总经理 审批中...', application: '-' };
            case 1.1: return { time: log.create_at, type: log.type, content: '商务部总经理 审批中...', application: '-' };
            case 2: return { time: log.create_at, type: log.type, content: '副总裁审批中...', application: '-' };
            case 3: return { time: log.create_at, type: log.type, content: '财务审批中...', application: '-' };
            case 4: return { time: log.create_at, type: log.type, content: '等待最终审批员最终确认...', application: '-' };
            case 5: return { time: log.create_at, type: log.type, content: '申请流程已完结', application: '-' };
        }
        return null;

    },

    //算两点之间的距离，返回距离，单位：米
    getInstance: (lng1, lat1, lng2, lat2) => {
        var EARTH_RADIUS = 6378.137;
        var radLat1 = lat1 * Math.PI / 180.0;
        var radLat2 = lat2 * Math.PI / 180.0;
        var a = radLat1 - radLat2;
        var b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
        var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2)
            + Math.cos(radLat1) * Math.cos(radLat2)
            * Math.pow(Math.sin(b / 2), 2)));
        s = s * EARTH_RADIUS;
        s = Math.round(s * 10000) / 10000;
        return s * 1000;
    },

    //算两点间的距离,大于500返回true,小于500返回false,公司位置不会有特殊处理
    checkInstance1: (lng1, lat1, lng2, lat2, ins = 500) => {
        var EARTH_RADIUS = 6378.137;
        var radLat1 = lat1 * Math.PI / 180.0;
        var radLat2 = lat2 * Math.PI / 180.0;
        var a = radLat1 - radLat2;
        var b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
        var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2)
            + Math.cos(radLat1) * Math.cos(radLat2)
            * Math.pow(Math.sin(b / 2), 2)));
        s = s * EARTH_RADIUS;
        s = Math.round(s * 10000) / 10000;
        if ((s * 1000) > ins) {
            return true
        } else {
            return false
        }
    },

    //算两点间的距离,大于500返回true,小于500返回false,在公司位置直接返回false
    checkInstance: (lng1, lat1, lng2, lat2) => {
        var EARTH_RADIUS = 6378.137;
        let mole_lng1 = 121.211675 * Math.PI / 180.0;
        let mole_lat1 = 31.047168 * Math.PI / 180.0;
        let red_lng1 = 121.360324 * Math.PI / 180.0;
        let red_lat1 = 31.124406 * Math.PI / 180.0;
        var radLat1 = lat1 * Math.PI / 180.0;
        var radLat2 = lat2 * Math.PI / 180.0;
        let pt_lng1 = 121.37268093532987 * Math.PI / 180.0;
        let pt_lat1 = 31.264744737413196 * Math.PI / 180.0;
        var a = radLat1 - radLat2;
        var b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
        var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2)
            + Math.cos(radLat1) * Math.cos(radLat2)
            * Math.pow(Math.sin(b / 2), 2)));
        s = s * EARTH_RADIUS;
        s = Math.round(s * 10000) / 10000;

        if ((Math.round((2 * Math.asin(Math.sqrt(Math.pow(Math.sin((mole_lat1 - radLat2) / 2), 2) + Math.cos(mole_lat1) * Math.cos(radLat2) * Math.pow(Math.sin((mole_lng1 - lng2 * Math.PI / 180.0) / 2), 2)))) * EARTH_RADIUS * 10000) / 10000 * 1000) < 250) {
            return true
        }

        if ((Math.round((2 * Math.asin(Math.sqrt(Math.pow(Math.sin((red_lat1 - radLat2) / 2), 2) + Math.cos(red_lat1) * Math.cos(radLat2) * Math.pow(Math.sin((red_lng1 - lng2 * Math.PI / 180.0) / 2), 2)))) * EARTH_RADIUS * 10000) / 10000 * 1000) < 250) {
            return true
        }

        if ((Math.round((2 * Math.asin(Math.sqrt(Math.pow(Math.sin((pt_lat1 - radLat2) / 2), 2) + Math.cos(pt_lat1) * Math.cos(radLat2) * Math.pow(Math.sin((pt_lng1 - lng2 * Math.PI / 180.0) / 2), 2)))) * EARTH_RADIUS * 10000) / 10000 * 1000) < 250) {
            return true;
        }

        if ((s * 1000) > 500) {
            return true
        } else {
            return false
        }
    },

    //kmp字符串匹配
    kmp: (word) => {
        let next = calcNext(word);
        return {
            match(content) {//返回单词首次匹配成功时第一个字符在content中的下标
                let i = 0;//当前匹配的content的字符的下标
                let j = 0;//当前匹配的单词的字符的下标
                while (i < content.length && j < word.length) {
                    if (j == -1 || content[i] == word[j]) {//如果匹配，则匹配下一个字符
                        i = i + 1;
                        j = j + 1;
                    } else {//如果不匹配，对word从next[j]开始匹配
                        j = next[j];
                    }
                }
                if (j == word.length) {//匹配成功
                    return true;
                }
                return false;
                //匹配失败，返回-1，表示没content中找到这个单词
            },
            next() {
                return next;
            }
        };
    },

    cut: (position) => {
        let value = position
        for (let i = 0; i < CUT_LIST.length; i++) {
            value = value.replace(CUT_LIST[i], '')
        }
        return value;
    },

    //判断是否是整数
    isNumber: (num) => {//包含字符串类型的数，如'39'也会返回true
        return num % 1 === 0
    },

    //表格列太长，给列设置最大宽度并省略多余的文字
    columnItemStyle: (width) => {
        return {
            style: {
                maxWidth: typeof width === "number" ? width : 200,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                cursor: 'pointer'
            }
        }
    },

    //算可申请的费用
    getExtractableAmount: (contract_amount, paid, marketing_cost, already, type, ratio = 1) => {
        if (type == 'all') {
            if (contract_amount <= paid) {
                return (marketing_cost);
            }
            return Math.floor(paid / contract_amount * marketing_cost * 100) / 100;
        }
        if (contract_amount <= paid) {
            return Math.floor((marketing_cost - already) * 100 * ratio) / 100;
        }
        return Math.floor((paid / contract_amount * marketing_cost - already) * 100 * ratio) / 100;
    },

    //文件下载，text是PDF文件添加的水印文字，isContract是否是合同文件，isDownload是否是下载文件否则查看文件
    downloadFile: async (history, file, text = "上海谋乐网络科技有限公司", isContract = false, isDownload = false, hasWaterMark = true) => {

        if (file.url[0] === "/") {
            file.url = file.url.replace("/", "");
        }
        const loading = message.loading("文件下载中", 0);
        let watermark;
        if (isDownload && memory.user.roles.find(o => o.type === ROLES.CONTRACT_DOWNLOAD_NO_WATERMARK)) {
            watermark = false;
        } else if (isDownload) {
            watermark = hasWaterMark;
        }
        const response = await reqDownloadFile({
            name: file.name,
            file: file.url,
            permission_type: isContract ? (isDownload ? 2 : 1) : undefined,
            watermark,
        });
        if (response?.data) {
            const blob = new Blob([response.data]);
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(blob);
            fileReader.onload = async (e) => {
                const fileName = file.name;
                if (!isDownload && [".png", ".jpg", ".jpeg"].indexOf(fileName.substring(fileName.lastIndexOf(".")).toLowerCase()) > -1) {//图片直接查看
                    const src = URL.createObjectURL(
                        new Blob([response.data], { type: 'image/png' }),
                    );
                    window.open(src);
                    loading();
                } else if (!isDownload && fileName.substring(fileName.lastIndexOf(".")).toLowerCase() === ".pdf") {//如果是查看文件并且文件类型是pdf支持预览
                    const src = URL.createObjectURL(
                        new Blob([response.data], { type: 'application/pdf' }),
                    );
                    // setCookie("pdf",src,1);
                    if (memory.is_mobile) {
                        history.push(`/pdf-view?file=${src}`);
                    } else {
                        window.open(`/pdf-view?file=${src}`, '_blank');
                    }
                    // window.open(`/pdfjs/web/viewer.html?file=${src}`, '_blank');
                    loading();
                } else if ('download' in document.createElement('a')) {
                    const link = document.createElement('a');
                    link.download = fileName;
                    link.style.display = 'none';
                    link.href = URL.createObjectURL(blob);
                    link.target = "_blank";
                    document.body.appendChild(link);
                    link.click();
                    URL.revokeObjectURL(link);
                    document.body.removeChild(link);
                    loading();
                } else {
                    navigator.msSaveBlob(blob, fileName)
                    loading();
                }
            }
        } else if (response?.response?.data) {
            loading();
            if (response.response.status === 403) {
                return ({ data: true });
            }
        }
    },

    /*
        根据成本计算毛利
        毛利=(合同金额-服务成本-额外费用-公司均摊成本-上游成本-其它成本-项目税费)*（1-报销费用比例）
        项目税费=(（0.13*产品合同金额/1.13）+（0.06*服务合同金额/1.06）- 上游税费)*1.12 < 0 ? 0 : (（0.13*产品合同金额/1.13）+（0.06*服务合同金额/1.06）- 上游税费)*1.12 + (上游成本+合同金额)*0.03/100 + 合同金额*1.6/100
        上游税费=上游成本*税点/（1+税点）
    */
    caculateGrossProfit: (data) => {
        const {
            contract_amount,//合同金额
            marketing_cost,//额外费用
            return_to_factory,//公司均摊成本
            cost_new,
            winning_at,
        } = data;
        const reimbursementRatio = winning_at && moment(winning_at).isBefore("2023-01-01") ? 0.05 : 0;//报销费用比例
        let upstreamCost = 0;//上游成本
        let serviceCost = 0;//服务成本
        let otherCost = 0;//其他成本
        let productContractAmount = 0;//产品合同金额
        let serviceContractAmount = 0;//服务合同金额
        let upstreamTax = 0;//上游税费
        let projectTax = 0;//项目税费
        let grossProfit = 0;//

        //计算产品金额、服务金额、上游成本、上游税费、服务成本、其他成本
        Object.keys(cost_new).forEach(value => {
            const item = cost_new[value]
            if (item.contract_amount != null && item.tax_type != null && item.is_examined !== false) {//没有金额、税点以及驳回的不计算
                if (item.tax_type === 6) {//6 服务，13 产品
                    serviceContractAmount += item.contract_amount;
                } else {
                    productContractAmount += item.contract_amount;
                }
                if (item.upstream?.length) {
                    const itemUpstreamCost = item.upstream.map(o => o.amount).reduce((pre, cur) => (pre + cur ?? 0), 0);
                    const itemUpstreamTax = item.upstream.map(o => o.amount * o.tax / 100 / (1 + o.tax / 100)).reduce((pre, cur) => (pre + cur ?? 0), 0);
                    upstreamCost += itemUpstreamCost;
                    upstreamTax += itemUpstreamTax;
                }
                if (item.service) {
                    const itemServiceCost = item.service.map(o => o.amount).reduce((pre, cur) => (pre + cur ?? 0), 0);
                    serviceCost += itemServiceCost;
                }
                if (item.average) {
                    const itemOtherCost = item.average.map(o => o.amount).reduce((pre, cur) => (pre + cur ?? 0), 0);
                    otherCost += itemOtherCost;
                }
            }
        });

        //计算项目税费
        if (((0.13 * productContractAmount / 1.13) + (0.06 * serviceContractAmount / 1.06) - upstreamTax) * 1.12 >= 0) {
            projectTax = ((0.13 * productContractAmount / 1.13) + (0.06 * serviceContractAmount / 1.06) - upstreamTax) * 1.12 + (upstreamCost + contract_amount) * 0.03 / 100 + contract_amount * 1.6 / 100;
        } else {
            projectTax = 0;
        }

        //计算毛利
        grossProfit = (contract_amount - serviceCost - marketing_cost - return_to_factory - upstreamCost - otherCost - projectTax) * (1 - reimbursementRatio);

        return grossProfit;
    }

};