import React from "react";
import {Link} from "react-router-dom";
import {Button} from "antd";

export default class LinkButton extends React.Component {
    render() {
        const {to, icon, txt, target} = this.props;
        return (
            <Link to={to} target={target}>
                <Button {...this.props} type="primary" icon={icon}>{txt}</Button>
            </Link>
        );
    }
}