import React from "react";
import {
    Spin,
    Form,
    Card,
    Row,
    Col,
    Input,
    Select,
    Button,
    message,
    Breadcrumb,
    Layout,
} from "antd";

import { repositoryApi } from "../../../../../api";
import {
    MODULE_TYPE,
    MenuSide
} from "../../../../../utils/constant";
import LinkButton from "../../../../crm/components/link-button";
import memory from "../../../../../utils/memory";
import UserSelector from "../../../../../components/user-selector";

const { Content } = Layout;
const { Option } = Select;

const {
    reqClassifyDetail,
    reqClassifyCreate,
    reqClassifyeEdit,
} = repositoryApi;

class ModuleAdd extends React.Component {
    static contextType = MenuSide;

    state = {
        loading: true,
        detail: {},

        department_sel: [], // 部门列表
        right_type: 1, // 1公开 2部门 3私人
    };
    id = this.props.match.params.id;

    handleTypeChange = value => {
        this.setState({
            right_type: value
        });
    };

    onFinish = async(values) => {
        let response;
        if (!this.id) {
            for (let i in values) {
                if (!values[i]) {
                    delete values[i];
                }
            }
        }
        if (this.id) {
            values.id = this.id;
            response = await reqClassifyeEdit(values);
            if (response && response.data) {
                message.success('修改成功');
                this.context();
                this.props.history.goBack();
            }
        } else {
            response = await reqClassifyCreate(values);
            if (response && response.data) {
                message.success('添加成功');
                this.context();
                this.setState({
                    right_type: 1
                });
            }
        }
    };

    getModuleDetail = async (params = {}) => {
        const response = await reqClassifyDetail(params);
        if (response && response.data) {
            this.setState({
                detail: response.data,
                right_type: response.data.type,
                loading: false
            });
        }
    };

    componentWillMount() {
        let department = [];
        for (let i = 0; i < memory.departments.length; i++) {
            const item = memory.departments[i];
            department.push({
                key: item.id,
                value: item.id,
                text: item.name
            });
        }
        this.setState({
            department_sel: department
        })
    }

    componentDidMount() {
        if (this.id) {
            // 获取详情
            this.getModuleDetail({
                id: this.id
            });
        }
    }

    render() {
        const { detail, department_sel } = this.state;

        return this.id && this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <Form onFinish={this.onFinish}>
                <Breadcrumb
                    style={{
                        padding: '24px 24px 0',
                        marginTop: '64px',
                    }}>
                    <Breadcrumb.Item>
                        首页
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Content style={{ margin: '24px 16px 0' }}>
                    <Button
                        type="link"
                        icon="left"
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                    >返回</Button>

                    <Card
                        title={
                            this.id ? "修改知识库" : "添加知识库"
                        }
                        style={{
                            marginBottom: 24,
                        }}
                        bordered={false}
                        extra={<LinkButton to="/repository/classify/add" icon="plus" txt="添加分类" />}
                    >
                        <Card
                            bordered={false}
                            type="inner"
                        >
                            <Form>
                                <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                        <Form.Item
                                            label="知识库名"
                                            name="name"
                                            initialValue={detail.name || ''}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请输入知识库名',
                                                },
                                            ]}
                                        >
                                            <Input placeholder="请输入知识库名" />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                        <Form.Item
                                            label="知识库权限"
                                            name="type"
                                            initialValue={detail.type || 1}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请选择知识库权限',
                                                },
                                            ]}
                                        >
                                            <Select placeholder="请选择知识库权限" onChange={this.handleTypeChange}>
                                                {MODULE_TYPE.map((item, index) => (
                                                    <Option key={index} value={item.key}>
                                                        {item.text}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    {
                                        this.state.right_type === 2 ? (
                                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                <Form.Item
                                                    label="授权部门"
                                                    name="department"
                                                    initialValue={detail.department || []}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '请选择要授予权限的部门',
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        mode="multiple"
                                                        placeholder="请选择要授予权限的部门"
                                                    >
                                                        {department_sel.map((item, index) => (
                                                            <Option key={index} value={item.key}>
                                                                {item.text}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        ) : this.state.right_type === 3 ? (
                                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                                <Form.Item
                                                    label="授权用户"
                                                    name="users"
                                                    initialValue={detail.users || []}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: '请选择要授权的用户',
                                                        },
                                                    ]}
                                                >
                                                    <UserSelector
                                                        multiple
                                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                        placeholder="请选择要授权的用户"
                                                        type={2}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        ) : null
                                    }

                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                        <Form.Item
                                            label="备注"
                                            name="desc"
                                            initialValue={detail.desc || ''}
                                        >
                                            <Input placeholder="备注" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Card>

                    <div className="submit-footer">
                        <Button type="primary" htmlType="submit">
                            {
                                this.id ? '保存' : '添加'
                            }
                        </Button>
                    </div>
                </Content>
            </Form>
        );
    }
}

export default ModuleAdd;