import React from "react";
import {
    Card,
    Table,
    Input,
    Icon,
    Button,
} from "antd";
import {
    Link,
} from "react-router-dom";
import memory from "../../../../../../utils/memory";
import { crmApi } from "../../../../../../api";

const {
    reqApplyOtherList,
    reqApplyProductList,
    reqApplyServiceList,
    reqApplyOperateList,
    reqApplyBusinessList,
    reqApplyProduct1List
} = crmApi;

export default class DepartmentApproveHistory extends React.Component {
    state = {
        data: [],
        pagination: {},
        loading: false
    };

    type = this.props.location.pathname.replace("/train/approve/department/history/", "");

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        });
        let tmpFilters = { ...filters };
        for (let item in tmpFilters) {
            if (tmpFilters[item]) {
                if (typeof (tmpFilters[item]) !== 'string' && item !== 'tags') {
                    tmpFilters[item] = tmpFilters[item][0];
                }
            }
        }
        let params = {
            page: pagination.current,
            ...tmpFilters,
            is_history: true
        };
        this.getApplyList(params);
    }

    /*
    *搜索相关
    * */
    getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`搜索 ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    搜索
                </Button>
                <Button onClick={() => this.handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    重置
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => text
    });
    handleSearch = (selectedKeys, confirm) => {
        confirm();
    };
    handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
    };

    columns = [
        {
            title: '项目名称',
            dataIndex: 'project_name',
            key: 'project_name'
        },
        {
            title: '所属销售',
            dataIndex: 'seller',
            key: 'seller',
        },
        {
            title: '审批进度',
            dataIndex: 'is_examined',
            key: 'is_examined',
            render: (text, record) => {
                return text ? "已审批" : "待审批"
            },
        },
        {
            title: '操作',
            dataIndex: 'option',
            valueType: 'option',
            render: (_, record, index) => {
                return <Link to={`/train/approve/department/detail/${this.type}/${record.key}`}>详情</Link>
            }
        },
    ];

    getApplyList = async (params = {}) => {
        this.setState({
            loading: true
        });
        let response;
        switch (this.type) {
            case "other": response = await reqApplyOtherList(params); break;
            case "product": response = await reqApplyProductList(params); break;
            case "service": response = await reqApplyServiceList(params); break;
            case "operate": response = await reqApplyOperateList(params); break;
            case "business": response = await reqApplyBusinessList(params); break;
            case "product1": response = await reqApplyProduct1List(params); break;
        }
        if (response?.data) {
            const pagination = { ...this.state.pagination };
            const data = response.data;
            pagination.total = data.page.total;
            pagination.pageSize = data.page.node;
            let newData = [];
            for (let i = 0; i < data.data.length; i++) {
                const item = data.data[i];
                let obj = {
                    key: item._id,
                    is_examined: !!item.is_examined,
                    is_history: item.is_history,
                    project_name: item?.data?.business_id ? <a href={`/crm/client/business-detail/${item.data.business_id}`} target="blank" >{item?.data?.project_name || "-"}</a> : "-",
                    seller: item?.data?.creator?.name,
                };
                newData.push(obj);
            }
            this.setState({
                data: newData,
                pagination
            })
        }
        this.setState({
            loading: false,
        });
    };

    componentDidMount() {
        this.getApplyList({
            page: 1,
            is_history: true
        });
    }

    render() {
        return (
            <>
                <Button
                    type="link"
                    icon="left"
                    onClick={() => {
                        this.props.history.goBack();
                    }}
                >返回</Button>

                <Card
                    title="历史审批列表"
                    bordered={false}
                >
                    <Table
                        columns={this.columns}
                        dataSource={this.state.data}
                        pagination={this.state.pagination}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                        size={memory.is_mobile ? 'small' : 'middle'}
                    />
                </Card>
            </>
        );
    }
}