import React from "react";
import {
    Spin,
    Card,
    Descriptions,
    Button,
} from "antd";

import {
    CONTRACT_HEAD_TYPE_ALL_OBJ,
    ROLES,
    CONTRACT_SUB_TYPE_OBJ,
} from "../../../../../utils/constant";
import { crmApi } from "../../../../../api";
import tools from "../../../../../utils/tools";
import memory from "../../../../../utils/memory";
import LinkButton from "../../../../../components/link-button";
import DownloadLink from "../../../../../components/download-link";

const {
    reqContractDetail,
    reqVendorDetail,
} = crmApi;
const {
    MOAADMIN,
    CONTRACTADMIN
} = ROLES;

export default class ContractDetail extends React.Component {
    state = {
        loading: true,
        detail: {},
        vendorDetail: {},
    };
    id = this.props.match.params.id;

    getContractDetail = async (params = {}) => {
        const response = await reqContractDetail(params);
        if (response && response.data) {
            if (response.data?.vendor) {
                this.getVendorDetail({
                    id: response.data.vendor,
                })
            }
            this.setState({
                detail: response.data,
                loading: false
            });
        }
    };

    getVendorDetail = async (params = {}) => {
        const response = await reqVendorDetail(params);
        if (response?.data) {
            this.setState({
                vendorDetail: response.data.msg,
            })
        }
    }

    componentDidMount() {
        // 获取详情
        this.getContractDetail({
            id: this.id
        });
    }

    render() {
        const { detail, vendorDetail } = this.state;
        let files = [];
        if (detail.moa_contract_file && detail.moa_contract_file.length && detail.moa_contract_file[0].name) {
            files = detail.moa_contract_file;
        } else if (detail.contract_file && detail.contract_file.length && detail.contract_file[0].name) {
            files = detail.contract_file;
        }

        return this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <>
                <Card
                    title="合同信息"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                    extra={detail.creator.id === memory.user._id ||
                        memory.user.role_type.indexOf(MOAADMIN) > -1 ||
                        memory.user.role_type.indexOf(CONTRACTADMIN) > -1 ? (
                        <LinkButton to={`/moa/contract/edit/${this.id}`} txt="编辑" />
                    ) : null}
                >
                    <Descriptions
                        title="基本信息"
                    >
                        <Descriptions.Item label="合同名称">
                            {detail.contract_name || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="合同类型">
                            {detail.contract_type ? CONTRACT_HEAD_TYPE_ALL_OBJ[detail.contract_type] : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="合同性质">
                            {detail.contract_stype && CONTRACT_SUB_TYPE_OBJ[detail.contract_stype] ? CONTRACT_SUB_TYPE_OBJ[detail.contract_stype].text : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="合同编号">
                            {detail.contract_number || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="合同签署日期">
                            {detail.sign_date ? tools.unixToTime((new Date(detail.sign_date)) / 1000, true) : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="合同金额">
                            {(detail.contract_amount || 0) + '元'}
                        </Descriptions.Item>
                        <Descriptions.Item label="客户名称">
                            {detail.customer_name || '-'}
                        </Descriptions.Item>
                        {
                            detail.business ?
                                <Descriptions.item label='项目名称'>
                                    <a onClick={() => this.props.history.push(`/moa/business/detail/${detail.business}`)}>{detail.business_name}</a>
                                </Descriptions.item>
                                :
                                null
                        }
                        <Descriptions.Item label="纸质合同份数">
                            {detail.paper_contract_number ? `${detail.paper_contract_number}份` : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="纸质合同存放位置">
                            {detail.paper_contract_position || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="起止日期">
                            {detail.contract_startdate && detail.contract_enddate ?
                                (`${tools.unixToTime((new Date(detail.contract_startdate)) / 1000, true)} - ${tools.unixToTime((new Date(detail.contract_enddate)) / 1000, true)}`) : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="备注">
                            {detail.remarks || '-'}
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions
                        style={{
                            marginBottom: 24,
                        }}
                        column={1}
                    >
                        <Descriptions.Item label="合同说明">
                            <div style={{ whiteSpace: 'pre-wrap' }}>
                                {detail.contract_survey || '-'}
                            </div>
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        title="创建信息"
                        style={{
                            marginBottom: 24,
                        }}
                    >
                        <Descriptions.Item label="销售">
                            {detail.seller && detail.seller.name ? detail.seller.name : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="添加人">
                            {detail.creator && detail.creator.name ? detail.creator.name : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="创建时间">
                            {detail.create_at
                                ? tools.unixToTime(new Date(detail.create_at).getTime() / 1000)
                                : '-'}
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        title="供应商信息"
                        style={{
                            marginBottom: 24,
                        }}
                    >
                        {
                            detail?.vendor && vendorDetail._id ?
                                <>
                                    <Descriptions.Item label={"名称"} ><a href={`/crm/finance/vendor/detail/${vendorDetail._id}`} target="_blank">{vendorDetail.name || "-"}</a></Descriptions.Item>
                                    <Descriptions.Item label={"社会信用码"} >{vendorDetail.social_credit_code || "-"}</Descriptions.Item>
                                    <Descriptions.Item label={"地址"} >{vendorDetail.address || "-"}</Descriptions.Item>
                                    <Descriptions.Item label={"电话"} >{vendorDetail.telephone || "-"}</Descriptions.Item>
                                    <Descriptions.Item label={"开户银行"} >{vendorDetail.bank || "-"}</Descriptions.Item>
                                    <Descriptions.Item label={"开户银行账号"} >{vendorDetail.bank_account || "-"}</Descriptions.Item>
                                </>
                                :
                                <Descriptions.Item>无</Descriptions.Item>
                        }
                    </Descriptions>

                    {(detail.moa_contract_file_single && detail.moa_contract_file_single.length) || (detail.moa_contract_file_double && detail.moa_contract_file_double.length) || !files.length ?
                        <>
                            <DownloadLink title={'合同单章文件'} file={detail.moa_contract_file_single} isContract={true} contract={this.id} />
                            <DownloadLink title={'合同双章文件'} file={detail.moa_contract_file_double} isContract={true} contract={this.id} />
                        </>
                        :
                        <DownloadLink title={'合同扫描文件'} file={files} isContract={true} contract={this.id} />
                    }
                </Card>
            </>
        );
    }
}