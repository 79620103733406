import React from "react";
import {
    Spin,
    Card,
    Descriptions,
    Button,
} from "antd";

import {moaApi} from "../../../../../../api";
import LinkButton from "../../../../../crm/components/link-button";
import tools from "../../../../../../utils/tools";

const {
    reqResponseDetail,
} = moaApi;

export default class ResponseDetail extends React.Component {
    state = {
        loading: true,
        detail: {},
    };
    id = this.props.match.params.id;

    getResponseDetail = async (params = {}) => {
        const response = await reqResponseDetail(params);
        if(response && response.data) {
            this.setState({
                detail: response.data,
                loading: false
            });
        }
    };

    componentDidMount() {
        // 获取详情
        this.getResponseDetail({
            id: this.id
        });
    }

    render() {
        const {detail} = this.state;

        return this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <>
                <Button
                    type="link"
                    icon="left"
                    onClick={() => {
                        this.props.history.goBack();
                    }}
                >返回</Button>

                <Card
                    title="负责单位信息"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                    extra={<LinkButton to={`/moa/capital/response/edit/${this.id}`} txt="编辑" />}
                >
                    <Descriptions
                        style={{
                            marginBottom: 24,
                        }}
                        title="基本信息"
                    >
                        <Descriptions.Item label="名称">
                            {detail.name || '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="官网">
                            {
                                detail.official_website ?
                                    <a href={detail.official_website} target='_blank'>{detail.official_website}</a> : '-'
                            }
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        style={{
                            marginBottom: 24,
                        }}
                        column={1}
                    >
                        <Descriptions.Item label="简介">
                            <div style={{whiteSpace: 'pre-wrap'}}>
                                {detail.brief || '无'}
                            </div>
                        </Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        title="创建信息"
                        style={{
                            marginBottom: 24,
                        }}
                    >
                        <Descriptions.Item label="创建人">
                            {detail.creator && detail.creator.name ? detail.creator.name : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="创建时间">
                            {detail.create_at
                                ? tools.unixToTime(new Date(detail.create_at).getTime() / 1000)
                                : '-'}
                        </Descriptions.Item>
                    </Descriptions>
                </Card>
            </>
        );
    }
}