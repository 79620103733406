/*
* 能发送异步ajax请求的函数模块
* 封装axios库
* 函数的返回值是promise对象
* 统一处理请求异常
*   在外层包一个自己创建的Promise对象
* */

import axios from 'axios';
import { message } from "antd";

import storage from "../utils/storage";
import  {AUTH } from "../utils/constant";

export default function ajax(url, data = {}, type = 'GET', config = {}, throwError = false) {//config是额外配置，导出文件设置相应数据格式, throwError是否resolve错误
    return new Promise((resolve, reject) => {
        let promise;
        let headers = {};
        const auth = storage.getStore(AUTH);
        if(auth) {
            headers = {
                authorization: 'Bearer ' + auth
            };
        }
        // 1. 执行异步ajax请求
        if(type === 'GET') {
            promise = axios.get(url, {
                params: data,
                headers: headers,
                ...config,
            });
        }else if(type === 'POST') {
            promise = axios.post(url, data, {
                headers: headers,
                ...config,
            });
        }else if(type === 'DELETE') {
            promise = axios.delete(url, {
                params: data,
                headers: headers,
                ...config,
            });
        }else if(type === 'PATCH') {
            promise = axios.patch(url, data, {
                headers: headers,
                ...config,
            });
        }
        // 2. 如果成功了，调用resolve(value)
        promise.then(response => {
            resolve(response);
        }).catch(error => {
            // 3. 如果失败了，不调用reject(reason),而是提示异常信息
            console.log('error.response:', error.response);
            if(throwError){
                resolve(error);
            }else{
                if(error.response && error.response.data) {
                    if(error.response.status === 401 || error.response.config.url === '/api/user/self') {
                        message.warning('身份信息过期，请重新登陆');
                        window.location.href = '/';
                        resolve('');
                    }else {
                        if(error.response.data.err_msg || error.response.data.msg) {
                            message.error(error.response.data.err_msg || error.response.data.msg);
                        }else {
                            message.error(error.response.data);
                        }
                        resolve();
                    }
                }else {
                    message.error('请求出错了');
                }
            }
        });
    });
}