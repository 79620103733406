import React from "react";
import {
    Card,
    Table,
    Select,
    Tooltip
} from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';

import { crmApi } from "../../../../api";
import memory from "../../../../utils/memory";
import './index.less';
import tools from "../../../../utils/tools";

const {
    Option
} = Select;
const {
    reqManageSeller
} = crmApi;

export default class SellerReport extends React.Component {
    state = {
        sellers: [],
        loading: true,
    };
    nowMonth = (new Date()).getMonth() + 1;
    nowYear = (new Date()).getFullYear();
    sort = '';
    sortYear = '';
    sellersColumns = [ // 行业触达率
        {
            title: '名称',
            dataIndex: 'tag',
            key: 'tag',
            render: (value) => {
                const obj = {
                    children: <b>{value}</b>,
                    props: {}
                };
                if (value) {
                    obj.props.rowSpan = 3;
                } else {
                    obj.props.rowSpan = 0;
                }
                return obj;
            }
        },
        {
            title: <span>指标 <Tooltip title="合同金额(上传)以合同上传时间为准，合同金额(赢单)以合同赢单时间为准"><QuestionCircleOutlined style={{ fontSize: 13.5, color: 'gray' }} /></Tooltip></span>,
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: () => {
                return (
                    (this.sortYear ? this.sortYear : this.nowYear) + '年度'
                )
            },
            dataIndex: 'year',
            key: 'year',
            render: (value) => {
                if (value) {
                    return tools.formatNumber(value.toFixed(2));
                } else {
                    return value;
                }
            },
        },
        {
            title: '1月',
            dataIndex: 'Jan',
            key: 'Jan',
            render: (value) => {
                if (value) {
                    return tools.formatNumber(value.toFixed(2));
                } else {
                    return value;
                }
            },
            onCell: () => {
                return {
                    className: this.nowMonth === 1 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            },
            onHeaderCell: () => {
                return {
                    className: this.nowMonth === 1 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            }
        },
        {
            title: '2月',
            dataIndex: 'Feb',
            key: 'Feb',
            render: (value) => {
                if (value) {
                    return tools.formatNumber(value.toFixed(2));
                } else {
                    return value;
                }
            },
            onCell: () => {
                return {
                    className: this.nowMonth === 2 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            },
            onHeaderCell: () => {
                return {
                    className: this.nowMonth === 2 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            }
        },
        {
            title: '3月',
            dataIndex: 'Mar',
            key: 'Mar',
            render: (value) => {
                if (value) {
                    return tools.formatNumber(value.toFixed(2));
                } else {
                    return value;
                }
            },
            onCell: () => {
                return {
                    className: this.nowMonth === 3 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            },
            onHeaderCell: () => {
                return {
                    className: this.nowMonth === 3 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            }
        },
        {
            title: '4月',
            dataIndex: 'Apr',
            key: 'Apr',
            render: (value) => {
                if (value) {
                    return tools.formatNumber(value.toFixed(2));
                } else {
                    return value;
                }
            },
            onCell: () => {
                return {
                    className: this.nowMonth === 4 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            },
            onHeaderCell: () => {
                return {
                    className: this.nowMonth === 4 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            }
        },
        {
            title: '5月',
            dataIndex: 'May',
            key: 'May',
            render: (value) => {
                if (value) {
                    return tools.formatNumber(value.toFixed(2));
                } else {
                    return value;
                }
            },
            onCell: () => {
                return {
                    className: this.nowMonth === 5 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            },
            onHeaderCell: () => {
                return {
                    className: this.nowMonth === 5 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            }
        },
        {
            title: '6月',
            dataIndex: 'Jun',
            key: 'Jun',
            render: (value) => {
                if (value) {
                    return tools.formatNumber(value.toFixed(2));
                } else {
                    return value;
                }
            },
            onCell: () => {
                return {
                    className: this.nowMonth === 6 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            },
            onHeaderCell: () => {
                return {
                    className: this.nowMonth === 6 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            }
        },
        {
            title: '7月',
            dataIndex: 'Jul',
            key: 'Jul',
            render: (value) => {
                if (value) {
                    return tools.formatNumber(value.toFixed(2));
                } else {
                    return value;
                }
            },
            onCell: () => {
                return {
                    className: this.nowMonth === 7 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            },
            onHeaderCell: () => {
                return {
                    className: this.nowMonth === 7 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            }
        },
        {
            title: '8月',
            dataIndex: 'Aug',
            key: 'Aug',
            render: (value) => {
                if (value) {
                    return tools.formatNumber(value.toFixed(2));
                } else {
                    return value;
                }
            },
            onCell: () => {
                return {
                    className: this.nowMonth === 8 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            },
            onHeaderCell: () => {
                return {
                    className: this.nowMonth === 8 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            }
        },
        {
            title: '9月',
            dataIndex: 'Sep',
            key: 'Sep',
            render: (value) => {
                if (value) {
                    return tools.formatNumber(value.toFixed(2));
                } else {
                    return value;
                }
            },
            onCell: () => {
                return {
                    className: this.nowMonth === 9 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            },
            onHeaderCell: () => {
                return {
                    className: this.nowMonth === 9 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            }
        },
        {
            title: '10月',
            dataIndex: 'Oct',
            key: 'Oct',
            render: (value) => {
                if (value) {
                    return tools.formatNumber(value.toFixed(2));
                } else {
                    return value;
                }
            },
            onCell: () => {
                return {
                    className: this.nowMonth === 10 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            },
            onHeaderCell: () => {
                return {
                    className: this.nowMonth === 10 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            }
        },
        {
            title: '11月',
            dataIndex: 'Nov',
            key: 'Nov',
            render: (value) => {
                if (value) {
                    return tools.formatNumber(value.toFixed(2));
                } else {
                    return value;
                }
            },
            onCell: () => {
                return {
                    className: this.nowMonth === 11 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            },
            onHeaderCell: () => {
                return {
                    className: this.nowMonth === 11 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            }
        },
        {
            title: '12月',
            dataIndex: 'Dec',
            key: 'Dec',
            render: (value) => {
                if (value) {
                    return tools.formatNumber(value.toFixed(2));
                } else {
                    return value;
                }
            },
            onCell: () => {
                return {
                    className: this.nowMonth === 12 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            },
            onHeaderCell: () => {
                return {
                    className: this.nowMonth === 12 && (!this.sortYear || this.sortYear == this.nowYear) ? 'now-month' : ''
                }
            }
        },
    ];

    handleSortChange = (value) => {
        this.sort = value;
        let params = {
            sort: value
        };
        if (this.sortYear !== '') {
            params['year'] = this.sortYear;
        }
        this.getSellers(params);
    };
    handleYearChange = (value) => {
        this.sortYear = value;
        let params = {
            year: value
        };
        if (this.sort !== '') {
            params['sort'] = this.sort;
        }
        this.getSellers(params);
    };

    getSellers = async (params = {}) => {
        this.setState({ loading: true })
        const response = await reqManageSeller(params);
        if (response && response.data && response.data.length) {
            const data = response.data;
            let sellers = [];
            let company_up = {
                tag: '全公司',
                type: '合同金额(上传)',
                year: 0,
                Jan: 0,
                Feb: 0,
                Mar: 0,
                Apr: 0,
                May: 0,
                Jun: 0,
                Jul: 0,
                Aug: 0,
                Sep: 0,
                Oct: 0,
                Nov: 0,
                Dec: 0
            };
            let company_win = {
                type: '合同金额(赢单)',
                year: 0,
                Jan: 0,
                Feb: 0,
                Mar: 0,
                Apr: 0,
                May: 0,
                Jun: 0,
                Jul: 0,
                Aug: 0,
                Sep: 0,
                Oct: 0,
                Nov: 0,
                Dec: 0
            };
            let profit = {
                type: '毛利',
                year: 0,
                Jan: 0,
                Feb: 0,
                Mar: 0,
                Apr: 0,
                May: 0,
                Jun: 0,
                Jul: 0,
                Aug: 0,
                Sep: 0,
                Oct: 0,
                Nov: 0,
                Dec: 0
            };
            for (let i = 0; i < data.length; i++) {
                const item = data[i];
                sellers.push({
                    tag: item.name,
                    type: '合同金额(上传)',
                    year: item.total_total_gold,
                    Jan: item.total_gold[0],
                    Feb: item.total_gold[1],
                    Mar: item.total_gold[2],
                    Apr: item.total_gold[3],
                    May: item.total_gold[4],
                    Jun: item.total_gold[5],
                    Jul: item.total_gold[6],
                    Aug: item.total_gold[7],
                    Sep: item.total_gold[8],
                    Oct: item.total_gold[9],
                    Nov: item.total_gold[10],
                    Dec: item.total_gold[11]
                });
                company_up.year += item.total_total_gold;
                company_up.Jan += item.total_gold[0];
                company_up.Feb += item.total_gold[1];
                company_up.Mar += item.total_gold[2];
                company_up.Apr += item.total_gold[3];
                company_up.May += item.total_gold[4];
                company_up.Jun += item.total_gold[5];
                company_up.Jul += item.total_gold[6];
                company_up.Aug += item.total_gold[7];
                company_up.Sep += item.total_gold[8];
                company_up.Oct += item.total_gold[9];
                company_up.Nov += item.total_gold[10];
                company_up.Dec += item.total_gold[11];
                sellers.push({
                    type: '合同金额(赢单)',
                    year: item.total_contract_amount,
                    Jan: item.contract_amounts[0],
                    Feb: item.contract_amounts[1],
                    Mar: item.contract_amounts[2],
                    Apr: item.contract_amounts[3],
                    May: item.contract_amounts[4],
                    Jun: item.contract_amounts[5],
                    Jul: item.contract_amounts[6],
                    Aug: item.contract_amounts[7],
                    Sep: item.contract_amounts[8],
                    Oct: item.contract_amounts[9],
                    Nov: item.contract_amounts[10],
                    Dec: item.contract_amounts[11]
                });
                company_win.year += item.total_contract_amount;
                company_win.Jan += item.contract_amounts[0];
                company_win.Feb += item.contract_amounts[1];
                company_win.Mar += item.contract_amounts[2];
                company_win.Apr += item.contract_amounts[3];
                company_win.May += item.contract_amounts[4];
                company_win.Jun += item.contract_amounts[5];
                company_win.Jul += item.contract_amounts[6];
                company_win.Aug += item.contract_amounts[7];
                company_win.Sep += item.contract_amounts[8];
                company_win.Oct += item.contract_amounts[9];
                company_win.Nov += item.contract_amounts[10];
                company_win.Dec += item.contract_amounts[11];
                sellers.push({
                    type: '毛利',
                    year: item.total_gross_profit,
                    Jan: item.gross_profits[0],
                    Feb: item.gross_profits[1],
                    Mar: item.gross_profits[2],
                    Apr: item.gross_profits[3],
                    May: item.gross_profits[4],
                    Jun: item.gross_profits[5],
                    Jul: item.gross_profits[6],
                    Aug: item.gross_profits[7],
                    Sep: item.gross_profits[8],
                    Oct: item.gross_profits[9],
                    Nov: item.gross_profits[10],
                    Dec: item.gross_profits[11]
                });
                profit.year += item.total_gross_profit;
                profit.Jan += item.gross_profits[0];
                profit.Feb += item.gross_profits[1];
                profit.Mar += item.gross_profits[2];
                profit.Apr += item.gross_profits[3];
                profit.May += item.gross_profits[4];
                profit.Jun += item.gross_profits[5];
                profit.Jul += item.gross_profits[6];
                profit.Aug += item.gross_profits[7];
                profit.Sep += item.gross_profits[8];
                profit.Oct += item.gross_profits[9];
                profit.Nov += item.gross_profits[10];
                profit.Dec += item.gross_profits[11];
            }
            sellers.unshift(profit);
            sellers.unshift(company_win);
            sellers.unshift(company_up);
            this.setState({
                sellers,
                loading: false,
            });
        }
    };

    componentDidMount = () => {
        this.getSellers();
    };

    render() {
        const nowYear = (new Date()).getFullYear();
        let years = [];
        for(let i = 0; i <=3; i++) {
            years.push(nowYear-i);
        }
        return (
            <>
                <Card
                    title="销售业绩管理"
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                >
                    <div className="table-operations" style={{ marginBottom: 24 }}>
                        <Select defaultValue={1} onChange={this.handleSortChange} style={{ marginRight: 12 }}>
                            <Option value={0}>按合同金额排序</Option>
                            <Option value={1}>按毛利排序</Option>
                        </Select>
                        <Select defaultValue={nowYear} onChange={this.handleYearChange}>
                            {
                                years.map(year => <Option value={year} key={year}>{year}</Option>)
                            }
                        </Select>
                    </div>
                    <Table
                        className="seller-table"
                        columns={this.sellersColumns}
                        dataSource={this.state.sellers}
                        pagination={false}
                        loading={this.state.loading}
                        bordered
                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                        size={memory.is_mobile ? 'small' : 'middle'}
                    />
                </Card>
            </>
        );
    }
}