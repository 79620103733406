import { Card, Col, List, Pagination, Row, Icon, Progress } from "antd";
import React from "react";
import moment from "moment";
import {BUSINESS_TO_PIPELINE, DELIVERY_PROGRESS_OBJ} from '../../../../../utils/constant'
import tools from "../../../../../utils/tools";
import "./index.less"
import { crmApi } from "../../../../../api";

const {reqProgressProductList,reqProgresssServiceList,reqProgressOperateList,reqProgressAdminList,reqProgressOtherList,reqWeaponList,reqProgressBusinessList} = crmApi;

const dataSource = [
    {
        business:"测试",
        "creator":"钟彪",
        weapon:"一云,漏洞之眼",
        create_at:moment(),
        step:4,
    },
    {
        business:"测试",
        "creator":"钟彪",
        weapon:"一云,漏洞之眼",
        create_at:moment(),
        step:5,
    },
    {
        business:"测试",
        "creator":"钟彪",
        weapon:"一云,漏洞之眼",
        create_at:moment(),
        step:8,
    },
    {
        business:"测试",
        "creator":"钟彪",
        weapon:"一云,漏洞之眼",
        create_at:moment(),
        step:9,
    },
    {
        business:"测试",
        "creator":"钟彪",
        weapon:"一云,漏洞之眼",
        create_at:moment(),
        step:9,
    },
]

export default class ProjectProgress extends React.Component{

    state = {
        dataSource : [],
        pagination : {},
        loading : false,
    }

    type = this.props.location.pathname.replace("/train/project/","");
    weaponListObj = {};

    getListItem = (item) => {
        return (
            <List.Item 
                key={item.key}
                /*actions={[
                    <Icon type={"star-o"} style={{ marginRight: 8 }} />,
                    <Icon type={"like-o"} style={{ marginRight: 8 }} />,
                ]}*/
            >
                <List.Item.Meta
                    title={<span className="font-weight-bolder font-size-16">{item.business}</span>}
                    description={item.weapon}
                    style={{
                        paddingLeft: 8
                    }}
                />
                <div className="list-content">
                    <div className="p-right-interval">
                        <div>
                            创建人
                        </div>
                        <div>
                            {item.creator}
                        </div>
                    </div>
                    <div className="p-right-interval">
                        <div>
                            创建时间
                        </div>
                        <div>
                            {moment(item.create_at).format("YYYY/MM/DD HH:mm:ss")}
                        </div>
                    </div>
                    <div style={{width:300}} className="p-right-interval ">
                        <Progress type="line" percent={DELIVERY_PROGRESS_OBJ[item.step]} size="small" />
                    </div>
                </div>
            </List.Item>
        )
    }

    getWeaponList = async(params,cb) => {
        const response = await reqWeaponList(params);
        if(response && response.data){
            response.data.data.map((value,index)=>{
                this.weaponListObj[value._id]=value.productname;
            })
            cb({
                id:this.id
            })
        }
    }

    handlePagination = (page) => {
        if(this.state.loading == true) return;
        this.getList({page});
    }

    getList = async(params = {}) => {
        this.setState({
            loading : true,
        })
        params.node = 10;
        let response;
        switch(this.type){
            case "admin" : response = await reqProgressAdminList(params);break;
            case "product" : response = await reqProgressProductList(params);break;
            case "service" : response = await reqProgresssServiceList(params) ;break;
            case "operate" : response = await reqProgressOperateList(params);break;
            case "other" : response = await reqProgressOtherList(params);break;
            case "business" : response = await reqProgressBusinessList(params);break;
        }
        if(response?.data){
            let arr = [],pagination = {};
            response.data.data.map((value,index) => {
                let item = {
                    business : value.project_name,
                    creator : value?.creator?.name || "-",
                    create_at : value?.create_at,
                    step : value?.stage ? BUSINESS_TO_PIPELINE[value.stage] : null,
                    weapon : value?.business_type?.length ? value.business_type.map(value => this.weaponListObj[value]).join(',') : '-',
                    key : value._id,
                }
                arr.push(item);
            })
            pagination = {
                current: response.data.page.current,
                pageSize: response.data.page.node,
                total: response.data.page.total,
            }
            this.setState({
                pagination,
                dataSource : arr,
                loading : false,
            })
        }
        this.setState({
            loading : false
        })
    }

    componentDidMount(){
        this.getWeaponList({
            node:9999
        },()=>{
            this.getList({page : 1});
        });
    }

    render(){
        return(
            <Card
                title="项目进度"
                bordered={false}
                style={{marginTop : 16}}
            >
                <List
                    itemLayout="horizontal"
                    dataSource={this.state.dataSource}
                    renderItem={this.getListItem}
                    loading = {this.state.loading}
                >
               </List>
                <div className="text-align-right">
                    <Pagination {...this.state.pagination} onChange={this.handlePagination} />
                </div>
            </Card>
        )
    }
}