import React from "react";
import {
    Card,
    Form,
    Row,
    Col,
    Input,
    Button,
    message,
    Upload,
    DatePicker,
    Select,
    Tooltip,
    Modal,
} from "antd";
import moment from "moment";
import { QuestionCircleOutlined, UploadOutlined } from '@ant-design/icons';

import {
    AUTH,
    CONTRACT_SUB_TYPE_OBJ,
    CONTRACT_HEAD_TYPE_MOA,
    ROLES,
    CONTRACT_STANDARD,
    PROCUREMENT_CONTRACT
} from "../../../../../utils/constant";
import { crmApi } from "../../../../../api";
import storage from "../../../../../utils/storage";
import NumberInput from "../../../../crm/components/number-input";
import memory from "../../../../../utils/memory";
import Remarks from "../../../../../components/remarks";
import tools from "../../../../../utils/tools";

const {
    Option
} = Select;
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const {
    reqBusinessDetail,
    reqContractApplyEdit,
    reqContractApply,
    reqContractNumber,
    reqApplyDetail,
    reqVendorList,
} = crmApi;
const {
    PROJECT_CONSTRACT
} = ROLES

class ContractApply extends React.Component {
    state = {
        detail: {},
        fileList: [],
        fileLoading: false,
        submitLoading: false,
        auto_create: false, // 是否点击了自动生成按钮
        contract_type: undefined,
        vendorList: [],
        vendorDetail: {},
    };
    formRef = React.createRef();
    bid = this.props.match.params.bid; //项目id
    id = this.props.match.params.id; //合同id
    cid = ''; //客户id
    CONTRACT_SUB_TYPE_ARR = memory.user.roles.find(o => o.type == PROJECT_CONSTRACT) ? [3, 2, 1, 4] : [3, 2, 1];

    handleTypeChange = value => {
        this.setState({
            contract_type: value,
        })
        this.getContractID({ contract_type: value, contract_stype: this.formRef.current.getFieldValue('contract_stype') })
    };

    /* 删除已上传文件 */
    handleRemoveFile = () => {
        this.setState({
            fileLoading: false,
        })
    }
    /*上传相关*/
    handleChange = info => {
        let fileList = [...info.fileList];
        if (info.file.status === 'uploading') {
            this.setState({
                fileLoading: true,
            })
        }
        if (info.file.status === 'done') {
            this.setState({
                fileLoading: false,
            })
        }
        // 2. Read from response and show file link
        fileList = fileList.map(file => {
            if (file.response) {
                // Component will show file.url as link
                file.url = file.response.url;
            }
            file = {
                uid: file.uid,
                name: file.name,
                status: file.status,
                url: file.url
            };
            return file;
        });

        this.setState({ fileList });
    };

    handlePreview = async (file) => {
        tools.downloadFile(this.props.history, file, `${memory.user.dd_info.name} ${moment().format("YYYY/MM/DD")}`);
    }

    validate = async (values) => {
        if (this.state.fileLoading) {
            message.error('提交失败，请先等待合同文件上传完毕');
            return;
        }
        this.setState({ submitLoading: true })
        const { fileList } = this.state;

        if (this.id == null) {
            await this.getContractID()
            let val = this.formRef.current.getFieldsValue(['contract_number'])
            if (values.contract_number !== val.contract_number) {
                message.warning('合同编号有变更，请确认后重新点击提交按钮', [5])
                this.setState({ submitLoading: false })
                return;
            }
        }
        let response;
        values.sign_date = new Date(values.sign_date._d); //签署日期
        values.contract_startdate = new Date(values.contract_time[0]._d); //开始时间
        values.contract_enddate = new Date(values.contract_time[1]._d); //结束时间
        delete values.contract_time;
        values.contract_file = [];
        if (fileList.length) {
            values.contract_file = fileList;
        } else {
            message.warning('请上传合同文件');
            this.setState({ submitLoading: false })
            return;
        }
        if (values.vendor === "no") {
            delete values.vendor;
        }
        if (this.id) {
            values.aid = this.id;
            delete values['contract_type']
            delete values['contract_stype']
            delete values['contract_number']
            delete values['is_customer_launch']
            response = await reqContractApplyEdit(values);
        } else {
            if (!values.remarks) {
                delete values.remarks;
            }
            //values.customer = this.cid;
            values.businessOpportunitySchema_id = this.bid;
            response = await reqContractApply(values);
        }

        if (response && response.data) {
            if (this.id) {
                message.success('修改成功');
            } else {
                message.success('申请成功');
            }
            this.formRef.current.resetFields()
            this.setState({ fileList: [], submitLoading: false })
        } else {
            this.setState({ submitLoading: false })
        }
    }

    getBusinessDetail = async (params = {}) => {
        const response = await reqBusinessDetail(params);
        if (response && response.data) {
            this.cid = response.data.customer;
        }
    };

    getContractDetail = async (params = {}) => {
        const response = await reqApplyDetail(params);
        if (response?.data) {
            if (PROCUREMENT_CONTRACT.indexOf(response.data.data.contract_type) > -1) {
                if (response.data.data?.vendor?.id) {
                    const vendorDetail = this.state.vendorList.find(o => o._id === response.data.data.vendor.id);
                    if (vendorDetail) {
                        this.setState({
                            vendorDetail
                        })
                    }
                } else {
                    this.formRef.current.setFieldsValue({ vendor: "no" });
                }
            }
            this.setState({
                detail: response.data.data,
                fileList: response.data.data.contract_file || [],
                contract_type: response.data.data.contract_type,
            });
        }
    };

    handleVendorChange = (value) => {
        if (value && value !== "no") {
            const vendorDetail = this.state.vendorList.find(o => o._id === value);
            if (vendorDetail) {
                this.setState({
                    vendorDetail: vendorDetail,
                })
            }
        } else {
            this.setState({
                vendorDetail: {},
            })
        }
    }

    getContractID = async (values = { contract_type: this.formRef.current.getFieldValue('contract_type'), contract_stype: this.formRef.current.getFieldValue('contract_stype') }) => {
        const {setFieldsValue} = this.formRef.current;
        if (values.contract_type && values.contract_stype) {
            const response = await reqContractNumber(values);
            if (response && response.data) {
                setFieldsValue({ contract_number: response.data })
            }
        } else {
            setFieldsValue({ contract_number: null })
        }
    }

    getVendorList = async (params = {}, callback) => {
        const response = await reqVendorList(params);
        if (response?.data) {
            this.setState({
                vendorList: response.data.msg.data,
            })
        }
        callback && callback();
    }

    componentDidMount() {
        this.getVendorList({
            page: 1,
            node: 9999,
        }, () => {
            if (this.id) {
                // 获取合同详情
                this.getContractDetail({
                    id: this.id
                });
            }
        });
        // 获取项目详情
        if (this.bid) {
            this.getBusinessDetail({
                id: this.bid
            });
        }
    }

    render() {
        const { detail, vendorDetail } = this.state;

        let headers = {};
        const auth = storage.getStore(AUTH);
        if (auth) {
            headers = {
                authorization: 'Bearer ' + auth
            };
        }

        const props = {
            action: '/api/files/ue?action=uploadfile',
            headers: headers,
            onChange: this.handleChange,
            onRemove: this.handleRemoveFile,
            multiple: true,
            accept: '.doc,.docx,.xlsx,.xls',
            onPreview: this.handlePreview,
        };

        return (
            <Form onFinish={this.validate} ref={this.formRef} layout="vertical">
                <Card
                    title={
                        this.id ? "修改合同信息" : "合同申请"
                    }
                    bordered={false}
                >
                    <Card
                        bordered={false}
                        type="inner"
                    >
                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="合同名称"
                                    name="contract_name"
                                    initialValue={detail.contract_name || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入合同名称',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入合同名称" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label={
                                        <span>是否是标准合同<Remarks title='标准合同审批速度更快' /></span>
                                    }
                                    name="specifications"
                                    initialValue={detail.specifications || undefined}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择是否是标准合同',
                                        },
                                    ]}
                                >
                                    <Select placeholder="请选择是否是标准合同">
                                        {CONTRACT_STANDARD.map((item, index) => (
                                            <Option
                                                key={index}
                                                value={item.key}
                                                onClick={() => {
                                                    if (item.key == 1) {
                                                        Modal.confirm({
                                                            content: '请确认是否是标准合同？（标准合同审批速度比非标准合同快）',
                                                            okText: '确认',
                                                            cancelText: '取消',
                                                        })
                                                    }
                                                }}
                                            >
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="合同类型"
                                    name="contract_type"
                                    initialValue={detail.contract_type !== undefined ? detail.contract_type : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择合同类型',
                                        },
                                    ]}
                                >
                                    <Select placeholder="请选择合同类型" onChange={this.handleTypeChange} disabled={this.id ? true : false}>
                                        {CONTRACT_HEAD_TYPE_MOA.map((item, index) => (
                                            <Option key={index} value={item.key}>
                                                {item.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="合同性质"
                                    name="contract_stype"
                                    initialValue={detail.contract_stype !== undefined ? detail.contract_stype : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择合同性质',
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder="请选择合同性质"
                                        onChange={(value) => this.getContractID({ contract_type: this.formRef.current.getFieldValue('contract_type'), contract_stype: value })}
                                        disabled={this.id ? true : false}
                                    >
                                        {this.CONTRACT_SUB_TYPE_ARR.map((item, index) => (
                                            <Option key={item} value={item}>
                                                {CONTRACT_SUB_TYPE_OBJ[item].text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label=
                                    {
                                        <span>合同编号
                                            <Tooltip title="根据合同类型自动生成">
                                                <QuestionCircleOutlined style={{ fontSize: 13.5, color: 'gray' }} />
                                            </Tooltip>
                                        </span>
                                    }
                                    name="contract_number"
                                    initialValue={detail.contract_number || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入合同编号',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请完善合同类型" disabled />
                                </Form.Item>
                            </Col>
                            {
                                this.state.contract_type && PROCUREMENT_CONTRACT.indexOf(this.state.contract_type) > -1 ?
                                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                        <Form.Item
                                            label="供应商"
                                            name="vendor"
                                            initialValue={detail?.vendor?.id || undefined}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请选择供应商',
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="请选择供应商"
                                                showSearch
                                                optionFilterProp="children"
                                                onChange={this.handleVendorChange}
                                            >
                                                <Select.Option key={"no"} value={"no"}>无</Select.Option>
                                                {this.state.vendorList.map((value) => (
                                                    <Select.Option key={value._id} value={value._id}>{value.name}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    :
                                    null
                            }
                            {
                                vendorDetail?._id ?
                                    <>
                                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                            <Form.Item
                                                label="供应商社会信用码"
                                            >
                                                <Input placeholder="请填写供应商社会信用码" value={vendorDetail.social_credit_code} disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                            <Form.Item
                                                label="供应商地址"
                                            >
                                                <Input placeholder="请填写供应商地址" value={vendorDetail.address} disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                            <Form.Item
                                                label="供应商电话"
                                            >
                                                <Input placeholder="请填写供应商电话" value={vendorDetail.telephone} disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                            <Form.Item
                                                label="供应商开户银行"
                                            >
                                                <Input placeholder="请填写供应商开户银行" value={vendorDetail.bank} disabled />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                            <Form.Item
                                                label="供应商开户银行账号"
                                            >
                                                <Input placeholder="请填写供应商开户银行账号" value={vendorDetail.bank_account} disabled />
                                            </Form.Item>
                                        </Col>
                                    </>
                                    :
                                    null
                            }
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="合同签署日期"
                                    name="sign_date"
                                    initialValue={detail.sign_date ? moment(detail.sign_date) : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择合同签署日期',
                                        },
                                    ]}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="请选择合同签署日期" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="合同金额"
                                    name="contract_amount"
                                    initialValue={detail.contract_amount || 0}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入合同金额',
                                        },
                                    ]}
                                >
                                    <NumberInput
                                        addonAfter="元"
                                        placeholder="请输入合同金额"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="服务起止日期"
                                    name="contract_time"
                                    initialValue={detail.contract_startdate && detail.contract_enddate ? [moment(detail.contract_startdate), moment(detail.contract_enddate)] : []}
                                    rules={[{ type: 'array', required: true, message: '请选择服务起止日期' }]}
                                >
                                    <RangePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item label=
                                    {
                                        <span>合同是由客户先盖章寄出
                                            <Tooltip title="合同是由客户先盖章寄出">
                                                <QuestionCircleOutlined style={{ fontSize: 13.5, color: 'gray' }} />
                                            </Tooltip>
                                        </span>
                                    }
                                    name="is_customer_launch"
                                    initialValue={detail.is_customer_launch}
                                    rules={[{ required: true, message: '合同是由客户先盖章寄出' }]}
                                >
                                    <Select placeholder="合同是由客户先盖章寄出" disabled={this.id ? true : false}>
                                        <Option key='否' value={false}>否</Option>
                                        <Option key='是' value={true}>是</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="合同打印份数"
                                    name="printing_number"
                                    initialValue={detail.printing_number || ''}
                                    rules={[{ required: true, message: '请填写合同打印份数' }]}
                                >
                                    <NumberInput placeholder="合同打印份数" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="寄送信息(姓名、电话、地址)"
                                    name="address"
                                    initialValue={detail.address || ''}
                                    rules={[{ required: true, message: '请填写寄送信息' }]}
                                >
                                    <Input placeholder="寄送信息" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="盖章说明"
                                    name="seal_explain"
                                    initialValue={detail.seal_explain || ''}
                                    rules={[{ required: true, message: '请填写盖章说明' }]}
                                >
                                    <Input placeholder="盖章说明" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="备注"
                                    name="remarks"
                                    initialValue={detail.remarks || ''}
                                >
                                    <Input placeholder="备注" />
                                </Form.Item>
                            </Col>

                        </Row>

                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item
                                    label="合同说明"
                                    name="contract_survey"
                                    initialValue={detail.contract_survey || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请填写合同说明',
                                        },
                                    ]}
                                >
                                    <TextArea rows={4} placeholder="请填写合同说明" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item label="上传合同原件" required>
                                    <Upload {...props} fileList={this.state.fileList}>
                                        <Button>
                                            <UploadOutlined /> 上传
                                        </Button>
                                    </Upload>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Card>

                <div className="submit-footer">
                    <Button type="primary" loading={this.state.submitLoading} htmlType="submit">
                        {
                            this.id ? '保存' : '提交'
                        }
                    </Button>
                </div>
            </Form>

        );
    }
}

export default ContractApply;