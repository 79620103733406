import React from "react";
import {
    Card,
    Col,
    Row,
    Select,
    Spin,
    Modal,
    Button
} from "antd";
import { Radar } from '@ant-design/charts';

import './index.less';
import LinkButton from "../../../../crm/components/link-button";
import {moaApi} from "../../../../../api";
import memory from "../../../../../utils/memory";
import sellerImg from "../../../assets/seller.png";
import serviceImg from "../../../assets/service.png";

const { Option } = Select;
const {
    reqAbilityList
} = moaApi;

const config = {
    angleField: 'item',
    radiusField: 'score',
    radiusAxis: {
        grid: {
            alternateColor: ['rgba(0, 0, 0, 0.04)', null],
            line: {
                type: 'line',
            },
        },
        max: 5,
        label: {
            visible: false,
        },
    },
    angleAxis: {
        label: {
            offsetY: 16,
            autoRotate: true,
        },
    },
    height: 160,
    /*line: {
        style: {
            stroke: 'rgb(255,185,0)'
        }
    },*/
    /*meta: {
        item: {
            formatter: (v1, v2) => {
                return `${v1}个,${v2}分`
            }
        }
    }*/
    label: {
        visible: true,
        offsetY: 6,
    }
};

export default class AbilityList extends React.Component {
    state = {
        data: [],
        loading: false,
        s_level: '-',
        a_level: '-',
        b_level: '-',

        visible: false
    };
    type = 1;
    departmentId = {
        1: '98357337', // 商务部
        2: '104596501', // 安服部
    };
    departmentTips = {
        1: '销售力', // 商务部
        2: '安服力', // 安服部
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };
    handleOk = e => {
        this.setState({
            visible: false,
        });
    };
    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    handleDepartmentChange = (value) => {
        if(this.departmentId[value]) {
            this.type = value;
            this.getAbilityList({
                department: this.departmentId[this.type]
            });
        }
    };

    getAbilityList = async (params) => {
        this.setState({
            loading: true
        });
        const response = await reqAbilityList(params);
        if(response && response.data) {
            let s = 0,
                a = 0,
                b = 0;
            for(let i = 0; i < response.data.length; i++) {
                const item = response.data[i];
                if(item.total_score > 15) {
                    s++;
                }else if(item.total_score > 10) {
                    a++;
                }else if(item.total_score >= 6) {
                    b++;
                }
            }
            this.setState({
                data: response.data || [],
                loading: false,
                s_level: s,
                a_level: a,
                b_level: b,
            });
        }
    };

    componentDidMount() {
        this.getAbilityList({
            department: this.departmentId[this.type]
        });
    }

    render() {
        const { data } = this.state;
        const urlType = this.props.location.pathname.split('/')[1];
        let editPath = '';
        if(urlType === 'manage') {
            editPath = `/manage/ability/edit/${this.departmentId[this.type]}`;
        }else {
            editPath = `/moa/admin/ability/edit/${this.departmentId[this.type]}`;
        }

        return this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <Card
                title="点将台"
                bordered={false}
                className="ability"
                extra={
                    <LinkButton to={editPath} txt="编辑" />
                }
            >
                <div className="ability-operation">
                    <Select className="ability-operation-select" defaultValue={this.type} onChange={this.handleDepartmentChange}>
                        <Option value={1}>商务部</Option>
                        <Option value={2}>安服部</Option>
                    </Select>

                    <div className="ability-tips">
                        <a onClick={this.showModal}>
                            {`${this.departmentTips[this.type]} 说明`}
                        </a>
                        <Modal
                            title={`${this.departmentTips[this.type]} 说明`}
                            visible={this.state.visible}
                            width="80%"
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            footer={[
                                <Button key="submit" type="primary" onClick={this.handleOk}>确定</Button>,
                            ]}
                        >
                            <div className="ability-tips-container">
                                <img src={this.type === 1 ? sellerImg : serviceImg} className="ability-tips-img" />
                            </div>
                        </Modal>
                    </div>
                </div>

                <div className="ability-header">
                    <h3 className="title">{`${memory.department_obj[this.departmentId[this.type]]}点将台`}</h3>
                    <div className="desc">销售能力的五个维度：价值观指数、资源指数、搞关系能力、绕阻抗能力、专业能力</div>
                    <div className="level-count">
                        <div className="level-count-item">
                            <div className="level-block s-level">S级</div>
                            <div className="level-num">{`${this.state.s_level}个`}</div>
                        </div>
                        <div className="level-count-item">
                            <div className="level-block a-level">A级</div>
                            <div className="level-num">{`${this.state.a_level}个`}</div>
                        </div>
                        <div className="level-count-item">
                            <div className="level-block b-level">B级</div>
                            <div className="level-num">{`${this.state.b_level}个`}</div>
                        </div>
                    </div>
                </div>

                <div className="ability-label">
                    <div className="level-label-item">
                        <div className="level-block s-level">S级</div>
                        <div className="level-num">15-25 不包含15</div>
                    </div>
                    <div className="level-label-item">
                        <div className="level-block a-level">A级</div>
                        <div className="level-num">10-15 不包含10</div>
                    </div>
                    <div className="level-label-item">
                        <div className="level-block b-level">B级</div>
                        <div className="level-num">6-10</div>
                    </div>
                </div>

                <div className="ability-container">
                    <Row gutter={16}>
                        {
                            data.map((item, index) => {
                                const totalScore = item.total_score || 0;
                                let numClass = '';
                                let radarColor = '';
                                if(totalScore > 15) {
                                    numClass = 's-num';
                                    radarColor = '#FFC101';
                                }else if(totalScore > 10) {
                                    numClass = 'a-num';
                                    radarColor = '#70AD46';
                                }else if(totalScore >= 6) {
                                    numClass = 'b-num';
                                    radarColor = '#ED7B30';
                                }
                                if(radarColor) {
                                    config.color = radarColor;
                                }
                                return (
                                    <Col lg={4} md={8} sm={12} xs={24} key={index}>
                                        <div className="ability-card">
                                            <div className="ability-card-title">
                                                <span className="name">{item.uname || '-'}</span>
                                                <span className={`num ${numClass}`}>{`${totalScore}分`}</span>
                                            </div>
                                            <Radar {...config} data={item.ability} />
                                        </div>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                </div>
            </Card>
        );
    }
}