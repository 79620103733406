import React from "react";
import {
    Card,
    Table,
} from "antd";
import {Link} from "react-router-dom";

import LinkButton from "../../../../crm/components/link-button";
import memory from "../../../../../utils/memory";
import {moaApi} from "../../../../../api";
import tools from "../../../../../utils/tools";
import {
    MOBILE_OWNER
} from "../../../../../utils/constant";

const {
    reqMobileList
} = moaApi;

export default class MobileList extends React.Component {
    state = {
        data: [],
        pagination: {},
        loading: false,
    };
    columns = [
        {
            title: '开户时间',
            dataIndex: 'open_account_time',
            key: 'open_account_time'
        },
        {
            title: '手机号码',
            dataIndex: 'bound_mobile',
            key: 'bound_mobile'
        },
        {
            title: '绑定人',
            dataIndex: 'bound_person',
            key: 'bound_person'
        },
        {
            title: '手机归属',
            dataIndex: 'bound_mobile_owner_type',
            key: 'bound_mobile_owner_type'
        },
        {
            title: '手机保管人',
            dataIndex: 'bound_mobile_custodian',
            key: 'bound_mobile_custodian'
        },
        {
            title: '操作',
            dataIndex: 'option',
            valueType: 'option',
            render: (_, record) => {
                return (
                    <Link to={`/moa/account/mobile/detail/${record.key}`}>详情</Link>
                );
            }
        },
    ];

    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        });
        let tmpFilters = {...filters};
        for(let item in tmpFilters) {
            if(tmpFilters[item]) {
                if(typeof(tmpFilters[item]) !== 'string' && item !== 'tags') {
                    tmpFilters[item] = tmpFilters[item][0];
                }
            }
        }
        let params = {
            page: pagination.current,
            ...tmpFilters,
        };
        this.getMobileList(params);
    }

    getMobileList = async (params = {}) => {
        this.setState({
            loading: true
        });
        const response = await reqMobileList(params);
        const pagination = {...this.state.pagination};
        const data = response.data;
        pagination.total = data.page.total;
        pagination.pageSize = data.page.node;
        let newData = [];
        for(let i = 0; i < data.data.length; i++) {
            const item = data.data[i];
            const obj = {
                key: item._id,
                open_account_time: tools.unixToTime((new Date(item.open_account_time)).getTime()/1000, true),
                bound_mobile: <Link to={`/moa/account/mobile/detail/${item._id}`}>{item.bound_mobile}</Link>,
                bound_person: item.bound_person.name || '-',
                bound_mobile_owner_type: MOBILE_OWNER[item.bound_mobile_owner_type-1].text,
                bound_mobile_custodian: item.bound_mobile_custodian.name || '-',
                create_time: tools.unixToTime((new Date(item.create_at)).getTime()/1000),
            };
            newData.push(obj);
        }
        this.setState({
            loading: false,
            data: newData,
            pagination
        });
    };

    componentDidMount() {
        this.getMobileList({
            page: 1,
        });
    }

    render() {
        return (
            <Card
                title="手机号列表"
                bordered={false}
                extra={<LinkButton to="/moa/account/mobile/add" txt="添加手机号" />}
            >
                <Table
                    columns={this.columns}
                    dataSource={this.state.data}
                    pagination={this.state.pagination}
                    loading={this.state.loading}
                    onChange={this.handleTableChange}
                    scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                    size={memory.is_mobile ? 'small' : 'middle'}
                />
            </Card>
        );
    }
}