import React from "react";
import {
    Spin,
    Form,
    Card,
    Row,
    Col,
    Input,
    Select,
    Button,
    message,
    Popconfirm,
    Cascader,
} from "antd";

import { moaApi } from "../../../../../../api";
import {
    PLATFORMQUALITY
} from "../../../../../../utils/constant";
import areaData from "../../../../../../assets/area_options";

const { Option } = Select;
const { TextArea } = Input;
const {
    reqPlatformAdd,
    reqPlatformDetail,
    reqPlatformEdit,
    reqPlatformDel
} = moaApi;

class AwardsPlatformAdd extends React.Component {
    state = {
        loading: true,
        detail: {}
    };
    id = this.props.match.params.id;

    validate = async (values) => {
        let response;
        if (!this.id) {
            for (let i in values) {
                if (!values[i]) {
                    delete values[i];
                }
            }
        }
        if (this.id) {
            values.id = this.id;
            response = await reqPlatformEdit(values);
            if (response && response.data) {
                message.success('修改成功');
                this.props.history.goBack();
            }
        } else {
            response = await reqPlatformAdd(values);
            if (response && response.data) {
                message.success('添加成功');
            }
        }
    };

    delPlatform = async () => {
        const response = await reqPlatformDel({
            id: this.id
        });
        if (response && response.data) {
            message.success('删除成功');
            this.props.history.replace('/moa/awards/platform/list');
        }
    };

    getPlatformDetail = async (params = {}) => {
        const response = await reqPlatformDetail(params);
        if (response && response.data) {
            this.setState({
                detail: response.data,
                loading: false
            });
        }
    };
    componentDidMount() {
        if (this.id) {
            // 获取详情
            this.getPlatformDetail({
                id: this.id
            });
        }
    }

    render() {
        const { detail } = this.state;

        return this.id && this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <Form onFinish={this.validate} layout="vertical">
                <Card
                    title={
                        this.id ? "修改奖项平台信息" : "添加奖项平台"
                    }
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                    extra={
                        this.id ? (
                            <Popconfirm
                                title="确定删除该奖项平台信息吗?"
                                onConfirm={this.delPlatform}
                                okText="确定"
                                cancelText="取消"
                            >
                                <Button>删除</Button>
                            </Popconfirm>
                        ) : null
                    }
                >
                    <Card
                        title="奖项平台基本信息"
                        bordered={false}
                        type="inner"
                    >
                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="名称"
                                    name="name"
                                    initialValue={detail.name || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入奖项平台名称',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入奖项平台名称" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="官网"
                                    name="official_website"
                                    initialValue={detail.official_website || ''}
                                    rules={[
                                        {
                                            type: 'url',
                                            message: '请输入完整官网'
                                        }
                                    ]}
                                >
                                    <Input placeholder="请输入奖项平台官网" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="平台质量"
                                    name="level"
                                    initialValue={detail.level || null}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择平台质量',
                                        },
                                    ]}
                                >
                                    <Select placeholder="请选择平台质量">
                                        {
                                            PLATFORMQUALITY.map((item, index) => (
                                                <Option key={item.value} value={item.value}>
                                                    {item.text}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="所在地区"
                                    name="place"
                                    initialValue={detail.place}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择所在地区'
                                        }
                                    ]}
                                >
                                    <Cascader options={areaData} placeholder="请选择所在地区" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item
                                    label="简介"
                                    name="brief"
                                    initialValue={detail.brief || ''}
                                >
                                    <TextArea rows={4} placeholder="请填写简介" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Card>

                <div className="submit-footer">
                    <Button type="primary" htmlType="submit">
                        {
                            this.id ? '保存' : '提交'
                        }
                    </Button>
                </div>
            </Form>
        );
    }
}

export default AwardsPlatformAdd;