import React from "react";
import {
    Button,
    Card,
    Input,
    Table,
} from "antd";
import {
    Link,
} from "react-router-dom";
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';

import {crmApi} from "../../../../../api";
import memory from "../../../../../utils/memory";
import tools from "../../../../../utils/tools";
import {
    BUSINESSNATURE,
    BUSINESSTYPE_OBJ,
    BUSINESSSCHEDULE_OBJ,
} from "../../../../../utils/constant";

const {
    reqBusinessList
} = crmApi;

export default class BusinessList extends React.Component {
    state = {
        business: [],
        pagination: {},
        loading: false,
    };
    startDate = moment().year(moment().year()).startOf('year').valueOf();
    endDate = moment().valueOf();

    handleTableChange = (pagination, filters, sorter) => {
        const pager = {...this.state.pagination};
        pager.current = pagination.current;
        this.setState({
            pagination: pager
        });
        let tmpFilters = {...filters};
        for(let item in tmpFilters) {
            if(tmpFilters[item]) {
                if(typeof(tmpFilters[item]) !== 'string' && item !== 'tags') {
                    tmpFilters[item] = tmpFilters[item][0];
                }
            }
        }
        let params = {
            page: pagination.current,
            ...tmpFilters,
            stage: 6,
            startdate: this.startDate,
            enddate: this.endDate
        };
        this.getBusinessList(params);
    }

    /*
    *搜索相关
    * */
    getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`搜索 ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    搜索
                </Button>
                <Button onClick={() => this.handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
                    重置
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text => text
    });
    handleSearch = (selectedKeys, confirm) => {
        confirm();
    };
    handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
    };

    businessColumns = [
        {
            title: '项目名称',
            dataIndex: 'project_name',
            key: 'project_name',
            ...this.getColumnSearchProps('project_name', '项目名称')
        },
        {
            title: '所属客户',
            dataIndex: 'cname',
            key: 'cname',
            ...this.getColumnSearchProps('cname', '所属客户')
        },
        {
            title: '项目分类',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: '项目性质',
            dataIndex: 'nature',
            key: 'nature',
        },
        {
            title: '合同金额',
            dataIndex: 'money',
            key: 'money',
        },
        {
            title: '跟进销售',
            dataIndex: 'uname',
            key: 'uname',
            ...this.getColumnSearchProps('uname', '跟进销售')
        },
        {
            title: '项目进度',
            dataIndex: 'stage',
            key: 'stage',
            render: (text, record) => {
                if(text) {
                    if(text === 1) {
                        return '创建项目';
                    }else if(BUSINESSSCHEDULE_OBJ[text]) {
                        return BUSINESSSCHEDULE_OBJ[text];
                    }else {
                        return '-';
                    }
                } else {
                    return '-';
                }
            },
        },
        {
            title: '创建时间',
            dataIndex: 'create_time',
            key: 'create_time',
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record) => {
                return (
                    <Link to={`/crm/client/business-detail/${record.key}`}>详情</Link>
                );
            },
        }
    ];

    getBusinessList = async (params = {}) => {
        this.setState({
            loading: true
        });
        const response = await reqBusinessList(params);
        const pagination = {...this.state.pagination};
        const data = response.data;
        pagination.total = data.page.total;
        pagination.pageSize = data.page.node;
        if(response && response.data) {
            const data = response.data;
            const newData = [];
            for(let i = 0; i < data.data.length; i++) {
                const item = data.data[i];
                let obj = {
                    key: item._id,
                    project_name: <Link to={`/crm/client/business-detail/${item._id}`}>{item.project_name}</Link>,
                    cname: item.customer_name ? <Link to={`/crm/client/detail/${item.customer}`}>{item.customer_name}</Link> : '-',
                    nature: BUSINESSNATURE[item.project_type-1].text,
                    money: item.contract_amount ? tools.formatNumber(item.contract_amount)+'元' : '-',
                    uname: item.creator.name,
                    stage: item.stage || 0,
                    create_time: tools.unixToTime((new Date(item.create_at)).getTime()/1000)
                };
                let tmp = [];
                for(let j = 0; j < item.business_type.length; j++) {
                    tmp.push(BUSINESSTYPE_OBJ[item.business_type[j]]);
                }
                if(tmp.length) {
                    obj.type = tmp.join('，');
                }else {
                    obj.type = '-';
                }
                newData.push(obj);
            }
            this.setState({
                loading: false,
                business: newData,
                pagination
            });
        }
    };

    componentDidMount() {
        this.getBusinessList({
            page: 1,
            stage: 6,
            startdate: this.startDate,
            enddate: this.endDate
        });
    }

    render() {
        return (
            <>
                <Button
                    type="link"
                    icon="left"
                    onClick={() => {
                        this.props.history.goBack();
                    }}
                >返回</Button>
                
                <Card
                    title="本年度赢单项目列表"
                    bordered={false}
                >
                    <Table
                        columns={this.businessColumns}
                        dataSource={this.state.business}
                        pagination={this.state.pagination}
                        loading={this.state.loading}
                        onChange={this.handleTableChange}
                        scroll={{ x: 'max-content' }} // 加上这条  横向滚动  支持此属性的浏览器内容就不会换行了
                        size={memory.is_mobile ? 'small' : 'middle'}
                    />
                </Card>
            </>
        );
    }
}