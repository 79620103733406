import React from "react";
import { StackedColumn } from "@ant-design/charts"
import { crmApi } from "../../../../api"
import { Card } from "antd";

const { reqIntroduceAll, reqIntroduceToday } = crmApi;

const config = {
    title: {
        visible: false,
    },
    forceFit: true,
    padding: 'auto',
    xField: 'name',
    yField: 'extension_frequency',
    yAxis: { min: 0 },
    color: ['#ae331b', '#1a6179'],
    stackField: 'type',
    xAxis: {
        title: {
            text: "产品名称",
        },
        label: {
            formatter: (text) => {
                if (typeof (text) === "string" && text?.length > 4) {
                    return text.slice(0, 3) + "..."
                }
                return text;
            }
        }
    },
    yAxis: { title: { text: "数量" } }
};

export default class IntroduceChart extends React.Component {

    state = {
        data: [],
    }

    getAllData = async (params = {}, callback) => {
        const response = await reqIntroduceAll(params);
        if (response?.data?.msg) {
            this.allData = response.data.msg;
            if (callback) callback();
        }
    }

    getTodayData = async (params = {}, callback) => {
        const response = await reqIntroduceToday(params);
        if (response?.data?.msg) {
            this.todayData = response.data.msg;
            if (callback) callback();
        }
    }

    getData = () => {
        this.getAllData({}, this.handleData);
        this.getTodayData({}, this.handleData);
    }

    handleData = () => {
        if (this.allData && this.todayData) {
            let arr = [];
            for (let i in this.allData) {
                this.allData[i].extension_frequency = this.allData[i].extension_frequency - (this.todayData[i]?.extension_frequency || 0);
                this.allData[i].type = "往日";
                this.todayData[i].type = "今天";
                arr.push(this.todayData[i]);
                arr.push(this.allData[i]);
            }
            this.setState({
                data: arr,
            })
        }
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        return (
            <Card
                title="产品介绍量"
                style={{
                    marginBottom: 16,
                }}
                bordered={false}
            >
                <StackedColumn {...config} data={this.state.data} />
            </Card>
        )
    }
}