import React from "react";
import {
    Spin,
    Card,
    Form,
    Row,
    Col,
    Input,
    Select,
    Button,
    message,
    Popconfirm,
    Cascader
} from "antd";

import { moaApi } from "../../../../../api";
import {
    CAPITALLEVEL,
    CAPITALCLASS,
    SOURCE_TYPE,
    DECLAREUNIT,
} from "../../../../../utils/constant";
import NumberInput from "../../../../crm/components/number-input";

const { Option } = Select;
const { TextArea } = Input;
const {
    reqFundAdd,
    reqUserList,
    reqFundDetail,
    reqFundEdit,
    reqFundDel,
    reqAgencyList,
    reqResponseList
} = moaApi;

class CapitalAdd extends React.Component {
    state = {
        loading: true,
        detail: {},
        agency: [],
        response: [],
    };
    id = this.props.match.params.id;
    yearOptions = [];
    userOptions = [];
    provideOptions = [
        {
            value: 1,
            label: '人员',
            children: []
        },
        {
            value: 2,
            label: '代理机构',
            children: []
        }
    ];

    validate = async (values) => {
        let response;
        if (!this.id) {
            for (let i in values) {
                if (!values[i]) {
                    delete values[i];
                }
            }
        }
        values.fund_date = values.fund_date.toString();
        if (values.clue_provider && values.clue_provider.length) {
            values.clue_provider = {
                type: values.clue_provider[0],
                id: values.clue_provider[1]
            };
        }
        if (this.id) {
            values.id = this.id;
            response = await reqFundEdit(values);
            if (response && response.data) {
                message.success('修改成功');
                this.props.history.goBack();
            }
        } else {
            response = await reqFundAdd(values);
            if (response && response.data) {
                message.success('添加成功');
            }
        }
    };

    getUserList = async (params = {}) => {
        const resposne = await reqUserList(params);
        if (resposne && resposne.data) {
            const data = resposne.data;
            for (let i = 0; i < data.data.length; i++) {
                this.userOptions.push({
                    value: data.data[i]._id,
                    text: data.data[i].dd_info.name
                });
                this.provideOptions[0].children.push({
                    value: data.data[i]._id,
                    label: data.data[i].dd_info.name,
                });
            }
            this.setState({
                loading: false,
            });
        }
    };

    getCapitalDetail = async (params = {}) => {
        const response = await reqFundDetail(params);
        if (response && response.data) {
            this.setState({
                detail: response.data
            });
        }
    };

    delCapital = async (params = {}) => {
        const response = await reqFundDel({
            id: this.id
        });
        if (response && response.data) {
            message.success('删除成功');
            this.props.history.replace('/moa/capital/list');
        }
    };

    getAgencyList = async (params = {}) => {
        const response = await reqAgencyList(params);
        const data = response.data;
        let newData = [];
        for (let i = 0; i < data.data.length; i++) {
            const item = data.data[i];
            const obj = {
                key: item._id,
                value: item._id,
                text: item.name,
            };
            newData.push(obj);
            this.provideOptions[1].children.push({
                value: item._id,
                label: item.name,
            });
        }
        this.setState({
            agency: newData
        });
    };

    getResonseList = async (params = {}) => {
        const response = await reqResponseList(params);
        const data = response.data;
        let newData = [];
        for (let i = 0; i < data.data.length; i++) {
            const item = data.data[i];
            const obj = {
                key: item._id,
                value: item._id,
                text: item.name,
            };
            newData.push(obj);
        }
        this.setState({
            response: newData
        });
    };

    componentWillMount() {
        for (let i = 2020; i <= (new Date()).getFullYear() + 10; i++) {
            this.yearOptions.push({
                key: i,
                value: i,
                text: i
            });
        }
    }

    componentDidMount = () => {
        if (this.id) {
            // 获取详情
            this.getCapitalDetail({
                id: this.id
            });
        }
        this.getUserList({
            node: 999
        });
        this.getAgencyList({
            node: 999,
            type: 1,
        });
        this.getResonseList({
            node: 999
        });
    }

    render() {
        const { detail } = this.state;
        let workers = [];
        let response = [];
        if (detail.team_worker && detail.team_worker.length) {
            for (let j = 0; j < detail.team_worker.length; j++) {
                workers.push(detail.team_worker[j].id);
            }
        }
        if (detail.response_unit && detail.response_unit.length) {
            for (let j = 0; j < detail.response_unit.length; j++) {
                response.push(detail.response_unit[j].id);
            }
        }

        return this.id && this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <Form onFinish={this.validate}>
                <Button
                    type="link"
                    icon="left"
                    onClick={() => {
                        this.props.history.goBack();
                    }}
                >返回</Button>

                <Card
                    title={
                        this.id ? "修改资金信息" : "添加资金"
                    }
                    style={{
                        marginBottom: 24,
                    }}
                    bordered={false}
                    extra={
                        this.id ? (
                            <Popconfirm
                                title="确定删除该资金信息吗?"
                                onConfirm={this.delCapital}
                                okText="确定"
                                cancelText="取消"
                            >
                                <Button>删除</Button>
                            </Popconfirm>
                        ) : null
                    }
                >
                    <Card
                        title="资金基本信息"
                        bordered={false}
                        type="inner"
                    >
                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="资金申报年份"
                                    name="fund_date"
                                    initialValue={detail.fund_date ? detail.fund_date : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择资金申报年份',
                                        },
                                    ]}
                                >
                                    <Select placeholder="请选择资金申报年份">
                                        {
                                            this.yearOptions.map((item, index) => (
                                                <Option key={item.key} value={item.value}>
                                                    {item.text}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="资金名称"
                                    name="fund_name"
                                    initialValue={detail.fund_name || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入资金名称',
                                        },
                                    ]}
                                >
                                    <Input placeholder="请输入资金名称" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="负责单位"
                                    name="response_unit"
                                    initialValue={response.length ? response : undefined}
                                >
                                    <Select
                                        mode="multiple"
                                        placeholder="请选择负责单位"
                                    >
                                        {
                                            this.state.response.map((item, index) => (
                                                <Option key={item.key} value={item.value}>
                                                    {item.text}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="资金信息链接"
                                    name="official_website"
                                    initialValue={detail.official_website || ''}
                                    rules={[
                                        {
                                            type: 'url',
                                            message: '请输入完整链接'
                                        }
                                    ]}
                                >
                                    <Input placeholder="请输入资金信息链接" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="资金量级"
                                    name="fund_grade"
                                    initialValue={detail.fund_grade ? detail.fund_grade : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择资金量级',
                                        },
                                    ]}
                                >
                                    <Select placeholder="请选择资金量级">
                                        {
                                            CAPITALLEVEL.map((item, index) => (
                                                <Option key={item.value} value={item.value}>
                                                    {item.text}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="资金类别"
                                    name="fund_class"
                                    initialValue={detail.fund_class ? detail.fund_class : undefined}
                                >
                                    <Select placeholder="请选择资金类别">
                                        {
                                            CAPITALCLASS.map((item, index) => (
                                                <Option key={item.value} value={item.value}>
                                                    {item.text}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="申报单位"
                                    name="declare_unit"
                                    initialValue={detail.declare_unit ? detail.declare_unit : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入申报单位',
                                        },
                                    ]}
                                >
                                    <Select placeholder="请输入申报单位">
                                        {
                                            DECLAREUNIT.map((item, index) => (
                                                <Option key={item.value} value={item.value}>
                                                    {item.text}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="项目经理"
                                    name="manager"
                                    initialValue={detail.manager && detail.manager.id ? detail.manager.id : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择项目经理',
                                        },
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="请选择项目经理"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            this.userOptions.map((item, index) => (
                                                <Option key={item.value} value={item.value}>
                                                    {item.text}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="协作者"
                                    name="team_worker"
                                    initialValue={workers.length ? workers : undefined}
                                >
                                    <Select
                                        showSearch
                                        placeholder="请选择协作者"
                                        mode="multiple"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {
                                            this.userOptions.map((item, index) => (
                                                <Option key={item.value} value={item.value}>
                                                    {item.text}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="线索提供人"
                                    name="clue_provider"
                                    initialValue={detail.clue_provider && detail.clue_provider.id ? [detail.clue_provider.type, detail.clue_provider.id] : undefined}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请选择线索提供人',
                                        },
                                    ]}
                                >
                                    <Cascader options={this.provideOptions} placeholder="请选择线索提供人" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="资金金额"
                                    name="fund_amount"
                                    initialValue={detail.fund_amount || ''}
                                    rules={[
                                        {
                                            required: true,
                                            message: '请输入资金金额',
                                        },
                                    ]}
                                >
                                    <NumberInput
                                        addonAfter="万元"
                                        placeholder="请输入资金金额"
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="项目名称"
                                    name="project_name"
                                    initialValue={detail.project_name || undefined}
                                >
                                    <Input placeholder="请输入项目名称" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="代理机构"
                                    name="agency"
                                    initialValue={detail.agency && detail.agency.id ? detail.agency.id : undefined}
                                >
                                    <Select
                                        placeholder="请选择代理机构"
                                    >
                                        {
                                            this.state.agency.map((item, index) => (
                                                <Option key={item.key} value={item.value}>
                                                    {item.text}
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="备注"
                                    name="remark"
                                    initialValue={detail.remark || ''}
                                >
                                    <Input placeholder="备注" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item
                                    label="资金简介"
                                    name="brief"
                                    initialValue={detail.brief || ''}
                                >
                                    <TextArea rows={4} placeholder="请填写资金简介" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item
                                    label="申报条件"
                                    name="declare_condition"
                                    initialValue={detail.declare_condition || ''}
                                >
                                    <TextArea rows={4} placeholder="请填写申报条件" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item
                                    label="申报步骤"
                                    name="declare_step"
                                    initialValue={detail.declare_step || ''}
                                >
                                    <TextArea rows={4} placeholder="请填写申报步骤" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item
                                    label="验收标准"
                                    name="acceptance_criteria"
                                    initialValue={detail.acceptance_criteria || ''}
                                >
                                    <TextArea rows={4} placeholder="请填写验收标准" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>

                    <Card
                        title="资金信息来源"
                        bordered={false}
                        type="inner"
                    >
                        <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="来源类型"
                                    name="source_type"
                                    initialValue={detail.source_type ? detail.source_type : undefined}
                                >
                                    <Select placeholder="请选择来源类型">
                                        {SOURCE_TYPE.map((item, index) => (
                                            <Option key={index} value={item.key}>
                                                {item.value}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Form.Item
                                    label="来源渠道"
                                    name="source_channel"
                                    initialValue={detail.source_channel ? detail.source_channel : undefined}
                                >
                                    <Input placeholder="请输入来源渠道" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Card>

                <div className="submit-footer">
                    <Button type="primary" htmlType="submit">
                        {
                            this.id ? '保存' : '提交'
                        }
                    </Button>
                </div>
            </Form>
        );
    }
}

export default CapitalAdd;