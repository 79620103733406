import React from "react";
import {Avatar} from "antd";

import './index.less';

export default class Photo extends React.Component {
    render() {
        const {url, size, onClick, style} = this.props;
        return (
            <div className="photo-show" style={style}>
                <Avatar
                    shape="square"
                    size={size}
                    src={url}
                />
                <div className="photo-mask" onClick={onClick}></div>
            </div>
        );
    }
}