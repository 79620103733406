import React from "react";
import {
    Spin,
    Form,
    Card,
    Row,
    Col,
    Input,
    Button,
    message,
    TreeSelect,
    Breadcrumb,
    Layout,
} from "antd";
import { Link } from "react-router-dom";

import { repositoryApi } from "../../../../../api";
import {
    MenuSide
} from "../../../../../utils/constant";
import LinkButton from "../../../../crm/components/link-button";

const { TreeNode } = TreeSelect;
const { Content } = Layout;

const {
    reqAllClassifies,
    reqArticleDetail,
    reqArticleAdd,
    reqArticleEdit,
} = repositoryApi;

class ArticleAdd extends React.Component {
    static contextType = MenuSide;

    state = {
        loading: true,
        detail: {},
        treeData: [],
    };
    id = this.props.match.params.id;

    onFinish = async (values) => {
        let response;
        if (!this.id) {
            for (let i in values) {
                if (!values[i]) {
                    delete values[i];
                }
            }
        }
        if (this.id) {
            values.id = this.id;
            response = await reqArticleEdit(values);
            if (response && response.data) {
                message.success('修改成功');
                this.context();
                this.props.history.goBack();
            }
        } else {
            response = await reqArticleAdd(values);
            if (response && response.data) {
                message.success('添加成功');
                this.context();
            }
        }
    };

    getMenuNodes = (arr, classifyObj) => {
        if (arr) {
            for (let i = 0; i < arr.length; i++) {
                if (classifyObj[arr[i].key]) {
                    arr[i].children = classifyObj[arr[i].key];
                    this.getMenuNodes(classifyObj[arr[i].key], classifyObj);
                }
            }
            return arr;
        }
    }
    /*获取分类列表*/
    getClassifies = async () => {
        const response = await reqAllClassifies();
        if (response && response.data) {
            let classifyObj = {}; //key是上级分类id，value是下级分类数组
            let menus = [];
            for (let i = 0; i < response.data.length; i++) {
                let item = response.data[i];
                if (item.parent_id) {
                    if (!classifyObj[item.parent_id]) {
                        classifyObj[item.parent_id] = [];
                    }
                    classifyObj[item.parent_id].push({
                        key: item._id,
                        value: item._id,
                        title: item.name
                    });
                } else {
                    menus.push({
                        key: item._id,
                        value: item._id,
                        title: item.name
                    });
                }
            }
            const result = this.getMenuNodes(menus, classifyObj);
            this.setState({
                treeData: result
            });
        }
    }

    getArticleDetail = async (params = {}) => {
        const response = await reqArticleDetail(params);
        if (response && response.data) {
            this.setState({
                detail: response.data,
                loading: false
            });
        }
    };

    componentDidMount() {
        this.getClassifies();
        if (this.id) {
            // 获取详情
            this.getArticleDetail({
                id: this.id
            });
        }
    }

    render() {
        const { detail, treeData } = this.state;

        return this.id && this.state.loading ? (
            <div className="loading-container">
                <Spin size="large" />
            </div>
        ) : (
            <Form
                onFinish={this.onFinish}
            >
                <Breadcrumb
                    style={{
                        padding: '24px 24px 0',
                        marginTop: '64px',
                    }}>
                    <Breadcrumb.Item>
                        <Link to="/repository">首页</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {this.id ? "修改文章" : "发布文章"}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Content style={{ margin: '24px 16px 0' }}>
                    <Button
                        type="link"
                        icon="left"
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                    >返回</Button>

                    <Card
                        title={
                            this.id ? "修改文章" : "发布文章"
                        }
                        style={{
                            marginBottom: 24,
                        }}
                        bordered={false}
                        extra={<LinkButton to="/repository/classify/add" icon="plus" txt="添加分类" />}
                    >
                        <Card
                            bordered={false}
                            type="inner"
                        >
                            <Row gutter={{ sm: 0, md: 16, lg: 24 }}>
                                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                    <Form.Item
                                        label="文章所属分类"
                                        name="classify_id"
                                    >
                                        <TreeSelect
                                            allowClear
                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                            placeholder="请选择文章所属分类"
                                            treeData={treeData}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                    <Form.Item
                                        label="标题"
                                        name="title"
                                        initialValue={detail.title || ''}
                                        rules={
                                            [
                                                {
                                                    required: true,
                                                    message: '请输入标题',
                                                },
                                            ]
                                        }
                                    >
                                        <Input placeholder="请输入标题" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Card>

                    <div className="submit-footer">
                        <Button type="primary" htmlType="submit">
                            {
                                this.id ? '保存' : '发布'
                            }
                        </Button>
                    </div>
                </Content>
            </Form>
        );
    }
}

export default ArticleAdd;